import "../VerifyIdentity/index.scss";

interface YotiDigitalIDMessageProps {
    yotiMsg: string;
}

export default function YotiDigitalIDMessage(props: YotiDigitalIDMessageProps){
    return (
        <span className="digital-id-message">{props.yotiMsg}</span>
    );
};
