import React, { useEffect, useState } from "react";
import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import PersonalInfoOptions from "../../utils/MetaDataJsons/PersonalInfoOptions.json";
import _ from "lodash";
import IPersonalDetails from "../../interfaces/IPersonalDetails";
import moment from "moment";
import EditInfoModal from "./EditInfoModal";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import InfoTypes from "../../interfaces/InfoTypes";
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from "@material-ui/core";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IProfile from "../../interfaces/IProfile";
import PreviousName from "./PreviousName";
import {
  GetCountriesData,
  GetNationalitiesData,
  GetTitlesData,
} from "../../utils/referenceDataService";
import Heading from "../../Components/Heading";
import PreviousNationality from "./PreviousNationality";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "../../Components/LoaderModal";
import utilFunctions from "../../utils/util";
import useAPIRequest from "../../Service/useAPIRequest";
import MiddleName from "./MiddleName";
import IValidationFailure from "../../interfaces/IValidationFailure";
import { ImportableTypeEditor, PersonalInfoAllowedAge, RegexEnum } from "../../Constants/enums";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import SelectEditor from "../../Components/CheckEditors/SelectEditor";

interface PersonalInfoProps {
  uiContent?: InfoTypes.ContentDefinition;
  complianceProcessData?: IComplianceProcess;
  isProfileView: boolean;
  isCheckDataEditable: boolean;
  errorHandler?: (msg: string) => void;
  handleSubmit?: (updatedProcessData?: IComplianceProcess, editor?: string) => void;
}

interface PersonalInfoState {
  title: string;
  titleVal?: InfoTypes.SelectValueType;
  gender: string;
  genderVal?: InfoTypes.SelectValueType;
  forename: string;
  middleNames: InfoTypes.MiddleName[];
  surname: string;
  dateOfBirth: Date | null;
  currentNationality: string;
  currentNationalityVal?: InfoTypes.SelectValueType;
  country?: string;
  town?: string;
  county?: string;
  previousNames: PreviousNameState[];
  previousNationalities: PreviousNationalityState[];
}

interface PreviousNameState {
  forename: string;
  surname: string;
  from: string;
  to: string;
}

interface PreviousNationalityState {
  nationality: string;
  nationalityVal?: InfoTypes.SelectValueType;
  from: Date | null;
  to: Date | null;
}

interface PreviousNationalityErrors {
  nationality: string;
  from: string;
  to: string;
}

interface PreviousNameErrors {
  forename: string;
  surname: string;
  from: string;
  to: string;
}

interface PersonalInfoErrors {
  title: string;
  gender: string;
  forename: string;
  surname: string;
  dateOfBirth: string;
  town: string;
  county: string;
  previousNationalities?: PreviousNationalityErrors[] | undefined;
  previousNames?: PreviousNameErrors[] | undefined;
}

const InitialPersonalInfoState: InfoTypes.PersonalDetails = {
  title: "",
  titleVal: {
    title: "",
    id: "",
    value: "",
  },
  gender: "",
  genderVal: {
    title: "",
    id: "",
    value: "",
  },
  forename: "",
  middleNames: [""],
  middleNamesData: [
    {
      mName: "",
      isNew: true,
    },
  ],
  surname: "",
  dateOfBirth: null,
  currentNationality: "",
  currentNationalityVal: {
    title: "",
    id: "",
    value: "",
  },
  placeOfBirth: {
    country: "",
    countryVal: {
      title: "",
      id: "",
      value: "",
    },
    county: "",
    town: "",
  },
  previousNames: [
    {
      forename: "",
      surname: "",
      from: null,
      to: null,
    },
  ],
  previousNationalities: [
    {
      nationality: "",
      nationalityVal: {
        title: "",
        id: "",
        value: "",
      },
      from: null,
      to: null,
    },
  ],
};

const InitialMiddleName: InfoTypes.MiddleName = {
  mName: "",
  isNew: true,
};

const InitialMiddleNames: InfoTypes.MiddleName[] = [
  {
    mName: "",
    isNew: true,
  },
];

const initialErrors = {
  title: "",
  gender: "",
  forename: "",
  surname: "",
  dateOfBirth: "",
  county: "",
  town: "",
  previousNationalities: [],
  previousNames: [],
};

const InitialPrevNameState = {
  forename: "",
  surname: "",
  from: null,
  to: null,
  isNew: true,
};

const InitialPrevNationalityState = {
  nationality: "",
  nationalityVal: {
    title: "",
    id: "",
    value: "",
  },
  from: null,
  to: null,
  isNew: true,
};

const InitialSelectValueState: InfoTypes.SelectValueType = {
  title: "",
  id: "",
  value: "",
};

const genericNonEmptyRegex: RegExp = new RegExp(RegexEnum.GENERIC_NON_EMPTY);
const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);
const personNameRegex: RegExp = new RegExp(RegexEnum.PERSON_NAME);

export default function PersonalInfo(props: PersonalInfoProps, state: PersonalInfoState) {
  const { t } = useTranslation();
  const [personalValues, setPersonalValues] = useState<IPersonalDetails>(InitialPersonalInfoState);
  const [, setMiddleNameValues] = useState<InfoTypes.MiddleName[]>(InitialMiddleNames);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [propsPersonalInfoData, setPropsPersonalInfoData] =
    useState<IPersonalDetails>(InitialPersonalInfoState);
  const [loading, setLoading] = useState(false);

  const [selectedFieldToEdit, setSelectedFieldToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
  });

  const [prevNamesValues, setPrevNamesValues] = useState<InfoTypes.PreviousNames[]>();
  const [prevNationalitiesValues, setPrevNationalitiesValues] =
    useState<InfoTypes.PreviousNationalities[]>();
  const [haveRespondedPrevName, setHaveRespondedPrevName] = useState(false);
  const [hasPrevNameYes, setHasPrevNameYes] = useState(false);
  const [hasPrevNameNo, setHasPrevNameNo] = useState(false);
  const [haveRespondedPrevNational, setHaveRespondedPrevNational] = useState(false);
  const [hasPrevNationalYes, setHasPrevNationalYes] = useState(false);
  const [hasPrevNationalNo, setHasPrevNationalNo] = useState(false);
  const [personalRequest, setPersonalRequest] = useState<IComplianceProcess>();
  const [errors] = useState<PersonalInfoErrors>(initialErrors);
  const [isEditRequest, setIsEditRequest] = useState(false);

  const [isPersonalInfoCompleted, setIsPersonalInfoCompleted] = useState<boolean>(true);

  const [haveMiddleNames, setHaveMiddleNames] = useState(true);
  const [havePrevNationalities, setHavePrevNationalities] = useState(true);
  const [havePrevNames, setHavePrevNames] = useState(true);


  let countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
  if (!countriesData) {
    countriesData = GetCountriesData();
  }

  let titlesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.TITLES_DATA);
  if (!titlesData) {
    titlesData = GetTitlesData();
  }

  let nationalitiesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA);
  if (!nationalitiesData) {
    nationalitiesData = GetNationalitiesData();
  }

  useEffect(() => {
    setLoading(true);
    let personalData: IPersonalDetails;
    if (props.complianceProcessData?.processData?.personal) {
      personalData = props.complianceProcessData.processData.personal;
    } else {
      let profileDtls: IProfile = LocalStorageService.getData(
        Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
      );
      if (profileDtls && profileDtls.personal) {
        personalData = profileDtls.personal!;
      }
    }

    if (personalData!) {
      if (personalData.title && personalData.title !== "") {
        personalData.titleVal = {
          title: personalData.title,
          value: personalData.title,
          id: personalData.title,
        };
      } else {
        personalData.title = "";
        personalData.titleVal = InitialSelectValueState;
      }

      if (!personalData.dateOfBirth) {
        personalData.dateOfBirth = null;
      }

      if (personalData.gender) {
        personalData.genderVal = {
          title: personalData.gender,
          id: personalData.gender,
          value: personalData.gender,
        };
      } else {
        personalData.gender = "";
        personalData.genderVal = InitialSelectValueState;
      }
      let middleNames: InfoTypes.MiddleName[] = [];
      if (personalData.middleNames) {
        _.map(personalData.middleNames, (mName: string) => {
          middleNames.push({
            mName: mName,
            isNew: false,
          });
        });
      } else {
        personalData.middleNames = [""];
        middleNames = InitialMiddleNames;
      }
      personalData.middleNamesData = middleNames;
      setMiddleNameValues(middleNames);

      if (personalData.placeOfBirth) {
        if (personalData.placeOfBirth.country && personalData.placeOfBirth.country !== "") {
          if (countriesData !== null) {
            let cntry = countriesData.find(
              (c: InfoTypes.Country) =>
                c.id.toLowerCase() === personalData.placeOfBirth!.country!.toLowerCase()
            );
            if (cntry) {
              personalData.placeOfBirth.countryVal = {
                title: cntry.title,
                value: cntry.id,
                id: cntry.id,
              };

              personalData.placeOfBirth.country = cntry.id;
            }
          }
        } else {
          personalData.placeOfBirth.country = "";
          personalData.placeOfBirth.countryVal = InitialSelectValueState;
        }
      } else {
        personalData.placeOfBirth = {
          country: "",
          town: "",
          county: "",
          countryVal: InitialSelectValueState,
        };
      }

      if (personalData.currentNationality && personalData.currentNationality !== "") {
        if (nationalitiesData === null || nationalitiesData.length === 0) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          nationalitiesData = LocalStorageService.getData(
            Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA
          );
        }
        if (nationalitiesData !== null) {
          let ntnlty = nationalitiesData.find(
            (c: InfoTypes.Nationality) =>
              c.id.toLowerCase() === personalData.currentNationality!.toLowerCase()
          );
          if (ntnlty) {
            personalData.currentNationalityVal = {
              title: ntnlty.title,
              value: ntnlty.value,
              id: ntnlty.id,
            };

            personalData.currentNationality = ntnlty.id;
          }
        }
      } else {
        personalData.currentNationality = "";
        personalData.currentNationalityVal = InitialSelectValueState;
      }

      if (personalData.previousNames && personalData.previousNames.length > 0) {
        setHasPrevNameYes(true);
        setHasPrevNameNo(false);
        setHavePrevNames(true);
      } else {
        setHasPrevNameNo(true);
        setHasPrevNameYes(false);
        setHavePrevNames(true);
      }
      setHaveRespondedPrevName(true);

      setPrevNamesValues(personalData.previousNames!);

      if (personalData.previousNationalities && personalData.previousNationalities.length > 0) {
        _.map(
          personalData!.previousNationalities!,
          (prevNational: InfoTypes.PreviousNationalities) => {
            if (nationalitiesData === null || nationalitiesData.length === 0) {
              nationalitiesData = LocalStorageService.getData(
                Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA
              );
            }
            if (
              nationalitiesData !== null &&
              prevNational.nationality &&
              prevNational.nationality !== ""
            ) {
              let ntnlty = nationalitiesData.find(
                (c: InfoTypes.Nationality) =>
                  c.id.toLowerCase() === prevNational.nationality!.toLowerCase()
              );
              if (ntnlty) {
                prevNational.nationalityVal = {
                  title: ntnlty.title,
                  value: ntnlty.value,
                  id: ntnlty.id,
                };
              } else {
                prevNational.nationalityVal = InitialSelectValueState;
              }
            } else {
              prevNational.nationalityVal = InitialSelectValueState;
            }
          }
        );

        setHasPrevNationalYes(true);
        setHasPrevNationalNo(false);
        setHavePrevNationalities(true);
      } else {
        setHasPrevNationalYes(false);
        setHasPrevNationalNo(true);
        setHavePrevNationalities(true);
      }
      setHaveRespondedPrevNational(true);

      setPrevNationalitiesValues(personalData.previousNationalities!);

      setPropsPersonalInfoData(personalData);
      setPersonalValues(personalData);
    } else {
      setPropsPersonalInfoData(InitialPersonalInfoState);
      setPersonalValues(InitialPersonalInfoState);
      setMiddleNameValues(InitialMiddleNames);
      setHasPrevNameNo(true);
      setHasPrevNationalNo(true);
      setHaveRespondedPrevName(true);
      setHaveRespondedPrevNational(true);
      setHavePrevNationalities(true);
      setHavePrevNames(true);
    }
    setLoading(false);
  }, [props.complianceProcessData]);

  const GenderOptionValues = _.map(
    _.get(
      _.find(
        _.get(PersonalInfoOptions, '[0]["metaDataOptions"]'),
        (e) => e.OptionSetName === "GenderOptions"
      ),
      "Options"
    ),
    (e: InfoTypes.Gender) => {
      return { title: e.title, id: e.title };
    }
  );

  const handleSelectChange =
    (prop: keyof PersonalInfoState, key?: string) =>
    (event: any, val: InfoTypes.SelectValueType) => {
      if (prop === "country") {
        setPersonalValues({
          ...personalValues,
          placeOfBirth: {
            ...personalValues.placeOfBirth,
            country: val.id,
            countryVal: val,
          },
        });
      } else {
        setPersonalValues({
          ...personalValues,
          [prop]: val.id,
          [prop + "Val"]: val,
        });
      }
    };

  const handleChange =
    (prop: keyof PersonalInfoState, key?: string) => (event: any, val: string) => {
      if (prop === "town") {
        setPersonalValues({
          ...personalValues,
          placeOfBirth: {
            ...personalValues.placeOfBirth,
            town: val,
          },
        });
      } else {
        setPersonalValues({
          ...personalValues,
          [event]: val,
        });
      }
    };

  const handleDateChange = (prop: keyof PersonalInfoState) => (val: Date | null) => {
    setPersonalValues({
      ...personalValues,
      [prop]: moment(val!).format(Constants.DATE_FORMAT_ISO),
    });
  };

  //******** Start - Middle Names Event Handlers**** */

  const handleAddMiddleName = () => {
    let statewithNewMiddleName = personalValues;
    if (statewithNewMiddleName?.middleNames && statewithNewMiddleName.middleNames.length === 0) {
      statewithNewMiddleName.middleNames! = [""];
      statewithNewMiddleName.middleNamesData = InitialMiddleNames;
    }
    statewithNewMiddleName!.middleNames!.push("");
    statewithNewMiddleName!.middleNamesData!.push(InitialMiddleName);

    setPersonalValues({
      ...personalValues,
      middleNames: statewithNewMiddleName!.middleNames!,
      middleNamesData: statewithNewMiddleName!.middleNamesData,
    });

    setMiddleNameValues(statewithNewMiddleName!.middleNamesData!);
  };

  const handleRemoveMiddleName = (idx: number) => {
    let statewithNewMiddleName = personalValues;

    statewithNewMiddleName.middleNames!.splice(idx, 1);
    statewithNewMiddleName.middleNamesData!.splice(idx, 1);

    setPersonalValues({
      ...personalValues,
      middleNames: statewithNewMiddleName.middleNames!,
      middleNamesData: statewithNewMiddleName!.middleNamesData,
    });

    setHaveMiddleNames(true);
    setMiddleNameValues(statewithNewMiddleName!.middleNamesData!); 
  };

  const handleUpdatedMiddlesNameData = (mName: InfoTypes.MiddleName, idx: number) => {
    let statewithNewMiddleName = personalValues;

    if (
      statewithNewMiddleName.middleNamesData &&
      statewithNewMiddleName.middleNamesData.length > 0
    ) {
      statewithNewMiddleName.middleNames![idx] = mName.mName;
      statewithNewMiddleName.middleNamesData![idx] = mName;
    } else {
      statewithNewMiddleName.middleNames!.push(mName.mName);
      statewithNewMiddleName.middleNamesData = [mName];
    }

    let isValidTextReg = !utilFunctions.testRegex(genericNonEmptyRegex, statewithNewMiddleName.middleNames![idx]);
    
    if(isValidTextReg) setHaveMiddleNames(false);
    else setHaveMiddleNames(true);

    setPersonalValues({
      ...personalValues,
      middleNames: statewithNewMiddleName.middleNames!,
      middleNamesData: statewithNewMiddleName!.middleNamesData,
    });
    setPropsPersonalInfoData(statewithNewMiddleName);
    setMiddleNameValues(statewithNewMiddleName!.middleNamesData!);
    if (
      statewithNewMiddleName.middleNamesData[idx] &&
      !statewithNewMiddleName.middleNamesData[idx].isNew
    ) {
      setIsEditRequest(true);
      acceptPersonalDetails();
    }
  };

  //******** End - Middle Names Event Handlers**** */

  //******** Start - PUT/Save Personal Data Handlers**** */

  const acceptPersonalDetails = () => {
    let personalDtls = personalValues;
    personalDtls.previousNames = personalValues.previousNames;
    personalDtls.previousNationalities = personalValues.previousNationalities;

    if(personalDtls.previousNames) {
      const isPreviousNamesIsEmpty = personalDtls.previousNames.every(prevName => 
        prevName.forename 
        && prevName.surname 
        && prevName.from 
        && prevName.to
      );
      if(!isPreviousNamesIsEmpty) delete personalDtls.previousNames;
    }

    if(personalDtls.previousNationalities) {
      const isPreviousNationalitiesIsEmpty = personalDtls.previousNationalities.every(prevNationality => 
        prevNationality.nationality 
        && prevNationality.from 
        && prevNationality.to
      );
      if(!isPreviousNationalitiesIsEmpty) delete personalDtls.previousNationalities;
    }

    let prepPersonalData: IComplianceProcess = {
      id: props.complianceProcessData!.id,
      complianceType: props.complianceProcessData!.complianceType,
      processData: {
        personal: personalDtls!,
      },
    };
    setPersonalRequest(prepPersonalData);
  };

  const SendPutPersonalData = async (prsnlRequest: IComplianceProcess) => {
    setLoading(true);
    useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + props.complianceProcessData!.id, {
      method: "PUT",
      body: prsnlRequest,
    })
      .then((res) => {
        if (res.ok) {
          if (props.handleSubmit && !isEditRequest) {
            props.handleSubmit(prsnlRequest, Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO);
          }
        } else {
          handleSaveError(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (props.errorHandler) {
          props.errorHandler(t("errors.save_error_msg"));
        }
      });
    setIsEditRequest(false);
  };

  const handleSaveError = (errors?: IValidationFailure[]) => {
    if (props.errorHandler) {
      if (errors) {
        let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";
        _.map(errors, (err) => {
          errorMsg = errorMsg.concat("<li>", err.errorMessage, "</li>");
        });
        errorMsg.concat("</ul>");
        props.errorHandler(errorMsg);
      } else {
        props.errorHandler(t("errors.save_error_msg"));
      }
    }
  };

  useEffect(() => {
    async function SendPutPersonalDataRequest() {
      if (personalRequest) {
        await SendPutPersonalData(personalRequest);
      }
    }
    SendPutPersonalDataRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalRequest]);

  //******** End - PUT/Save Personal Data Handlers**** */

  //******** Start - Edit Data Fields Event Handlers**** */

  const onEditModalClose = () => {
    setIsEditClicked(false);
    setSelectedFieldToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
    });
  };

  const handleEditSaveCallback = (prop: keyof PersonalInfoState, val: any, index: number) => {
    let propwithChange: any = personalValues!;
    if (prop === "country") {
      propwithChange!.placeOfBirth!.country = val.id;
      propwithChange!.placeOfBirth!.countryVal! = val;
    } else if (prop === "town") {
      propwithChange!.placeOfBirth!.town! = val;
    } else if (prop === "county") {
      propwithChange!.placeOfBirth!.county! = val;
    } else if (prop === "currentNationality") {
      propwithChange.currentNationality = val.id;
      propwithChange.currentNationalityVal = val;
    } else if (prop === "gender") {
      propwithChange.gender = val.id;
      propwithChange.genderVal = val;
    } else if (prop === "title") {
      propwithChange.title = val.id;
      propwithChange.titleVal = val;
    } else if (prop === "dateOfBirth") {
      propwithChange.dateOfBirth = moment(val).format(Constants.DATE_FORMAT_ISO);
    } else if (prop === "middleNames") {
      propwithChange.middleNames![index] = val.mName;
      propwithChange.middleNamesData![index] = val;
      setMiddleNameValues(propwithChange!.middleNamesData!);
    } else {
      propwithChange![prop] = val;
    }

    setPersonalValues(propwithChange);
    setPropsPersonalInfoData(propwithChange);
    setIsEditRequest(true);
    acceptPersonalDetails();
  };

  const handleEditFieldChange = (id: string, title: string, val: any, mNameIdx: number) => {
    setSelectedFieldToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: mNameIdx,
    });
  };

  //******** End - Edit Data Fields Event Handlers**** */

  //******** Start - Previous Names Event Handlers**** */

  const handleAddPreviousName = () => {
    let propwithNewPrevName = prevNamesValues;

    if (!propwithNewPrevName) {
      let prevNames: InfoTypes.PreviousNames[] = [];
      prevNames.push(InitialPrevNameState);

      propwithNewPrevName = prevNames;
    } else {

      let idx = propwithNewPrevName!.length - 1;
      let lastItemOfPrevNames = propwithNewPrevName![idx];

      if (lastItemOfPrevNames && ((
          !lastItemOfPrevNames!.from || lastItemOfPrevNames!.from.toString() === 'Invalid date') || (
          !lastItemOfPrevNames!.to || lastItemOfPrevNames!.to.toString() === 'Invalid date') || (
          lastItemOfPrevNames.from >= lastItemOfPrevNames.to) || 
          lastItemOfPrevNames.to <= lastItemOfPrevNames.from ||
          lastItemOfPrevNames.forename === '' ||
          lastItemOfPrevNames.surname === ''
      )) return;
      
      propwithNewPrevName.splice(propwithNewPrevName.length, 0, {
        forename: "",
        surname: "",
        from: null,
        to: null,
        isNew: true,
      });
    }
    setPrevNamesValues(propwithNewPrevName);
    setPersonalValues({
      ...personalValues,
      previousNames: propwithNewPrevName,
    });

    setPropsPersonalInfoData({
      ...propsPersonalInfoData,
      previousNames: propwithNewPrevName,
    });

    let newIndx = propwithNewPrevName!.length - 1;
    if (propwithNewPrevName && (!propwithNewPrevName[newIndx]!.from ||
        !propwithNewPrevName[newIndx]!.to || 
        propwithNewPrevName[newIndx]!.forename === '' || 
        propwithNewPrevName[newIndx]!.surname === '') 
    ) setHavePrevNames(false);
    else setHavePrevNames(true);

  };

  const handleTogglePrevNameAdd = (selection: string) => {
    setHaveRespondedPrevName(true);
    setHavePrevNames(true);
    if (selection === "yes") {
      setHasPrevNameYes(true);
      setHasPrevNameNo(false);
      handleAddPreviousName();
    } else {
      setHasPrevNameYes(false);
      setHasPrevNameNo(true);
      handleRemoveAllPreviousNames();
    }
  };

  const handleRemovePreviousName = (idx: number) => {
    let statewithPrevName = prevNamesValues;
    statewithPrevName!.splice(idx, 1);

    if (statewithPrevName && statewithPrevName.length > 0) {
      const now = moment().startOf('day');
      const newIdx = idx - 1;
      
      setHasPrevNameNo(false);
      setHasPrevNameYes(true);
      if ((statewithPrevName[newIdx].from === null || statewithPrevName[newIdx].from?.toString() === 'Invalid date') || (
        statewithPrevName[newIdx].to === null || statewithPrevName[newIdx].to?.toString() === 'Invalid date') || (
        statewithPrevName[newIdx].from! >= statewithPrevName[newIdx].to!) || (
        statewithPrevName[newIdx].to! <= statewithPrevName[newIdx].from!) ||
        moment(statewithPrevName[newIdx].to!).isSameOrAfter(now) ||
        statewithPrevName[newIdx].forename === '' ||
        statewithPrevName[newIdx].surname === ''
      ) setHavePrevNames(false); 
      else if (!utilFunctions.testRegex(personNameRegex, statewithPrevName[newIdx].surname) === true ||
      !utilFunctions.testRegex(personNameRegex, statewithPrevName[newIdx].forename) === true
      ) setHavePrevNames(false);    
      else setHavePrevNames(true);

      setPersonalValues({
        ...personalValues,
        previousNames: statewithPrevName,
      });

      setPropsPersonalInfoData(personalValues);
    } else {
      setHasPrevNameNo(true);
      setHasPrevNameYes(false);
      setHavePrevNames(true);
      setPersonalValues(current => {
        const {previousNames, ...personalValues} = current;
        return personalValues;
      });
  
      setPropsPersonalInfoData(current => {
        const {previousNames, ...personalValues} = current;
        return personalValues;
      });
    }

    setHaveRespondedPrevName(true);
  };

  const handleRemoveAllPreviousNames = () => {
    setPrevNamesValues([]);

    setPersonalValues(current => {
      const {previousNames, ...personalValues} = current;
      return personalValues;
    });

    setPropsPersonalInfoData(current => {
      const {previousNames, ...personalValues} = current;
      return personalValues;
    });
  };

  const handleUpdatedPreviousNameData = (pName: InfoTypes.PreviousNames, idx: number) => {
    const now = moment().startOf('day');
    
    let propsPrevNames = prevNamesValues!;
    propsPrevNames![idx] = pName;

    let personalV = personalValues;
    personalV.previousNames = propsPrevNames;

    if ((propsPrevNames[idx].from === null || propsPrevNames[idx].from?.toString() === 'Invalid date') || (
      propsPrevNames[idx].to === null || propsPrevNames[idx].to?.toString() === 'Invalid date') || (
      propsPrevNames[idx].from! >= propsPrevNames[idx].to!) || (
      propsPrevNames[idx].to! <= propsPrevNames[idx].from!) ||
      moment(propsPrevNames[idx].to!).isSameOrAfter(now) ||
      propsPrevNames[idx].forename === '' ||
      propsPrevNames[idx].surname === ''
    ) setHavePrevNames(false); 
    else if (!utilFunctions.testRegex(personNameRegex, propsPrevNames[idx].surname) === true ||
    !utilFunctions.testRegex(personNameRegex, propsPrevNames[idx].forename) === true
    ) setHavePrevNames(false);    
    else setHavePrevNames(true);

    setPrevNamesValues(propsPrevNames);
    setPersonalValues(personalV);
    setPropsPersonalInfoData(personalV);
    if (propsPrevNames[idx] && !propsPrevNames[idx].isNew) {
      setIsEditRequest(true);
      acceptPersonalDetails();
    }
  };
  //******** End - Previous Names Event Handlers**** */

  //******** Start - Previous Nationality Event Handlers**** */

  const handleAddPreviousNationality = () => {
    let propwithNewPrevNational = prevNationalitiesValues;
    if (!propwithNewPrevNational) {
      let prevNationals: InfoTypes.PreviousNationalities[] = [];
      prevNationals.push(InitialPrevNationalityState);
      propwithNewPrevNational = prevNationals;
    } else {

      let idx = propwithNewPrevNational.length - 1;
      let lastItemOfPrevNationalities = propwithNewPrevNational[idx];

      if (lastItemOfPrevNationalities && ((
          !lastItemOfPrevNationalities.from || lastItemOfPrevNationalities.from.toString() === 'Invalid date') || (
          !lastItemOfPrevNationalities.to || lastItemOfPrevNationalities.to.toString() === 'Invalid date') || (
          lastItemOfPrevNationalities.from >= lastItemOfPrevNationalities.to) || 
          lastItemOfPrevNationalities.to <= lastItemOfPrevNationalities.from ||
          lastItemOfPrevNationalities.nationality === ''
      )) return;

      propwithNewPrevNational.splice(propwithNewPrevNational.length, 0, {
        nationality: "",
        nationalityVal: {
          title: "",
          id: "",
          value: "",
        },
        from: null,
        to: null,
        isNew: true,
      });
    }

    setPrevNationalitiesValues(propwithNewPrevNational);
    setPersonalValues({
      ...personalValues,
      previousNationalities: propwithNewPrevNational,
    });

    setPropsPersonalInfoData({
      ...propsPersonalInfoData,
      previousNationalities: propwithNewPrevNational,
    });

    let newIndx = propwithNewPrevNational.length - 1;
    if (propwithNewPrevNational && (!propwithNewPrevNational[newIndx]!.from ||
        !propwithNewPrevNational[newIndx]!.to || 
        propwithNewPrevNational[newIndx]!.nationality === '') 
    ) setHavePrevNationalities(false);
    else setHavePrevNationalities(true);
  };

  const handleTogglePrevNationalityAdd = (selection: string) => {
    setHaveRespondedPrevNational(true);
    setHavePrevNationalities(true);

    if (selection === "yes") {
      setHasPrevNationalYes(true);
      handleAddPreviousNationality();
      setHasPrevNationalNo(false);
    } else {
      setHasPrevNationalYes(false);
      setHasPrevNationalNo(true);
      handleRemoveAllPreviousNationalities();
    }
  };

  const handleRemoveAllPreviousNationalities = () => {
    setPrevNationalitiesValues([]);
    setPersonalValues(current => {
      const {previousNationalities, ...personalValues} = current;
      return personalValues;
    });

    setPropsPersonalInfoData(current => {
      const {previousNationalities, ...personalValues} = current;
      return personalValues;
    });
  };

  const handleRemovePreviousNationality = (idx: number) => {
    let statewithPrevNational = personalValues.previousNationalities;
    statewithPrevNational!.splice(idx, 1);

    setPersonalValues({
      ...personalValues,
      previousNationalities: statewithPrevNational,
    });

    setHaveRespondedPrevNational(true);

    if (statewithPrevNational && statewithPrevNational.length > 0) {
      const now = moment().startOf('day');
      const newIdx = idx - 1;

      setHasPrevNationalNo(false);
      setHasPrevNationalYes(true);
      if ((statewithPrevNational[newIdx].from === null || statewithPrevNational[newIdx].from?.toString() === 'Invalid date') || (
        statewithPrevNational[newIdx].to === null || statewithPrevNational[newIdx].to?.toString() === 'Invalid date') || (
        statewithPrevNational[newIdx].from! >= statewithPrevNational[newIdx].to!) || (
        statewithPrevNational[newIdx].to! <= statewithPrevNational[newIdx].from!) ||
        moment(statewithPrevNational[newIdx].to!).isSameOrAfter(now) ||
        statewithPrevNational[newIdx].nationality === ''
      ) setHavePrevNationalities(false);   
      else setHavePrevNationalities(true);
      setPropsPersonalInfoData(personalValues);

    } else {
      setHasPrevNationalNo(true);
      setHasPrevNationalYes(false);
      setHavePrevNationalities(true);

      setPersonalValues(current => {
        const {previousNationalities, ...personalValues} = current;
        return personalValues;
      });
  
      setPropsPersonalInfoData(current => {
        const {previousNationalities, ...personalValues} = current;
        return personalValues;
      });
    }

  };

  const handleUpdatedPreviousNationalityData = (
    pNational: InfoTypes.PreviousNationalities,
    idx: number
  ) => {
    let propsPrevNationality = prevNationalitiesValues!;
    propsPrevNationality[idx] = pNational;

    let personalV = personalValues;
    personalV.previousNationalities = propsPrevNationality;
    const now = moment().startOf('day');

    if ((propsPrevNationality[idx].from === null || propsPrevNationality[idx].from?.toString() === 'Invalid date') || (
      propsPrevNationality[idx].to === null || propsPrevNationality[idx].to?.toString() === 'Invalid date') || (
      propsPrevNationality[idx].from! >= propsPrevNationality[idx].to!) || (
      propsPrevNationality[idx].to! <= propsPrevNationality[idx].from!) ||
      moment(propsPrevNationality[idx].to!).isSameOrAfter(now) ||
      propsPrevNationality[idx].nationality === ''
    ) setHavePrevNationalities(false);    
    else setHavePrevNationalities(true);

    setPrevNationalitiesValues(propsPrevNationality!);
    setPersonalValues(personalV);
    setPropsPersonalInfoData(personalV);
    if (propsPrevNationality[idx] && !propsPrevNationality[idx].isNew) {
      setIsEditRequest(true);
      acceptPersonalDetails();
    }
  };

  //******** End - Previous Nationality Event Handlers**** */

  const handleGenericErrors = (val: any, property: string, type: string) : boolean => {
    const pattern = (property && property === 'regex') ? personNameRegex : genericRegex;
    const isValidTextReg = !utilFunctions.testRegex(pattern, val);

    switch (type) {
      case 'text':      
        if (!val || val === '') return true;
        else return isValidTextReg;
      
      case 'property':
        if (val && val.hasOwnProperty(property) && property !== '') {
          if (val[property].trim() === '') return true;
          else return false;
        } else return false;
 
      case 'date':
        let personalAge = moment(val).format('YYYY, DD, MM');
        let todayIsDate = moment().format('YYYY, DD, MM');
    
        let isAgeInTheZone = moment(todayIsDate,'YYYY, DD, MM').diff(moment(personalAge, 'YYYY, DD, MM'), 'years');

        if (val === '' || val === null || val === 'Invalid date' || !(
          isAgeInTheZone >= PersonalInfoAllowedAge.MinAge && isAgeInTheZone <= PersonalInfoAllowedAge.MaxAge)
        ) return true;
        else return false;

      case 'optional': 
        return isValidTextReg;

      default:
        return false;
    }
  }

  const handleGenericHelperText = (val: any, property: string, type: string) : string => {
    const pattern = (property && property === 'regex') ? personNameRegex : genericRegex;
    const isValidTextReg = !utilFunctions.testRegex(pattern, val);

    switch (type) { 
      case 'text':
        if (!val || val === '') return t("validators.required");
        else {
          if(isValidTextReg) return t("validators.special_chars");
          else return "";
        }
  
      case 'property':
        if (val && val.hasOwnProperty(property) && property !== ''){
          if (val[property].trim() === "") return t("validators.required");
          else return '';
        } 
        else return '';
      case 'date':
        let personalAge = moment(val).format('YYYY, DD, MM');
        let todayIsDate = moment().format('YYYY, DD, MM');
    
        let isAgeInTheZone = moment(todayIsDate,'YYYY, DD, MM').diff(moment(personalAge, 'YYYY, DD, MM'), 'years');

        if (val === '' || val === null || val === 'Invalid date') return t("errors.birthdate_msg");
        else if (!(isAgeInTheZone >= PersonalInfoAllowedAge.MinAge && isAgeInTheZone <= PersonalInfoAllowedAge.MaxAge)) return t("errors.birthdate_allowed_interval_msg");
        else return "";

      case 'optional':
        if(isValidTextReg) return t("validators.special_chars");
        else return "";  
      default:
        return "";
    }
  }

  useEffect(() => {
    const personalAge = moment(personalValues!.dateOfBirth).format('YYYY, DD, MM');
    const todayIsDate = moment().format('YYYY, DD, MM');

    const isAgeInTheZone = moment(todayIsDate,'YYYY, DD, MM').diff(moment(personalAge, 'YYYY, DD, MM'), 'years');
    
    if ((personalValues.forename !== '' && utilFunctions.testRegex(personNameRegex, personalValues.forename)) && (
         personalValues.surname !== '' && utilFunctions.testRegex(personNameRegex, personalValues.surname)) && (
         personalValues.placeOfBirth?.town !== '' && utilFunctions.testRegex(genericRegex, personalValues.placeOfBirth?.town)) && (
         isAgeInTheZone >= PersonalInfoAllowedAge.MinAge && isAgeInTheZone <= PersonalInfoAllowedAge.MaxAge) && (
         personalValues.titleVal && personalValues.titleVal.title !== '') && (
         personalValues.currentNationalityVal && personalValues.currentNationalityVal.title !== '') && (
         personalValues.placeOfBirth?.countryVal! && personalValues.placeOfBirth?.countryVal.title !== '') && (
         personalValues.genderVal && personalValues.genderVal.title !== '') && 
         haveMiddleNames
    ) setIsPersonalInfoCompleted(true);
    else setIsPersonalInfoCompleted(false);

  }, [haveMiddleNames, personalValues, setIsPersonalInfoCompleted]);

  return (
    <>
      <div
        id="personal-info"
        className="personal-info editor-component"
      >
        <div className="personal-info-body">
          <div className="personal-info-body-container">
            <Heading
              title={
                props.uiContent && props.uiContent.title !== ""
                  ? props.uiContent!.title
                  : t("personal_information.title")
              }
            />
            <div className="personal-info-body-container-details">
              {props.uiContent && props.uiContent.content && props.uiContent.content !== "" ? (
                <div className="personal-info-body-container-details-row editor-component-body">
                  {props.uiContent.content !== "" ? (
                    <div
                      className="editor-component-body"
                      dangerouslySetInnerHTML={{
                        __html: props.uiContent.content.replace(/(<? *script)/gi, "illegalscript"),
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <SelectEditor
                    id={"title"}
                    value={propsPersonalInfoData!.titleVal?.title}
                    editValue={personalValues.titleVal}
                    label={t("common.title")}
                    isCheckEditable={props.isCheckDataEditable}
                    dataSet={titlesData}
                    handleChange={handleSelectChange("title")}
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "title",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "title",
                        t("common.title"),
                        propsPersonalInfoData!.titleVal,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.titleVal, 'title', 'property')}
                  />
                  <FormHelperText className="error MuiFormHelperText-contained">{handleGenericHelperText(personalValues.titleVal, 'title', 'property')}</FormHelperText>
                </div>
                <div className="oneHalfWidth"></div>
              </div>
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="forename"
                    value={propsPersonalInfoData!.forename}
                    editValue={personalValues.forename}
                    label={t("common.forename")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "forename",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleChange("forename")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "forename",
                        t("common.forename"),
                        propsPersonalInfoData!.forename,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.forename, 'regex', 'text')}
                    helperText={handleGenericHelperText(personalValues.forename, 'regex', 'text')}
                  />
                </div>
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="surname"
                    value={propsPersonalInfoData!.surname}
                    editValue={personalValues.surname}
                    label={t("common.surname")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "surname",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleChange("surname")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "surname",
                        t("common.surname"),
                        propsPersonalInfoData!.surname,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.surname, 'regex', 'text')}
                    helperText={handleGenericHelperText(personalValues.surname, 'regex', 'text')}
                  />
                </div>
              </div>
              {(personalValues?.middleNames && personalValues.middleNames.length > 0) ||
              !props.isCheckDataEditable
                ? _.map(
                    personalValues!.middleNamesData!,
                    (mName: InfoTypes.MiddleName, index: number) => (
                        <div
                          key={`personal-info-middleName-div-${index}`}
                          className="personal-info-body-container-details-row"
                        >
                          <div
                            className="oneHalfWidth"
                            style={{ display: "flex" }}
                          >
                            <MiddleName
                              isCheckDataEditable={
                                props.isCheckDataEditable &&
                                utilFunctions.CheckIDVImportedFieldIsEditable(
                                  "middleNames",
                                  ImportableTypeEditor.PersonalInfo,
                                  props.complianceProcessData!
                                )
                              }
                              mNameData={mName}
                              mNameIdx={index}
                              personalValues={personalValues}
                              updatedData={(mName, idx) => handleUpdatedMiddlesNameData(mName, idx)}
                            />
                            {props.isCheckDataEditable &&
                            utilFunctions.CheckIDVImportedFieldIsEditable(
                              "middleNames",
                              ImportableTypeEditor.PersonalInfo,
                              props.complianceProcessData!,
                            ) ? (
                              <div className="add-middleName">
                                {index === personalValues!.middleNames!.length - 1 &&
                                personalValues!.middleNames!.length < 3 ? (
                                  <>
                                    <div
                                      onClick={handleAddMiddleName}
                                      className="icon-btns"
                                    >
                                      <AddCircleOutlineIcon />
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {index === personalValues!.middleNames!.length - 1 &&
                                personalValues!.middleNames!.length > 1 ? (
                                  <>
                                    <div
                                      onClick={() => handleRemoveMiddleName(index)}
                                      className="icon-btns"
                                    >
                                      <DeleteIcon />
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="oneHalfWidth"
                            style={{ display: "flex" }}
                          ></div>
                        </div>
                    )
                  )
                : ""}
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <SelectEditor
                    id={"gender"}
                    value={propsPersonalInfoData!.genderVal?.title}
                    editValue={propsPersonalInfoData!.genderVal}
                    label={t("common.gender")}
                    isCheckEditable={props.isCheckDataEditable}
                    dataSet={GenderOptionValues}
                    handleChange={handleSelectChange("gender")}
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "gender",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "gender",
                        t("common.gender"),
                        propsPersonalInfoData!.genderVal,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.genderVal, 'title', 'property')}
                  />
                  <FormHelperText className="error MuiFormHelperText-contained">{handleGenericHelperText(personalValues.genderVal, 'title', 'property')}</FormHelperText>
                </div>
                <div className="oneHalfWidth">
                  <DateEditor
                    value={propsPersonalInfoData!.dateOfBirth}
                    editValue={personalValues.dateOfBirth}
                    label={t("common.birthdate")}
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "dateOfBirth",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleDateChange("dateOfBirth")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "dateOfBirth",
                        t("common.birthdate"),
                        propsPersonalInfoData!.dateOfBirth,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.dateOfBirth, '', 'date')}
                    helperText={handleGenericHelperText(personalValues.dateOfBirth, '', 'date')}
                  />
                </div>
              </div>
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="town_of_birth"
                    value={propsPersonalInfoData!.placeOfBirth?.town}
                    editValue={personalValues.placeOfBirth?.town}
                    label={t("profile_details.town_of_birth")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "town",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleChange("town")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "town",
                        t("profile_details.town_of_birth"),
                        propsPersonalInfoData!.placeOfBirth?.town,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.placeOfBirth?.town, '', 'text')}
                    helperText={handleGenericHelperText(personalValues.placeOfBirth?.town, '', 'text')}
                  />
                </div>
                <div className="oneHalfWidth">
                  <SelectEditor
                    id={"country"}
                    value={propsPersonalInfoData!.placeOfBirth?.countryVal?.title}
                    editValue={personalValues.placeOfBirth?.countryVal}
                    label={t("profile_details.country_of_birth")}
                    isCheckEditable={props.isCheckDataEditable}
                    dataSet={countriesData}
                    handleChange={handleSelectChange("country")}
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "country",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "country",
                        t("profile_details.country_of_birth"),
                        propsPersonalInfoData!.placeOfBirth?.countryVal!,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.placeOfBirth?.countryVal!, 'title', 'property')}
                  />
                  <FormHelperText className="error MuiFormHelperText-contained">{handleGenericHelperText(personalValues.placeOfBirth?.countryVal!, 'title', 'property')}</FormHelperText>
                </div>
              </div>
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <SelectEditor
                    id={"current_nationality"}
                    value={propsPersonalInfoData!.currentNationalityVal?.title}
                    editValue={personalValues.currentNationalityVal}
                    label={t("common.current_nationality")}
                    isCheckEditable={props.isCheckDataEditable}
                    dataSet={nationalitiesData}
                    handleChange={handleSelectChange("currentNationality")}
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "currentNationality",
                        ImportableTypeEditor.PersonalInfo,
                        props.complianceProcessData!
                      )
                    }
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange(
                        "currentNationality",
                        t("common.current_nationality"),
                        propsPersonalInfoData!.currentNationalityVal,
                        0
                      );
                    }}
                    error={handleGenericErrors(personalValues.currentNationalityVal, 'title', 'property')}
                  />
                  <FormHelperText className="error MuiFormHelperText-contained">{handleGenericHelperText(personalValues.currentNationalityVal, 'title', 'property')}</FormHelperText>
                </div>
                <div className="oneHalfWidth"></div>
              </div>
            </div>
          </div>
          <div className="personal-info-body-container">
            <div className="personal-info-body-container-details-row">
              <div className="personal-info-body-container-details-row">
                <div className="fullWidth question-field">
                  <div className="container-details-row-labels">
                    {t("personal_information.other_name_ques")}
                  </div>
                  <div className="check-box-ctrls">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={haveRespondedPrevName && hasPrevNameYes}
                            disabled={!props.isCheckDataEditable}
                            onClick={() => handleTogglePrevNameAdd("yes")}
                          />
                        }
                        label={t("common.yes")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={haveRespondedPrevName && hasPrevNameNo}
                            disabled={!props.isCheckDataEditable}
                            onClick={() => handleTogglePrevNameAdd("no")}
                          />
                        }
                        label={t("common.no")}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            {propsPersonalInfoData! && prevNamesValues! && haveRespondedPrevName
              ? _.map(prevNamesValues!, (prevName: InfoTypes.PreviousNames, pNameIdx: number) => (
                  <>
                    <div
                      key={`persona-info-prevName-title-${pNameIdx}`}
                      className="personal-info-body-container-title editor-component-title icon-btns"
                    >
                      {t("personal_information.prev_name")}
                      {props.isProfileView ? (
                        props.isCheckDataEditable ? (
                          <>
                            <span>
                              {
                                <AddCircleOutlineIcon
                                  onClick={handleAddPreviousName}
                                  style={{ float: "right" }}
                                  className="icon-size-big"
                                />
                              }
                            </span>{" "}
                            {"  "}
                            <span>
                              {
                                <DeleteIcon
                                  onClick={() => handleRemovePreviousName(pNameIdx)}
                                  style={{ float: "right" }}
                                  className="icon-size-big"
                                />
                              }
                            </span>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <PreviousName
                      key={`persona-prevName-${pNameIdx}`}
                      haveRespondedPrevName={haveRespondedPrevName}
                      isProfileView={props.isProfileView}
                      pNameIdx={pNameIdx}
                      prevNameData={prevName}
                      updatedData={(pname, idx) => handleUpdatedPreviousNameData(pname, idx)}
                      isCheckDataEditable={props.isCheckDataEditable}
                    />
                  </>
                ))
              : ""}
          </div>
          <div className="personal-info-body-container">
            <div className="personal-info-body-container-details-row">
              <div className="personal-info-body-container-details-row">
                <div className="fullWidth question-field">
                  <div className="container-details-row-labels">
                    {t("personal_information.nationality_change_ques")}
                  </div>
                  <div className="check-box-ctrls">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={haveRespondedPrevNational && hasPrevNationalYes}
                            disabled={!props.isCheckDataEditable}
                            onClick={() => handleTogglePrevNationalityAdd("yes")}
                          />
                        }
                        label={t("common.yes")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={haveRespondedPrevNational && hasPrevNationalNo}
                            disabled={!props.isCheckDataEditable}
                            onClick={() => handleTogglePrevNationalityAdd("no")}
                          />
                        }
                        label={t("common.no")}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            {propsPersonalInfoData! && prevNationalitiesValues! && haveRespondedPrevNational
              ? _.map(
                  prevNationalitiesValues!,
                  (prevNational: InfoTypes.PreviousNationalities, pNationalIdx: number) => (
                    <>
                      <div
                        key={`persona-info-prevNationality-title-${pNationalIdx}`}
                        className="personal-info-body-container-title editor-component-title icon-btns"
                      >
                        {t("personal_information.prev_nationalities")}
                        {props.isProfileView ? (
                          props.isCheckDataEditable ? (
                            <>
                              <span>
                                {
                                  <AddCircleOutlineIcon
                                    onClick={handleAddPreviousNationality}
                                    style={{ float: "right" }}
                                    className="icon-size-big"
                                  />
                                }
                              </span>{" "}
                              {"  "}
                              <span>
                                {
                                  <DeleteIcon
                                    onClick={() => handleRemovePreviousNationality(pNationalIdx)}
                                    style={{ float: "right" }}
                                    className="icon-size-big"
                                  />
                                }
                              </span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {props.isProfileView ? <span className={""}></span> : ""}
                      </div>
                      <PreviousNationality
                        key={`persona-prevNationality-${pNationalIdx}`}
                        haveRespondedPrevNationality={haveRespondedPrevNational}
                        isProfileView={props.isProfileView}
                        pNationalityIdx={pNationalIdx}
                        prevNationalityData={prevNational}
                        updatedData={(pNatnl, idx) =>
                          handleUpdatedPreviousNationalityData(pNatnl, idx)
                        }
                        isCheckDataEditable={props.isCheckDataEditable}
                      />
                    </>
                  )
                )
              : ""}
          </div>
          <div className="personal-info-body-container">
            {props.isCheckDataEditable ? (
              props.isProfileView ? (
                <div className="personal-info-body-container-details-row-submit">
                  <div className="container-submit-btn">
                    <GenericButton
                      onClick={() => {
                        acceptPersonalDetails();
                      }}
                      disabled={!isPersonalInfoCompleted || 
                        !haveRespondedPrevName || 
                        !haveRespondedPrevNational || 
                        !havePrevNationalities || 
                        !havePrevNames
                      }
                      type="primary"
                      buttonText={t("common.accept")}
                    ></GenericButton>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <LoaderModal isLoading={loading} />
        </div>
      </div>
      {isEditClicked ? (
        <EditInfoModal
          isEditIconClicked={isEditClicked}
          personalValueState={personalValues}
          selectedFieldToEdit={selectedFieldToEdit}
          onCloseEditModal={onEditModalClose}
          onInfoSaveCallback={handleEditSaveCallback}
        />
      ) : (
        ""
      )}
    </>
  );
}
