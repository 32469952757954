import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import OoopsImage from "../../Assets/Ooops.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Constants from "../../Constants/constants";

const GenericError = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="error-container">
      <img
        className="oops-img"
        alt="Something Went Wrong"
        src={OoopsImage}
      />
      <div className="text-container">
        <div className="message-text">{t("errors.ooops_something_wrong")}</div>
        <GenericButton
          onClick={() => {
            history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
            window.location.reload();
          }}
          disabled={false}
          type="primary"
          buttonText={t("errors.return")}
        />
      </div>
    </div>
  );
};

export default GenericError;
