import "../index.scss";
import Heading from "../Components/Heading";
import { useTranslation } from "react-i18next";

export default function CookiePolicy() {
  const { t } = useTranslation();

  return (
    <div id="cookie-policy" className="cookie-policy editor-component">
      <div className="cookie-policy-body">
        <div className="cookie-policy-body-container">
          <Heading title={t("cookie_policy.title")} />
          <div className="cookie-policy-body-container-details-rows">
            <div className="cookie-policy-body-container-details-row">
              <div
                className="cookie-policy-body-container-details-row-content editor-component-body"
                style={{ float: "none" }}
              >
                <div className="ques-text">{t("cookie_policy.q1")}</div>
                <div className="ans-text">{t("cookie_policy.ans1")}</div>
                <div className="ques-text">{t("cookie_policy.q2")}</div>
                <div className="ans-text">{t("cookie_policy.ans2")}</div>
                <div className="ques-text">{t("cookie_policy.q3")}</div>
                <div className="ans-text">{t("cookie_policy.ans3")}</div>
                <div className="ques-text">{t("cookie_policy.q4")}</div>
                <div className="ans-text">{t("cookie_policy.ans4")}</div>
                <div className="ques-text">{t("cookie_policy.q5")}</div>
                <div className="ans-text">{t("cookie_policy.ans5")}</div>
              </div>
              <div
                className="cookie-policy-body-container-details-row-content editor-component-body"
                style={{ float: "none" }}
              >
                <div className="ques-text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
