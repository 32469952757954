import { PropsWithChildren, useEffect } from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { GenericLoader } from "../Components/GenericLoader";

const ProtectedRoute = ({ component, ...args }: PropsWithChildren<any>) => {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <GenericLoader />,
      })}
      {...args}
    />
  );
};

export default ProtectedRoute;
