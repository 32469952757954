import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type CompareDatesResult = {
  isValid: boolean;
  errorMessage: string;
};

type DateType = 'from' | 'to' | 'residentFrom' | 'residentTo' | 'validFrom' | 'validTo';

const useCompareDates = () => {
  const { t } = useTranslation();
  
  const compareDates = useCallback((newDate: string, prevDates: any, dateType: DateType): CompareDatesResult => {
    const dateFormat = (prevDates.validTo || prevDates.validFrom)  ? 'YYYY-MM-DDTHH:mm:ssZ' : 'DD/MM/YYYY';
    const fromDate = moment(prevDates.residentFrom ?? prevDates.from ?? prevDates.validFrom, dateFormat);
    const toDate = moment(prevDates.residentTo ?? prevDates.to ?? prevDates.validTo, dateFormat);
    const actualDate = moment(newDate, 'DD/MM/YYYY');
    const fromType = dateType === 'from' || dateType === 'residentFrom' || dateType === 'validFrom';
    const toType = dateType === 'to' || dateType === 'residentTo' || dateType === 'validTo';
  
    if (fromType && actualDate.isSameOrAfter(toDate)) {
      return {
        isValid: true,
        errorMessage: t("errors.from_date_greater_msg")
      };
    } else if (toType && actualDate.isSameOrBefore(fromDate)) {
      return {
        isValid: true,
        errorMessage: t("errors.to_date_lesser_msg")
      };
    }
  
    return {
      isValid: false,
      errorMessage: ''
    };
  }, [t]);

  return useMemo(() => ({ compareDates }), [compareDates]);
};

export default useCompareDates;