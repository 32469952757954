import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import InfoTypes from "../../interfaces/InfoTypes";
import EditPrevNationalityModal from "./EditPrevNationalityModal";
import "./index.scss";
import { GetNationalitiesData } from "../../utils/referenceDataService";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import { useTranslation } from "react-i18next";
import SelectEditor from "../../Components/CheckEditors/SelectEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import { FormHelperText } from "@material-ui/core";

interface PreviousNationalityProps {
  prevNationalityData: InfoTypes.PreviousNationalities;
  pNationalityIdx: number;
  haveRespondedPrevNationality: boolean;
  isProfileView: boolean;
  updatedData: (data: InfoTypes.PreviousNationalities, index: number) => void;
  isCheckDataEditable: boolean;
}

interface PreviousNationalityState {
  mappedPrevNationalityData: InfoTypes.PreviousNationalities;
}

interface PreviousNationalityStateData {
  nationality: string;
  from: Date | null;
  to: Date | null;
  isNew: boolean;
  [k: string]: any;
}

export default function PreviousNationality(
  props: PreviousNationalityProps,
  state: PreviousNationalityState
) {
  const { t } = useTranslation();
  const [prevNationalitiesValue, setPrevNationalitiesValue] = useState<PreviousNationalityState>({
    mappedPrevNationalityData: props.prevNationalityData,
  });
  const [prevNationalityEditClicked, setPrevNationalityEditClicked] = useState(false);
  const [selectedPrevNationalityValue, setSelectedPrevNationalityValue] = useState({
    nationality: "",
    nationalityVal: {
      title: "",
      id: "",
      value: "",
    },
    from: "",
    to: "",
  });
  const [selectedPrevNationalityToEdit, setSelectedPrevNationalityToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
  });

  const [prevNationalityDateFromError, setPrevNationalityDateFromError] = useState(false);
  const [prevNationalityDateFromHelperText, setPrevNationalityDateFromHelperText] = useState('');

  const [prevNationalityDateToError, setPrevNationalityDateToError] = useState(false);
  const [prevNationalityDateToHelperText, setPrevNationalityDateToHelperText] = useState('');

  var nationalitiesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA);
  if (!nationalitiesData) {
    nationalitiesData = GetNationalitiesData();
  }

  const handlePreviousNationalityChange =
    (prop: keyof PreviousNationalityStateData, index: number) =>
    (event: ChangeEvent<{}>, val: InfoTypes.SelectValueType) => {
      var mappedVal: any = prevNationalitiesValue;
      mappedVal!.mappedPrevNationalityData!.nationality = val.id;
      mappedVal!.mappedPrevNationalityData!.nationalityVal = val;

      setPrevNationalitiesValue({
        ...state,
        mappedPrevNationalityData: mappedVal.mappedPrevNationalityData,
      });
      props.updatedData(prevNationalitiesValue.mappedPrevNationalityData, index);
    };

  const handlePreviousNationalityDateChange =
    (prop: keyof PreviousNationalityStateData, index: number) => (val: Date | null) => {
      var mappedVal: any = prevNationalitiesValue;
      if (prop === "from") {
        mappedVal!.mappedPrevNationalityData!.from = moment(val!).format(Constants.DATE_FORMAT_ISO);
      } else {
        mappedVal!.mappedPrevNationalityData!.to = moment(val!).format(Constants.DATE_FORMAT_ISO);
      }
      setPrevNationalitiesValue({
        ...state,
        mappedPrevNationalityData: mappedVal.mappedPrevNationalityData,
      });
      props.updatedData(mappedVal.mappedPrevNationalityData, index);
    };

  const onEditPrevNationalityModalClose = () => {
    setPrevNationalityEditClicked(false);
    setSelectedPrevNationalityValue({
      nationality: "",
      nationalityVal: {
        title: "",
        id: "",
        value: "",
      },
      from: "",
      to: "",
    });
    setSelectedPrevNationalityToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
    });
  };

  const handleEditPrevNationalityFieldChange = (
    prevNationality: InfoTypes.PreviousNationalities,
    id: string,
    title: string,
    val: any,
    index: number
  ) => {
    setSelectedPrevNationalityToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: index,
    });
    setSelectedPrevNationalityValue({
      nationality: prevNationality.nationality!,
      nationalityVal: prevNationality.nationalityVal!,
      from: moment(prevNationality.from).format(Constants.DATE_FORMAT_DISPLAY),
      to: moment(prevNationality.to).format(Constants.DATE_FORMAT_DISPLAY),
    });
  };

  const handleEditPrevNationalitySaveCallback = (
    prop: keyof PreviousNationalityStateData,
    val: any,
    index: number
  ) => {
    var propwithChange: any = prevNationalitiesValue!.mappedPrevNationalityData!;
    if (prop === "from" || prop === "to") {
      propwithChange[prop] = moment(val).format(Constants.DATE_FORMAT_ISO);
    } else {
      propwithChange[prop] = val.id;
      propwithChange[prop + "Val"] = val;
    }

    setPrevNationalitiesValue({
      ...state,
      mappedPrevNationalityData: propwithChange,
    });
    props.updatedData(propwithChange, index);
  };

  const handlePrevNationalityDateErrors = useCallback(() => {

    const prevAddr = prevNationalitiesValue.mappedPrevNationalityData!;

    // Dates validations
    if ((prevAddr.from && prevAddr.to) && 
        (prevAddr!.from.toString() !== 'Invalid date' && prevAddr!.to.toString() !== 'Invalid date')) {
        const isMinDate = prevAddr.from >= prevAddr.to;
        const isMaxDate = prevAddr.to <= prevAddr.from;
        const now = moment().startOf('day');
        const isDateToGreaterThanToday = moment(prevAddr.to).isSameOrAfter(now);

        if (isMaxDate) {
          setPrevNationalityDateToError(isMaxDate);
          setPrevNationalityDateToHelperText(t("errors.to_date_lesser_msg"));
        } else {
          setPrevNationalityDateToError(false);
          setPrevNationalityDateToHelperText("");
        }
        
        if (isDateToGreaterThanToday) {
          setPrevNationalityDateToError(isDateToGreaterThanToday);
          setPrevNationalityDateToHelperText(t("errors.to_date_greater_today_msg"));
        }  else {
          setPrevNationalityDateToError(false);
          setPrevNationalityDateToHelperText("");
        }

        if (isMinDate) {
          setPrevNationalityDateFromError(isMinDate);
          setPrevNationalityDateFromHelperText(t("errors.from_date_greater_msg"));
        }  else {
          setPrevNationalityDateFromError(false);
          setPrevNationalityDateFromHelperText("");
        }

    } else {
      setPrevNationalityDateToError(true);
      setPrevNationalityDateToHelperText(t("errors.is_valid_date_or_empty_msg"));

      setPrevNationalityDateFromError(true);
      setPrevNationalityDateFromHelperText(t("errors.is_valid_date_or_empty_msg"));
    }

  }, [prevNationalitiesValue.mappedPrevNationalityData, t])
  
  useEffect(() => {
    handlePrevNationalityDateErrors();
  }, [prevNationalitiesValue.mappedPrevNationalityData.from, 
    prevNationalitiesValue.mappedPrevNationalityData.to, 
    prevNationalityDateToError, 
    prevNationalityDateToHelperText, 
    prevNationalityDateFromError, 
    prevNationalityDateFromHelperText, 
    handlePrevNationalityDateErrors
  ]);
  
  return (
    <>
      <div
        id={`persona-info-prevNationality-div-${props.pNationalityIdx}`}
        className="personal-info-body-container-details empty-row"
      >
        <div className="personal-info-body-container-details-row">
          <div className="oneHalfWidth">
            <SelectEditor
              id={"prev_nationality"}
              value={props.prevNationalityData?.nationalityVal?.title}
              editValue={prevNationalitiesValue.mappedPrevNationalityData?.nationalityVal}
              label={t("prev_nationality.prev_nationality")}
              isCheckEditable={props.isCheckDataEditable}
              dataSet={nationalitiesData}
              showEdit={props.isCheckDataEditable}
              isNew={props.prevNationalityData.isNew}
              handleChange={handlePreviousNationalityChange("nationality", props.pNationalityIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevNationalityEditClicked(true);
                handleEditPrevNationalityFieldChange(
                  props.prevNationalityData,
                  "nationality",
                  t("prev_nationality.prev_nationality"),
                  props.prevNationalityData.nationalityVal,
                  props.pNationalityIdx
                );
              }}
              error={
                prevNationalitiesValue.mappedPrevNationalityData?.nationalityVal &&
                prevNationalitiesValue.mappedPrevNationalityData?.nationalityVal.title !== ""
                  ? false
                  : true
              }
            />
             {(prevNationalitiesValue.mappedPrevNationalityData?.nationalityVal &&
                prevNationalitiesValue.mappedPrevNationalityData?.nationalityVal.title !== "") ? <></> :
                <FormHelperText className="error MuiFormHelperText-contained">
                  {t("validators.required")}
                </FormHelperText>
              }
          </div>
          <div className="oneHalfWidth"></div>
        </div>
        <div className="personal-info-body-container-details-row">
          <div className="personal-info-body-container-details-row">
            <div className="oneHalfWidth">
              <DateEditor
                value={props.prevNationalityData.from}
                editValue={prevNationalitiesValue.mappedPrevNationalityData.from}
                label={t("common.date_from_generic")}
                showEdit={props.isCheckDataEditable}
                isCheckEditable={props.isCheckDataEditable}
                onChangeValue={handlePreviousNationalityDateChange("from", props.pNationalityIdx)}
                isNew={props.prevNationalityData.isNew}
                onEditClicked={(e: EventTarget) => {
                  setPrevNationalityEditClicked(true);
                  handleEditPrevNationalityFieldChange(
                    props.prevNationalityData,
                    "from",
                    t("common.date_from_generic"),
                    props.prevNationalityData.from,
                    props.pNationalityIdx
                  );
                }}
                error={prevNationalityDateFromError}
                helperText={prevNationalityDateFromHelperText}
              />
            </div>
            <div className="oneHalfWidth">
              <DateEditor
                value={props.prevNationalityData.to}
                editValue={prevNationalitiesValue.mappedPrevNationalityData!.to}
                label={t("common.date_to_generic")}
                showEdit={props.isCheckDataEditable}
                isCheckEditable={props.isCheckDataEditable}
                onChangeValue={handlePreviousNationalityDateChange("to", props.pNationalityIdx)}
                isNew={props.prevNationalityData.isNew}
                onEditClicked={(e: EventTarget) => {
                  setPrevNationalityEditClicked(true);
                  handleEditPrevNationalityFieldChange(
                    props.prevNationalityData,
                    "to",
                    t("common.date_to_generic"),
                    props.prevNationalityData.to,
                    props.pNationalityIdx
                  );
                }}
                error={prevNationalityDateToError}
                helperText={prevNationalityDateToHelperText}
              />
            </div>
          </div>
        </div>
        <div className="personal-info-body-container-details-row empty-row">
          <div></div>
        </div>
      </div>
      {prevNationalityEditClicked ? (
        <EditPrevNationalityModal
          isEditIconPrevNationalityClicked={prevNationalityEditClicked}
          prevNationalityState={selectedPrevNationalityValue}
          selectedPrevNationalityToEdit={selectedPrevNationalityToEdit}
          onCloseEditPrevNationalityModal={onEditPrevNationalityModalClose}
          onPrevNationalitySaveCallback={handleEditPrevNationalitySaveCallback}
        />
      ) : (
        ""
      )}
    </>
  );
}
