import { Checkbox, Chip, TextField, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import InfoTypes from "../../interfaces/InfoTypes";
import "./index.scss";

const CustomAutocomplete: any = withStyles({
	root: {
		fontFamily: "Montserrat-Medium",
		"& .MuiFormLabel-asterisk": {
			color: "#2D7FF9 !important",
		},
		"& label.Mui-focused": {
			// label color
			color: "rgba(45, 127, 249, 1)",
			fontFamily: "Montserrat-Regular",
		},
		"& label.MuiInputLabel-outlined": {
			color: "#343C44",
			fontFamily: "Montserrat-Regular",
			textAlign: "left !important",
			whiteSpace: "break-spaces",
			marginTop: "-5px",
		},
		"& .MuiFormControl-root ": {
			fontFamily: "Lato-Regular",			
		},
		"& label.Mui-disabled": {
			color: "rgba(0, 0, 0, 0.38) !important",
		},

		"& label.MuiInputLabel-shrink": {
			color: "#343C44",
			fontFamily: "Montserrat-Regular",
			lineHeight: "12px",
			fontSize: "14px",
			fontWeight: "600",
			letterSpacing: "0px",
			height: "12px",
		},
		"& input.MuiInputBase-input": {
			color: "rgb(49, 49, 49)",
			fontFamily: "Lato-Regular",
			fontSize: "16px",
			fontWeight: "500",
			height: "16px",
			letterSpacing: "0px",
			"&.Mui-disabled": {
				opacity: "0.72 !important",
			},
		},

		"& .MuiOutlinedInput-root": {
			borderRadius: "10px",
			"&.Mui-disabled": {
				opacity: "0.72 !important",
				borderRadius: "10px",
				border: "1px solid #E0E0E0",
			},
			"&input": {
				"&:-webkit-autofill": {
					WebkitBoxShadow: "0 0 0 100px rgb(255, 255, 255)inset",
				},
			},

			"& textarea": {
				overflow: "auto",
				height: "auto",
			},
			"& fieldset": {
				"& legend": {
					"& span": {
						padding: "0px",
						fontFamily: "Lato-Regular",
					},
				},
				color: "red",
				border: "1px solid #757575",
				borderColor: "#757575",
				borderRadius: "10px",
			},

			"&:hover fieldset": {
				// hover
        		border: "2px solid #478FFA",
			},
			// focus active
			"&.Mui-focused fieldset": {
				border: "2px solid #2D7FF9",
			},
			"  MuiInputLabel": {
				outlined: {
					"&$shrink": {
						color: "#757575",
						transform: "translate(0px, 0px) scale(0.75)",
					},
				},
			},
		},
		"& .Mui-error": {
			"&.MuiFormHelperText-root.Mui-error": {
				color: "rgb(209, 0, 0)",
			},
			"&.Mui-focused fieldset": {
				border: "1px solid rgb(209, 0, 0)",
				borderColor: "rgb(209, 0, 0)",
			},
			"&:hover fieldset": {
				// hover
				border: "1px solid rgb(209, 10, 67)",
			},
		},
		"&.MuiFormControl-root .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
			border: "1px solid rgb(209, 0, 0)",
		},
		".MuiFormHelperText-root.Mui-error": {
			color: "rgb(209, 0, 0)",
		},
	},
})(Autocomplete);
export interface DataSetInterface {
	title: string;
	id: string | number;
	[key: string]: any;
}

interface Props {
	value: any;
	dataSet: DataSetInterface[];
	handleChange: (event: React.ChangeEvent<{}>, value: InfoTypes.SelectValueType) => void;
	fullWidth?: boolean;
	style?: {};
	className?: string;
	multiple?: boolean;
	autoComplete?: boolean;
	disabled?: boolean;
	checkbox?: boolean;
	label?: string;
	name?: string;
	freeSolo?: boolean;
	error?: boolean;
	required?: boolean;
	key?: string | number | null;
	renderChip?: boolean;
	enableClear?: boolean;
	disableCloseOnSelect?: boolean;
	renderLabelCount?: boolean;
	countLabel?: string;
	id?: string;
	allOption?: boolean;
}

const GenericSelect = (props: Props) => {
	const handleChange = (
		event: React.ChangeEvent<{}>,
		value: InfoTypes.SelectValueType,
	) => {
		props.handleChange(event, value);
	};

	const handleLabelValue = () => {
		let label: string = "";
		if (props.allOption) {
			if (props.dataSet.length === props.value.length) {
				label = `All ${props.countLabel} Selected`;
			}
			else if (props.value.length > 0) {
				label = `${props.value.length} ${props.countLabel} Selected `;
			}
		} else {
			label = props.value.length > 0
				? `${props.value.length} ${props.countLabel} Selected `
				: ""
		}
		return label;
	}

	const highlightQuery = (text: any, highlight: any) => {
		// Split on highlight term and include term into parts, ignore case
		const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
		return <span> {parts?.map((part: any, i: any) =>
			<span key={i} style={part?.toLowerCase() === highlight?.toLowerCase() ? { color: '#2D7FF9' } : {}}>
				{part}
			</span>)
		} </span>;
	}
	return (
		<>
			{props.multiple ? (
				<>
					<CustomAutocomplete
						id={props.id ? props.id : "combo-box-demo"}
						key={props.key}
						options={props.dataSet}
						getOptionLabel={(option: any) => option.title ? option.title : ""}
						style={props.style}
						fullWidth={props.fullWidth}
						onChange={handleChange}
						className={props.className}
						multiple
						disabled={props.disabled}
						value={props.value}
						autoComplete={props.autoComplete}
						freeSolo={props.freeSolo}
						autoSelect={props.freeSolo}
						disableCloseOnSelect={props.disableCloseOnSelect}
						getOptionSelected={(option: any, value: any) => 
							option?.id === value?.id
						}
						renderOption={(option: any, parameter: any) => {
							let selected = parameter?.selected
							return (
								<React.Fragment>
									<Checkbox
										icon={
											<CheckBoxOutlineBlankIcon fontSize='small' />
										}
										checkedIcon={
											<CheckBoxIcon fontSize='small' style={{
												color: "rgb(26, 65, 152)",
											}} />
										}
										style={{ marginRight: 8 }}
										checked={selected}
										className={
											props.checkbox ? "" : "display-none"
										}
									/>
									<div className='options-container'>

										<label className='sub-detail-1'>
											{highlightQuery(option.title, parameter.inputValue)}
										</label>
									</div>
								</React.Fragment>
							)
						}
						}
						renderTags={(value: any, getTagProps: any) =>
							props.renderLabelCount ? (
								<div
									style={{
										color: "#2D7FF9",
										textAlign: "start",
										paddingLeft: "10px",
										fontWeight: "bold",
										fontSize: "16px",
										fontFamily: "Montserrat-Semibold"
									}}
								>
									{handleLabelValue()}
								</div>
							) : (
								value.map((option: any, index: any) => (
									<Chip
										variant='outlined'
										label={option.title}
										{...getTagProps({ index })}
									/>
								))
							)
						}
						renderInput={(params: any) => (
							<TextField
								{...params}
								label={props.label || ""}
								variant='outlined'
								error={props.error}
								required={props.required}
							/>
						)}
						noOptionsText={"No Result Found"}
						disableClearable={props.enableClear ? false : true}
						ListboxProps={{
							style: {
								maxHeight: "200px",
							},
						}}
					/>
				</>
			) : (
				<CustomAutocomplete
					id={props.id ? props.id : "combo-box-demo"}
					options={props.dataSet}
					getOptionLabel={(option: any) => option.title ? option.title : ""}
					style={props.style}
					fullWidth={props.fullWidth}
					className={props.className}
					autoComplete={props.autoComplete}
					disabled={props.disabled}
					value={props.value}
					blurOnSelect
					onChange={handleChange}
					freeSolo={props.freeSolo}
					autoSelect={props.freeSolo}
					disableCloseOnSelect={props.disableCloseOnSelect}
					renderInput={(params: any) => (
						<TextField
							{...params}
							label={props.label || ""}
							variant='outlined'
							error={props.error}
							required={props.required}
						/>
					)}
					noOptionsText={"No Result Found"}
					disableClearable={props.enableClear ? false : true}
					ListboxProps={{
						style: {
							maxHeight: "200px",
						},
					}}
					renderTags={(value: any, getTagProps: any) =>
						value.map((option: any, index: any) =>
							props.renderChip ? (
								<Chip
									variant='outlined'
									label={option.title}
									{...getTagProps({ index })}
								/>
							) : (
								<>
									<React.Fragment {...getTagProps({ index })}>
										{option.title}
									</React.Fragment>
								</>
							)
						)
					}
					renderOption={(option: any, { inputValue }: { inputValue: any }) =>
						(
							<React.Fragment>
								<div className='options-container'>

									<label className='sub-detail-1'>
										{highlightQuery(option.title, inputValue)}
									</label>
								</div>
							</React.Fragment>
						)
					}
				/>
			)}
		</>
	);
};

export default GenericSelect;