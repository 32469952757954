import React, { useEffect, useState } from "react";
import "./index.scss";
import { RouteComponentProps } from "react-router-dom";
import GenericButton from "../../Components/GenericButton";
import IAddressInfo from "../../interfaces/IAddressInfo";
import moment from "moment";
import _ from "lodash";
import EditAddressModal from "./EditAddressInfoModal";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import InfoTypes from "../../interfaces/InfoTypes";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IProfile from "../../interfaces/IProfile";
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import useAPIRequest from "../../Service/useAPIRequest";
import { GetCountriesData } from "../../utils/referenceDataService";
import Heading from "../../Components/Heading";
import PreviousAddress from "./PreviousAddress";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "../../Components/LoaderModal";
import utilFunctions from "../../utils/util";
import IValidationFailure from "../../interfaces/IValidationFailure";
import {CountryEnum, ImportableTypeEditor, MinResidentFromAddressHistory, RegexEnum } from "../../Constants/enums";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import SelectEditor from "../../Components/CheckEditors/SelectEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import useSelectedPostCodeRegex from "../../utils/useSelectedPostCodeRegex";

interface AddressHistoryState {
  building: string;
  street: string;
  town: string;
  postCode: string;
  country: string;
  countryVal?: InfoTypes.SelectValueType;
  county: string;
  residentFrom: Date | null;
  residentTo: Date | null;
}

interface AddressHistoryProps extends Partial<RouteComponentProps<any>> {
  uiContent?: InfoTypes.ContentDefinition;
  complianceProcessData?: IComplianceProcess;
  isProfileView: boolean;
  isCheckDataEditable: boolean;
  handleSubmit?: (updatedProcessData?: IComplianceProcess, editor?: string) => void;
  errorHandler?: (msg: string) => void;
}

const InitialAddressState: InfoTypes.AddressInfo = {
  building: "",
  country: "",
  countryVal: {
    title: "",
    value: "",
    id: "",
  },
  county: "",
  residentFrom: null,
  residentTo: null,
  postCode: "",
  street: "",
  town: "",
};

const InitialPrevAddress: InfoTypes.PreviousAddress = {
  building: "",
  country: "",
  countryVal: {
    title: "",
    value: "",
    id: "",
  },
  county: "",
  residentFrom: null,
  residentTo: null,
  postCode: "",
  street: "",
  town: "",
  isNew: true,
};

const InitialPrevAddressesState: InfoTypes.PreviousAddress[] = [InitialPrevAddress];

const InitialIAddressInfo: IAddressInfo = {
  currentAddress: InitialAddressState,
};

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);
const UKPostCodeRegex: RegExp = new RegExp(RegexEnum.UK_POSTCODE);
const GenericPostCodeRegex: RegExp = new RegExp(RegexEnum.GENERIC_POSTCODE);

export default function AddressHistory(props: AddressHistoryProps) {
  const { t } = useTranslation();

  const [currAddressValue, setCurrAddressValue] = useState(InitialAddressState);
  const [prevAddressValues, setPrevAddressValues] =
    useState<InfoTypes.PreviousAddress[]>(InitialPrevAddressesState);
  const [isEditAddressClicked, setIsEditAddressClicked] = useState(false);
  const [propsAddressInfoData, setPropsAddressInfoData] =
    useState<IAddressInfo>(InitialIAddressInfo);
  const [haveRespondedCurrAddress, setHaveRespondedCurrAddress] = useState(false);
  const [haveRespondedPrevAddress, setHaveRespondedPrevAddress] = useState(false);
  const [havePrevAddresses, setHavePrevAddresses] = useState(false);
  const [hasPrevAddressYes, setHasPrevAddressYes] = useState(false);
  const [hasPrevAddressNo, setHasPrevAddressNo] = useState(false);
  const [addressInfoRequest, setAddressInfoRequest] = useState<IComplianceProcess>();
  const [loading, setLoading] = useState(false);
  const [isEditRequest, setIsEditRequest] = useState(false);
  const [hasMinResidentFromAddressHistory, setHasMinResidentFromAddressHistory] = useState(false);
  const selectedPostCodeRegex = useSelectedPostCodeRegex(currAddressValue.countryVal!.id);

  const [selectedFieldToEdit, setSelectedFieldToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    inputProps: {},
  });

  let countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
  if (countriesData === null || countriesData === undefined) {
    countriesData = GetCountriesData();
  }

  useEffect(() => {
    setLoading(true);

    let addressData: IAddressInfo;

    if (props.complianceProcessData?.processData?.addresses) {
      addressData = props.complianceProcessData.processData.addresses;
    } else {
      let profileDtls: IProfile = LocalStorageService.getData(
        Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
      );
      if (profileDtls && profileDtls.addresses) {
        addressData = profileDtls.addresses!;
      }
    }

    if (addressData!) {
      setPropsAddressInfoData(addressData!);

      if (addressData.currentAddress) {
        if (addressData.currentAddress.country && addressData.currentAddress.country !== "") {
          const cntry: InfoTypes.Country = countriesData.find(
            (c: InfoTypes.Country) =>
              c.id.toLowerCase() === addressData!.currentAddress.country.toLowerCase()
          );

          addressData.currentAddress.countryVal = {
            title: cntry.title,
            value: cntry.id,
            id: cntry.id,
          };
        } else {
          addressData.currentAddress.countryVal = {
            title: "",
            value: "",
            id: "",
          };
        }

        let formattedAddress = addressData.currentAddress;

        if (!addressData.currentAddress.residentFrom) {
          formattedAddress.residentFrom = null;
        }

        if (!addressData.currentAddress.residentTo) {
          formattedAddress.residentTo = null;
        }

        setCurrAddressValue(addressData.currentAddress);
      } else {
        setCurrAddressValue(InitialAddressState);
      }

      if (addressData.previousAddresses && addressData.previousAddresses.length > 0) {
        setHaveRespondedPrevAddress(true);
        setHavePrevAddresses(true);
        setHasPrevAddressYes(true);
        setHasPrevAddressNo(false);

        let formattedPrevAddresses = addressData.previousAddresses;
        let idx = 0;
        _.map(addressData.previousAddresses!, (prevAdd: InfoTypes.AddressInfo) => {

          
          if (!prevAdd.residentFrom) {
            prevAdd.residentFrom = null;
          }
          if (!prevAdd.residentTo) {
            prevAdd.residentTo = null;
          }

          if (prevAdd.country && prevAdd.country !== "") {
            const prevcntry: InfoTypes.Country = countriesData.find(
              (c: InfoTypes.Country) => c.id.toLowerCase() === prevAdd.country.toLowerCase()
            );

            prevAdd.countryVal = {
              title: prevcntry?.title,
              value: prevcntry?.id,
              id: prevcntry?.id,
            };
          } else {
            prevAdd.countryVal = {
              title: "",
              value: "",
              id: "",
            };
          }
          formattedPrevAddresses[idx] = prevAdd;
          idx++;
        });

        setPrevAddressValues(addressData.previousAddresses);
      } else {
        setHaveRespondedPrevAddress(true);
        setHavePrevAddresses(true);
        setHasPrevAddressYes(false);
        setHasPrevAddressNo(true);
        setPrevAddressValues([]);
      }
      setPropsAddressInfoData(addressData!);
    } else {
      setHaveRespondedPrevAddress(true);
      setHavePrevAddresses(true);
      setHasPrevAddressYes(false);
      setHasPrevAddressNo(true);
      setPrevAddressValues([]);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.complianceProcessData]);

 

  useEffect(() => {
    let date = moment(currAddressValue.residentFrom);
    let now = moment();

    let isMinResidentFromAddressHistory = moment().diff(date, 'years', true);

    if ((currAddressValue.building !== "" && 
      currAddressValue.country !== "" &&
      (currAddressValue.residentFrom && (currAddressValue.residentFrom !== null || currAddressValue.residentFrom !== "Invalid date")) &&
      currAddressValue.postCode !== "" &&
      currAddressValue.street !== "" &&
      currAddressValue.county !== "" &&
      currAddressValue.town !== "" &&
      utilFunctions.testRegex(selectedPostCodeRegex, currAddressValue.postCode) === true &&
      utilFunctions.testRegex(genericRegex, currAddressValue.building) === true &&
      utilFunctions.testRegex(genericRegex, currAddressValue.country) === true &&
      utilFunctions.testRegex(genericRegex, currAddressValue.street) === true &&
      utilFunctions.testRegex(genericRegex, currAddressValue.county) === true &&
      utilFunctions.testRegex(genericRegex, currAddressValue.town) === true
    )
    
    ) setHaveRespondedCurrAddress(true);
    else setHaveRespondedCurrAddress(false);

    if (isMinResidentFromAddressHistory < MinResidentFromAddressHistory.MinYears && hasPrevAddressNo) setHasMinResidentFromAddressHistory(false);
    else setHasMinResidentFromAddressHistory(true);

    if (date > now) setHaveRespondedCurrAddress(false);

    if (currAddressValue.residentFrom?.toString() === "Invalid date") setHaveRespondedCurrAddress(false);
  }, [currAddressValue, hasPrevAddressNo, hasPrevAddressYes, selectedPostCodeRegex])

  //******** Start - Personal Info Event Handlers**** */

  const handleAddressChange =
    (prop: keyof AddressHistoryState, key?: string) => (event: any, val: string) => {
      setCurrAddressValue({
        ...currAddressValue,
        [event]: prop === "postCode" ? val.toUpperCase() : val,
      });
    };

  const handleAddressSelectChange =
    (prop: keyof AddressHistoryState, key?: string) =>
    (event: any, val: InfoTypes.SelectValueType) => {
      setCurrAddressValue({
        ...currAddressValue,
        [prop]: val.id,
        [prop + "Val"]: val,
      });
    };

  const handleDateChange = (prop: keyof AddressHistoryState) => (val: Date | null) => {
    let propwithNewPrevAddress = propsAddressInfoData!.previousAddresses
    setCurrAddressValue({
      ...currAddressValue,
      [prop]: moment(val!).format(Constants.DATE_FORMAT_ISO),
    });

    if (propwithNewPrevAddress && propwithNewPrevAddress.length > 0) {
      
      propwithNewPrevAddress[0].residentTo = val;
      setPrevAddressValues(propwithNewPrevAddress!);

      setPropsAddressInfoData({
        ...propsAddressInfoData!,
        previousAddresses: propwithNewPrevAddress!,
      });
    }
  };

  const handleAddressErrors = (val: any, type: string) : boolean => {
    let isValidTextReg = !utilFunctions.testRegex(genericRegex, val), 
    isValidPostCode = !utilFunctions.testRegex(
      selectedPostCodeRegex, val
    );
    let isMinResidentFromAddressHistory = moment().diff(val, 'years', true);

    switch (type) {
      case 'text':
        if (val === '' || !val) return true;
        else return isValidTextReg;
 
      case 'date':
        let date = moment(val);
        let now = moment();

        if (val === '' || val === null || val === 'Invalid date' || (date > now)) return true;
        else if (isMinResidentFromAddressHistory < MinResidentFromAddressHistory.MinYears && hasPrevAddressNo) return true;
        else return false;

      case 'code': 
        if (val === '' || !val) return true;
        else return isValidPostCode;

      case 'optional': 
        return isValidTextReg;

      default:
        return false;
    }
  }

  const handleAddressHelperText = (val: any, type: string) : string => {
    let isValidTextReg = !utilFunctions.testRegex(genericRegex, val), 
    isValidPostCode = !utilFunctions.testRegex(
      selectedPostCodeRegex, val
    );
    let isMinResidentFromAddressHistory = moment().diff(val, 'years', true);

    switch (type) { 
      case 'text':
        if (val === '' || !val) return t("validators.required");
        else {
          if(isValidTextReg) return t("validators.special_chars");
          else return "";
        }
  
      case 'date':
        let date = moment(val);
        let now = moment();

        if (val === '' || val === null || val === 'Invalid date') return t("validators.required");
        else if(date > now) return t("errors.current_date_greater_msg");
        else if (isMinResidentFromAddressHistory < MinResidentFromAddressHistory.MinYears && hasPrevAddressNo) return t("errors.date_five_years_resident_from_msg");
        else return "";

      case 'code':
        if (val === '' || !val) return t("validators.required");
        else {
          if(isValidPostCode) return t("validators.invalid_postcode");
          else return "";
        } 
      case 'optional':
        if(isValidTextReg) return t("validators.special_chars");
        else return "";  
      default:
        return "";
    }
  }

  //******** End - Personal Info Event Handlers**** */

  //******** Start - Edit Personal Info Handlers**** */

  const onEditAddressModalClose = () => {
    setIsEditAddressClicked(false);
    setSelectedFieldToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      inputProps: {},
    });
  };

  const handleEditAddressSaveCallback = (prop: keyof AddressHistoryState, val: any) => {
    let updatedProps: any = propsAddressInfoData!;
    setCurrAddressValue({ ...currAddressValue, [prop]: prop === "country" ? val.id : val });

    if (prop === "country") {
      updatedProps.currentAddress!.country = val.id;
      updatedProps.currentAddress!.countryVal = val;
    } else if (prop === "residentFrom") {
      updatedProps.currentAddress!.residentFrom = moment(val).format(Constants.DATE_FORMAT_ISO);
      if (updatedProps.previousAddresses.length > 0) updatedProps.previousAddresses[0].residentTo = moment(val).format(Constants.DATE_FORMAT_ISO);
    } else {
      updatedProps!.currentAddress![prop] = val;
    }

    setPropsAddressInfoData(updatedProps);
    setCurrAddressValue(updatedProps.currentAddress);
    setIsEditRequest(true);
    acceptAddressDetails();
  };

  //******** End - Edit Personal Info Handlers**** */

  //******** Start - Previous Address Event Handlers**** */

  const handleAddPreviousAddress = () => {
    let propwithNewPrevAddress = propsAddressInfoData!.previousAddresses;
    if (!propwithNewPrevAddress!) {
      let prevAddress: InfoTypes.PreviousAddress[] = [];
      InitialPrevAddress.residentTo = currAddressValue.residentFrom;
      prevAddress.push(InitialPrevAddress);
      propwithNewPrevAddress! = prevAddress;
    } else {
      let idx = propwithNewPrevAddress!.length - 1;
      let lastItemOfPrevAddresses = propwithNewPrevAddress![idx];

      if (lastItemOfPrevAddresses && ((
          !lastItemOfPrevAddresses!.residentFrom || lastItemOfPrevAddresses!.residentFrom.toString() === 'Invalid date') || (
          !lastItemOfPrevAddresses!.residentTo || lastItemOfPrevAddresses!.residentTo.toString() === 'Invalid date') || (
          lastItemOfPrevAddresses.residentFrom >= lastItemOfPrevAddresses.residentTo) || 
          lastItemOfPrevAddresses.residentTo <= lastItemOfPrevAddresses.residentFrom
      )) return;

      propwithNewPrevAddress!.splice(propwithNewPrevAddress.length, 0, {
        building: "",
        country: "",
        countryVal: {
          title: "",
          value: "",
          id: "",
        },
        county: "",
        residentFrom: null,
        residentTo: hasPrevAddressYes ? lastItemOfPrevAddresses!.residentFrom : currAddressValue.residentFrom,
        postCode: "",
        street: "",
        town: "",
        isNew: true,
      });
    }

    setPrevAddressValues(propwithNewPrevAddress!);

    setPropsAddressInfoData({
      ...propsAddressInfoData!,
      previousAddresses: propwithNewPrevAddress!,
    });

    let newIndx = propwithNewPrevAddress!.length - 1;
    if (propwithNewPrevAddress && (!propwithNewPrevAddress[newIndx]!.residentFrom ||
        !propwithNewPrevAddress[newIndx]!.residentTo)) setHavePrevAddresses(false);
    else setHavePrevAddresses(true);
  };
 

  const handleTogglePrevAddressAdd = (selection: string) => {
    setHaveRespondedPrevAddress(true);
    setHavePrevAddresses(true);

    if (selection === "yes") {
      setHasPrevAddressYes(true);
      setHasPrevAddressNo(false);
      handleAddPreviousAddress();
    } else {
      setHasPrevAddressYes(false);
      setHasPrevAddressNo(true);
      handleRemoveAllPreviousAddresses();
    }
  };


  const handleRemovePreviousAddress = (idx: number) => {
    let statewithPrevAddress = propsAddressInfoData?.previousAddresses;
    statewithPrevAddress!.splice(idx, 1);
    setPrevAddressValues(statewithPrevAddress!);

    setPropsAddressInfoData({
      ...propsAddressInfoData,
      previousAddresses: statewithPrevAddress,
    });

    if (statewithPrevAddress && statewithPrevAddress.length > 0) {
      const now = moment().startOf('day');
      const newIdx = idx - 1;

      setHasPrevAddressNo(false);
      setHasPrevAddressYes(true);

      if ((statewithPrevAddress![newIdx].residentFrom === null || statewithPrevAddress![newIdx].residentFrom?.toString() === 'Invalid date') || (
        statewithPrevAddress![newIdx].residentTo === null || statewithPrevAddress![newIdx].residentTo?.toString() === 'Invalid date') || (
        statewithPrevAddress![newIdx].residentFrom! >= statewithPrevAddress![newIdx].residentTo!) || (
        statewithPrevAddress![newIdx].residentTo! <= statewithPrevAddress![newIdx].residentFrom! ||
        moment(statewithPrevAddress[newIdx].residentTo!).isSameOrAfter(now) ||         
        statewithPrevAddress![newIdx].building === '' ||
        statewithPrevAddress![newIdx].county === '' ||
        statewithPrevAddress![newIdx].street === '' ||
        statewithPrevAddress![newIdx].country === '' ||
        statewithPrevAddress![newIdx].town === ''
      )) setHavePrevAddresses(false);    
      else setHavePrevAddresses(true);

    } else {
      setHasPrevAddressNo(true);
      setHasPrevAddressYes(false);
      setHavePrevAddresses(true);
    }
    setHaveRespondedPrevAddress(true);
  };

  const handleRemoveAllPreviousAddresses = () => {
    setPrevAddressValues([]);

   
    setPropsAddressInfoData({
      ...propsAddressInfoData!,
      previousAddresses: [],
    });
  };

  const handleEditAddressFieldChange = (
    id: string,
    title: string,
    val: any,
    inputProps?: { [key: string]: any }
  ) => {
    setSelectedFieldToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      inputProps: inputProps!,
    });
  };

  const handleUpdatedPreviousAddressData = (pAdd: InfoTypes.PreviousAddress, idx: number) => {
    const now = moment().startOf('day');

    let propsPrevAdds = [...prevAddressValues!];
    propsPrevAdds![idx] = pAdd;

    let propsAdds = propsAddressInfoData;
    propsAdds.previousAddresses = propsPrevAdds;

    const postCodeRegex = propsPrevAdds[idx].country === CountryEnum.GBR ? UKPostCodeRegex : GenericPostCodeRegex;

    if (idx >= 0 && currAddressValue.residentFrom) currAddressValue.residentFrom = propsPrevAdds![0].residentTo;

    if (idx > 0) propsPrevAdds![idx - 1].residentFrom = propsPrevAdds![idx].residentTo;

    if (propsPrevAdds.length >= 1 && propsPrevAdds![idx + 1]) {
      propsPrevAdds![idx + 1].residentTo = propsPrevAdds![idx].residentFrom;
    }

    if ((propsPrevAdds![idx].residentFrom === null || propsPrevAdds![idx].residentFrom?.toString() === 'Invalid date') || (
        propsPrevAdds![idx].residentTo === null || propsPrevAdds![idx].residentTo?.toString() === 'Invalid date') || (
        propsPrevAdds![idx].residentFrom! >= propsPrevAdds![idx].residentTo!) || (
        propsPrevAdds![idx].residentTo! <= propsPrevAdds![idx].residentFrom! ||
        moment(propsPrevAdds[idx].residentTo!).isSameOrAfter(now) ||
        propsPrevAdds[idx].building === '' ||
        propsPrevAdds[idx].county === '' ||
        propsPrevAdds[idx].street === '' ||
        propsPrevAdds[idx].country === '' ||
        propsPrevAdds[idx].town === ''
      )) setHavePrevAddresses(false);    
    else setHavePrevAddresses(true);

    if(utilFunctions.testRegex(postCodeRegex, propsPrevAdds![idx].postCode) === true && 
      utilFunctions.testRegex(genericRegex, propsPrevAdds![idx].building) === true && 
      utilFunctions.testRegex(genericRegex, propsPrevAdds![idx].country) === true && 
      utilFunctions.testRegex(genericRegex, propsPrevAdds![idx].street) === true && 
      utilFunctions.testRegex(genericRegex, propsPrevAdds![idx].county) === true &&
      utilFunctions.testRegex(genericRegex, propsPrevAdds![idx].town) === true) setHaveRespondedCurrAddress(true);
    else setHaveRespondedCurrAddress(false);
 
    setPrevAddressValues(propsPrevAdds);

    if (propsPrevAdds[idx] && !propsPrevAdds[idx].isNew) {
      setIsEditRequest(true);
      acceptAddressDetails();
    }
  };

  //******** End - Previous Address Event Handlers**** */

  //******** Start - PUT/Save Address Data Handlers**** */
  const acceptAddressDetails = () => {
    let addressInfo: IAddressInfo = InitialIAddressInfo;
    addressInfo.currentAddress = currAddressValue;

    let prevAddresses = _.map(prevAddressValues, (paddress: InfoTypes.AddressInfo) => {
      return { ...paddress, country: paddress.country ? paddress.country : "" };
    });

    addressInfo.previousAddresses = prevAddresses;

    const addressData: IAddressInfo = utilFunctions.cleanseJson(addressInfo);

    let prepAddressInfoData: IComplianceProcess = {
      id: props.complianceProcessData!.id,
      complianceType: props.complianceProcessData!.complianceType,
      processData: { addresses: addressData },
    };

    setAddressInfoRequest(prepAddressInfoData);
  };

  const SendPutAddressInfoData = async (addressInfoRequest: IComplianceProcess) => {
    setLoading(true);

    useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + props.complianceProcessData!.id, {
      method: "PUT",
      body: addressInfoRequest,
    })
      .then((res) => {
        if (res.ok) {
          if (props.handleSubmit && !isEditRequest) {
            props.handleSubmit(addressInfoRequest, Constants.COMPLIANCE_STEPS_EDITORS.ADDRESS_INFO);
          }
        } else {
          handleSaveError(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (props.errorHandler) {
          props.errorHandler(t("errors.save_error_msg"));
        }
      });

    setIsEditRequest(false);
  };

  const handleSaveError = (errors?: IValidationFailure[]) => {
    if (props.errorHandler) {
      if (errors) {
        let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";
        _.map(errors, (err) => {
          errorMsg = errorMsg.concat("<li>", err.errorMessage, "</li>");
        });
        errorMsg.concat("</ul>");
        props.errorHandler(errorMsg);
      } else {
        props.errorHandler(t("errors.save_error_msg"));
      }
    }
  };

  useEffect(() => {
    async function SendPutConvictionsDataRequest() {
      if (addressInfoRequest) {
        await SendPutAddressInfoData(addressInfoRequest);
      }
    }

    SendPutConvictionsDataRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressInfoRequest]);

  //******** End - PUT/Save Personal Data Handlers**** */

  return (
    <>
      <div
        id="address-history"
        className="address-history editor-component"
      >
        <div className="address-history-body">
          <div className="address-history-body-container">
            <Heading
              title={
                props.uiContent && props.uiContent.title !== ""
                  ? props.uiContent!.title
                  : t("address_history.current_address_info")
              }
            />
            <div className="address-history-body-container-details">
              {props.uiContent?.content && props.uiContent.content !== "" ? (
                <div className="address-history-body-container-details-row editor-component-body">
                  {props.uiContent.content !== "" ? (
                    <div
                      className="editor-component-body"
                      dangerouslySetInnerHTML={{
                        __html: props.uiContent!.content.replace(/(<? *script)/gi, "illegalscript"),
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="postCode"
                    value={(propsAddressInfoData.currentAddress && propsAddressInfoData.currentAddress.postCode) ? 
                      propsAddressInfoData.currentAddress.postCode : ""}
                    editValue={currAddressValue.postCode}
                    label={t("address_history.postal_code")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "postCode",
                        ImportableTypeEditor.AddressInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleAddressChange("postCode")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditAddressClicked(true);
                      handleEditAddressFieldChange(
                        "postCode",
                        t("address_history.postal_code"),
                        propsAddressInfoData.currentAddress.postCode
                      );
                    }}
                    error={handleAddressErrors(currAddressValue.postCode, 'code')}
                    helperText={handleAddressHelperText(currAddressValue.postCode, 'code')}
                  />
                </div>
                <div className="oneHalfWidth"></div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName={"building"}
                    value={(propsAddressInfoData.currentAddress && propsAddressInfoData.currentAddress.building) ? 
                      propsAddressInfoData.currentAddress.building : ""}
                    editValue={currAddressValue.building}
                    label={t("address_history.building")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "building",
                        ImportableTypeEditor.AddressInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleAddressChange("building")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditAddressClicked(true);
                      handleEditAddressFieldChange(
                        "building",
                        t("address_history.building"),
                        propsAddressInfoData!.currentAddress.building
                      );
                    }}
                    error={handleAddressErrors(currAddressValue.building, 'text')}
                    helperText={handleAddressHelperText(currAddressValue.building, 'text')}
                  />
                </div>
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="street"
                    value={(propsAddressInfoData.currentAddress &&  propsAddressInfoData.currentAddress.street) ? 
                        propsAddressInfoData.currentAddress.street : ""
                    }
                    editValue={currAddressValue.street}
                    label={t("address_history.street")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "street",
                        ImportableTypeEditor.AddressInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    inputProps={{ maxLength: 60 }}
                    onChangeValue={handleAddressChange("street")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditAddressClicked(true);
                      handleEditAddressFieldChange(
                        "street",
                        t("address_history.street"),
                        propsAddressInfoData!.currentAddress.street,
                        { maxLength: 60 }
                      );
                    }}
                    error={handleAddressErrors(currAddressValue.street, 'text')}
                    helperText={handleAddressHelperText(currAddressValue.street, 'text')}
                  />
                </div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="town"
                    value={(propsAddressInfoData.currentAddress && propsAddressInfoData.currentAddress.town) ? 
                      propsAddressInfoData.currentAddress.town : ""}
                    editValue={currAddressValue.town}
                    label={t("address_history.town")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "town",
                        ImportableTypeEditor.AddressInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleAddressChange("town")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditAddressClicked(true);
                      handleEditAddressFieldChange(
                        "town",
                        t("address_history.town"),
                        propsAddressInfoData!.currentAddress.town,
                        { maxLength: 30 }
                      );
                    }}
                    inputProps={{ maxLength: 30 }}
                    error={handleAddressErrors(currAddressValue.town, 'text')}
                    helperText={handleAddressHelperText(currAddressValue.town, 'text')}
                  />
                </div>
                <div className="oneHalfWidth"></div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="county"
                    value={(propsAddressInfoData.currentAddress && propsAddressInfoData.currentAddress.county) ? 
                      propsAddressInfoData.currentAddress.county : ""}
                    editValue={currAddressValue.county}
                    label={t("address_history.county_required")}
                    type="text"
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "county",
                        ImportableTypeEditor.AddressInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleAddressChange("county")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditAddressClicked(true);
                      handleEditAddressFieldChange(
                        "county",
                        t("address_history.county"),
                        propsAddressInfoData!.currentAddress.county,
                        { maxLength: 30 }
                      );
                    }}
                    inputProps={{ maxLength: 30 }}
                    error={handleAddressErrors(currAddressValue.county, 'text')}
                    helperText={handleAddressHelperText(currAddressValue.county, 'text')}
                  />
                </div>
                <div className="oneHalfWidth">
                  <SelectEditor
                    id="country"
                    value={(propsAddressInfoData.currentAddress && propsAddressInfoData.currentAddress.country) ? 
                      propsAddressInfoData.currentAddress.country : ""}
                    editValue={currAddressValue.countryVal}
                    label={t("common.country")}
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "country",
                        ImportableTypeEditor.AddressInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    dataSet={countriesData}
                    handleChange={handleAddressSelectChange("country")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditAddressClicked(true);
                      handleEditAddressFieldChange(
                        "country",
                        t("common.country"),
                        currAddressValue.country
                      );
                    }}
                    error={handleAddressErrors(currAddressValue.country, 'text')}
                  />
                  <FormHelperText className="error MuiFormHelperText-contained">{handleAddressHelperText(currAddressValue.country, 'text')}</FormHelperText>
                </div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <DateEditor
                    value={(propsAddressInfoData.currentAddress && propsAddressInfoData.currentAddress.residentFrom) ? 
                      propsAddressInfoData.currentAddress.residentFrom : null}
                    editValue={currAddressValue.residentFrom}
                    label={t("address_history.resident_from")}
                    showEdit={
                      props.isCheckDataEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "residentFrom",
                        ImportableTypeEditor.AddressInfo,
                        props.complianceProcessData!
                      )
                    }
                    isCheckEditable={props.isCheckDataEditable}
                    onChangeValue={handleDateChange("residentFrom")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditAddressClicked(true);
                      handleEditAddressFieldChange(
                        "residentFrom",
                        t("address_history.resident_from"),
                        propsAddressInfoData!.currentAddress.residentFrom
                      );
                    }}
                    error={handleAddressErrors(currAddressValue.residentFrom, 'date')}
                    helperText={handleAddressHelperText(currAddressValue.residentFrom, 'date')}
                  />
                </div>
                <div className="oneHalfWidth"></div>
              </div>
            </div>
          </div>
          <div className="address-history-body-container">
            <div className="address-history-body-container-details-row">
              <div className="address-history-body-container-details-row">
                <div className="fullWidth question-field">
                  <div className="container-details-row-labels">
                    {t("address_history.address_history_ques")}
                  </div>
                  <div className="check-box-ctrls">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={haveRespondedPrevAddress && hasPrevAddressYes}
                            disabled={!props.isCheckDataEditable || !haveRespondedCurrAddress}
                            onClick={() => handleTogglePrevAddressAdd("yes")}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={haveRespondedPrevAddress && hasPrevAddressNo}
                            disabled={!props.isCheckDataEditable}
                            onClick={() => handleTogglePrevAddressAdd("no")}
                          />
                        }
                        label="No"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
            {propsAddressInfoData! &&
            propsAddressInfoData.previousAddresses &&
            haveRespondedPrevAddress
              ? _.map(
                  propsAddressInfoData.previousAddresses,
                  (prevAdd: InfoTypes.AddressInfo, pAddressIdx: number) => (
                    <React.Fragment key={`address-info-prevAddress-main-${pAddressIdx}`}>
                      <div
                        key={`address-info-prevAddress-title-${pAddressIdx}`}
                        className="address-history-body-container-title editor-component-title icon-btns"
                      >
                        {t("address_history.prev_address")}
                        {props.isProfileView ? (
                          props.isCheckDataEditable ? (
                            <>
                              <span>
                                {
                                  <AddCircleOutlineIcon
                                    onClick={handleAddPreviousAddress}
                                    style={{ float: "right" }}
                                    className="icon-size-big"
                                  />
                                }
                              </span>{" "}
                              {"  "}
                              <span>
                                {
                                  <DeleteIcon
                                    onClick={() => handleRemovePreviousAddress(pAddressIdx)}
                                    style={{ float: "right" }}
                                    className="icon-size-big"
                                  />
                                }
                              </span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <PreviousAddress
                        key={`address-prevAddress-${pAddressIdx}`}
                        haveRespondedPrevAddress={haveRespondedPrevAddress}
                        isProfileView={props.isProfileView}
                        pAddressIdx={pAddressIdx}
                        prevAddressData={prevAdd}
                        currAddressData={currAddressValue}
                        updatedData={(pAdd, idx) => handleUpdatedPreviousAddressData(pAdd, idx)}
                        isCheckDataEditable={props.isCheckDataEditable}
                        linkedPrevAddress={propsAddressInfoData}
                      />
                    </React.Fragment>
                  )
                )
              : ""}
          </div>
          {props.isProfileView ? (
            props.isCheckDataEditable ? (
              <div className="address-history-body-container-details-row-submit">
                <div className="container-submit-btn">
                  <GenericButton
                    onClick={() => {
                      acceptAddressDetails();
                    }}
                    disabled={(!haveRespondedPrevAddress || 
                      !haveRespondedCurrAddress || 
                      !havePrevAddresses || 
                      !hasMinResidentFromAddressHistory
                    )}
                    type="primary"
                    buttonText={t("common.accept")}
                  ></GenericButton>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <LoaderModal isLoading={loading} />
        </div>
      </div>
      {isEditAddressClicked ? (
        <EditAddressModal
          isEditIconClicked={isEditAddressClicked}
          addressValueState={currAddressValue}
          selectedFieldToEdit={selectedFieldToEdit}
          onCloseEditAddressModal={onEditAddressModalClose}
          onEditAddressSaveCallback={handleEditAddressSaveCallback}
          firstIndexPrevAddressValueState={prevAddressValues[0]}
        />
      ) : (
        ""
      )}
    </>
  );
}
