import React, { ChangeEvent, useEffect, useState } from "react";
import "./index.scss";
import GenericTextField from "../../Components/GenericTextField";
import GenericSelect from "../../Components/GenericSelect";
import GenericDatePicker from "../../Components/GenericDatePicker";
import GenericButton from "../../Components/GenericButton";
import moment from "moment";
import GenericDataShow from "../../Components/GenericDataShow";
import GenericModal from "../../Components/GenericModal";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import { GenericLoader } from "../../Components/GenericLoader";
import IProfilePatchRequest from "../../interfaces/IProfilePatchRequest";
import useAPIRequest from "../../Service/useAPIRequest";
import { useTranslation } from "react-i18next";
import utilFunctions from "../../utils/util";
import { FormHelperText } from "@material-ui/core";
import { RegexEnum } from "../../Constants/enums";
import useSelectedPostCodeRegex from "../../utils/useSelectedPostCodeRegex";
import InfoTypes from "../../interfaces/InfoTypes";

interface EditAddressModalProps {
  selectedFieldToEdit: {
    selectedFieldTitle: string;
    selectedFieldId: any;
    selectedFieldValue: any;
    inputProps?: { [key: string]: any };
  };
  addressValueState: EditAddressState;
  isEditIconClicked: boolean;
  onCloseEditAddressModal: () => void;
  onEditAddressSaveCallback: (propName: keyof EditAddressState, val: {} | string) => void;
  firstIndexPrevAddressValueState: InfoTypes.PreviousAddress;
}

interface EditAddressState {
  building: string;
  street: string;
  county: string;
  country: string;
  town: string;
  postCode: string;
  residentFrom: Date | null;
  residentTo: Date | null;
}

const InitialPatchRequest: IProfilePatchRequest = {
  path: "",
  newValue: "",
  reason: "",
};

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);

export default function EditAddressModal(props: EditAddressModalProps) {
  const { t } = useTranslation();
  const oneRef = React.createRef();
  const [isEditAddressClicked, setIsEditAddressClicked] = useState(true);
  const [editAddressFieldNewValue, setEditAddressFieldNewValue] = useState("");
  const [editAddressDateNewValue, setEditAddressDateNewValue] = useState<any>(null);
  const [editAddressFieldReason, setEditAddressFieldReason] = useState("");
  const [editAddressSelectedValue, setEditAddressSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [patchRequested, setPatchRequest] = useState(InitialPatchRequest);
  const [editAddressFieldError, setEditAddressFieldError] = useState(false);
  const [editAddressFieldHelperText, setEditAddressFieldHelperText] = useState<string>("");
  const [editAddressReasonError, setEditAddressReasonError] = useState(false);
  const [editAddressReasonHelperText, setEditAddressReasonHelperText] = useState<string>("");
  const [editAddressDateError, setEditAddressDateError] = useState(false);
  const [editAddressDateHelperText, setEditAddressDateHelperText] = useState<string>("");
  const selectedPostCodeRegex = useSelectedPostCodeRegex(props.addressValueState.country);

  const SendPatch = async (patchRequest: IProfilePatchRequest) => {
    useAPIRequest(Constants.API_URLS.PATCH_WORKER_PROFILE, {
      method: "PATCH",
      body: patchRequest,
    });
    props.onEditAddressSaveCallback(
      props.selectedFieldToEdit.selectedFieldId,
      props.selectedFieldToEdit.selectedFieldId === "residentFrom"
        ? editAddressDateNewValue
        : editAddressFieldNewValue
    );
    onEditAddressModalClose();
  };

  useEffect(() => {
    async function SendPatchRequest() {
      if (patchRequested.path !== "") {
        await SendPatch(patchRequested);
      }
    }
    SendPatchRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequested]);

  useEffect(() => {
    setIsEditAddressClicked(props.isEditIconClicked);
  }, [props.isEditIconClicked]);

  const countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);

  const onEditAddressModalClose = (e?: EventTarget) => {
    setIsEditAddressClicked(false);
    setEditAddressFieldNewValue("");
    setEditAddressFieldReason("");
    setEditAddressSelectedValue("");
    props.onCloseEditAddressModal();
  };

  const handleEditAddressFieldChange =
    (prop: keyof EditAddressState, key?: string) => (e: ChangeEvent<{}>, val?: any) => {
      setEditAddressFieldNewValue(prop === "postCode" ? val.toUpperCase() : val);
      setEditAddressSelectedValue(val.id);
    };

  const handleEditAddressReasonChange = (event: ChangeEvent<HTMLInputElement>, val: string) => {
    setEditAddressFieldReason(val);
  };

  const handleEditAddressDateChange = (prop: keyof EditAddressState) => (val: Date | null) => {
    setEditAddressDateNewValue(val!);
  };

  useEffect(() => {
    const todayIsDate = moment();
    const fieldType = props.selectedFieldToEdit.selectedFieldId;
    let fieldError = false;
    let fieldHelperText = '';
    let reasonError = false;
    let reasonHelperText = '';
    let dateError = false;
    let dateHelperText = '';
  
    if (editAddressFieldNewValue === '') {
      fieldError = true;
      fieldHelperText = t("validators.required");
    } else {
      const isPostCodeField =  fieldType === "postCode";
      const isRegexValid = !utilFunctions.testRegex(isPostCodeField ? selectedPostCodeRegex : genericRegex, editAddressFieldNewValue);
      const isCountryField = (fieldType === "country") ? "" : t("validators.special_chars");
      const regexErrorText = isPostCodeField ? t("validators.invalid_postcode") : isCountryField;
      fieldError = (fieldType === "country") ? false : isRegexValid;
      fieldHelperText = isRegexValid ? regexErrorText : '';
    }
  
    if (editAddressFieldReason === '') {
      reasonError = true;
      reasonHelperText = t("validators.required");
    } else {
      const isReasonFieldValid = !utilFunctions.testRegex(genericRegex, editAddressFieldReason);
      reasonError = isReasonFieldValid;
      reasonHelperText = isReasonFieldValid ? t("validators.special_chars") : '';
    }
  
    if (editAddressDateNewValue === null || editAddressDateNewValue === 'Invalid date') {
      dateError = true;
      dateHelperText = t("errors.is_valid_date_or_empty_msg");
    } else if (moment(editAddressDateNewValue) > todayIsDate) {
      dateError = true;
      dateHelperText = t("errors.current_date_greater_msg");
    } else if (props.firstIndexPrevAddressValueState && (moment(props.firstIndexPrevAddressValueState.residentFrom)) >= moment(editAddressDateNewValue)) {
      dateError = true;
      dateHelperText = t("errors.previous_dates_current_dates_must_be_consecutive");
    }
  
    setEditAddressFieldError(fieldError);
    setEditAddressFieldHelperText(fieldHelperText);
    setEditAddressReasonError(reasonError);
    setEditAddressReasonHelperText(reasonHelperText);
    setEditAddressDateError(dateError);
    setEditAddressDateHelperText(dateHelperText);
  }, [editAddressFieldNewValue, 
    editAddressFieldReason, 
    editAddressDateNewValue, 
    t, 
    props.selectedFieldToEdit.selectedFieldId, 
    selectedPostCodeRegex, 
    props.firstIndexPrevAddressValueState
  ]);

  const acceptEditAddressChange = () => {
    setLoading(true);
    var path,
      newVal: any = "";
    path = "addresses.currentAddress." + props.selectedFieldToEdit.selectedFieldId;

    switch (props.selectedFieldToEdit.selectedFieldId) {
      case "country":
        newVal = editAddressSelectedValue;
        break;
      case "residentFrom":
      case "residentTo":
        newVal = moment(editAddressDateNewValue).format(Constants.DATE_FORMAT_DISPLAY);
        break;
      case "building":
      case "street":
      case "postCode":
      case "county":
      case "town":
        newVal = editAddressFieldNewValue;
        break;
      default:
        path = "";
        newVal = "";
        break;
    }
    let patchRequest: IProfilePatchRequest = {
      path: path,
      newValue: newVal,
      reason: editAddressFieldReason,
    };
    setPatchRequest(patchRequest);
  };

  const renderAddressInputComponent = () => {
    switch (props.selectedFieldToEdit.selectedFieldId!) {
      case "residentFrom":
      case "residentTo":
        return (
          <GenericDatePicker
            variant="inline"
            label={t("common.date_from_formatted")}
            id="date-picker-inline"
            inputVariant="outlined"
            required={props?.selectedFieldToEdit?.selectedFieldId !== "residentTo"}
            value={editAddressDateNewValue}
            onChangeValue={handleEditAddressDateChange("residentTo")}
            format={Constants.DATE_FORMAT_CALENDAR}
            enableFuture={props.selectedFieldToEdit.selectedFieldId === "residentTo"}
            fullWidth={true}
            error={editAddressDateError}
            helperText={editAddressDateHelperText}
          />
        );
      case "country":
        return (
          <>
            <GenericSelect
              id="country"
              value={editAddressFieldNewValue}
              dataSet={countriesData}
              fullWidth={true}
              handleChange={handleEditAddressFieldChange("country")}
              multiple={false}
              freeSolo={false}
              label={t("address.country")}
              renderChip={false}
              required={true}
              error={editAddressFieldError}
            />
            <FormHelperText className="helper-text-error MuiFormHelperText-contained">
              {editAddressFieldHelperText}
            </FormHelperText>
          </>
        );
      case "building":
      case "street":
      case "postCode":
      case "county":
      case "town":
        return (
          <GenericTextField
            label={props.selectedFieldToEdit.selectedFieldTitle}
            name={props.selectedFieldToEdit.selectedFieldId}
            type="text"
            id={props.selectedFieldToEdit.selectedFieldId}
            variant="outlined"
            margin="dense"
            fullWidth={true}
            required={props.selectedFieldToEdit.selectedFieldId! !== "county"}
            inputProps={props.selectedFieldToEdit?.inputProps}
            value={editAddressFieldNewValue}
            inputRef={oneRef}
            onChangeValue={handleEditAddressFieldChange(props.selectedFieldToEdit.selectedFieldId)}
            error={editAddressFieldError}
            helperText={editAddressFieldHelperText}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <GenericModal
        heading={t("address.edit_details")}
        onCloseModal={(e?: EventTarget) => onEditAddressModalClose(e)}
        open={props.isEditIconClicked && isEditAddressClicked}
        closeOnBackDrop={true}
        modalWidth={"500px"}
        modalHeight={"400px"}
        modalTop={"15%"}
        className="personal-Address-edit-modal"
      >
        <div className="personal-Address-edit-modal">
          {loading ? (
            <div style={{ marginTop: "50px", textAlign: "center" }}>
              <GenericLoader />
              <div className="loading-text">{t("common.saving")}</div>
            </div>
          ) : (
            <>
              <div className="fullWidth">
                <GenericDataShow
                  label={props.selectedFieldToEdit.selectedFieldTitle}
                  value={
                    props.selectedFieldToEdit.selectedFieldId === "residentFrom" ||
                    props.selectedFieldToEdit.selectedFieldId === "residentTo"
                      ? moment(props.selectedFieldToEdit.selectedFieldValue).format(
                          Constants.DATE_FORMAT_DISPLAY
                        )
                      : props.selectedFieldToEdit.selectedFieldValue
                  }
                  isSelectField={props.selectedFieldToEdit.selectedFieldId === "country"}
                />
              </div>
              <div className="fullWidth">{renderAddressInputComponent()}</div>
              <div className="fullWidth">
                <GenericTextField
                  label={t("common.reason_for_change")}
                  name={"reasonForChange"}
                  type="text"
                  id={"reasonForChange"}
                  variant="outlined"
                  margin="dense"
                  fullWidth={true}
                  required
                  value={editAddressFieldReason}
                  inputRef={oneRef}
                  onChangeValue={handleEditAddressReasonChange}
                  error={editAddressReasonError}
                  helperText={editAddressReasonHelperText}
                />
              </div>
              <div
                className="fullWidth"
                style={{ width: "100%", display: "flex", justifyContent: "center" }}
              >
                <GenericButton
                  onClick={async () => {
                    acceptEditAddressChange();
                  }}
                  disabled={
                    props.selectedFieldToEdit.selectedFieldId === "residentFrom" ||
                    props.selectedFieldToEdit.selectedFieldId === "residentTo"
                      ? editAddressDateError || editAddressReasonError
                      : editAddressFieldError || editAddressReasonError
                  }
                  type="primary"
                  buttonText={t("common.save")}
                />
              </div>
            </>
          )}
        </div>
      </GenericModal>
    </>
  );
}
