import * as React from "react";
import { CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export type StyleType = {
  color: string,
  width: string,
  height: string,
  margin: string,
  right: string,
  top: string
}
export interface ModalProps {
  style?: Partial<StyleType>;
  bottomLoaderHeight?: string
  bottomLoaderWidth?: string
  topLoaderHeight?: string
  topLoaderWidth?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderCustom: {
      position: "relative",
      bottomLoader: {
        position: "absolute",
        color:  "rgb(223, 227, 230)  !important",
      },
      topLoader: {
        color:  "rgba(4, 45, 118, 1) !important",
        position: "absolute",
      }
    }
  })
);

export const GenericLoader: React.FC<ModalProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.loaderCustom} style={props.style}>
      <CircularProgress
        variant="indeterminate" value={5}
        disableShrink
        className="topLoader"
        size={40}
        thickness={4}
        style={{
          height: props.topLoaderHeight || '41px',
          width: props.topLoaderWidth || '41px'
        }}
      />
    </div>
  )
}