import { useEffect, useState } from "react";
import "../../VerifyIdentity/index.scss";
import GenericButton from "../../../Components/GenericButton";
import Constants from "../../../Constants/constants";
import useAPIRequest from "../../../Service/useAPIRequest";
import { useTranslation } from "react-i18next";
import GenericModal from "../../../Components/GenericModal";
import IImportableDigitalData from "../../../interfaces/IImportableDigitalData";
import { LoaderModal } from "../../../Components/LoaderModal";
import { GenericLoader } from "../../../Components/GenericLoader";
import GenericAlert from "../../../Components/GenericAlert";
import DigitalIdentityDataPersonal from "./DigitalIdentity-Personal";
import DigitalIdentityDataAddress from "./DigitalIdentity-Address";
import DigitalIdentityDataDocuments from "./DigitalIdentity-Documents";

interface ImportDigitalIdentityDataProps {
  digitalIdentityData: IImportableDigitalData;
  complianceId: string;
  confirmImportModalOpen: boolean;
  onconfirmImportCallback?: () => void;
  onCloseConfirmImportModal: () => void;
  handleNextStep: () => void;
}

export default function ImportDigitalIdentityData(props: ImportDigitalIdentityDataProps) {
  const { t } = useTranslation();
  const [openConfirmImportModal, setOpenConfirmImportModal] = useState(true);
  const [loaderModal, setLoaderModal] = useState(false);
  const [digitalIDData, setDigialIDData] = useState<IImportableDigitalData>();
  const [showOutcome, setShowOutcome] = useState(false);
  const [showError, setShowError] = useState(false);
  const [actionMessage, setActionMessage] = useState("");

  useEffect(() => {
    setOpenConfirmImportModal(props.confirmImportModalOpen);
    if (props.confirmImportModalOpen) {
      GetDigitalIDExtracted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.confirmImportModalOpen]);

  const GetDigitalIDExtracted = () => {
    useAPIRequest(
      Constants.API_URLS.DIGITAL_ID_SUFFIX +
        props.complianceId +
        Constants.API_URLS.GET_DIGITAL_ID_DATA,
      {
        method: "GET",
        body: "",
      }
    )
      .then((res) => {
        if (res && res !== undefined) {
          setDigialIDData(res);
        }
      })
      .catch(() => {});
  };

  const onEditAddressModalClose = (e?: EventTarget) => {
    setOpenConfirmImportModal(false);
    props.onCloseConfirmImportModal();
  };

  const ActionDigitalIdentityData = (isImport: boolean) => {
    let url: string = "";
    if (isImport) {
      url = `${Constants.API_URLS.DIGITAL_ID_SUFFIX}${props.complianceId}${Constants.API_URLS.PUT_DIGITAL_ID_IMPORT}`;
    } else {
      url = `${Constants.API_URLS.DIGITAL_ID_SUFFIX}${props.complianceId}${Constants.API_URLS.PUT_DIGITAL_ID_REJECT}`;
    }

    setLoaderModal(true);
    useAPIRequest(url, {
      method: "PUT",
      body: null,
    })
      .then((res) => {
        if (res.ok) {
          setShowError(false);
          setActionMessage(
            isImport ? t("digital_identity.import_success") : t("digital_identity.import_cancelled")
          );
          if(isImport) props.handleNextStep();
        } else {
          setShowError(true);
          setActionMessage(t("errors.error_msg"));
        }
        setShowOutcome(true);
      })
      .catch(() => {
        setShowError(true);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
    setLoaderModal(false);
  };

  return (
    <>
      <GenericModal
        heading={t("digital_identity.header_text")}
        onCloseModal={(e?: EventTarget) => onEditAddressModalClose(e)}
        open={props.confirmImportModalOpen && openConfirmImportModal && digitalIDData !== undefined}
        closeOnBackDrop={false}
        modalWidth={"auto"}
        hasFooterContent={true}
        modalHeight={"auto"}
        hideModalClose
        modalTop={"0%"}
        className="confirm-import-modal"
      >
        <div>
          <div className="confirm-import-modal">
            {digitalIDData !== undefined || digitalIDData !== null ? (
              <>
                <div className="fullWidth data-fields">
                  <div
                    className="fullWidth data-field-sub-title"
                    aria-label={t("digital_identity.sub_header_text")}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("digital_identity.sub_header_text").replace(
                          /(<? *script)/gi,
                          "illegalscript"
                        ),
                      }}
                    ></div>
                  </div>
                </div>
                <DigitalIdentityDataPersonal digitalIdentityData={digitalIDData!} />

                {digitalIDData?.currentAddress && (
                  <DigitalIdentityDataAddress digitalIdentityData={digitalIDData!} />
                )}

                {digitalIDData?.documents && (
                  <DigitalIdentityDataDocuments digitalIdentityData={digitalIDData!} />
                )}

                <LoaderModal
                  isLoading={loaderModal}
                  message={"digital_identity.action_message"}
                />
              </>
            ) : (
              <GenericLoader />
            )}
          </div>

          <div
            className="fullWidth import-actions modal-content-footer"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <GenericButton
              onClick={() => {
                ActionDigitalIdentityData(true);
              }}
              disabled={false}
              type="primary"
              buttonText={t("digital_identity.import")}
            />
            <GenericButton
              onClick={() => {
                ActionDigitalIdentityData(false);
              }}
              disabled={false}
              type="secondary"
              buttonText={t("common.cancel")}
            />
          </div>
          <GenericAlert
            showButtons={true}
            showSecondaryBtn={false}
            error={showError}
            showSuccessIcon={!showError}
            primaryButtonText={t("common.ok")}
            onCloseModal={() => {
              setShowOutcome(false);
              setOpenConfirmImportModal(false);
              props.onCloseConfirmImportModal();
            }}
            onPrimaryButtonClicked={() => {
              setShowOutcome(false);
              setOpenConfirmImportModal(false);
              props.onCloseConfirmImportModal();
            }}
            open={showOutcome}
            modalHeight={"250px"}
            modalWidth="620px"
            modalMargin="200px auto"
          >
            <div className="modal-input">{actionMessage}</div>
          </GenericAlert>
        </div>
      </GenericModal>
    </>
  );
}
