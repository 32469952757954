import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

interface PropsCompoenent {
  variant: "dialog" | "inline" | "static";
  label: string;
  name?: string;
  id: "date-picker-dialog" | "date-picker-inline";
  inputVariant: "standard" | "filled" | "outlined" | undefined; // 'filled' | 'outlined' | 'standard'
  value: Date | null;
  onChangeValue: (val: Date | null) => void;
  error?: boolean;
  helperText?: string;
  InputProps?: {
    endAdornment?: JSX.Element;
  };
  margin?: "none" | "dense" | "normal";
  disabled?: boolean;
  disablePast?: boolean;
  inputProps?: {
    [key: string]: string | Function;
  };
  fullWidth?: boolean;
  required?: boolean;
  enableFuture?: boolean;
  inputRef?: React.Ref<any>;
  className?: string;
  format: string;
  readOnly?: boolean;
  inputReadOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
  views?: any;
  defaultValue?: Date;
}

const DateField: any = withStyles({
  root: {
    "& .MuiFormLabel-asterisk": {
      color: "#2d7ff9",
    },
    fontFamily: "Montserrat-Regular",
    "& label.Mui-focused": {
      color: "#757575",
      fontFamily: "Montserrat-Regular",
    },
    " & .MuiOutlinedInput-root": {
      height: "4px;",
    },
    "& label.MuiInputLabel-outlined": {
      fontFamily: "Montserrat-Regular",
      textAlign: "left !important",
      whiteSpace: "break-spaces",
      marginTop: "-5px",
    },
    "& .MuiFormControl-root ": {
      fontFamily: "Montserrat-Regular",
    },
    "& label.Mui-disabled": {
      color: "#ECECEC !important",
    },

    "& label.MuiInputLabel-shrink": {
      color: "#343C44",
      fontFamily: "Montserrat-Regular",
      lineHeight: "12px",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "0px",
      height: "12px",
    },
    "& input.MuiInputBase-input": {
      color: "#343C44",
      fontFamily: "Montserrat-Regular",
      fontSize: "14px",
      fontWeight: "600",
      height: "16px",
      letterSpacing: "0px",
      "&.Mui-disabled": {
        opacity: "0.72 !important",
      },
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-disabled": {
        opacity: "0.72 !important",
        border: "1px solid #E0E0E0",
        borderRadius: "10px",
      },
      "&input": {
        border: "2px solid #757575",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px rgb(255, 255, 255)inset",
        },
      },

      "& textarea": {
        overflow: "auto",
        height: "auto",
      },

      "& fieldset": {
        border: "1px solid #757575",
        borderRadius: "10px",
        "& legend": {
          "& span": {
            padding: "0px",
            fontFamily: "Montserrat-Medium",
          },
        },
        color: "red",
        borderColor: "#757575",
      },

      "&:hover fieldset": {
        // hover
        border: "2px solid #478FFA",
      },
      // focus active
      "&.Mui-focused fieldset": {
        border: "1px solid #478FFA",
      },
      "  MuiInputLabel": {
        marginTop: "2px !important",
        outlined: {
          "&$shrink": {
            color: "rgb(49, 49, 49)",
            transform: "translate(0px, 0px) scale(0.75)",
          },
        },
      },
    },
    "& .Mui-error": {
      "&.MuiFormHelperText-root.Mui-error": {
        color: "rgb(209, 0, 0)",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #D74D26",
        borderColor: "#D74D26",
      },
      "&:hover fieldset": {
        border: "1px solid rgb(209, 10, 67)",
      },
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgb(209, 0, 0)",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(209, 0, 0)",
    },
  },
})(KeyboardDatePicker);

export default function GenericDatePicker(props: PropsCompoenent) {
  // The first commit of Material-UI
  const handleDateChange = (date: Date | null) => {
    props.onChangeValue(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateField
        variant={props.variant}
        inputVariant={props.inputVariant}
        format={props.format}
        margin={props.margin}
        id={props.id}
        label={props.label}
        value={props.value}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disabled={props.disabled}
        disablePast={props.disablePast}
        disableFuture={!props.enableFuture}
        required={props.required}
        helperText={props.helperText}
        className={props.className}
        fullWidth={props.fullWidth}
        error={props.error}
        inputRef={props.inputRef}
        InputLabelProps={{
          shrink: props.value ? true : false,
        }}
        InputProps={{
          disabled: props.inputReadOnly,
        }}
        autoOk={true}
        readOnly={props.readOnly}
        minDate={props.minDate}
        maxDate={props.maxDate}
        views={props.views}
        defaultValue={props.defaultValue}
      />
    </MuiPickersUtilsProvider>
  );
}
