import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";

import { DRAWER_LIST } from "../../Constants/menuList";
import MenuItem from "./MenuItem";
import "./index.scss";

const MenuItemsList = () => {
  const { pathname } = useLocation();

  return (
    <div className="leftSidebar">
      <Grid>
        <List className="menu-item-text">
          {DRAWER_LIST.map(({ literal, route, Icon }) => (
            <MenuItem
              Icon={Icon}
              literal={literal}
              route={route}
              key={route}
              selected={pathname === route}
            />
          ))}
        </List>
      </Grid>
    </div>
  );
};

export default MenuItemsList;
