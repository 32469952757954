import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import FeedbackRating from "../../Components/FeedbackRating";
import InfoTypes from "../../interfaces/InfoTypes";
import IFeedbackRating from "../../interfaces/IFeedbackRating";
import { useEffect, useState } from "react";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import Heading from "../../Components/Heading";
import { useTranslation } from "react-i18next"
import { LoaderModal } from "../../Components/LoaderModal";
import { ImportStatus } from "../../Constants/enums";

type CheckOutroProps = {
  content: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
  handleSubmit: (feedback: IFeedbackRating, complianceData: IComplianceProcess) => void;
  isCheckDataEditable: boolean;
  isCheckSubmissionDisabled? : boolean;
  isSubmissionLaunched? : boolean;
  isDocumentSubmissionComplete? : boolean;
}

export default function CheckOutro(props: CheckOutroProps) {
  const { t } = useTranslation();
  const [ratingLevel, setRatingLevel] = useState(0);
  const [ratingContent, setRatingContent] = useState("");
  const [isDisabled, setDisabled] = useState(false);

  const handleProcessFeedbackRating = (ratingValue: number) => {
    setRatingLevel(ratingValue);
  }

  const handleProcessFeedbackContent = (val: string)  => {
    setRatingContent(val);
  }
  
  const handleSubmit = () => {
    var feedbackObj: IFeedbackRating = {
      comment: ratingContent,
      sourceId: props.complianceProcessData.id,
      level: ratingLevel,
      type: props.complianceProcessData.complianceType
    }
    props.handleSubmit(feedbackObj, props.complianceProcessData);
  }

  useEffect(() => {
    // if(props.isCheckSubmissionDisabled) setDisabled(props.isCheckSubmissionDisabled)
    // else setDisabled(false);

    if(props.isSubmissionLaunched) setDisabled(props.isSubmissionLaunched);
  }, [props.isCheckSubmissionDisabled, props.isSubmissionLaunched]);
  
  return (
    <div id="check-outro" className="check-outro editor-component">
      <div className="check-outro-body">
        <div className="check-outro-body-container">
          <Heading title={(props.content && props.content!.title !== "") ? props.content!.title : t("outro.outro_title") } />
          <div className="check-outro-body-container-details-row editor-component-body">
          {(props.content) && (props.content.content) && props.content.content !== "" ?
            <div className="check-outro-body-container-details-row-content editor-component-body">
            { props.content.content !== ""
              ? (
                  <div dangerouslySetInnerHTML={{__html: props.content.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                  </div>
                )
              : ""
            }
            </div>
            : ""}
          </div>
          <div className="check-outro-body-container-details-row editor-component-body">
            <div className="check-outro-body-container-details-row-content">
              <div className="check-outro-body-container-details-row-content">
                <FeedbackRating handleFeedbackRating={handleProcessFeedbackRating}  handleFeedbackContent={handleProcessFeedbackContent} disabled={(!props.isCheckDataEditable)} />
              </div>
            </div>
          </div>
          <div className="check-outro-body-container-details-row editor-component-body">
            <div className="check-outro-body-container-details-row-content act-btns">
              <div className="container-submit-btn">
                {(!props.isCheckDataEditable || 
                  props.isDocumentSubmissionComplete || (
                    props.complianceProcessData.digitalIdentity?.isComplete && 
                    props.complianceProcessData.digitalIdentity?.importStatus === ImportStatus.Imported)
                  ) ?
                  <>
                    <GenericButton
                      onClick={handleSubmit}
                      disabled={isDisabled}
                      style = {{width: "250px"}}
                      type="primary"
                      buttonText={isDisabled ? t("common.processing") : t("common.submit")}
                    ></GenericButton>
                  </>
                : ""}
                <LoaderModal
                  isLoading={isDisabled}
                  message={"digital_identity.action_message"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
