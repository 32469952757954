import React from "react";
import { Route, Redirect } from "react-router-dom";

interface NonProtectedRouteProps {
	component: React.ComponentType;
	path: string;
	exact: boolean;
	authenticated: boolean;
	redirectPath?: string;
}

const NonProtectedRoute: React.FC<NonProtectedRouteProps> = (props) => {
	return props.authenticated && props.redirectPath ? (
		<Redirect to={props.redirectPath} />
	) : (
		<Route path={props.path} exact={props.exact} component={props.component} />
	);
};
export default NonProtectedRoute;
