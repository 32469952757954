import { useState } from "react";
import "../VerifyIdentity/index.scss";
import GenericButton from "../../Components/GenericButton";
import { GenericLoader } from "../../Components/GenericLoader";
import { useTranslation } from "react-i18next";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import YotiIcon from "../../Assets/yoti-icon.png";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";

interface YotiDigitalIDProps {
  complianceProcessData: IComplianceProcess;
  isCheckEditable: boolean;
  onInfoSaveCallback: (res: any) => void;
}

export default function YotiDigitalID(props: YotiDigitalIDProps) {
  const { t } = useTranslation();
  const [loading] = useState(false);
  const [digitalIDCompleted] = useState(false);

  return (
    <>
      <div
        className=""
        style={{ justifyContent: "center", display: "flex" }}
      >
        {digitalIDCompleted ? (
          <span className="digital-id-message">
            {t("verify_identity.yoti_process_pending_msg")}
          </span>
        ) : props.complianceProcessData && props.complianceProcessData.digitalIdentity ? (
          props.complianceProcessData.digitalIdentity.isComplete ? (
            <span className="digital-id-message">
              {t("verify_identity.yoti_process_complete_msg")}
            </span>
          ) : props.complianceProcessData.digitalIdentity.validTo &&
            moment(props.complianceProcessData.digitalIdentity?.validTo).format(
              "YYYY-MM-DDTHH:mm:ss.SSSSZ"
            ) > moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSZ") ? (
            loading ? (
              <GenericLoader />
            ) : (
              <GenericButton
                onClick={() => {}}
                disabled={!props.isCheckEditable}
                style={{
                  width: isMobileOnly ? "200px" : "380px",
                  height: isMobileOnly ? "80px" : "60px",
                }}
                type="primary"
                iconSrc={YotiIcon}
                iconClassName="yoti-icon"
                isIconButton={true}
                buttonText={t("verify_identity.continue_digital_id_verification")}
              />
            )
          ) : (
            <span className="digital-id-message">
              {t("verify_identity.yoti_process_expired_msg")}
            </span>
          )
        ) : loading ? (
          <GenericLoader />
        ) : (
          <GenericButton
            onClick={() => {
              //TODO
            }}
            disabled={!props.isCheckEditable}
            style={{
              width: isMobileOnly ? "200px" : "380px",
              height: isMobileOnly ? "80px" : "60px",
            }}
            type="primary"
            iconSrc={YotiIcon}
            iconClassName="yoti-icon"
            isIconButton={true}
            buttonText={t("verify_identity.start_digital_id_verification")}
          />
        )}
      </div>
    </>
  );
}
