import React from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { useDrawerContext } from "../../Contexts/drawer-context";
import "./index.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import LogoImg from "../../Assets/LogoTC.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: 67,
    boxShadow: "none !important",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    maxHeight: 65,
    boxShadow: "none !important",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    width: "100%",
    minHeight: 65,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
  },
  icon: {
    padding: theme.spacing(1),
    fill: "#343c34",
  },
  menuButton: {
    marginRight: 30,
  },
  menuButtonHidden: {
    display: "none",
  },
}));

const Header = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user } = useAuth0();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={clsx(classes.appBar, isOpened && classes.appBarShift)}>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
      ></Typography>
      <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => toggleIsOpened(!isOpened)}
            className={clsx(
              classes.menuButton,
              isOpened && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
        <div className="menu">
          <div className={classes.toolbarIcon}>
            <a href="/dashboard">
              <img src={LogoImg} alt="Company logo" className="logo" />
            </a>
          </div>
        </div>
        <div>
          <IconButton
            className="header-icon"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <img
              className="profile-div"
              src={user?.picture}
              alt="user profile"
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            style={{ margin: "40px 0px" }}
          >
          {/* To-do :put it back when the profile page is working */} 
          {/*             
          <MenuItem onClick={handleClose} component={Link} to="/profile">
              {t("menu_items.profile")}
            </MenuItem>
          */}
            <MenuItem onClick={handleClose} component={Link} to="/logout">
              {t("menu_items.logout")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
