import { useMemo } from 'react';
import { CountryEnum, RegexEnum } from '../Constants/enums';

const genericPostCodeRegex: RegExp = new RegExp(RegexEnum.GENERIC_POSTCODE);
const UKPostCodeRegex: RegExp = new RegExp(RegexEnum.UK_POSTCODE);

const useSelectedPostCodeRegex = (countryId: CountryEnum | string): RegExp => {
  const selectedPostCodeRegex = useMemo(() => {
    let regexToSet: RegExp = genericPostCodeRegex;

    if (countryId === CountryEnum.GBR) {
      regexToSet = UKPostCodeRegex;
    }

    return regexToSet;
  }, [countryId]);

  return selectedPostCodeRegex;
};

export default useSelectedPostCodeRegex;
