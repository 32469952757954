import "./index.scss";
import { useHistory, useParams } from "react-router-dom";
import Constants from "../../Constants/constants";
import { BrowserView, isMobileOnly, MobileView } from "react-device-detect";
import { Box, IconButton, makeStyles, Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { IDContextProvider, useIDCheckContext } from "../../Contexts/idCheck-context";
import InfoTypes from "../../interfaces/InfoTypes";
import _ from "lodash";
import { ImportStatus, ProcessStatus } from "../../Constants/enums";
import { GenericLoader } from "../../Components/GenericLoader";
import { useEffect, useState } from "react";
import useAPIRequest from "../../Service/useAPIRequest";
import { useTranslation } from "react-i18next"
import GenericAlert from "../../Components/GenericAlert";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IFeedbackRating from "../../interfaces/IFeedbackRating";
import IImportableDigitalData from "../../interfaces/IImportableDigitalData";
import ImportDigitalIdentityData from "../Yoti/Import/ImportDigitalIdentityData";

const useStyles = makeStyles(() => ({
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    position: "sticky",
    top: "50vh",
    height: "100px"
  },
}));

type IDComplianceParams = {
  checkId: string;
};

export default function IDCompliance() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const { checkId } = useParams<IDComplianceParams>();
    const [feedback, setFeedback] = useState<IFeedbackRating>();

    const [loader, setLoader] = useState(false);
    const [callRefresh, setCallRefresh] = useState(false);
    const [complianceTypeSubmitData, setComplianceTypeSubmitData] = useState<IComplianceProcess>();
    let digitalIDImportableData: IImportableDigitalData = {};

 

    const { 
      idUIContent,
      activeStep,
      complianceProcessData,
      setComplianceProcessData,
      stepsContainer,
      handleNextStep,
      setActiveStep,
      setConfirmImportModalOpen,
      confirmImportModalOpen,
      isSubmissionComplete,
      isSubmissionError,
      setSubmissionError,
      setSubmissionComplete,
      showError,
      setShowError,
      errorMessage,
      setErrorMessage,
      setIsCheckEditable,
    } = useIDCheckContext();

    useEffect(() => {
      setCallRefresh(true);
      setLoader(true);
    }, []);

    useEffect(() => {
      async function SubmitFeedbackRequest() {
        if (feedback) {
            await SubmitFeedback(feedback);
        }
      }
      SubmitFeedbackRequest();
    }, [feedback]);

    const onCloseConfirmImportModal = () => {
      setConfirmImportModalOpen(false);
      setCallRefresh(true);
    };

    useEffect(() => {
      async function SendRefreshRequest() {
        if (callRefresh) {
          await RefreshComplianceProcessData();
        }
      }
      SendRefreshRequest();
    }, [callRefresh]);
    
    useEffect(() => {
      if (complianceProcessData) {
        setIsCheckEditable(complianceProcessData!.status === ProcessStatus.WaitingForWorker);
        if ((complianceProcessData.digitalIdentity!) && (complianceProcessData.digitalIdentity.isComplete) && (complianceProcessData.digitalIdentity.importStatus === ImportStatus.Pending)) {
          setConfirmImportModalOpen(true);
        }
      }
    }, [complianceProcessData]);
  
    const RefreshComplianceProcessData = () => {
      useAPIRequest(Constants.API_URLS.GET_WORKER_ROLE_COMPLIANCE + checkId, {
        method: "GET",
        body: "",
      }).then((res) => {
        setComplianceProcessData(res!);
        setCallRefresh(false);
        setLoader(false);
      }).catch(() => {
        setLoader(false);
      })
    };

    const handleBackStep = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const SubmitFeedback = async (feedback: IFeedbackRating) => {
      useAPIRequest(Constants.API_URLS.POST_FEEDBACK_RATING, {
        method: "POST",
        body: feedback
      })
    }
  
    return (
      (loader) ? 
      <GenericLoader />
    : 
    (complianceProcessData) ? 
      <div id="id-comp-wrapper" className="main-wrapper">
        <div className="header">
          <div className="title">{Constants.COMPLIANCE_TYPES_DISPLAY_VALUE.IdentityCheck} {"  "}
          </div>
        </div>
        <div className="compliance-container" style={{flexDirection: (isMobileOnly) ? "column" : "inherit" }}>
            <MobileView>
              <div className="container-content">
                <Box>
                  <Stepper key={activeStep} activeStep={activeStep} orientation="horizontal">
                  {idUIContent.steps.map((step) => (
                    <Step key={step.title}>
                      <StepLabel key={activeStep} className="step-label">
                        {""}
                      </StepLabel>
                    </Step>
                  ))}
                  </Stepper>
                </Box>
              </div>
            </MobileView>
            <div className="container-content">
              {complianceProcessData.status === ProcessStatus.WaitingForWorker && complianceProcessData.changesRequested ?
                <div className="complaince-change-request">
                  {_.map(complianceProcessData.changesRequested, (changeRequest: InfoTypes.ChangeRequest) => (
                    !(changeRequest.completeDate) ? 
                    <>
                    <div className="changes-requested-text">
                      <div dangerouslySetInnerHTML={{__html: changeRequest.message.replace(/(<? *script)/gi, 'illegalscript')}} >
                      </div>
                    </div>
                    </>
                    : ""
                  ))}
              </div> : ""}
              <div className="container-content-dtls">
                {(activeStep !== 0) ? 
                <IconButton color="inherit" className={classes.disableRipple}
                  disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
                  onClick={() => handleBackStep()}>
                  <ArrowBackIcon fontSize="large" />
                </IconButton> : "" }
                <div className="container-content-form" style={{paddingLeft: (activeStep === 0) ? "58px" : "0px", paddingRight: (activeStep === idUIContent.steps.length - 1) ? "58px" : "0px"}}>
                  {stepsContainer}
                </div>
                {(activeStep === 0) ? 
                <IconButton color="inherit" className={classes.disableRipple}
                  disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
                  onClick={() => handleNextStep()}>
                  <ArrowForwardIcon fontSize="large" />
                </IconButton> : "" }
              </div>
            </div>
            <div className="oneFourthWidth">
              <div className="centralize-content">
                <BrowserView>
                  {idUIContent && idUIContent.id !== "" && idUIContent.steps && idUIContent.steps.length > 0 ?
                  <Stepper key={activeStep} activeStep={activeStep} orientation="vertical" className="stepper-content">
                    {idUIContent.steps.map((step) => (
                      <Step key={step.title}>
                        <StepLabel className="step-label">
                          {step.title}
                        </StepLabel>
                        <StepContent>
                          <div className="step-label-desc" key={activeStep}>
                            <div dangerouslySetInnerHTML={{__html: step.detail.replace(/(<? *script)/gi, 'illegalscript')}} >
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  : ""}
                </BrowserView>
              </div>
            </div>
          </div>
          <ImportDigitalIdentityData
            digitalIdentityData={digitalIDImportableData!}
            confirmImportModalOpen={confirmImportModalOpen!}
            complianceId={complianceProcessData!.id}
            onCloseConfirmImportModal={onCloseConfirmImportModal}
            handleNextStep={handleNextStep}
          />
          {isSubmissionComplete ? 
            <GenericAlert
              showButtons={true}
              showSecondaryBtn={false}
              showSuccessIcon={!isSubmissionError}
              redAlertIcon={isSubmissionError}
              error={isSubmissionError}
              primaryButtonText={t("common.ok")}
              onCloseModal={() => {
                setSubmissionComplete(false);
                if (!isSubmissionError) {
                  setCallRefresh(true);
                  setLoader(true);
                }
              }}
              onPrimaryButtonClicked={() => {
                setSubmissionComplete(false);
                if (!isSubmissionError) {
                  history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
                }
                setComplianceTypeSubmitData(undefined);
                setSubmissionError(false);
              }}
              open={isSubmissionComplete}
              modalHeight={"250px"}
              modalWidth="520px"
              modalMargin="200px auto"
            >
              <div className="modal-input">
              
                {isSubmissionError ? 
                  <div dangerouslySetInnerHTML={{__html: (errorMessage && errorMessage !== "") ?  errorMessage.replace(/(<? *script)/gi, 'illegalscript') : t("errors.error_msg")}} >
                  </div>
                : t("id_check.id_check_success_message")}
              </div>
            </GenericAlert>
          : ""}
          {showError ? 
            <GenericAlert
            showButtons={true}
            showSecondaryBtn={false}
            error={true}
            primaryButtonText={t("common.ok")}
            onCloseModal={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            onPrimaryButtonClicked={() => {
              setShowError(false);
              setErrorMessage("");
            }}
            open={showError}
            modalHeight={"350px"}
            modalWidth="620px"
            modalMargin="200px auto"
          >
            <div className="modal-input">
                <div dangerouslySetInnerHTML={{__html: (errorMessage && errorMessage !== "") ?  errorMessage.replace(/(<? *script)/gi, 'illegalscript') : t("errors.error_msg")}} >
                </div>
            </div>
          </GenericAlert>
          : ""}
      </div>
      : <div>Data Not Fetched </div>
    );
  }

export const IDComplianceScreen = () => {
  return <IDContextProvider>
    <IDCompliance />
  </IDContextProvider>
}