import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import { createRef, useEffect, useState } from "react";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import Constants from "../../Constants/constants";
import GenericTextField from "../../Components/GenericTextField";
import LocalStorageService from "../../utils/localStorageService";
import Heading from "../../Components/Heading";
import { GetCountriesData } from "../../utils/referenceDataService";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "../../Components/LoaderModal";
import { useRTWContext } from "../../Contexts/rtw-context";
import { RTWPersonalInfo } from "./RTWPersonalInfo";
import YotiIDV from "../Yoti/YotiIDV";
import useAPIRequest from "../../Service/useAPIRequest";
import { useHistory, useParams } from "react-router-dom";
import GenericAlert from "../../Components/GenericAlert";
import { DocumentsContextProvider } from "../../Contexts/DocumentsContext";
import RTWDocumentComponent from "./rtwDocuments";
import { usePersonalContext } from "../../Contexts/personal-context";

interface VerifyIdentityProps {
  requestRefresh?: () => void;
}

type DBSComplianceParams = {
  checkId: string;
};

export default function RTWVerifyIdentity(props: VerifyIdentityProps) {
  const { t } = useTranslation();
  const oneRef = createRef();
  const history = useHistory();
  const { checkId } = useParams<DBSComplianceParams>();
  const [isDigitalVerifyOption] = useState("yes");

  const [callRefresh, setCallRefresh] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setCallRefresh(true);
  }, []);

  var countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
  if (!countriesData) {
    countriesData = GetCountriesData();
  }

  const {
    rtwVerifyIdentityContent,
    complianceProcessData,
    setComplianceProcessData,
    handleNextStep,
    isCheckEditable,
    govCode,
    setGovCode,
    setComplianceTypeSubmitData,
    isSubmissionComplete,
    isSubmissionError,
    setSubmissionComplete,
    setSubmissionError,
    loading,
    isYotiRTWChoosen,
    setisYotiRTWChoosen,
    isSetteledVariantChoosen,
    setIsSetteledVariantChoosen,
    showManualCheck,
    isManualCheckChoosen,
    setIsManualCheckChoosen,
    setDocumentSubmissionComplete,
  } = useRTWContext();

  const { acceptPersonalDetailsAndGovermentCode } = usePersonalContext();

  //******** Start - Event Handlers**** */

  const handleSubmit = () => {
    if (isSetteledVariantChoosen) {
      acceptPersonalDetailsAndGovermentCode();
    } else {
      if (handleNextStep) {
        handleNextStep(
          complianceProcessData,
          Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON
        );
      }
    }
    setDocumentSubmissionComplete(true);
  };

  const handleChange = () => (event: any, val: string) => {
    setGovCode(val);
  };

  const handleErrors = (val: string) => {
    if(val.trim() === "") return true;
    else return false;
  }

  useEffect(() => {
    async function SendRefreshRequest() {
      if (callRefresh) {
        await RefreshComplianceProcessData();
      }
    }

    SendRefreshRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRefresh]);

  const RefreshComplianceProcessData = () => {
    useAPIRequest(Constants.API_URLS.GET_WORKER_ROLE_COMPLIANCE + checkId, {
      method: "GET",
      body: "",
    }).then((res) => {
      setComplianceProcessData(res!);
      setCallRefresh(false);
    });
  };

  //******** End - Event Handlers**** */
  return (
    <>
      <div
        id="verify-identity"
        className="verify-identity editor-component"
      >
        <div className="verify-identity-body">
          <div className="verify-identity-body-container">
            <Heading
              title={
                rtwVerifyIdentityContent && rtwVerifyIdentityContent!.title !== ""
                  ? rtwVerifyIdentityContent!.title
                  : t("rtw_check.rtw_check_identity_verification")
              }
            />
            <div className="verify-identity-body-container-details-row">
              <div className="verify-identity-body-container-details-row-content">
                {rtwVerifyIdentityContent!.content !== "" ? (
                  <div
                    className="editor-component-body"
                    dangerouslySetInnerHTML={{
                      __html: rtwVerifyIdentityContent!.content.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <br />
              </div>
            </div>
            <div className="verify-identity-body-container-details-row">
              <div className="verify-identity-body-container-details-row-content">
                <FormControl>
                  <RadioGroup
                    row={false}
                    aria-label="text"
                    name="text"
                    value={isDigitalVerifyOption}
                  >
                    <FormControlLabel
                      checked={isYotiRTWChoosen}
                      disabled={!isCheckEditable}
                      control={<Radio color="primary" />}
                      label={t("rtw_check.rtw_check_YOTI")}
                      onClick={() => {
                        if (isCheckEditable) {
                          setisYotiRTWChoosen(true);
                          setIsSetteledVariantChoosen(false);
                          setIsManualCheckChoosen(false);
                        }
                      }}
                    />
                    {isYotiRTWChoosen ? (
                      <div className="verify-identity-body-container-details-row">
                        <div className="verify-identity-body-container-details-row-content">
                          <div className="">
                            <YotiIDV
                              isCheckEditable={isCheckEditable}
                              onInfoSaveCallback={() => {}}
                              complianceProcessData={complianceProcessData!}
                              requestRefresh={props.requestRefresh}
                              buttonMessage={t("rtw_check.rtw_check_YOTI_message")}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <FormControlLabel
                      checked={isSetteledVariantChoosen}
                      disabled={!isCheckEditable}
                      control={<Radio color="primary" />}
                      label={t("rtw_check.rtw_check_goverment")}
                      onClick={() => {
                        if (isCheckEditable) {
                          setisYotiRTWChoosen(false);
                          setIsSetteledVariantChoosen(true);
                          setIsManualCheckChoosen(false);
                        }
                      }}
                    />
                    {isSetteledVariantChoosen && (
                      <>
                        <RTWPersonalInfo />
                        <div className="editor-component-body">
                            {t("rtw_check.rtw_check_share_code_title")}<br />
                            <ul>
                              <li>
                                {t("rtw_check.rtw_check_share_code_first_option")}
                                <a 
                                  href="https://www.gov.uk/prove-right-to-work/get-a-share-code-online" 
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  https://www.gov.uk/prove-right-to-work/get-a-share-code-online
                                </a>.
                              </li>
                              <li>
                                {t("rtw_check.rtw_check_share_code_second_option")} 
                                <a 
                                  href="https://www.gov.uk/view-prove-immigration-status" 
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  https://www.gov.uk/view-prove-immigration-status
                                </a>.                  
                              </li>
                              <li>
                                {t("rtw_check.rtw_check_share_code_unable_to_get")} 
                              </li>
                            </ul>
                          <GenericTextField
                            label={t("rtw_check.rtw_check_goverment_label")}
                            name="identifier1"
                            type="text"
                            id="identifier1"
                            variant="outlined"
                            margin="dense"
                            fullWidth={true}
                            inputRef={oneRef}
                            value={govCode}
                            onChangeValue={handleChange()}
                            error={handleErrors(govCode)}
                            // helperText={t("rtw_check.rtw_check_goverment_help_text")}
                          />
                        </div>
                        <div className="verify-identity-body-container-details-row">
                          <div className="container-submit-btn">
                            {isCheckEditable ? (
                              <GenericButton
                                onClick={handleSubmit}
                                disabled={handleErrors(govCode)}
                                type="primary"
                                buttonText={t("common.accept")}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {/* {showManualCheck && (<FormControlLabel
                      checked={isManualCheckChoosen}
                      onClick={() => {
                        if (isCheckEditable) {
                          setisYotiRTWChoosen(false);
                          setIsSetteledVariantChoosen(false);
                          setIsManualCheckChoosen(true);
                        }
                      }}
                      disabled={!isCheckEditable}
                      control={<Radio color="primary" />}
                      label={t("rtw_check.rtw_check_manual_msg")}
                    />)} */}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {isManualCheckChoosen && (
              <DocumentsContextProvider>
                <RTWDocumentComponent />
              </DocumentsContextProvider>
            )}
          </div>
        </div>
        <LoaderModal isLoading={loading} />
      </div>
      {isSubmissionComplete ? (
        <GenericAlert
          showButtons={true}
          showSecondaryBtn={false}
          showSuccessIcon={!isSubmissionError}
          redAlertIcon={isSubmissionError}
          error={isSubmissionError}
          primaryButtonText={t("common.ok")}
          onCloseModal={() => {
            setSubmissionComplete(false);
            if (!isSubmissionError) {
              setCallRefresh(true);
            }
          }}
          onPrimaryButtonClicked={() => {
            setSubmissionComplete(false);
            if (!isSubmissionError) {
              history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
            }
            setComplianceTypeSubmitData(undefined);
            setSubmissionError(false);
          }}
          open={isSubmissionComplete}
          modalHeight={"360px"}
          modalWidth="620px"
          modalMargin="200px auto"
        >
          <div className="modal-input">
            {isSubmissionError ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    errorMessage && errorMessage !== ""
                      ? errorMessage.replace(/(<? *script)/gi, "illegalscript")
                      : t("errors.error_msg"),
                }}
              ></div>
            ) : (
              t("dbs_compliance.success_msg")
            )}
          </div>
        </GenericAlert>
      ) : (
        ""
      )}
      {showError ? (
        <GenericAlert
          showButtons={true}
          showSecondaryBtn={false}
          error={true}
          primaryButtonText={t("common.ok")}
          onCloseModal={() => {
            setShowError(false);
            setErrorMessage("");
          }}
          onPrimaryButtonClicked={() => {
            setShowError(false);
            setErrorMessage("");
          }}
          open={showError}
          modalHeight="350px"
          modalWidth="620px"
          modalMargin="200px auto"
        >
          <div className="modal-input">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  errorMessage && errorMessage !== ""
                    ? errorMessage.replace(/(<? *script)/gi, "illegalscript")
                    : t("errors.error_msg"),
              }}
            ></div>
          </div>
        </GenericAlert>
      ) : (
        ""
      )}
    </>
  );
}
