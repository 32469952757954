
import React, { ChangeEvent, useEffect, useState } from "react";
import "./index.scss";
import GenericTextField from "../../Components/GenericTextField";
import GenericSelect from "../../Components/GenericSelect";
import GenericDatePicker  from "../../Components/GenericDatePicker";
import GenericButton from "../../Components/GenericButton";
import PersonalInfoOptions from "../../utils/MetaDataJsons/PersonalInfoOptions.json";
import _ from "lodash";
import moment from "moment";
import GenericDataShow from "../../Components/GenericDataShow";
import GenericModal from "../../Components/GenericModal";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import { GenericLoader } from "../../Components/GenericLoader";
import IProfilePatchRequest from "../../interfaces/IProfilePatchRequest";
import InfoTypes from "../../interfaces/InfoTypes";
import useAPIRequest from "../../Service/useAPIRequest";
import { useTranslation } from "react-i18next"
import utilFunctions from "../../utils/util";
import { FormHelperText } from "@material-ui/core";
import { PersonalInfoAllowedAge, RegexEnum } from "../../Constants/enums";

interface EditInfoModalProps {
    selectedFieldToEdit: {
        selectedFieldTitle: string;
        selectedFieldId: any;
        selectedFieldValue: any;
        selectedFieldIndex: number;
    };
    personalValueState: {};
    isEditIconClicked: boolean;
    onCloseEditModal: () => void;
    onInfoSaveCallback: (propName: keyof EditInfoState, val: {} | string, index: number) => void;
}

interface EditInfoState{
    title: string;
    gender: string;
    forename: string;
    middleNames: string[];
    surname: string;
    dateOfBirth: Date | null;
    currentNationality: string;
    country: string;
    town: string;
    county: string;
    previousNames: string[];
    previousNationalities: string[];
}

type FieldType = 'forename' | 'surname' | 'middleNames' | 'default';

const InitialPatchRequest: IProfilePatchRequest = {
    path: "",
    newValue: "",
    reason: ""
}

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);
const personNameRegex: RegExp = new RegExp(RegexEnum.PERSON_NAME);

const excludedFields: string[] = ['title', 'gender', 'country', 'currentNationality'];

const regexOptions: { [key in FieldType]: RegExp } = {
    forename: personNameRegex,
    surname: personNameRegex,
    middleNames: personNameRegex,
    default: genericRegex,
  };

export default function EditInfoModal (props: EditInfoModalProps) {
    const { t } = useTranslation();
    const oneRef = React.createRef();
    const [isEditClicked, setIsEditClicked] = useState(true);
    const [editInfoFieldNewValue, setEditInfoFieldNewValue] = useState("");
    const [editInfoDateNewValue, setEditInfoDateNewValue] = useState<any>(null);
    const [editInfoSelectedValue, setEditInfoSelectedValue] = useState("");
    const [editInfoFieldReason, setEditInfoFieldReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [patchRequested, setPatchRequest] = useState(InitialPatchRequest);
    const [editInfoFieldError, setEditInfoFieldError] = useState(false);
    const [editInfoFieldHelperText, setEditInfoFieldHelperText] = useState<string>("");
    const [editInfoReasonError, setEditInfoReasonError] = useState(false);
    const [editInfoReasonHelperText, setEditInfoReasonHelperText] = useState<string>("");
    const [editInfoDateError, setEditInfoDateError] = useState(false);
    const [editInfoDateHelperText, setEditInfoDateHelperText] = useState<string>("");
    
    const SendPatch = async (patchRequest: IProfilePatchRequest) => {
        useAPIRequest(Constants.API_URLS.PATCH_WORKER_PROFILE, {
            method: "PATCH",
            body: patchRequest
        })
        props.onInfoSaveCallback(props.selectedFieldToEdit.selectedFieldId, 
            ((props.selectedFieldToEdit.selectedFieldId === "dateOfBirth") ? editInfoDateNewValue : editInfoFieldNewValue), 
            props.selectedFieldToEdit.selectedFieldIndex);
        onEditInfoModalClose();
    }

    useEffect(() => {
        async function SendPatchRequest() {
            if(patchRequested.path !== "") {
                await SendPatch(patchRequested);
            }
        }
        SendPatchRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patchRequested]);

    useEffect(() => {
        setIsEditClicked(props.isEditIconClicked);
    }, [props.isEditIconClicked, editInfoFieldNewValue])

    const titlesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.TITLES_DATA);
    const countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
    const nationalitiesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA);

    const GenderOptionValues = _.map(_.get(
        _.find(
        _.get(
            PersonalInfoOptions,
            '[0]["metaDataOptions"]'
        ),
        (e) => e.OptionSetName === "GenderOptions"
        ),
        "Options"
    ), (e: InfoTypes.Gender) => {
        return {title: e.title, id: e.title};
    });

    const onEditInfoModalClose = (e?: EventTarget) => {
        setIsEditClicked(false);
        setEditInfoFieldNewValue("");
        setEditInfoFieldReason("");
        setEditInfoSelectedValue("");
        props.onCloseEditModal();
    };

    const handleEditReasonFieldChange = (e: ChangeEvent<HTMLInputElement>, val: string)  => {
        setEditInfoFieldReason(val);
    }

    const handleEditChange = (prop: keyof EditInfoState, key?: string) => (e: ChangeEvent<{}>, val?: any) => {
        setEditInfoFieldNewValue(val);
        setEditInfoSelectedValue(val.id);
    };

    const handleDateChange = (prop: keyof EditInfoState) => (val: Date | null) => {
        setEditInfoDateNewValue(val!);
    };

    useEffect(() => {
        const fieldType = props.selectedFieldToEdit.selectedFieldId;
        const regex = regexOptions[fieldType as keyof typeof regexOptions] || regexOptions.default;
        const personalAge = moment(editInfoDateNewValue).format('YYYY, DD, MM');
        const todayIsDate = moment().format('YYYY, DD, MM');
    
        const isAgeInTheZone = moment(todayIsDate,'YYYY, DD, MM').diff(moment(personalAge, 'YYYY, DD, MM'), 'years');
    
        if (editInfoFieldNewValue === '') {
            setEditInfoFieldError(true);
            setEditInfoFieldHelperText(t("validators.required"));
        } else {
            if(!excludedFields.includes(fieldType)){
                (!utilFunctions.testRegex(regex, editInfoFieldNewValue)) ? setEditInfoFieldError(true) : setEditInfoFieldError(false);    
                setEditInfoFieldHelperText(!utilFunctions.testRegex(regex, editInfoFieldNewValue) ? t("validators.special_chars") : "");
            } else {
                setEditInfoFieldError(false);
                setEditInfoFieldHelperText("");
            }
        }

        if (editInfoDateNewValue === null || editInfoDateNewValue === 'Invalid date') {
            setEditInfoDateError(true);
            setEditInfoDateHelperText(t("errors.birthdate_msg"));
          } else if (isAgeInTheZone < PersonalInfoAllowedAge.MinAge || isAgeInTheZone >= PersonalInfoAllowedAge.MaxAge) {
            setEditInfoDateError(true);
            setEditInfoDateHelperText(t("errors.birthdate_allowed_interval_msg"));
          } else {
            setEditInfoDateError(false);
            setEditInfoDateHelperText(t(""));
          }

        if (editInfoFieldReason === '') {
            setEditInfoReasonError(true);
            setEditInfoReasonHelperText(t("validators.required"));
        } else {
            if (!utilFunctions.testRegex(genericRegex, editInfoFieldReason)) {
              setEditInfoReasonError(true);
              setEditInfoReasonHelperText(t("validators.special_chars"));
            } else {
              setEditInfoReasonError(false);
              setEditInfoReasonHelperText("");
            }
        }
    }, [props.selectedFieldToEdit.selectedFieldId, 
        editInfoFieldNewValue, 
        editInfoFieldReason, t, 
        editInfoDateNewValue]);

    const AcceptEditInfoChange = () => {
        setLoading(true);
        let path, newVal: any = "";
        path = "personal." + props.selectedFieldToEdit.selectedFieldId;
                
        switch(props.selectedFieldToEdit.selectedFieldId) {
            case "gender":
            case "title":
            case "currentNationality":
                newVal = editInfoSelectedValue;
                break;
            case "country":
                path = "personal.placeOfBirth." + props.selectedFieldToEdit.selectedFieldId;
                newVal = editInfoSelectedValue;
                break;
            case "town":
            case "county":
                path = "personal.placeOfBirth." + props.selectedFieldToEdit.selectedFieldId;
                newVal = editInfoFieldNewValue;
                break;
            case "dateOfBirth":
                newVal = moment(editInfoDateNewValue).format(Constants.DATE_FORMAT_DISPLAY);
                break;
            case "forename":
            case "surname":
                newVal = editInfoFieldNewValue;
                break;
            case "middleNames":
                path = "personal.middleNames[" + props.selectedFieldToEdit.selectedFieldIndex + "]";
                newVal = editInfoFieldNewValue;
                break;
            default:
                path = "";
                newVal = "";
                break;
        }
        let patchRequest: IProfilePatchRequest = {
            path: path,
            newValue: newVal,
            reason: editInfoFieldReason
        }
        setPatchRequest(patchRequest);
    }

    const renderInputComponent = () => {
        switch(props.selectedFieldToEdit.selectedFieldId!) {
            case "gender":
                return <>
                    <GenericSelect
                        id="gender"
                        value={editInfoFieldNewValue}
                        dataSet={GenderOptionValues}
                        fullWidth={true}
                        handleChange={handleEditChange("gender")}
                        multiple={false}
                        freeSolo={false}
                        label={t("common.gender")}
                        renderChip={false}
                        required={true}
                        error={editInfoFieldError}
                    />
                    <FormHelperText className="helper-text-error MuiFormHelperText-contained">
                        {editInfoFieldHelperText}
                    </FormHelperText>
                </>
            case "dateOfBirth":
                return <GenericDatePicker
                    variant="inline"
                    label={t("common.birthdate_formatted")}
                    id="date-picker-inline"
                    inputVariant="outlined"
                    required
                    value={editInfoDateNewValue}
                    onChangeValue={handleDateChange("dateOfBirth")}
                    format={Constants.DATE_FORMAT_CALENDAR}
                    enableFuture={false}
                    inputRef={oneRef}
                    fullWidth={true}
                    error={editInfoDateError}
                    helperText={editInfoDateHelperText}
                ></GenericDatePicker>
            case "title":
                return <GenericSelect
                    id="title"
                    value={editInfoFieldNewValue}
                    dataSet={titlesData}
                    fullWidth={true}
                    handleChange={handleEditChange("title")}
                    multiple={false}
                    freeSolo={false}
                    label={t("common.title")}
                    renderChip={false}
                    required={true}
                    name="title"
                    error={editInfoFieldError}
                />
                
            case "country":
                return <>
                    <GenericSelect
                        id="country"
                        value={editInfoFieldNewValue}
                        dataSet={countriesData}
                        fullWidth={true}
                        handleChange={handleEditChange("country")}
                        multiple={false}
                        freeSolo={false}
                        label={t("common.country_birth_province")}
                        renderChip={false}
                        required={true}
                        error={editInfoFieldError}
                    />
                    <FormHelperText className="helper-text-error MuiFormHelperText-contained">
                        {editInfoFieldHelperText}
                    </FormHelperText>
                </>
            case "forename":
            case "surname":
            case "middleNames":
            case "town":
            case "county":
                return <GenericTextField
                    label={props.selectedFieldToEdit.selectedFieldTitle}
                    name={props.selectedFieldToEdit.selectedFieldId}
                    type="text"
                    id={props.selectedFieldToEdit.selectedFieldId}
                    variant="outlined"
                    margin="dense"
                    fullWidth={true}
                    required
                    value={editInfoFieldNewValue}
                    inputRef={oneRef}
                    onChangeValue={handleEditChange(props.selectedFieldToEdit.selectedFieldId)}
                    error={editInfoFieldError}
                    helperText={editInfoFieldHelperText}
                    />
            case "currentNationality":
                return <>
                    <GenericSelect
                        id="currnationality"
                        value={editInfoFieldNewValue}
                        dataSet={nationalitiesData}
                        fullWidth={true}
                        handleChange={handleEditChange("currentNationality")}
                        multiple={false}
                        freeSolo={false}
                        label={t("common.current_nationality")}
                        renderChip={false}
                        required={true}
                        error={editInfoFieldError}
                    />
                    <FormHelperText className="helper-text-error MuiFormHelperText-contained">
                        {editInfoFieldHelperText}
                    </FormHelperText>
                </>
            default:
                return <></>
        }
    }

    return (
        <>
        <GenericModal
            heading={t("profile_details.edit_personal_details")}
            onCloseModal={(e?: EventTarget) => onEditInfoModalClose(e)}
            open={props.isEditIconClicked && isEditClicked}
            closeOnBackDrop={true}
            modalWidth={"500px"}
            modalHeight={"400px"}
            modalTop={"15%"}
            className="personal-info-edit-modal"
            >
            <div className="personal-info-edit-modal">
                {loading ? (
                <div style={{ marginTop: "50px", textAlign: "center" }}>
                    <GenericLoader />
                    <div className="loading-text">{t("common.saving")}</div>
                </div>
                ) : (
                <>
                <div className="fullWidth">
                    <GenericDataShow
                    label={props.selectedFieldToEdit.selectedFieldTitle}
                    value={(props.selectedFieldToEdit.selectedFieldId === "dateOfBirth") ? moment(props.selectedFieldToEdit.selectedFieldValue).format(Constants.DATE_FORMAT_DISPLAY) : props.selectedFieldToEdit.selectedFieldValue}
                    isSelectField={(props.selectedFieldToEdit.selectedFieldId === "title" || props.selectedFieldToEdit.selectedFieldId === "gender" || props.selectedFieldToEdit.selectedFieldId === "country" || props.selectedFieldToEdit.selectedFieldId === "currentNationality")}
                    />
                </div>
                <div className="fullWidth">
                    {renderInputComponent()}
                </div>
                <div className="fullWidth">
                    <GenericTextField
                    label={t("common.reason_for_change")}
                    name={"reasonForChange"}
                    type="text"
                    id={"reasonForChange"}
                    variant="outlined"
                    margin="dense"
                    fullWidth={true}
                    required
                    value={editInfoFieldReason}
                    inputRef={oneRef}
                    onChangeValue={handleEditReasonFieldChange}
                    error={editInfoReasonError}
                    helperText={editInfoReasonHelperText}
                    />
                </div>
                <div className="fullWidth" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <GenericButton
                    onClick={() => {
                        AcceptEditInfoChange();
                    }}
                    disabled={(props.selectedFieldToEdit.selectedFieldId === "dateOfBirth") ? 
                        (editInfoReasonError || editInfoDateError) : 
                        (editInfoReasonError || editInfoFieldError)}
                    type="primary"
                    buttonText={t("common.save")}
                    ></GenericButton>
                </div>   
                </>
                )}
            </div>
            </GenericModal>
        </>
    )
}