const Constants = {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    WORKER_EMAIL: "WORKER_EMAIL",
    DOC_UPLOAD: {
        VALID_TYPES: [
        "",
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/x-png",
        "application/pdf",
        ],
        FILE_ACCESS_TYPES: ".pdf, .jpg, .png",
        MAX_FILE_SIZE_IN_MB: 20,
        MAX_FILE_SIZE: 1024 * 1024 * 20,
        MAX_PACKAGE_SIZE: 1024 * 1024 * 20,
    },
    DATE_FORMAT_ISO: "yyyy-MM-DDT00:00:00.000",
    DATE_FORMAT_DISPLAY: "DD/MM/YYYY",
    DATE_FORMAT_CALENDAR: "dd/MM/yyyy",
    DATE_FORMAT_LOCALE: "YYYY-DD-MM HH:mm:ss",
    API_URLS: {
        COMPLIANCE: "/compliance/",
        DOCUMENTS: "/documents/",
        GET_WORKER_ROLE_HISTORY: "/compliance/history",
        GET_WORKER_ROLE_COMPLIANCE: "/compliance/process/",
        PUT_WORKER_ROLE_COMPLIANCE: "/compliance/process/",
        GET_WORKER_PROFILE: "/worker/profile",
        GET_TITLES: "/reference/titles",
        GET_COUNTRIES: "/reference/countries",
        PATCH_WORKER_PROFILE: "/worker/profile",
        GET_COMPLIANCE_CONTENT: "/content",
        POST_FEEDBACK_RATING: "/reference/feedback",
        GET_NATIONALITIES: "/reference/nationalities",
        GET_DOCUMENTS_GROUPS_DBS: "/documents/dbs/",
        REFERENCE: "/reference",
        GET_DOCUMENT_TYPE_FIELDS: "/fields",
        POST_CREATE_YOTI_SESSION: "/session",
        DIGITAL_ID_SUFFIX: "/digital-id/",
        POST_WORKER_COMPLETE_DBS_DATA_SUFFIX: "/compliance/dbs/",
        POST_WORKER_COMPLETE_RTW_DATA_SUFFIX: "/compliance/rtw/",
        POST_WORKER_COMPLETE_ID_CHECK_DATA_SUFFIX: "/compliance/id-check/",
        POST_WORKER_COMPLETE_DATA: "/worker/complete",
        GET_DIGITAL_ID_DATA: "/idv-results",
        PUT_DIGITAL_ID_IMPORT: "/import/digital",
        PUT_DIGITAL_ID_REJECT: "/reject/digital",
        DBS_SUFFIX: "/compliance/dbs/",
        POST_DBS_TERMS: "/terms",
        POST_INTENT_STRIPE_SECRET_KEY: "/payment/stripe/clientsecret",
        PAYMENT: "/payment",
        REMOVE_DIGITAL_IDENTITY: "/compliance/remove-digital-identity/",
    },
    LOCAL_STORE_KEY: {
        WORKER_ROLE_DATA: "WORKER_ROLE_DATA",
        WORKER_PROFILE_DATA: "WORKER_PROFILE_DATA",
        TITLES_DATA: "TITLES_DATA",
        COUNTRIES_DATA: "COUNTRIES_DATA",
        NATIONALITIES_DATA: "NATIONALITIES_DATA",
    },
    COMPLIANCE_FORM_ROUTES: {
        PROFILE: "/profile",
        ROLEDETAILS: "/role-dtls/",
        DASHBORAD: "/dashboard",
        DBS: "/dbs/",
        DVLA: "/dvla/",
        BASICDBS: "/basicdbs/",
        IDENTITYCHECK:"/id/",
        CHECKINTRO: "/checkIntro/",
        CHECKOUTRO: "/checkOutro/",
        CONVICTIONS: "/convictions/",
        WORK_ABROAD: "/work-abroad/",
        RTW: "/rtw/",
        PAYMENT: "/payment/",
    },
    NON_PROTECTED_ROUTES: {
        COOKIE_POLICY: "/cookie-policy",
        PRIVACY_POLICY: "/privacy-policy"
    },
    COMPLIANCE_STEPS_EDITORS: {
        PERSONAL_INFO: "PersonalInfo",
        ADDRESS_INFO: "AddressInfo",
        IDENTITY_VERFIICAITON: "IdentityVerification",
        INTRO: "Intro",
        OUTRO: "Outro",
        CONVICTIONS: "Convictions",
        WORK_ABROAD: "WorkAbroad",
        DBS_TERMS: "DbsTerms"
    },
    YOTI_ORIGIN_URL: "https://api.yoti.com",
    COMPLIANCE_TYPES_DISPLAY_VALUE: {
        DBS: "DBS",
        LADO: "LADO",
        CSBL: "Children Barred List",
        OccupationalHealth: "Occupational Health",
        EmploymentHistoryAndReferences: "Employment History And References",
        QTS: "Qualified Teacher Status",
        ProfessionalRegistry: "Professional Registry",
        OverseasPolice: "Overseas Police",
        DVLA: "DVLA",
        IdentityAndRWAudit: "Right to Work",
        IdentityCheck: "Identity Check",
        BasicDBS: "Basic DBS",
        AcademicReferences: "Academic References",
        AcademicReferencesSpecial: "Special Academic References",
    }
}

export default Constants;