import "./index.scss";
import Constants from "../../Constants/constants";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { useEffect, useState } from "react";
import InfoTypes from "../../interfaces/InfoTypes";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import IFeedbackRating from "../../interfaces/IFeedbackRating";
import useAPIRequest from "../../Service/useAPIRequest";
import { GenericLoader } from "../../Components/GenericLoader";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import {
  GetCountriesData,
  GetNationalitiesData,
  GetTitlesData,
} from "../../utils/referenceDataService";
import { BrowserView, MobileView, isMobileOnly } from "react-device-detect";
import { ImportStatus, ProcessStatus } from "../../Constants/enums";
import GenericAlert from "../../Components/GenericAlert";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { RTWContextProvider, useRTWContext } from "../../Contexts/rtw-context";
import ImportDigitalIdentityData from "../Yoti/Import/ImportDigitalIdentityData";
import IImportableDigitalData from "../../interfaces/IImportableDigitalData";
import { PersonalContextProvider } from "../../Contexts/personal-context";
import moment from "moment";

const useStyles = makeStyles(() => ({
  disableRipple: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    position: "sticky",
    top: "50vh",
    height: "100px",
  },
}));

type DBSComplianceParams = {
  checkId: string;
};

export default function RTWCompliance() {
  const { t } = useTranslation();
  const history = useHistory();
  const { checkId } = useParams<DBSComplianceParams>();
  const classes = useStyles();
  const [feedback] = useState<IFeedbackRating>();
  const [, setComplianceTypeSubmitData] = useState<IComplianceProcess>();
  const [callRefresh, setCallRefresh] = useState(false);
  const [loader, setLoader] = useState(false);

  let digitalIDImportableData: IImportableDigitalData = {};

  const {
    rtwUIContent,
    complianceProcessData,
    setComplianceProcessData,
    stepsContainer,
    activeStep,
    setActiveStep,
    handleNextStep,
    isSubmissionComplete,
    isSubmissionError,
    setSubmissionError,
    setSubmissionComplete,
    showError,
    setShowError,
    setIsCheckEditable,
    setConfirmImportModalOpen,
    confirmImportModalOpen,
    errorMessage,
    setErrorMessage,
    setGovCode,
    setisYotiRTWChoosen,
    setShowManualCheck,
    setIsSetteledVariantChoosen,
    setIsManualCheckChoosen,
  } = useRTWContext();

  const onCloseConfirmImportModal = () => {
    setConfirmImportModalOpen(false);
    setCallRefresh(true);
    history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
  };

  useEffect(() => {
    setCallRefresh(true);
    setLoader(true);
  }, []);

  GetCountriesData();
  GetNationalitiesData();
  GetTitlesData();

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const SubmitFeedback = async (feedback: IFeedbackRating) => {
    useAPIRequest(Constants.API_URLS.POST_FEEDBACK_RATING, {
      method: "POST",
      body: feedback,
    });
  };

  useEffect(() => {
    if (complianceProcessData) {
      setIsCheckEditable(complianceProcessData!.status === ProcessStatus.WaitingForWorker);

      if (complianceProcessData.digitalIdentity) {
        setisYotiRTWChoosen(true);
        setIsManualCheckChoosen(false);
        setIsSetteledVariantChoosen(false);
        setShowManualCheck(false);
        
        if (
          complianceProcessData.digitalIdentity.isComplete &&
          (complianceProcessData.digitalIdentity.importStatus === ImportStatus.Pending ||
            complianceProcessData.digitalIdentity.importStatus === ImportStatus.Imported)
        ) {
          setIsCheckEditable(false);
        }

        if (
          complianceProcessData.digitalIdentity.isComplete &&
          complianceProcessData.digitalIdentity.importStatus === ImportStatus.Pending
        ) {
          setConfirmImportModalOpen(true);
        }

        if (
          (complianceProcessData.digitalIdentity.isComplete &&
            complianceProcessData.digitalIdentity.importStatus === ImportStatus.Rejected) ||
          moment(complianceProcessData.digitalIdentity.validTo).format(
            Constants.DATE_FORMAT_LOCALE
          ) < moment(Date.now()).format(Constants.DATE_FORMAT_LOCALE)
        ) {
          checkManualRoutes();
        }
      } else {
        checkManualRoutes();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceProcessData]);

  const checkManualRoutes = () => {
    if (complianceProcessData?.processData?.documents) {
      if (complianceProcessData.processData.documents[0]) {
        if (complianceProcessData.processData.documents[0].type === "SettledStatusShareCode") {
          setisYotiRTWChoosen(false);
          setIsManualCheckChoosen(false);
          setIsSetteledVariantChoosen(true);
          if (complianceProcessData.processData.documents[0].identifier !== "") {
            setGovCode(complianceProcessData.processData.documents[0].identifier);
          }
          setShowManualCheck(false);
        } else {
          setisYotiRTWChoosen(false);
          setIsManualCheckChoosen(true);
          setIsSetteledVariantChoosen(false);
          setShowManualCheck(true);
        }
      }
    } else {
      setisYotiRTWChoosen(true);
      setIsManualCheckChoosen(false);
      setIsSetteledVariantChoosen(false);
      setShowManualCheck(true);
    }
  };

  useEffect(() => {
    async function SubmitFeedbackRequest() {
      if (feedback) {
        await SubmitFeedback(feedback);
      }
    }
    SubmitFeedbackRequest();
  }, [feedback]);

  useEffect(() => {
    async function SendRefreshRequest() {
      if (callRefresh) {
        await RefreshComplianceProcessData();
      }
    }
    SendRefreshRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRefresh]);

  const RefreshComplianceProcessData = () => {
    useAPIRequest(Constants.API_URLS.GET_WORKER_ROLE_COMPLIANCE + checkId, {
      method: "GET",
      body: "",
    })
      .then((res) => {
        setComplianceProcessData(res!);
        setCallRefresh(false);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return loader ? (
    <GenericLoader />
  ) : complianceProcessData ? (
    <div
      id="dbs-comp-wrapper"
      className="main-wrapper"
    >
      <div className="header">
        <div className="title">
          {Constants.COMPLIANCE_TYPES_DISPLAY_VALUE.IdentityAndRWAudit} {"  "}
        </div>
      </div>
      <div
        className="compliance-container"
        style={{ flexDirection: isMobileOnly ? "column" : "inherit" }}
      >
        <MobileView>
          <div className="container-content">
            <Box>
              <Stepper
                key={activeStep}
                activeStep={activeStep}
                orientation="horizontal"
              >
                {rtwUIContent.steps.map((step) => (
                  <Step key={step.title}>
                    <StepLabel
                      key={activeStep}
                      className="step-label"
                    >
                      {""}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </MobileView>
        <div className="container-content">
          {complianceProcessData.status === ProcessStatus.WaitingForWorker &&
          complianceProcessData.changesRequested ? (
            <div className="complaince-change-request">
              {_.map(
                complianceProcessData.changesRequested,
                (changeRequest: InfoTypes.ChangeRequest) =>
                  !changeRequest.completeDate ? (
                    <>
                      <div className="changes-requested-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: changeRequest.message.replace(
                              /(<? *script)/gi,
                              "illegalscript"
                            ),
                          }}
                        ></div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
              )}
            </div>
          ) : (
            ""
          )}
          <div className="container-content-dtls">
            {/* {activeStep !== 0 ? (
              <IconButton
                color="inherit"
                className={classes.disableRipple}
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                onClick={() => handleBackStep()}
              >
                <ArrowBackIcon fontSize="large" />
              </IconButton>
            ) : (
              ""
            )} */}
            <div
              className="container-content-form"
              style={{
                paddingLeft: activeStep === 0 ? "58px" : "0px",
                paddingRight: activeStep === rtwUIContent.steps.length - 1 ? "58px" : "0px",
              }}
            >
              {stepsContainer}
            </div>
            {(activeStep === 0) ? (
              <IconButton
                color="inherit"
                className={classes.disableRipple}
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                onClick={() => handleNextStep()}
              >
                <ArrowForwardIcon fontSize="large" />
              </IconButton>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="oneFourthWidth">
          <div className="centralize-content">
            {rtwUIContent &&
              rtwUIContent.id !== "" &&
              rtwUIContent.steps &&
              rtwUIContent.steps.length > 0 && (
                <BrowserView>
                  <Stepper
                    key={activeStep}
                    activeStep={activeStep}
                    orientation="vertical"
                    className="stepper-content"
                  >
                    {rtwUIContent.steps.map(
                      (step) =>
                        step && (
                          <Step key={step.title}>
                            <StepLabel className="step-label">{step.title}</StepLabel>
                            <StepContent>
                              <div
                                className="step-label-desc"
                                key={activeStep}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: step.detail.replace(/(<? *script)/gi, "illegalscript"),
                                  }}
                                ></div>
                              </div>
                            </StepContent>
                          </Step>
                        )
                    )}
                  </Stepper>
                </BrowserView>
              )}
          </div>
        </div>
      </div>
      <ImportDigitalIdentityData
        digitalIdentityData={digitalIDImportableData!}
        confirmImportModalOpen={confirmImportModalOpen!}
        complianceId={complianceProcessData!.id}
        onCloseConfirmImportModal={onCloseConfirmImportModal}
        handleNextStep={handleNextStep}
      />
      {isSubmissionComplete ? (
        <GenericAlert
          showButtons={true}
          showSecondaryBtn={false}
          showSuccessIcon={!isSubmissionError}
          redAlertIcon={isSubmissionError}
          error={isSubmissionError}
          primaryButtonText={t("common.ok")}
          onCloseModal={() => {
            setSubmissionComplete(false);
            if (!isSubmissionError) {
              setCallRefresh(true);
              setLoader(true);
            }
          }}
          onPrimaryButtonClicked={() => {
            setSubmissionComplete(false);
            if (!isSubmissionError) {
              history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
            }
            setComplianceTypeSubmitData(undefined);
            setSubmissionError(false);
          }}
          open={isSubmissionComplete}
          modalHeight={"auto"}
          modalWidth="520px"
          modalMargin="200px auto"
        >
          <div className="modal-input">
            {isSubmissionError ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    errorMessage && errorMessage !== ""
                      ? errorMessage.replace(/(<? *script)/gi, "illegalscript")
                      : t("errors.error_msg"),
                }}
              ></div>
            ) : (
              t("rtw_check.rtw_check_success_msg") 
            )}
          </div>
        </GenericAlert>
      ) : (
        ""
      )}
      {showError ? (
        <GenericAlert
          showButtons={true}
          showSecondaryBtn={false}
          error={true}
          primaryButtonText={t("common.ok")}
          onCloseModal={() => {
            setShowError(false);
            setErrorMessage("");
          }}
          onPrimaryButtonClicked={() => {
            setShowError(false);
            setErrorMessage("");
          }}
          open={showError}
          modalHeight="350px"
          modalWidth="620px"
          modalMargin="200px auto"
        >
          <div className="modal-input">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  errorMessage && errorMessage !== ""
                    ? errorMessage.replace(/(<? *script)/gi, "illegalscript")
                    : t("errors.error_msg"),
              }}
            ></div>
          </div>
        </GenericAlert>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div>Data Not Fetched </div>
  );
}

export const RTWComplianceScreen = () => {
  return (
    <RTWContextProvider>
      <PersonalContextProvider>
        <RTWCompliance />
      </PersonalContextProvider>
    </RTWContextProvider>
  );
};
