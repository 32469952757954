import React from "react";
import "./index.scss";
import Modal from "@material-ui/core/Modal";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import infoIcon from "../../Assets/OutlinedInfo.svg";
import errorIcon from "../../Assets/error.svg";
import successIcon from "../../Assets/success-icon.png";
import alertIcon from "../../Assets/warning-icon.svg";
import { isMobileOnly } from "react-device-detect";

export interface ModalProps {
  heading?: any;
  onCloseModal: () => void;
  open: boolean;
  children?: any;
  error?: boolean;
  info?: boolean;
  showButtons?: boolean;
  primaryButtonText?: any;
  secondaryButtonText?: string;
  disabled?: boolean;
  onPrimaryButtonClicked?: any;
  showSecondaryBtn?: boolean;
  disablePrimaryButton?: any;
  className?: string;
  redAlertIcon?: boolean;
  modalWidth?: string;
  modalHeight?: string;
  modalMargin?: string;
  showSuccessIcon?: boolean;
  customStyle?: any;
  showAlertIcon?: boolean;
  modalChildClass?: string;
  primaryButtonStyle?: any;
  closeOnBackDrop?: boolean;
}

const GenericAlert: React.FC<ModalProps> = (props) => {
  let buttonRef = React.createRef<HTMLButtonElement>();

  return (
    <Modal
      open={props.open}
      onClose={(e, reason) => {
        if(reason === "backdropClick" && props.closeOnBackDrop)
          props.onCloseModal();
      }}
      className={`alert-modal-container ${props.className}`}
    >
      <div
        className="modal-inner"
        style={{
          height: props.modalHeight,
          width: (isMobileOnly) ? "90%" : props.modalWidth,
          margin: props.modalMargin,
        }}
      >
        {props.info && (
          <div className="image-container">
            <img src={infoIcon} alt="error-icon" className="icon" />
          </div>
        )}
        {props.error &&
          (props.redAlertIcon ? (
            <div className="image-container">
              <img src={errorIcon} alt="error-icon" className="icon" />
            </div>
          ) : (
            <div className="image-container">
              <ErrorOutlineIcon style={{ width: "34px", height: "34px" }} />
            </div>
          ))}
        {props.showSuccessIcon ? (
          <div className="image-container">
            <img src={successIcon} alt="success-icon" className="icon" />
          </div>
        ) : (
          ""
        )}
        {props.showAlertIcon ? (
          <div className="image-container">
            <img src={alertIcon} alt="alert-icon" className="icon" />
          </div>
        ) : (
          ""
        )}
        {props.heading && (
          <div
            className={
              props.error ? "section-header error-header" : "section-header"
            }
          >
            {props.heading}
          </div>
        )}
        <div className={`modal-content ${props.modalChildClass}`}>
          {props.children}
        </div>
        {props.showButtons && (
          <div className="button-container" style={props.customStyle}>
            <button
            className={
                props.disablePrimaryButton
                ? "primary primary-disabled"
                : "primary"
            }
            style={props.primaryButtonStyle}
            disabled={props.disablePrimaryButton}
            onClick={() => props.onPrimaryButtonClicked()}
            ref={(node) => {
                if (node) {
                buttonRef = { current: node };
                buttonRef?.current?.focus();
                }
            }}
            >
            {props.primaryButtonText}
            </button>
            {props.showSecondaryBtn ? (
              <button
                className="primary secondary"
                onClick={() => props.onCloseModal()}
              >
                {props.secondaryButtonText}
              </button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GenericAlert;