
import { Box, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import GenericButton from "../../Components/GenericButton";
import { GenericLoader } from "../../Components/GenericLoader";
import GenericModalResponsive from "../../Components/GenericModalResponsive";
import Constants from "../../Constants/constants";
import { ComplianceTypes } from "../../Constants/enums";
import useAPIRequest from "../../Service/useAPIRequest";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IDocumentDefinition from "../../interfaces/IDocumentDefinition";
import InfoTypes from "../../interfaces/InfoTypes";
import utilFunctions from "../../utils/util";
import "./index.scss";
import { InitialDocValues } from "./types";

interface PromptIssueDatesProps {
    processData: IComplianceProcess;
    confirmDocumentIssueDate: boolean;
    onInfoSaveCallback: () => void;
}

export default function PromptIssueDates (props: PromptIssueDatesProps) {
    const { t } = useTranslation();
    const [isEditClicked, setIsEditClicked] = useState(true);
    const [loading, setLoading] = useState(false);
    const [putRequested, setPutRequest] = useState<IComplianceProcess>();
    const [identityDocValues, setIdentityDocValues] = useState<IDocumentDefinition[]>(InitialDocValues);
    const [documentDefinitionData, setDocumentDefinitionData] = useState<IDocumentDefinition[]>();

    const [primaryValidFromError, setPrimaryValidFromError] = useState(false);
    const [primaryValidFromHelperText, setPrimaryValidFromHelperText] = useState<string>("");

    const [secondaryValidFromError, setSecondaryValidFromError] = useState(false);
    const [secondaryValidFromHelperText, setSecondaryValidFromHelperText] = useState<string>("");

    const [ternaryValidFromError, setTernaryValidFromError] = useState(false);
    const [ternaryValidFromHelperText, setTernaryValidFromHelperText] = useState<string>("");

    useEffect(() => {
        if (props.processData) {
          setDocumentDefinitionData(props.processData.processData.documents);
        }
    }, [props.processData]);

    const SendPatchDocumentsIssueDates = async (request: IComplianceProcess) => {
        useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + props.processData.id, {
            method: "PUT",
            body: request
            }).then((res) => {
            if (res.ok) {
                props.onInfoSaveCallback();
                onEditInfoModalClose();
            } else {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        async function SendPatchDocumentsIssueDatesRequest() {
            if(putRequested) {
                await SendPatchDocumentsIssueDates(putRequested);
            }
        }
        SendPatchDocumentsIssueDatesRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [putRequested]);

    const onEditInfoModalClose = (e?: EventTarget) => {
        props.onInfoSaveCallback();
        setIsEditClicked(false);
    };

    const handleDocValueDateChange =
    (prop: keyof InfoTypes.DocumentDefinition, index: number) => (val: Date | null) => {
      setIdentityDocValues((items) => ({
        ...items,
        [index]: {
          ...items[index],
          [prop]: moment(val!).format(Constants.DATE_FORMAT_ISO),
        },
      }));
    };

    const saveDocumentsIssueDates = () => {
        setLoading(true);
        
        let docs = _.map(identityDocValues, (doc: IDocumentDefinition) => {
            return { ...doc };
        });

        let documentsData: IDocumentDefinition[] = utilFunctions.cleanseJson(docs);
        let filtereddocumentsData = utilFunctions.removeEmptyObjects(documentsData)

        let prepDocumentData: IComplianceProcess = {
            id: props.processData.id,
            complianceType: props.processData!.complianceType.toLowerCase() === ComplianceTypes.Unkown.toLowerCase() ? 
              ComplianceTypes.IdentityAndRWAudit : props.processData!.complianceType,
            processData: {
              documents: filtereddocumentsData,
            },
        };
      
        setPutRequest(prepDocumentData);
    }

    useEffect(() => {
        const primaryDocValues = identityDocValues![0];
        const primaryValidFrom = primaryDocValues.validFrom ? primaryDocValues.validFrom!.toString() : 'Invalid date';

        const secondaryDocValues = identityDocValues![1];
        const secondaryValidFrom = secondaryDocValues.validFrom ? secondaryDocValues.validFrom!.toString() : 'Invalid date';

        const ternaryDocValues = identityDocValues![2];
        const ternaryValidFrom = ternaryDocValues.validFrom ? ternaryDocValues.validFrom!.toString() : 'Invalid date';
    
    
        if ((primaryDocValues.validFrom === null || primaryValidFrom === 'Invalid date') && primaryDocValues.documentId.toLowerCase().includes('passport')){
          setPrimaryValidFromError(true);
          setPrimaryValidFromHelperText(t("errors.valid_from_msg"));
        } else if (moment(primaryDocValues.validFrom) > moment()) {
          setPrimaryValidFromError(true);
          setPrimaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
        } else if (moment(primaryDocValues.validFrom) > moment(primaryDocValues.validTo)) {
          setPrimaryValidFromError(true);
          setPrimaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
        } else {
          setPrimaryValidFromError(false);
          setPrimaryValidFromHelperText(t(""));
        }

        if ((secondaryDocValues.validFrom === null || secondaryValidFrom === 'Invalid date') && secondaryDocValues.documentId.toLowerCase().includes('passport')){
          setSecondaryValidFromError(true);
          setSecondaryValidFromHelperText(t("errors.valid_from_msg"));
        } else if (moment(primaryDocValues.validFrom) > moment()) {
          setSecondaryValidFromError(true);
          setSecondaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
        } else if (moment(primaryDocValues.validFrom) > moment(primaryDocValues.validTo)) {
          setSecondaryValidFromError(true);
          setSecondaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
        } else {
          setSecondaryValidFromError(false);
          setSecondaryValidFromHelperText(t(""));
        }

        if ((ternaryDocValues.validFrom === null || ternaryValidFrom === 'Invalid date') && ternaryDocValues.documentId.toLowerCase().includes('passport')){
          setTernaryValidFromError(true);
          setTernaryValidFromHelperText(t("errors.valid_from_msg"));
        } else if (moment(primaryDocValues.validFrom) > moment()) {
          setTernaryValidFromError(true);
          setTernaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
        } else if (moment(primaryDocValues.validFrom) > moment(primaryDocValues.validTo)) {
          setTernaryValidFromError(true);
          setTernaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
        } else {
          setTernaryValidFromError(false);
          setTernaryValidFromHelperText(t(""));
        }
    
      }, [identityDocValues, t]);
      
    return (
        <GenericModalResponsive
            heading={t("verify_identity.confirm_issue_dates_title")}
            onCloseModal={(e?: EventTarget) => onEditInfoModalClose(e)}
            open={isEditClicked}
            closeOnBackDrop={false}
            modalWidth={isMobileOnly ? "90%" : "50%"}
            modalHeight={"auto"}
            modalPadding={isMobileOnly ? 20 : 30}
            modalMargin={isMobileOnly ? 10 : 50}
            modalTop={"15%"}
            className="personal-info-issue-date-edit-modal"
            hideModalClose={true}
            >
            <div className="personal-info-issue-date-edit-modal">
                {loading ? (
                <div style={{ marginTop: "50px", textAlign: "center" }}>
                    <GenericLoader />
                    <div className="loading-text">{t("common.saving")}</div>
                </div>
                ) : 
                _.map(
                    _.filter(
                      documentDefinitionData,
                      (documentsData: InfoTypes.DocumentDefinition) =>
                        documentsData && !documentsData.validFrom && documentsData.documentId.includes("Passport")
                    ),
                    (filteredDocumentData: InfoTypes.DocumentDefinition, index: number) => (
                        <Box sx={{ p: 2 }}>
                            <Grid
                                id={"document-details-valid-from-" + index}
                                key={"document-details-valid-from-" + index}
                                container 
                                spacing={2}
                            >
                                <Grid item xs={4}>   
                                    <Typography variant="subtitle2">{t('common.passport_valid_from_date')} {index + 1}</Typography>
                                </Grid >
                                <Grid item xs={8}>
                                    <DateEditor
                                        value={filteredDocumentData.validFrom ? filteredDocumentData.validFrom : null}
                                        editValue={identityDocValues[index].validFrom}
                                        label={t("digital_identity.date_of_issue")}
                                        showEdit={true}
                                        isCheckEditable={true}
                                        onChangeValue={handleDocValueDateChange("validFrom", index)}
                                        onEditClicked={(e: EventTarget) => {}}
                                        error={index === 0 ? primaryValidFromError : ( 
                                            index === 1 ? secondaryValidFromError : ternaryValidFromError
                                        )}
                                        helperText={index === 0 ? primaryValidFromHelperText : ( 
                                            index === 1 ? secondaryValidFromHelperText : ternaryValidFromHelperText
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )
                )}

                <div className="fullWidth"></div>
                    <div className="fullWidth" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <GenericButton
                            onClick={() => {
                                saveDocumentsIssueDates();
                            }}
                            disabled={primaryValidFromError ||
                                secondaryValidFromError ||
                                ternaryValidFromError
                            }
                            style={{width: "160px"}}
                            type="primary"
                            buttonText={t("common.confirm")}
                        />
                    </div>   
                </div>   
        </GenericModalResponsive>

    )
}