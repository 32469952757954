import _ from "lodash";
import moment from "moment";
import { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import Constants from "../../Constants/constants";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IDocumentDefinition from "../../interfaces/IDocumentDefinition";
import IDocumentGroupList from "../../interfaces/IDocumentGroupList";
import InfoTypes from "../../interfaces/InfoTypes";
import useAPIRequest from "../../Service/useAPIRequest";
import utilFunctions from "../../utils/util";
import { useRTWContext } from "../rtw-context";
import { DocGroupFieldsState, DocGroupState, DocumentsContext, DocumentsContextProviderProps, InitialDocGroupFieldsState, InitialDocGroupState, InitialDocValues, InitialPrimaryDocumentDefinition, InitialSecondaryDocumentDefinition, docGrpList, documentGroups } from "./documents-types";

const DocumentsContextProvider = ({ children }: DocumentsContextProviderProps) => {
  const { t } = useTranslation();

  const [documentTypeFields, setDocumentTypeFields] = useState<DocGroupFieldsState>(
    InitialDocGroupFieldsState
  );
  const [docGroupListState, setDocGroupListState] = useState<IDocumentGroupList | undefined>();
  const [documentDefinitionData, setDocumentDefinitionData] = useState<IDocumentDefinition[]>();
  const [identityDocValues, setIdentityDocValues] =
    useState<IDocumentDefinition[]>(InitialDocValues);
  const [docGroupSelection, setDocGroupSelection] = useState(InitialDocGroupState);
  const [, setTempFiles] = useState([]);
  const [, setAttachementDisabled] = useState(true);
  const [isPrimaryFormValid, setIsPrimaryFormValid] = useState(false);
  const [isSecondaryFormValid, setIsSecondaryFormValid] = useState(false);
  const [isPrimaryFileExist, setIsPrimaryFileExist] = useState(false);
  const [isSecondaryFileExist, setIsSecondaryFileExist] = useState(false);
  const [isPrimarySelectSwitch, setIsPrimarySelectSwitch] = useState(false);
  const [isSecondarySelectSwitch, setIsSecondarySelectSwitch] = useState(false);
  const [isPrimarySelectSwitchMessage, setIsPrimarySelectSwitchMessage] = useState(false);
  const [isSecondarySelectSwitchMessage, setIsSecondarySelectSwitchMessage] = useState(false);

  const { complianceProcessData, setLoading, handleNextStep, handleSaveError, handleGenericError, setDocumentSubmissionComplete } =
    useRTWContext();

  const GetDocumentGroupSelectedLabels = (prop: keyof DocGroupState, docType: string) => {
    let docGrpTypeFields = documentTypeFields;

    useAPIRequest(
      Constants.API_URLS.DOCUMENTS + docType + "/" + Constants.API_URLS.GET_DOCUMENT_TYPE_FIELDS,
      {
        method: "GET",
        body: "",
      }
    )
      .then((res) => {
        if (res && res !== undefined) {
          docGrpTypeFields![prop] = res;
          setDocumentTypeFields((items) => ({
            ...items,
            [prop]: res,
          }));
        }
      })
      .catch((e) => {});
  };

  const handleDocGroupChange =
    (prop: keyof DocGroupState, key?: string) => (event: ChangeEvent<{}>, val: any) => {
      let docGrpListFiltered = docGroupListState;
      let docDefinition = documentDefinitionData;
      let identityDefinition = identityDocValues;
      let primarySelectSwitchFirstAttempt = 0;
      let secondarySelectSwitchFirstAttempt = 0;

      const primaryDocsFiltered = utilFunctions.filterDocuments(docGroupListState!.primaryDocuments, val);
      const secondaryDocsFiltered = utilFunctions.filterDocuments(docGroupListState!.secondaryDocuments, val);

      setDocumentTypeFields({
        ...documentTypeFields,
        [prop]: {},
      });

      switch (prop) {
        case "primaryDocuments":
          docGrpListFiltered!.secondaryDocuments = secondaryDocsFiltered;
          if (docDefinition && docDefinition[0]) {
            utilFunctions.setDocumentDefinition(docDefinition, 0, InitialPrimaryDocumentDefinition, val);
            utilFunctions.setDocumentDefinition(identityDefinition, 0, InitialPrimaryDocumentDefinition, val);

            if(documentDefinitionData && documentDefinitionData[0].filename !== '') documentDefinitionData[0].filename = '';

            setIsPrimaryFileExist(false);

            primarySelectSwitchFirstAttempt++;
            primarySelectSwitchFirstAttempt > 0 ? setIsPrimarySelectSwitchMessage(true) : setIsPrimarySelectSwitchMessage(false);
            setIsPrimarySelectSwitch(true);
          }
          break;
        case "secondaryDocuments":
          docGrpListFiltered!.primaryDocuments = primaryDocsFiltered;
          if (docDefinition && docDefinition[1]) {
            utilFunctions.setDocumentDefinition(docDefinition, 1, InitialSecondaryDocumentDefinition, val);
            utilFunctions.setDocumentDefinition(identityDefinition, 1, InitialSecondaryDocumentDefinition, val);

            if(documentDefinitionData && documentDefinitionData[1].filename !== '') documentDefinitionData[1].filename = '';

            setIsSecondaryFileExist(false);

            secondarySelectSwitchFirstAttempt++;
            secondarySelectSwitchFirstAttempt > 0 ? setIsSecondarySelectSwitchMessage(true) : setIsSecondarySelectSwitchMessage(false);
            setIsSecondarySelectSwitch(true);
          }
          break;
        default:
          break;
      }
      setDocumentDefinitionData(docDefinition);
      setIdentityDocValues(identityDefinition);
      setDocGroupListState(docGrpListFiltered);
      setDocGroupSelection({
        ...docGroupSelection,
        [prop]: val!,
      });
      GetDocumentGroupSelectedLabels(prop, val.type);
    };

  const handleRemoveSavedFile = (index: number) => {
    let identityVals = identityDocValues;

    utilFunctions.cleanFileObject(identityVals[index], 'complete');

    index === 0 ? setIsPrimaryFileExist(false) : setIsSecondaryFileExist(false);

    setDocumentDefinitionData(identityVals);
    setIdentityDocValues(identityVals);
  };

  const removeDocument = (index: number) => {
    let identityDocVals = identityDocValues;

    utilFunctions.cleanFileObject(identityDocVals[index]);
    
    index === 0 ? setIsPrimaryFileExist(false) : setIsSecondaryFileExist(false);

    setIdentityDocValues(identityDocVals);
  };

  const pushFiles = (files: any, index: number) => {
    _.forEach(files, async (e: any) => {
      e.fileFrom = "local";
      let arr: any = await utilFunctions.getFileToByteArray(e);
      e.binaryFormat = Array.from(new Uint8Array(arr));

      let identityDocVals = identityDocValues;
      identityDocVals[index].binary = files[0].binaryFormat;
      identityDocVals[index].filename = files[0].name;
      identityDocVals[index].contentType = files[0].type;

      index === 0 ? setIsPrimaryFileExist(true) : setIsSecondaryFileExist(true);

      setIdentityDocValues(identityDocVals);
    });

    if (files?.length > 0 && files?.length < 1) {
      setAttachementDisabled(false);
      setTempFiles(files);
    } else {
      setAttachementDisabled(true);
      setTempFiles([]);
    }
  };

  const GetDocumentGroupList = async (nationality: string) => {
    setLoading(true);
    useAPIRequest(Constants.API_URLS.GET_DOCUMENTS_GROUPS_DBS + nationality.toLowerCase(), {
      method: "GET",
      body: "",
    })
      .then((res) => {
        setLoading(false);
        if (res && res !== undefined) {
          if (res) {
            documentGroups.forEach((group) => {
              docGrpList[group] = _.map(
                res![group],
                (e: InfoTypes.DocumentGroupDefinition) => ({ ...e, title: e.description!, id: e.id, documentId: e.id, value: e.id })
              );
            });

            setDocGroupListState(docGrpList);
          }
        } else setLoading(false);
      
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleEditSaveCallbackDoc = (
    prop: keyof InfoTypes.DocumentDefinition,
    val: any,
    index: number
  ) => {
    let docValToUpdate: any = documentDefinitionData;
    if (prop === "emittingCountry") {
      docValToUpdate![index][prop + "Val"] = val;
      docValToUpdate![index][prop] = val.id;
    } else if (prop === "validFrom" || prop === "validTo") docValToUpdate![index][prop] = moment(val).format(Constants.DATE_FORMAT_ISO);
    else docValToUpdate![index][prop] = val;

    setDocumentDefinitionData(docValToUpdate);
    setIdentityDocValues(docValToUpdate);
  };

  const SendPutDocumentsData = async (docRequest: IComplianceProcess) => {
    setLoading(true);
    useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + complianceProcessData!.id, {
      method: "PUT",
      body: docRequest,
    })
      .then((res) => {
        if (res.ok) {
          if (handleNextStep) {
            handleNextStep(docRequest, Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON);
            setDocumentSubmissionComplete(true);
          }
        } else handleSaveError(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (handleGenericError) handleGenericError(t("errors.save_error_msg"));
      });
  };

  const handleDocValueChange =
  (prop: keyof InfoTypes.DocumentDefinition, index: number) =>
  (event: ChangeEvent<HTMLInputElement>, val: string) => {
    setIdentityDocValues((items) => ({
      ...items,
      [index]: {
        ...items[index],
        [prop]: val,
      },
    }));
  };

const handleDocValueDateChange =
  (prop: keyof InfoTypes.DocumentDefinition, index: number) => (val: Date | null) => {
    setIdentityDocValues((items) => ({
      ...items,
      [index]: {
        ...items[index],
        [prop]: moment(val!).format(Constants.DATE_FORMAT_ISO),
      },
    }));
  };

const handleDocValueSelectChange =
  (prop: keyof InfoTypes.DocumentDefinition, index: number) =>
  (event: any, val: InfoTypes.SelectValueType) => {
    setIdentityDocValues((items) => ({
      ...items,
      [index]: {
        ...items[index],
        [prop]: val.id,
        [prop + "Val"]: val,
      },
    }));
  };

  return (
    <DocumentsContext.Provider
      value={{
        docGroupListState,
        setDocGroupListState,
        docGroupSelection,
        setDocGroupSelection,
        handleDocGroupChange,
        documentDefinitionData,
        setDocumentDefinitionData,
        identityDocValues,
        setIdentityDocValues,
        documentTypeFields,
        setDocumentTypeFields,
        handleRemoveSavedFile,
        removeDocument,
        pushFiles,
        handleEditSaveCallbackDoc,
        GetDocumentGroupList,
        GetDocumentGroupSelectedLabels,
        SendPutDocumentsData,
        isPrimaryFormValid,
        setIsPrimaryFormValid,
        isSecondaryFormValid,
        setIsSecondaryFormValid,
        isPrimaryFileExist,
        setIsPrimaryFileExist,
        isSecondaryFileExist,
        setIsSecondaryFileExist,
        isPrimarySelectSwitch,
        setIsPrimarySelectSwitch,
        isSecondarySelectSwitch,
        setIsSecondarySelectSwitch,
        isPrimarySelectSwitchMessage,
        setIsPrimarySelectSwitchMessage,
        isSecondarySelectSwitchMessage,
        setIsSecondarySelectSwitchMessage,
        handleDocValueChange,
        handleDocValueDateChange,
        handleDocValueSelectChange,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export default DocumentsContextProvider;
