import Constants from "../Constants/constants";
import DBSComplianceIcon from "../Assets/DBS-check.png";
import DVLAComplianceIcon from "../Assets/DVLA-check.png";
import IDComplianceIcon from "../Assets/address-card.png";
import { ComplianceTypes, ImportStatus, ImportableTypeEditor, ProcessStatus } from "../Constants/enums";
import IComplianceProcess from "../interfaces/IComplianceProcess";
import ImportableTypes from "../Constants/ImportableTypes";
import InfoTypes from "../interfaces/InfoTypes";
import i18next from 'i18next';

export default class utilFunctions {

  static cleanseJson = (obj: any) => {

    Object.keys(obj).forEach(key => {
      if (Object.prototype.toString.call(obj[key]) === '[object Date]' &&
          (obj[key].toString().length === 0 ||
            obj[key].toString() === 'Invalid Date')) {
          delete obj[key];
      } else if (obj[key] && typeof obj[key] === 'object') {
        this.cleanseJson(obj[key]);
      } else if (obj[key] == null || obj[key] === '') {
        delete obj[key];
      }

      if (obj[key] && typeof obj[key] === 'object' &&
        Object.keys(obj[key]).length === 0 &&
        Object.prototype.toString.call(obj[key]) !== '[object Date]') {
          delete obj[key];
      }
    });
    return obj;
  }

  static removeEmptyObjects(array: any) {
    const newArray = array.filter((element: any) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
  
      return false;
    });
  
    return newArray;
  }
  

  static renderComplianceIcon = (processName: string) => {
    switch(processName.toLowerCase().trim()) {
        case ComplianceTypes.DBS.toLowerCase():
        case ComplianceTypes.BasicDBS.toLowerCase():
            return DBSComplianceIcon;
        case ComplianceTypes.DVLA.toLowerCase():
            return DVLAComplianceIcon;
        default:
            return IDComplianceIcon;   
    }
  }

  static getProcessBackgroundColor = (processStatus: ProcessStatus) => {
    switch(processStatus) {
      case ProcessStatus.WaitingForWorker:
        return "#E7D95B";
      case ProcessStatus.Complete:
        return "#B0D998";
      case ProcessStatus.ResultNegative:
        return "#87C1BE";
      default:
        return "#E7D95B";
    }
  }
  
  static getComplianceFormRoute = (processName: string) => {
    switch(processName.toLowerCase().trim()) {
        case ComplianceTypes.BasicDBS.toLowerCase():
          return Constants.COMPLIANCE_FORM_ROUTES.BASICDBS;
        case ComplianceTypes.DBS.toLowerCase():
          return Constants.COMPLIANCE_FORM_ROUTES.DBS;
        case ComplianceTypes.DVLA.toLowerCase():
          return Constants.COMPLIANCE_FORM_ROUTES.DVLA;
        case ComplianceTypes.IdentityCheck.toLowerCase():
          return Constants.COMPLIANCE_FORM_ROUTES.IDENTITYCHECK;
        case ComplianceTypes.IdentityAndRWAudit.toLowerCase():
          return Constants.COMPLIANCE_FORM_ROUTES.RTW;
        default:
          return Constants.COMPLIANCE_FORM_ROUTES.IDENTITYCHECK;
    }
  }

  static testRegex = (regex: RegExp, value: any) => {
    return regex.test(value)
  }

  static getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
  };

  static getFileToByteArray = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
  }

  static cleanseComplianceTypeSubmitRequestObject = (complianceData: any) => {
    if (complianceData) {
      delete complianceData.partitionKey;
      delete complianceData.createdDate;
      delete complianceData.assignedTo;
      delete complianceData.requestedBy;
      delete complianceData.status;
      if (complianceData.digitalIdentity) {
        delete complianceData.digitalIdentity;
      }
      if (complianceData.processData) {
        delete complianceData.processData.createdDate;
      }
    }
    return complianceData;
  }

  static setDocumentDefinition = (
    array: InfoTypes.DocumentDefinition[],
    index: number,
    documentType: InfoTypes.DocumentDefinition,
    val: { id: string; type: string; group: string }
  ) => {
      array[index] = { ...documentType };
      array[index].documentId = val.id;
      array[index].type = val.type;
      array[index].group = val.group;
  }

  static cleanFileObject = (fileData: any, level?: string) => {
    if (fileData) {
      delete fileData.binary;
      delete fileData.filename;
      delete fileData.contentType;
      if (level === 'complete') {
        delete fileData.url;
        delete fileData.docFiles;
      }
    }
    return fileData;
  }

  static CheckIDVImportedFieldIsEditable(fieldName: string, type: string, data: IComplianceProcess) {
    if (data && data.status === ProcessStatus.WaitingForWorker) {
      
      if (data.digitalIdentity && data.digitalIdentity.importStatus === ImportStatus.Imported) {
       
        switch (type) {
          case ImportableTypeEditor.PersonalInfo:
             return !(fieldName in ImportableTypes.PersonalInfoFields);
          case ImportableTypeEditor.AddressInfo:
             return (fieldName in ImportableTypes.AddressInfoFields);
          case ImportableTypeEditor.DocumentsInfo:
             return !(fieldName in ImportableTypes.DocumentInfoFields);
          default:
            return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  static GetProcessStatusTranslationKey(status: ProcessStatus) {
    switch (status) {
      case ProcessStatus.Created:
        return "process_status.created";
      case ProcessStatus.WaitingForWorker:
        return "process_status.waiting_for_worker";
      // case ProcessStatus.WaitingForClient:
      //   return "process_status.waiting_for_client";
      case ProcessStatus.WaitingForClient:
        return "process_status.waiting_for_employer";
      case ProcessStatus.WaitingForCounterSigning:
        return "process_status.waiting_for_countersigning";
      case ProcessStatus.SubmittedToThirdParty:
        return "process_status.submitted_to_third_party";
      case ProcessStatus.ResultPositive:
        return "process_status.result_positive";
      case ProcessStatus.ResultNegative:
        return "process_status.result_negative";
      case ProcessStatus.Cancelled:
        return "process_status.cancelled";
      case ProcessStatus.Complete:
        return "process_status.complete";
      default:
        return "process_status.created";
    }
  }

  
  static handleErrors = (val: any, property: string, type: string) => {
    let status = false;

    switch (type) {
      case 'text':
        if(!val) status = true;
        else if (val.hasOwnProperty(property) && property !== ''){
          if (val[property].trim() === "") status = true;
          else status = false;
        } 
        else if (val.trim() === "") status = true;
        else  status = false;
        break;

      default:
        status = false;
        break;
    }
    
    return status;
  }


  static handleHelperText = (val: any, property: string, type: string) => {
    let message = '';

    switch (type) {
      case 'text':
        if(!val) message = i18next.t("validators.required");
        else if (val.hasOwnProperty(property) && property !== ''){
          if (val[property].trim() === "") message = i18next.t("validators.required");
          else message = '';
        } 
        else if (val.trim() === "") message = i18next.t("validators.required");
        else  message = '';
        break;

      default:
        message = '';
        break;
    }
    
    return message;
  }

  static filterDocuments = (documents: any, val: any) => {
    if (documents) {
      return documents.filter(
        (t: InfoTypes.DocumentGroupDefinition) =>
          t.id.toLowerCase() !== val.id.toLowerCase() || t.id === "UtilityBill"
      );
    } else {
      return [];
    }
  };
}



