import "./index.scss";
import Constants from "../../Constants/constants";
import { useTranslation } from "react-i18next"
import LocalStorageService from "../../utils/localStorageService";
import { useApi } from "../../Auth/UseApi";
import IProfile from "../../interfaces/IProfile";
import { GenericLoader } from "../../Components/GenericLoader";

export default function GetWorkerProfileData() {
    const { t } = useTranslation();
    const { loading, error, data } = useApi<IProfile>(Constants.API_URLS.GET_WORKER_PROFILE);

    if (loading) {
      return <GenericLoader />;
    }

    if (error) {
      LocalStorageService.removeData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA);
      if (error instanceof Error) {
        return <div>{t("errors.something_wrong_err", { error: error?.message })}</div>;
      }
      return (
        <div>
          {t("errors.something_wrong")}
          {t("errors.please_refresh")}
        </div>
      );
    } else {
      LocalStorageService.setData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA, data!);
    }
}
