import React, { createRef, CSSProperties } from "react";
import GenericDataShow from "../GenericDataShow";
import GenericTextField from "../GenericTextField";

interface Props {
  fieldName: string;
  value: string | number | null | undefined;
  editValue: string | number | null | undefined;
  label: string;
  type: string; // html5 types "text" , "number", "tel" ...
  showEdit: boolean;
  isCheckEditable: boolean;
  helperText?: string;
  error?: boolean;
  isNew?: boolean;
  inputProps?: {
    [key: string]: any;
  };
  editStyle?: CSSProperties | undefined;
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>, val: string, index?: number) => void;
  onEditClicked?: (e: EventTarget) => void;
}

const TextEditor = (props: Props) => {
  const oneRef = createRef();
  return (
    <>
      {((props.isNew === undefined || props.isNew === null || !props.isNew) &&
        props.value &&
        props.value !== "") ||
      !props.isCheckEditable ? (
        <GenericDataShow
          label={props.label}
          value={props.value}
          showEdit={props.showEdit}
          onEditClicked={props.onEditClicked}
        />
      ) : (
        <div
          className="p-t-30"
          style={props.editStyle}
        >
          <GenericTextField
            label={props.label}
            name={props.fieldName}
            type={props.type}
            id={props.fieldName}
            variant="outlined"
            margin="dense"
            fullWidth={true}
            inputRef={oneRef}
            value={props.editValue}
            onChangeValue={props.onChangeValue}
            helperText={props.helperText}
            error={props.error}
            inputProps={props.inputProps}
          />
        </div>
      )}
    </>
  );
};

export default TextEditor;
