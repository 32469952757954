/* tslint:disable */
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";

interface Props {
  pushFiles: (val: any) => void;
  supportMessage: string;
  maxNumberOfFiles: number;
  sizeOfEachFile: number;
  fileAcceptType: string;
  maxSize: number;
  validTypes: any;
  minContainerHeight: string;
  minFileHeight: number;
  disabled?: boolean;
  isPrimarySelectSwitch?: boolean;
  isSecondarySelectSwitch?: boolean;
  isTernarySelectSwitch?: boolean;
  dropText?: string;
  removeFile?: () => void;
}

const Uploader = (props: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [validFiles, setValidFiles] = useState<any | []>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const preventDefault = (e: any) => {
    e.preventDefault();
  };

  const dragOver = (e: any) => {
    preventDefault(e);
  };

  const dragEnter = (e: any) => {
    preventDefault(e);
  };

  const dragLeave = (e: any) => {
    preventDefault(e);
  };

  const fileDrop = (e: any) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles([...validFiles, ...files]);
    }
  };

  const filesSelected = () => {
    if (fileInputRef && (fileInputRef as any)?.current?.files.length) {
      const file: any = fileInputRef?.current?.files;
      handleFiles([...validFiles, ...file]);
    }
  };

  const fileInputClicked = () => {
    fileInputRef?.current?.click();
  };

  const handleFiles = (files: any) => {
    _.forEach(files, (e: any) => {
      e.isValidFile = validateFile(e);
    });
    if (files?.length > 0) {
      setValidFiles([...files]);
      sendData([...files]);
    } else {
      setValidFiles([]);
      sendData([]);
    }
  };

  const sendData = (files: any) => {
    if (
      _.compact(_.map(files, (e: any) => e.isValidFile)).length === files?.length &&
      files?.length < props.maxNumberOfFiles + 1
    ) {
      props.pushFiles(files);
      setError(false);
      setErrorMessage(``);
    } else {
      setError(true);
      if (files?.length > props.maxNumberOfFiles) {
        setErrorMessage(
          `You can select only upto ${
            props.maxNumberOfFiles > 1
              ? props.maxNumberOfFiles + "files for upload."
              : "1 file for upload."
          }`
        );
      } else if (checkType(files)) {
        setErrorMessage(`Invalid File Type: Only .pdf, .jpg, .png`);
      } else if (checkSize(files)) {
        setErrorMessage(`Invalid File Size: Max size 20Mb`);
      }
    }
  };

  const checkType = (files: any): boolean => {
    const validTypes = props.validTypes;
    const values = _.map(files, (e: any) => {
      if (e.name.endsWith(".msg")) {
        return true;
      } else {
        return validTypes.includes(e.type);
      }
    });

    return _.compact(values).length === files.length ? false : true;
  };

  const checkSize = (files: any): boolean => {
    const values = _.map(files, (e: any) => {
      return !(e.size > props.sizeOfEachFile);
    });

    return _.compact(values).length === files.length ? false : true;
  };

  const validateFile = (file: any) => {
    const validTypes = props.validTypes;
    if (file.name.endsWith(".msg")) {
      return true;
    } else if (!validTypes.includes(file.type) || file.size > props.sizeOfEachFile) {
      return false;
    } else {
      return true;
    }
  };

  const removeFile = (name: string) => {
    setError(false);
    setErrorMessage(``);
    const values: any = _.filter(validFiles, (e: any) => {
      return e.name !== name;
    });

    if (props.removeFile) {
      props.removeFile();
    }

    handleFiles(values);
  };

  useEffect(() => {
    if(props.isPrimarySelectSwitch || props.isSecondarySelectSwitch || props.isTernarySelectSwitch){
      const temp = [...validFiles];
      temp.splice(0, 1);
      setValidFiles(temp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPrimarySelectSwitch, 
    props.isSecondarySelectSwitch,
    props.isTernarySelectSwitch
  ]);

  return (
    <>
      <div
        className="container"
        style={{ pointerEvents: props.disabled ? "none" : "auto" }}
      >
        {validFiles && props.maxNumberOfFiles > validFiles.length ? (
          <>
            <div
              className={`drop-container ${
                validFiles?.length >= props.maxNumberOfFiles ? "display-none" : ""
              }`}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              style={{ minHeight: props.minContainerHeight ? props.minContainerHeight : "160px" }}
            >
              <div className="drop-message">
                <div
                  className="upload-icon"
                  onClick={fileInputClicked}
                >
                  <AddCircleIcon />
                </div>
                <span
                  className="upload-icon-name"
                  onClick={fileInputClicked}
                >
                  {" "}
                  {props.dropText && props.dropText !== ""
                    ? props.dropText
                    : "Drop your document here, or"}{" "}
                  <span className="upload-icon-name-broser"> Browse </span>{" "}
                </span>
                <div className="upload-icon-second">{props.supportMessage}</div>
              </div>
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                onChange={filesSelected}
                accept={props.fileAcceptType}
                multiple={props.maxNumberOfFiles > 1}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {validFiles && validFiles.length > 0 ? (
          <>
            <div
              className="file-display-container"
              style={{
                minHeight: props.minFileHeight
                  ? props.minFileHeight * validFiles.length + "px"
                  : "200px",
              }}
            >
              {_.map(validFiles, (data: any, i) => (
                <div
                  className="file-status-bar"
                  key={i}
                >
                  <div>
                    <div className="file-type-logo">
                      {data.isValidFile ? (
                        <CheckCircleIcon
                          htmlColor={"#0da931"}
                          style={{ margin: "10px" }}
                        />
                      ) : (
                        <CancelIcon
                          htmlColor={"#d10000"}
                          style={{ margin: "10px" }}
                        />
                      )}
                    </div>
                    <span className={`file-name `}>{data.name}</span>
                  </div>
                  <div
                    className="file-remove"
                    onClick={() => {
                      removeFile(data.name);
                    }}
                  >
                    <CancelIcon htmlColor={"#ff5e00"} />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}

        <>{error ? <div className="file-error file-error-message">{errorMessage}</div> : <></>}</>
      </div>
    </>
  );
};

export default Uploader;
