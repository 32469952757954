import "../index.scss";
import Heading from "../Components/Heading";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <div id="privacy-policy" className="privacy-policy editor-component">
      <div className="privacy-policy-body">
        <div className="privacy-policy-body-container">
          <Heading title={t("privacy_policy.title")} />
          <div className="privacy-policy-body-container-details-rows">
            <div className="privacy-policy-body-container-details-row">
              <div
                className="privacy-policy-body-container-details-row-content editor-component-body"
                style={{ float: "none" }}
              >
                <div className="policy-details">
                  <p className="sub-heading">Introduction</p>
                  <p className="body-para">
                  Welcome to  <a href="www.talentclouds.co.uk" target={"_blank"}>www.talentclouds.co.uk</a>. These Terms of Service contain the terms and conditions that govern all use of our Platform and Talent Clouds Services.
                  </p>
                  <p className="body-para">
                  The Talent Clouds Services are offered to you without modification (other than as agreed by us in writing), of all of these terms and conditions and all other operating rules, policies (including, without limitation, our Privacy Policy at the Guidelines as amended from time to time, and such procedures that may be published from time to time on the Platform or made available to you on or through the Talent Clouds Services) (collectively, the "Terms"). When accepted by you, these Terms form a legally binding contract between you and us.
                  </p>
                  <p className="body-para">
                  If you are entering into these Terms on behalf of an entity, such as your employer or the company you work for, you represent that you have the legal authority to bind that entity.
                  </p>
                  <p className="body-para">
                  PLEASE READ THESE TERMS CAREFULLY. BY REGISTERING FOR, ACCESSING, BROWSING, AND/OR OTHERWISE USING THE TALENT CLOUDS SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, DO NOT ACCESS, BROWSE OR OTHERWISE USE THE PLATFORM OR THE TALENT CLOUDS SERVICES.
                  </p>
                  <p className="body-para">
                  We may, in our sole discretion, elect to suspend or terminate access to, or use of the Talent Clouds Services to anyone who does not comply with these Terms.
                  </p>
                  <p className="sub-heading">
                    1.&nbsp;&nbsp; Definitions
                  </p>
                  <p className="body-para">
                  <b>Account: </b>The primary means for accessing and using the Talent Clouds Services, subject (in the case of an Employer) to payment of the applicable Account Fees;
                  </p>
                  <p className="body-para">
                  <b>Account Fee: </b>The applicable fee specified in the Guidelines from time to time for each Talent Clouds Service, each such fee being subject to VAT (if applicable); 
                  </p>
                  <p className="body-para">
                  <b>Client:</b> A natural or legal person who has accepted these Terms with us; 
                  </p>
                  <p className="body-para">
                  <b>Checks:</b> Any compliance processes selected within the Platform by an Employer; </p>
                  <p className="body-para">
                  <b>Employer:</b> A Client who is not a Worker; 
                  </p>
                  <p className="body-para">
                  <b>Guidelines:</b> Additional guidelines or rules applicable to specific features, applications, products, or services which may be posted from time to time on the Platform or otherwise made available on or through the Talent Clouds Services including our privacy policy and acceptable use policy; 
                  </p>
                  <p className="body-para">
                  <b>Platform:</b> Our platform at talentclouds.co.uk or such other URL as is notified to you by us from time to time; 
                  </p>
                  <p className="body-para">
                  <b>Supplier, We, Us:</b> Talent Cloud Solutions Limited whose principal place of business is at Unit 123, Screenworks 22 Highbury Grove, London, England, N5 2EF and whose registered company number is 10993087; 
                  </p>
                  <p className="body-para">
                  <b>Talent Clouds Services:</b> The Website, Platform and all content, services and/or products available on or through the Platform. 
                  </p>
                  <p className="body-para">
                  <b>Worker:</b> A Client in respect of whom Checks are made as part of the Talent Clouds Services; 
                  </p>
                  <p className="body-para">
                  <b>Worker Data:</b>  Any digital data and information, which is uploaded to the Platform by a Worker, as amended or supplemented as part of the Talent Clouds Services.
                  </p>
                  <p className="sub-heading">
                    2.&nbsp;&nbsp; Authority and Consent
                  </p>
                  <p className="body-para">
                  The use of the Talent Clouds Services is subject to acceptance of these Terms. To accept these Terms, a person must have the legal capacity to do so. In the case of an individual, the individual must be at least 18 years of age or have valid authorization from his/her legal representative or custodian. In the case of a legal entity, the entity must be duly incorporated and in good standing.
                  </p>
                  <p className="body-para">
                  You may not, without our prior written consent, access the Talent Clouds Services (i) if you are our competitor, (ii) to monitor the availability, performance or functionality of the Talent Clouds Services or (iii) for other benchmarking or competitive purposes.
                  </p>
                  <p className="body-para">
                  If you are an Employer and provide the personal details of Workers and prospective Workers to us, you confirm that you have all consents (or other ground for processing) necessary to the sharing of those personal details.
                  </p>
                  <p className="sub-heading">
                    3.&nbsp;&nbsp; Modifications to Terms
                  </p>
                  <p className="body-para">
                  We reserve the right, at our sole discretion, to amend the Terms (including the Account Fees) at any time by posting changes on or through the Platform or the Talent Clouds Services. Please check these Terms periodically for changes. Your continued use of the Talent Clouds Services after such changes have been communicated to you constitutes your binding acceptance of such changes. Such amended Terms will automatically be effective upon the earlier of (i) your continued use of the Talent Clouds Services and (ii) 30 days from posting of such modified Terms on or through the Platform. Notwithstanding the foregoing, the resolution of any dispute that arises between us will be governed by the Terms in effect at the time such dispute arose.
                  </p>
                  <p className="sub-heading">
                    4.&nbsp;&nbsp; Our Responsibilities
                  </p>
                  <p className="body-para">
                  4.1&nbsp;&nbsp;Provision of Talent Clouds Services
                  </p>
                  <p className="body-para">
                  ​We will use commercially reasonable efforts to make the Talent Clouds Services available at least 99% of the time within UK business hours, except for:
                  </p>
                  <ul>
                    <li>planned downtime (of which we shall give advance electronic notice as provided in the Guidelines); and</li>
                    <li>any unavailability caused by circumstances beyond our reasonable control, including (without limitation) an act of God, act of government, pandemic, epidemic, flood, fire, earthquake, civil unrest, act of terror, strike or other labour problem, Internet service provider failure or delay, or denial of service attack.</li>
                  </ul>
                  <p className="body-para">
                  4.2&nbsp;&nbsp;Personal Data
                  </p>
                  <p className="body-para">
                  In relation to personal data, we will comply with the provisions of our Privacy Policy at <a href="https://www.talentclouds.co.uk/privacy"target={"_blank"}>https://www.talentclouds.co.uk/privacy</a>. 
                  </p>
                  <p className="sub-heading">
                    5.&nbsp;&nbsp; Using the Talent Clouds Services
                  </p>
                  <p className="body-para">
                  5.1&nbsp;&nbsp;Authorised Users
                  </p>
                  <p className="body-para">
                  Certain features, functions, parts or elements of the Talent Clouds Services can be used or accessed only by the holder of an Account. 
                  </p>
                  <p className="body-para">
                  Each Client may have only one Account although an Employer may authorise a number of individuals to use the Account and shall be liable for any breach of these Terms by any such individual as if it were a breach by the Employer.  
                  </p>
                  <p className="body-para">
                    Each such user will be deemed to be authorized to act on behalf of the Employer when using the Account. We are not responsible for and shall have no liability for verifying the validity of authorization of any user. However, we may, in our discretion, request additional information or proof of a user’s credentials on issue of login details or at any time after issue.  If we are not certain if a user is authorised, we may, in our sole discretion, prevent that user from accessing the Talent Clouds Services.
                  </p>
                  <p className="body-para">
                  5.2&nbsp;&nbsp;Logging into an Account
                  </p>
                  <p className="body-para">
                  ​You (and each authorised user) are responsible for keeping confidential all login credentials associated with an Account. You must promptly notify us:
                  </p>
                  <ul>
                    <li>of any disclosure, loss or unauthorized use of any login credentials; </li>
                    <li>of an authorised user’s departure from the Employer’s organization; </li>
                    <li>of a change in an authorised user’s role in the Employer’s organization; </li>
                    <li>of any termination of an authorised user’s right for any reason. </li>
                  </ul>
                  <p className="sub-heading">
                    6.&nbsp;&nbsp;Payments
                  </p>
                  <p className="body-para">
                  6.1&nbsp;&nbsp;Account Fees 
                  </p>
                  <p className="body-para">
                  The use of certain Talent Clouds Services by an Employer is subject to payment of the applicable Account Fee.  
                  </p>
                  <p className="body-para">
                  It is a condition of use of the Talent Clouds Service as an Employer that you provide your payment card or account details to our nominated payment services provider and ensure that those details are valid, up-to-date and complete at all times. You hereby authorise us to instruct its payment services provider to bill such payment card or account via direct debit for the Account Fee and any associated service charge on or after provision of the applicable Talent Clouds Service. 
                  </p>
                  <p className="body-para">
                  If for any reason the payment services provider is unable to bill the payment card or account, we shall be entitled to invoice you for the Account Fee and you undertake to pay such invoice immediately. 
                  </p>
                  <p className="body-para">
                  6.2&nbsp;&nbsp;Interest 
                  </p>
                  <p className="body-para">
                    ​We shall be entitled to charge interest on any overdue payment at the rate of 4% a year above the Bank of England’s base rate from time to time, but at 4% a year for any period when that base rate is below 0%.
                  </p>
                  <p className="sub-heading">
                    7.&nbsp;&nbsp;Worker Data
                  </p>
                  <p className="body-para">
                  7.1&nbsp;&nbsp;Ownership of Worker Data 
                  </p>
                  <p className="body-para">
                  All rights, title and interest in and to Worker Data belong to the Worker (or its licensor) and the Worker licenses us to use the Worker Data in the provision of the Talent Clouds Services in accordance with our privacy policy.  We may use Worker Data to create aggregated and/or anonymized data for research, educational and other similar purposes and such data shall not be Worker Data and shall be owned by us. 
                  </p>
                  <p className="body-para">
                  7.2&nbsp;&nbsp;Limited Guarantee of Accuracy 
                  </p>
                  <p className="body-para">
                  ​We are only responsible to a Client for the accuracy and completeness of Worker Data if and to the extent that we have agreed in writing to validate that Worker Data as part of the Talent Clouds Services.   
                  </p>
                  <p className="body-para">
                  You are responsible for checking Worker Data (particularly immediately prior to relying on that Worker Data for any reason) and for notifying us promptly on becoming aware of any error in it.   
                  </p>
                  <p className="body-para">
                  We will correct promptly any error in Worker Data which is relevant to you and of which we become aware and will notify you that we have done so.
                  </p>
                  <p className="body-para">
                  7.3&nbsp;&nbsp;Compelled Disclosure  
                  </p>
                  <p className="body-para">
                    ​We may disclose a Client’s confidential information to the extent compelled by law or regulatory authority to do so but will use commercially reasonable efforts to provide you with prior notice of the compelled disclosure (to the extent legally permitted). We shall be entitled to charge you any reasonable costs incurred by us as a result.  
                  </p>
                  <p className="sub-heading">
                    8.&nbsp;&nbsp;Intellectual Property, including access to Worker Data 
                  </p>
                  <p className="body-para">
                  8.1&nbsp;&nbsp;Access to Worker Data 
                  </p>
                  <p className="body-para">
                  ​We grant you and your authorized users a non-exclusive, non-transferable, non-sub-licensable licence during the term of this agreement to use the Talent Clouds Services to access, download and copy your own Worker Data (if any) and any third party Worker Data in relation to which and to the extent that the owner of the relevant Worker Data has authorised us to give access to you.  This applies to a Worker’s current employer and any prospective employer. 
                  </p>
                  <p className="body-para">
                  ​As an Employer, you are entitled to download a single copy of the Worker Data of each of your employees, each time that you are entitled to access that Employee Data to receive the Talent Clouds Services that we have agreed to provide to you.   
                  </p>
                  <p className="body-para">
                  8.2&nbsp;&nbsp;Intellectual Property Rights in the Talent Clouds Services 
                  </p>
                  <p className="body-para">
                  ​All intellectual property rights (including without limitation trade marks and trade names) in and relating to the Talent Clouds Services and the Platform, and any parts or elements of them are solely and exclusively owned and operated by us (and our licensors) (including without limitation any joint branding of the Platform and the Talent Clouds Services).   
                  </p>
                  <p className="body-para">
                  </p>
                  <p className="body-para">
                  Your use of the Talent Clouds Services and the Platform, and any parts or elements does not grant to you any ownership right or intellectual property rights in them.  Any commercial or promotional distribution, publishing or exploitation of the Talent Clouds Services is strictly prohibited unless you have received our express prior written permission.  We reserve all rights to the Talent Clouds Services, the Platform and our trade names and trademarks. 
                  </p>
                  <p className="body-para">
                  8.3&nbsp;&nbsp;Feedback and anonymised data 
                  </p>
                  <p className="body-para">
                  ​We shall be entitled to use any feedback that you provide at our discretion, including, but not limited to incorporating suggested changes into the Talent Clouds Services.  We shall also be entitled to anonymise the data that you provide to us and to use it at our discretion, including but not limited to in the provision and improvement of Talent Cloud Services. 
                  </p>
                  <p className="sub-heading">
                    9.&nbsp;&nbsp;Third-Party Sites, Products and Services 
                  </p>
                  <p className="body-para">
                  The Talent Clouds Services may include links to other websites or services (“Linked Sites”). Unless otherwise specifically and explicitly indicated, we do not endorse any such Linked Sites or the information, material, products, or services contained on or accessible through Linked Sites or give any express or implied warranties with regard to the information, material, products, or services that are contained on or accessible through Linked Sites. ACCESS AND USE OF LINKED SITES, INCLUDING THE INFORMATION, MATERIAL, PRODUCTS, AND SERVICES ON LINKED SITES OR AVAILABLE THROUGH LINKED SITES, IS SOLELY AT YOUR OWN RISK. 
                  </p>
                  <p className="body-para">
                  </p>
                  <p className="sub-heading">
                    10.&nbsp;&nbsp;Liability 
                  </p>
                  <p className="body-para">
                  Nothing in the Contract limits any liability which cannot legally be limited, including liability for death or personal injury caused by negligence; fraud or fraudulent misrepresentation; and breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title and quiet possession). 
                  </p>
                  <p className="body-para">
                  We will not be liable to you, whether in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising under or in connection with these Terms Contract for loss of profits; loss of sales or business; loss of agreements or contracts; loss of anticipated savings; loss of use or corruption of software, data or information; loss of or damage to goodwill; and any indirect or consequential loss. 
                  </p>
                  <p className="body-para">
                  Our total liability to you arising under or in connection with these Terms, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, will be limited to the lower of £1,000 (one thousand pounds) and the aggregate amounts paid by you as Account Fees in the six months preceding the date of a claim. 
                  </p>
                  <p className="sub-heading">
                    11.&nbsp;&nbsp;Termination of These Terms 
                  </p>
                  <p className="body-para">
                    Either of us may terminate this agreement between us at any time by giving written notice to the other. 
                    </p>
                  <p className="body-para">
                    On termination, you will no longer be entitled to use the Talent Clouds Services, including, without limitation, the Platform. 
                    </p>
                  <p className="body-para">
                    Any provision of this agreement that expressly or by implication is intended to come into or continue in force on or after termination of this agreement shall remain in full force and effect. 
                    </p>
                  <p className="body-para">
                    Termination of this agreement shall not affect any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination. 
                  </p>
                  <p className="sub-heading">
                    12.&nbsp;&nbsp;Governing Law and Jurisdiction 
                  </p>
                  <p className="body-para">
                  This agreement shall be governed by and construed in accordance with the laws of England and Wales and subject to the exclusive jurisdiction of the courts of England and Wales.  
                  </p>
                  <p className="sub-heading">
                    13.&nbsp;&nbsp;General Provisions 
                  </p>
                  <p className="body-para">
                  13.1&nbsp;&nbsp; Relationship of the Parties 
                  </p>
                  <p className="body-para">
                  The parties are independent contractors. These Terms shall not be construed as creating an agency, partnership, joint venture, fiduciary duty, or any other form of legal association between the parties, and you shall not represent to the contrary, whether expressly, by implication, appearance or otherwise. No third party shall be entitled to enforce these Terms whether under the Contracts (Rights of Third Parties) Act 1999 or otherwise. 
                  </p>
                  <p className="body-para">
                  13.2&nbsp;&nbsp; Severability 
                  </p>
                  <p className="body-para">
                   If any term, condition or provision of these Terms is held to be invalid, unenforceable or illegal in whole or in part for any reason, that provision shall be enforced to the maximum extent permissible so as to give effect the intent of the parties. The validity and enforceability of the remaining terms, conditions or provisions, or portions of them, shall not be affected. 
                  </p>
                  <p className="body-para">
                  13.3&nbsp;&nbsp; Entire Agreement 
                  </p>
                  <p className="body-para">
                  ​These Terms are the entire agreement between us regarding your use of the Talent Clouds Services and supersede all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. Except as otherwise provided herein, no modification, amendment, or waiver of any provision of these Terms will be effective unless in writing and signed by the party against whom the modification, amendment or waiver is to be asserted. 
                  </p>
                  <p className="body-para">
                  13.4&nbsp;&nbsp; Assignment 
                  </p>
                  <p className="body-para">
                   You may not, directly or indirectly, in whole or in part, by operation of law or otherwise, assign or transfer these Terms or delegate any of your rights and/or obligations under these Terms without our prior written consent. Any attempted assignment, transfer or delegation without such prior written consent will be void and unenforceable. Notwithstanding the foregoing, you, or your permitted successive assignees or transferees, may assign or transfer these Terms or delegate any rights or obligations hereunder without consent: (1) to any entity controlled by, or under common control with you, or your permitted successive assignees or transferees; or (2) in connection with a merger, reorganization, transfer, sale of assets or product lines, or your change of control or ownership, or of your permitted successive assignees or transferees. 
                  </p>
                  <p className="body-para">
                  13.5&nbsp;&nbsp; No Waiver 
                  </p>
                  <p className="body-para">
                   Failure of either Party to exercise or enforce any provision of or any of its rights under these Terms shall not be deemed a waiver of future enforcement of that or any other provision or right. 
                  </p>
                  <p className="body-para">
                  13.6&nbsp;&nbsp; Notices 
                  </p>
                  <p className="body-para">
                   Any notice or other communication given to a party under or in connection with this agreement shall be in writing and shall be delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business (in any other case); or sent by email to the address specified by the receiving party from time to time. 
                  </p>
                  <p className="body-para">
                  Any notice or communication shall be deemed to have been received: if delivered by hand, at the time the notice is left at the proper address; if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the second business day after posting; or if sent by email, at the time of transmission, or, if this time falls outside business hours in the place of receipt, when business hours resume (business hours meaning 9.00am to 5.00pm Monday to Friday on a day that is not a public holiday in the place of receipt). 
                  </p>
                  <p className="body-para">
                  This clause does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any arbitration or other method of dispute resolution.  
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
