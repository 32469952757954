import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import InfoTypes from "../../interfaces/InfoTypes";
import { useEffect, useState } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IOtherInformation from "../../interfaces/IOtherInformation";
import Constants from "../../Constants/constants";
import IProfile from "../../interfaces/IProfile";
import LocalStorageService from "../../utils/localStorageService";
import useAPIRequest from "../../Service/useAPIRequest";
import Heading from "../../Components/Heading";
import { useTranslation } from "react-i18next"
import { LoaderModal } from "../../Components/LoaderModal";


type WorkedAbroadProps = {
  content: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
  isCheckDataEditable: boolean;
  handleSubmit?: (updatedProcessData?: IComplianceProcess, editor?: string) => void;
  errorHandler?: (msg: string) => void;
}

export default function WorkedAbroad(props: WorkedAbroadProps) {
    const { t } = useTranslation();
    const [haveResponded, setHaveResponded] = useState(false);
    const [haveWorkedAbroadYes, setHaveWorkedAbroadYes] = useState(false);
    const [haveWorkedAbroadNo, setHaveWorkedAbroadNo] = useState(false);
    const [otherInfoRequest, setOtherInfoRequest] = useState<IComplianceProcess>();
    const [loading, setLoading] = useState(false);

    const SendPutOtherInfoData = async (otherInfoRequest: IComplianceProcess) => {
      setLoading(true);
      useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + props.complianceProcessData.id, {
        method: "PUT",
        body: otherInfoRequest
        }).then((res) => {
          if (res.ok) {
            setLoading(false);
            if (props.handleSubmit) {
              props.handleSubmit(otherInfoRequest, Constants.COMPLIANCE_STEPS_EDITORS.WORK_ABROAD);
            }
          } else {
            setLoading(false);
          }
        }).catch(() => {
          setLoading(false);
          if (props.errorHandler) {
            props.errorHandler(t("errors.save_error_msg"));
          }
        });
    }

    useEffect(() => {
      async function SendPutOtherInfoDataRequest() {
          if(otherInfoRequest) {
              await SendPutOtherInfoData(otherInfoRequest);
          }
      }
      SendPutOtherInfoDataRequest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherInfoRequest]);

    useEffect(() => {
      setLoading(true);
      var otherInfoData: IOtherInformation;
      if (props.complianceProcessData) {
        if (props.complianceProcessData.processData && props.complianceProcessData.processData.other) {
          otherInfoData = props.complianceProcessData.processData.other;
        }
      } else {
        var profileDtls: IProfile = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA);
        if (profileDtls && profileDtls.other) {
          otherInfoData = profileDtls.other;
        }
      }

      if (otherInfoData!) {
        if(otherInfoData.hasLivedOrWorkedAbroadInLast5Years) {
          setHaveWorkedAbroadYes(true);
          setHaveWorkedAbroadNo(false);
        } else {
          setHaveWorkedAbroadNo(true);
          setHaveWorkedAbroadYes(false);
        }
        setHaveResponded(true);
      }
      setLoading(false);
    },[props.complianceProcessData]);

    const handleCheckResponse = (selection: string) => {
      setHaveResponded(true);

      if (selection === "yes") {
        if (haveWorkedAbroadYes) {
          setHaveWorkedAbroadYes(false);
          setHaveResponded(false);
        } else {
          setHaveWorkedAbroadYes(true);
        }
        setHaveWorkedAbroadNo(false);
      } else {
        setHaveWorkedAbroadYes(false);
        if (haveWorkedAbroadNo) {
          setHaveWorkedAbroadNo(false);
          setHaveResponded(false);
        } else {
          setHaveWorkedAbroadNo(true);
        }
      }
    }

    const handleSubmit = () => {
      const otherData: IOtherInformation = {
        hasLivedOrWorkedAbroadInLast5Years : haveWorkedAbroadYes
      }

      var prepOtherInfoData: IComplianceProcess = {
        id: props.complianceProcessData.id,
        complianceType: props.complianceProcessData.complianceType,
        processData: {
          other: otherData
        }
      }
      setOtherInfoRequest(prepOtherInfoData);
    }
  
    return (
    <div id="worked-abroad" className="worked-abroad editor-component">
      <div className="worked-abroad-body">
        <div className="worked-abroad-body-container">
          <Heading title={(props.content && props.content!.title !== "") ? props.content!.title : t("worked_abroad.title") } />
          <div className="worked-abroad-body-container-details-row">
          {(props.content) && (props.content.content) && props.content.content !== "" ?
            <div className="worked-abroad-body-container-details-row-content">
            { props.content.content.indexOf('</') !== -1
              ? (
                  <div className="editor-component-body" dangerouslySetInnerHTML={{__html: props.content.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                  </div>
                )
              : ""
            }
            </div>
            : ""}
            <div className="worked-abroad-body-container-details-row-content editor-component-body" style={{float: "none"}}>
             {t("worked_abroad.worked_abroad_ques")}
            </div>
            <div className="worked-abroad-body-container-details-row-content editor-component-body check-box-ctrls" style={{float: "none"}}>
              <FormGroup>
                  <FormControlLabel control={<Checkbox checked={haveResponded && haveWorkedAbroadYes} disabled={!props.isCheckDataEditable} onClick={() => handleCheckResponse("yes")} />} label={t("common.yes")} />
                  <FormControlLabel control={<Checkbox checked={haveResponded && haveWorkedAbroadNo} disabled={!props.isCheckDataEditable} onClick={() => handleCheckResponse("no")} />}  label={t("common.no")}/>
              </FormGroup>
            </div>
            <div className="worked-abroad-body-container-details-row-content editor-component-body" style={{float: "none"}}>
              <div className="container-submit-btn">
              {(props.isCheckDataEditable) ? 
                <GenericButton
                  onClick={handleSubmit}
                  disabled={(!haveResponded)}
                  type="primary"
                  buttonText={t("common.accept")}
                ></GenericButton>
              : ""}
              </div>
            </div>
          </div>
        </div>
        <LoaderModal isLoading={loading} />
      </div>
    </div>
  );
}
