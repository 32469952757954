import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IDocumentFields from "../../interfaces/IDocumentFields";
import InfoTypes from "../../interfaces/InfoTypes";

export interface VerifyIdentityProps {
    uiContent?: InfoTypes.ContentDefinition;
    complianceProcessData: IComplianceProcess;
    isCheckDataEditable: boolean;
    handleSubmit?: (updatedProcessData?: IComplianceProcess, editor?: string) => void;
    errorHandler?: (msg: string) => void;
    requestRefresh?: () => void;
  }
  
  export interface DocGroupState {
    primaryDocuments: InfoTypes.DocumentGroupDefinition;
    secondaryDocuments: InfoTypes.DocumentGroupDefinition;
    ternaryDocuments: InfoTypes.DocumentGroupDefinition;
  }
  
  export interface DocGroupFieldsState {
    primaryDocuments?: IDocumentFields;
    secondaryDocuments?: IDocumentFields;
    ternaryDocuments?: IDocumentFields;
  }
  
  export const InitialDocGroupFieldsState = {
    primaryDocuments: {},
    secondaryDocuments: {},
    ternaryDocuments: {},
  };
  
  export const InitialDocGroupState = {
    primaryDocuments: {
      title: "",
      id: "",
      value: "",
    },
    secondaryDocuments: {
      title: "",
      id: "",
      value: "",
    },
    ternaryDocuments: {
      title: "",
      id: "",
      value: "",
    },
  };
  
  export const InitialPrimaryDocumentDefinition: InfoTypes.DocumentDefinition = {
    type: "",
    documentId: "",
    group: "",
    name: "",
    emittingCountry: "",
    emittingCountryVal: {
      title: "",
      id: "",
      value: "",
    },
    emittingEntity: "",
    identifier: "",
    validFrom: null,
    validTo: null,
    url: "",
  };
  
  export const InitialSecondaryDocumentDefinition: InfoTypes.DocumentDefinition = {
    type: "",
    documentId: "",
    group: "",
    name: "",
    emittingCountry: "",
    emittingCountryVal: {
      title: "",
      id: "",
      value: "",
    },
    emittingEntity: "",
    identifier: "",
    validFrom: null,
    validTo: null,
    url: "",
  };
  
  export const InitialTernaryDocumentDefinition: InfoTypes.DocumentDefinition = {
    type: "",
    documentId: "",
    group: "",
    name: "",
    emittingCountry: "",
    emittingCountryVal: {
      title: "",
      id: "",
      value: "",
    },
    emittingEntity: "",
    identifier: "",
    validFrom: null,
    validTo: null,
    url: "",
  };
  
  export const InitialDocValues = [
    InitialPrimaryDocumentDefinition,
    InitialSecondaryDocumentDefinition,
    InitialTernaryDocumentDefinition,
  ];
  