import * as React from "react";
import { GenericLoader } from "./GenericLoader";
import GenericModal from "./GenericModal";
import { useTranslation } from "react-i18next"

export interface LoaderModalProps {
    isLoading: boolean;
    message?: string;
}

export const LoaderModal: React.FC<LoaderModalProps> = (props) => {
    const { t } = useTranslation();

  return (
    <div className="loading-wrapper">
        <GenericModal
            open={props.isLoading}
            modalHeight="130px"
            modalWidth="350px"
            closeOnBackDrop={false}
            modalTop={"30%"}
            onCloseModal={() => void 0}
            hideModalClose={true}
        >
            <div className="loading-content">
                <GenericLoader />
                <div className="loading-text">
                    {props.message ? t(props.message) : t("common.loading")}{" "}
                </div>
            </div>
        </GenericModal>
    </div>
  )
}