import "../../VerifyIdentity/index.scss";
import { useTranslation } from "react-i18next";
import IImportableDigitalData from "../../../interfaces/IImportableDigitalData";
import InfoTypes from "../../../interfaces/InfoTypes";
import _ from "lodash";

interface DigitalIdentityDataDocumentsProps {
  digitalIdentityData: IImportableDigitalData;
}

export default function DigitalIdentityDataDocuments(props: DigitalIdentityDataDocumentsProps) {
  const { t } = useTranslation();

  return (
    <>
      {_.map(
        props.digitalIdentityData?.documents,
        (documentsData: InfoTypes.ImportableDocumentDetail, index: number) =>
          documentsData && (
            <div
              id={"document-details-id-" + index}
              key={"document-details-" + index}
            >
              <div className="fullWidth data-fields">
                <div
                  className="fullWidth data-field-title"
                  aria-label={t("common.document_details") + " # " + (index + 1)}
                >
                  {t("common.document_details") + " # " + (index + 1)}
                </div>
              </div>
              <div className="fullWidth data-fields">
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("profile_details.fullname")}
                  >
                    {t("profile_details.fullname")}:&nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.fullName!}</div>
                  </div>
                </div>
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("common.birthdate")}
                  >
                    {t("common.birthdate")}:&nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.dateOfBirth!}</div>
                  </div>
                </div>
              </div>
              <div className="fullWidth data-fields">
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.nationality")}
                  >
                    {t("digital_identity.nationality")}: &nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.nationality}</div>
                  </div>
                </div>
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("profile_details.given_names")}
                  >
                    {t("profile_details.given_names")}:&nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.givenNames!}</div>
                  </div>
                </div>
              </div>
              <div className="fullWidth data-fields">
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("common.surname")}
                  >
                    {t("common.surname")}: &nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.familyName!}</div>
                  </div>
                </div>
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.place_of_birth")}
                  >
                    {t("digital_identity.place_of_birth")}:&nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.placeOfBirth!}</div>
                  </div>
                </div>
              </div>
              <div className="fullWidth data-fields">
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("common.gender")}
                  >
                    {t("common.gender")}: &nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.gender!}</div>
                  </div>
                </div>
                <div className="oneHalfWidth"></div>
              </div>
              <div className="fullWidth data-fields">
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.document_type")}
                  >
                    {t("digital_identity.document_type")}: &nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.documentType!}</div>
                  </div>
                </div>
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.document_number")}
                  >
                    {t("digital_identity.document_number")}:&nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.documentNumber!}</div>
                  </div>
                </div>
              </div>
              <div className="fullWidth data-fields">
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.issuing_country")}
                  >
                    {t("digital_identity.issuing_country")}: &nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.issuingCountry!}</div>
                  </div>
                </div>
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.issuing_authority")}
                  >
                    {t("digital_identity.issuing_authority")}:&nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.issuingAuthority!}</div>
                  </div>
                </div>
              </div>
              <div className="fullWidth data-fields">
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.expiration_date")}
                  >
                    {t("digital_identity.expiration_date")}: &nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.expirationDate!}</div>
                  </div>
                </div>
                <div className="oneHalfWidth">
                  <div
                    className="data-show-label"
                    aria-label={t("digital_identity.date_of_issue")}
                  >
                    {t("digital_identity.date_of_issue")}:&nbsp;&nbsp;
                    <div className="data-show-value">{documentsData?.dateOfIssue!}</div>
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
}
