export enum ProcessStatus {
    Created = "Created",
    WaitingForWorker = "WaitingForWorker",
    WaitingForClient = "WaitingForClient",
    WaitingForCounterSigning = "WaitingForCounterSigning",
    SubmittedToThirdParty = "SubmittedToThirdParty",
    ResultPositive = "ResultPositive",
    ResultNegative = "ResultNegative",
    Cancelled = "Cancelled",
    Complete = "Complete"
}

export enum EngagementStatus {
    NotSet = "NotSet",
    Active = "Active",
    Complete = "Complete"
}

export enum ComplianceTypes {
    DBS = "DBS",
    LADO = "LADO",
    CSBL = "Children Barred List",
    OccupationalHealth = "OccupationalHealth",
    EmploymentHistoryAndReferences = "EmploymentHistoryAndReferences",
    QTS = "QTS",
    ProfessionalRegistry = "ProfessionalRegistry",
    OverseasPolice = "OverseasPolice",
    DVLA = "DVLA",
    IdentityAndRWAudit = "IdentityAndRWAudit",
    IdentityCheck = "IdentityCheck",
    AcademicReferences = "AcademicReferences",
    AcademicReferencesSpecial = "AcademicReferencesSpecial",
    Unkown = "Unkown",
    BasicDBS = "BasicDBS"
}

export enum ImportStatus {
    Pending = "Pending",
    Imported = "Imported",
    Rejected = "Rejected",
    RequirementsNotMet = "RequirementsNotMet",
    Invalid = "Invalid"
}

export enum DigitalIDOption {
    DIGITALID = "DIGITALID",
    IDV = "IDV"
}

export enum Severity {
    Error = 0,
    Warning = 1,
    Info = 2
}

export enum ImportableTypeEditor {
    PersonalInfo = "Personal",
    AddressInfo = "Address",
    DocumentsInfo = "DocumentsInfo"
}

export enum CandidatePaymentStatus
{
    Pending = "Pending",
    Paid = "Paid",
    Declined = "Declined",
    Failed = "Failed",
    Requires3DSecure = "Requires3DSecure"
}

export enum PersonalInfoAllowedAge {
    MaxAge = 110,
    MinAge = 16,
}

export enum MinResidentFromAddressHistory {
    MinYears = 5
}

export enum RegexEnum {
    GENERIC = "^(?!.*([!'-])\\1)[a-zA-Z\\d\\s'!-]*[^!'-.;,:@%$_§~`()_+=[\\]\\{}|<>?&\"#€÷£¨¤µ°²]$",
    GENERIC_NON_EMPTY = "^(?:(?!.*(['-])\\1)(?:[A-Za-z]+(?:['-][A-Za-z]+)*\\s?)+)?$",
    PERSON_NAME = "^(?!.*(['-])\\1)(?:[A-Za-z]+(?:['-][A-Za-z]+)*\\s?)+$",
    GENERIC_POSTCODE = "^[\\w\\d\\s]*([\\w\\d]+[-]?[\\w\\d\\s]*)*[\\w\\d\\s]$",
    UK_POSTCODE = "^([A-Z][A-HJ-Y]?\\d[A-Z\\d]? \\d[A-Z]{2}|GIR ?0A{2})$"
}

export enum CountryEnum {
    GBR = 'GBR',
    Other = 'Other',
}