import InfoTypes from "../../interfaces/InfoTypes";
import GenericDataShow from "../GenericDataShow";
import GenericSelect, { DataSetInterface } from "../GenericSelect";

interface Props {
  id: string;
  value: string | number | null | undefined;
  editValue: any;
  label: string;
  showEdit: boolean;
  isCheckEditable: boolean;
  dataSet: DataSetInterface[];
  error?: boolean;
  isNew?: boolean;
  handleChange: (event: React.ChangeEvent<{}>, value: InfoTypes.SelectValueType) => void;
  onEditClicked?: (e: EventTarget) => void;
}

const SelectEditor = (props: Props) => {
  return (
    <>
      {((props.isNew === undefined || props.isNew === null || !props.isNew) &&
        props.value &&
        props.value !== "") ||
      !props.isCheckEditable ? (
        <GenericDataShow
          label={props.label}
          value={props.value}
          showEdit={props.isCheckEditable}
          onEditClicked={props.onEditClicked}
        />
      ) : (
        <div className="p-t-30">
          <GenericSelect
            id={props.id}
            value={props.editValue}
            dataSet={props.dataSet}
            fullWidth={true}
            handleChange={props.handleChange}
            multiple={false}
            freeSolo={false}
            label={props.label}
            renderChip={false}
            error={props.error}
          />
        </div>
      )}
    </>
  );
};

export default SelectEditor;
