import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

import { Container } from "@material-ui/core";
import GenericTextField from "../../Components/GenericTextField";
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import CheckoutForm from "./CheckoutForm";

import InfoTypes from "../../interfaces/InfoTypes";
import utilFunctions from "../../utils/util";

const useStyles = makeStyles({
  contentWithPadding: {
   padding: 30
  },
  formControlLabel: {
    fontWeight: 'normal',
  },
  top20: {
    marginTop: 20
  }
});

type Props = {
  receiptEmail?: string;
  orders: InfoTypes.RoleCheckOrderMinimized;
  clientSecret: string;
  checkId: string;
};

export default function CardDetails(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [clientSecret] = useState(props.clientSecret);
  const [receiptEmail, setReceiptEmail] = useState(props.receiptEmail);
  const emailOrEmptyRegex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+?[a-z0-9-]+)+@([\w-]+\.)+[\w-]{2,4}$/i;
  const isValidEmail = !utilFunctions.testRegex(emailOrEmptyRegex, receiptEmail);

  const handleChangeReceiptEmail = (e: ChangeEvent<HTMLInputElement>, val: string) => {
    setReceiptEmail(val);
  };

  return <Container component="main" maxWidth="xs" className={classes.contentWithPadding}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenericTextField
            value={receiptEmail}
            variant="outlined"
            margin="dense"
            fullWidth
            required
            name="receipt-email"
            label={t("payment.receipt_email_address")}
            type="text"
            onChangeValue={handleChangeReceiptEmail}
            id="receipt-email"
            error={isValidEmail}
            helperText={isValidEmail ? t('validators.invalid_email') : ''}
          />
        </Grid>
        <Grid item xs={12} className={classes.top20}>
          <CheckoutForm clientSecret={clientSecret} 
            receiptEmail={receiptEmail!} 
            isValidEmail={isValidEmail} 
            checkId={props.checkId}
          />    
        </Grid>
    </Grid>
  </Container>
}
