import "./index.scss";
import { useTranslation } from "react-i18next"
import { isMobile } from "react-device-detect";
import IEngagementRole from "../../interfaces/IEngagementRole";

interface RoleWrapperProps {
    info: IEngagementRole;
}

export default function RoleWrapper(props: RoleWrapperProps) {
    const { t } = useTranslation();
    const info = props.info;
    return (
        <div className="info-wrapper">
            <div
                className="role-description"
                style={{ paddingRight: isMobile ? "40px" : "110px" }}
            >
                <p className="description">{t("dashboard.role")}</p>
                <p className="info"> {info?.role}</p>
            </div>
            <div className="role-description">
                <p className="description">{t("dashboard.location")}</p>
                <p className="info"> {info?.location}</p>
            </div>
        </div>
    );
}
