import React, { useEffect, useState } from "react";
import "./index.scss";
import moment from "moment";
import Constants from "../../Constants/constants";
import InfoTypes from "../../interfaces/InfoTypes";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "../../Components/LoaderModal";
import utilFunctions from "../../utils/util";
import { ImportableTypeEditor, PersonalInfoAllowedAge, RegexEnum } from "../../Constants/enums";
import { useRTWContext } from "../../Contexts/rtw-context";
import EditInfoModal from "../PersonalInfo/EditInfoModal";
import { PersonalContextProvider, usePersonalContext } from "../../Contexts/personal-context";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";

interface PersonalInfoProps {
  isProfileView?: boolean;
}

interface PersonalInfoState {
  forename: string;
  surname: string;
  dateOfBirth: Date | null;
  currentNationality: string;
  currentNationalityVal?: InfoTypes.SelectValueType;
}

interface PersonalInfoErrors {
  forename: string;
  surname: string;
  dateOfBirth: string;
}

const initialErrors = {
  forename: "",
  surname: "",
  dateOfBirth: "",
};

const personNameRegex: RegExp = new RegExp(RegexEnum.PERSON_NAME);

export function RTWPersonalInfo(props: PersonalInfoProps, state: PersonalInfoState) {
  const { t } = useTranslation();
  const [isEditClicked, setIsEditClicked] = useState(false);

  const [forenameError, setForenameError] = useState(false);
  const [forenameHelperText, setForenameHelperText] = useState<string>("");

  const [surnameError, setSurnameError] = useState(false);
  const [surnameHelperText, setSurnameHelperText] = useState<string>("");

  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [dateOfBirthHelperText, setDateOfBirthHelperText] = useState<string>("");

  const [errors] = useState<PersonalInfoErrors>(initialErrors);

  const { complianceProcessData, isCheckEditable, loading } = useRTWContext();

  const {
    personalValues,
    setPersonalValues,
    personalInfoData,
    SendPutPersonalData,
    personalRequest,
    handleEditSaveCallback,
    setIsPersonalInfoCompleted
  } = usePersonalContext();

  const handleChange = (prop: keyof PersonalInfoState, key?: string) => (event: any, val: string) => {
    setPersonalValues({
      ...personalValues,
      [event]: val,
    });
  };

  const handleDateChange = (prop: keyof PersonalInfoState) => (val: Date | null) => {
    setPersonalValues({ ...personalValues, [prop]: moment(val!).format(Constants.DATE_FORMAT_ISO) });
  };

  const [selectedFieldToEdit, setSelectedFieldToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
  });

  const handleEditFieldChange = (id: string, title: string, val: any, mNameIdx: number) => {
    setSelectedFieldToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: mNameIdx,
    });
  };

  const onEditModalClose = () => {
    setIsEditClicked(false);
    setSelectedFieldToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
    });
  };

  /****** Start - PUT/Save Personal Data Handlers ******/

  useEffect(() => {
    async function SendPutPersonalDataRequest() {
      if (personalRequest) {
        await SendPutPersonalData(personalRequest);
      }
    }
    SendPutPersonalDataRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalRequest]);

  /****** End - PUT/Save Personal Data Handlers ******/

  /****** Start - Personal Data Validations ******/

  useEffect(() => {

    const dateOfBirth = personalValues.dateOfBirth ? personalValues.dateOfBirth.toString() : 'Invalid date';
    const personalAge = moment(personalValues!.dateOfBirth).format('YYYY, DD, MM');
    const todayIsDate = moment().format('YYYY, DD, MM');

    const isAgeInTheZone = moment(todayIsDate,'YYYY, DD, MM').diff(moment(personalAge, 'YYYY, DD, MM'), 'years');

    if (!personalValues.forename || personalValues.forename === '') {
      setForenameError(true);
      setForenameHelperText(t("validators.required"));
    }
    else {
      if (!utilFunctions.testRegex(personNameRegex, personalValues.forename)) {
        setForenameError(true);
        setForenameHelperText(t("validators.special_chars"));
      }
      else {
        setForenameError(false);
        setForenameHelperText("");
      }
    }

    if (!personalValues.surname || personalValues.surname === '') {
      setSurnameError(true);
      setSurnameHelperText(t("validators.required"));
    }
    else {
      if (!utilFunctions.testRegex(personNameRegex, personalValues.surname)) {
        setSurnameError(true);
        setSurnameHelperText(t("validators.special_chars"));
      }
      else {
        setSurnameError(false);
        setSurnameHelperText("");
      }
    }

    if(personalValues!.dateOfBirth === null || dateOfBirth === 'Invalid date'){
      setDateOfBirthError(true);
      setDateOfBirthHelperText(t("errors.birthdate_msg"));
    } else if (isAgeInTheZone < PersonalInfoAllowedAge.MinAge || isAgeInTheZone >= PersonalInfoAllowedAge.MaxAge) {
      setDateOfBirthError(true);
      setDateOfBirthHelperText(t("errors.birthdate_allowed_interval_msg"));
    } else {
      setDateOfBirthError(false);
      setDateOfBirthHelperText(t(""));
    }

    if (personalValues!.forename !== '' && 
        personalValues!.surname  !== '' && 
        utilFunctions.testRegex(personNameRegex, personalValues!.forename) &&
        utilFunctions.testRegex(personNameRegex, personalValues!.surname) && (
        personalValues!.dateOfBirth !== null || dateOfBirth !== 'Invalid date') && (
        isAgeInTheZone >= PersonalInfoAllowedAge.MinAge && isAgeInTheZone <= PersonalInfoAllowedAge.MaxAge) 
    ) setIsPersonalInfoCompleted(true);
    else setIsPersonalInfoCompleted(false);

  }, [personalValues, setIsPersonalInfoCompleted, t]);

  /****** End - Personal Data Validations ******/

  return (
    <PersonalContextProvider>
      <div id="personal-info" className="personal-info editor-component">
        <div className="personal-info-body">
          <div className="personal-info-body-container">
            <div className="personal-info-body-container-details">
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName="forename"
                    value={personalInfoData!.forename}
                    editValue={personalValues.forename}
                    label={t("common.forename")}
                    type="text"
                    showEdit={
                      isCheckEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "forename",
                        ImportableTypeEditor.PersonalInfo,
                        complianceProcessData!
                      )
                    }
                    isCheckEditable={isCheckEditable}
                    onChangeValue={handleChange("forename")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange("forename", t("common.forename"), personalInfoData!.forename, 0);
                    }}
                    error={forenameError}
                    helperText={forenameHelperText}
                  />
                </div>
                <div className="oneHalfWidth">
                  <TextEditor
                    fieldName={"surname"}
                    value={personalInfoData!.surname}
                    editValue={personalValues.surname}
                    label={t("common.surname")}
                    type="text"
                    showEdit={
                      isCheckEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "surname",
                        ImportableTypeEditor.PersonalInfo,
                        complianceProcessData!
                      )
                    }
                    isCheckEditable={isCheckEditable}
                    onChangeValue={handleChange("surname")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange("surname", t("common.surname"), personalInfoData!.surname, 0);
                    }}
                    error={surnameError}
                    helperText={surnameHelperText}
                  />
                </div>
              </div>
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth"></div>
                <div className="oneHalfWidth">
                  <DateEditor
                    value={personalInfoData!.dateOfBirth}
                    editValue={personalValues.dateOfBirth}
                    label={t("common.birthdate")}
                    showEdit={
                      isCheckEditable &&
                      utilFunctions.CheckIDVImportedFieldIsEditable(
                        "dateOfBirth",
                        ImportableTypeEditor.PersonalInfo,
                        complianceProcessData!
                      )
                    }
                    isCheckEditable={isCheckEditable}
                    onChangeValue={handleDateChange("dateOfBirth")}
                    onEditClicked={(e: EventTarget) => {
                      setIsEditClicked(true);
                      handleEditFieldChange("dateOfBirth", t("common.birthdate"), personalInfoData!.dateOfBirth, 0);
                    }}
                    error={dateOfBirthError}
                    helperText={dateOfBirthHelperText}
                  />
                </div>
              </div>
            </div>
          </div>
          <LoaderModal isLoading={loading} />
        </div>
      </div>
      {isEditClicked ? (
        <EditInfoModal
          isEditIconClicked={isEditClicked}
          personalValueState={personalValues}
          selectedFieldToEdit={selectedFieldToEdit}
          onCloseEditModal={onEditModalClose}
          onInfoSaveCallback={handleEditSaveCallback}
        />
      ) : (
        ""
      )}
    </PersonalContextProvider>
  );
}
