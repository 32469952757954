
import { ChangeEvent, useEffect, useState } from "react";
import "./index.scss";
import GenericSelect from "../../Components/GenericSelect";
import GenericButton from "../../Components/GenericButton";
import GenericModal from "../../Components/GenericModal";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import { GenericLoader } from "../../Components/GenericLoader";
import InfoTypes from "../../interfaces/InfoTypes";
import useAPIRequest from "../../Service/useAPIRequest";
import { GetNationalitiesData } from "../../utils/referenceDataService";
import { useTranslation } from "react-i18next"
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IProfile from "../../interfaces/IProfile";
import IPersonalDetails from "../../interfaces/IPersonalDetails";
import GenericModalResponsive from "../../Components/GenericModalResponsive";
import { isMobileOnly } from "react-device-detect";

interface PromptNationalityProps {
    processData: IComplianceProcess;
    confirmNationality: boolean;
    onInfoSaveCallback: (val: string) => void;
}

interface PromptNationalityState{
    currentNationality: string;
}

export default function PromptNationality (props: PromptNationalityProps) {
    const { t } = useTranslation();
    const [isEditClicked, setIsEditClicked] = useState(true);
    const [currNationalityValue, setCurrNationalityValue] = useState("");
    const [currNationalityVal, setCurrNationalityVal] = useState<InfoTypes.SelectValueType>();
    const [loading, setLoading] = useState(false);
    const [putRequested, setPutRequest] = useState<IComplianceProcess>();
    
    const SendPatchNationality = async (request: IComplianceProcess) => {
        useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + props.processData.id, {
            method: "PUT",
            body: request
            }).then((res) => {
            if (res.ok) {
                props.onInfoSaveCallback(currNationalityValue);
                onEditInfoModalClose();
            } else {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        async function SendPatchNationalityRequest() {
            if(putRequested) {
                await SendPatchNationality(putRequested);
            }
        }
        SendPatchNationalityRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [putRequested]);

    useEffect(() => {
        if (currNationalityValue === "") {
            setIsEditClicked(props.confirmNationality);
        }
    }, [props.confirmNationality, currNationalityValue])

    let nationalitiesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA);
    if (!nationalitiesData) {
        nationalitiesData = GetNationalitiesData();
    }

    const onEditInfoModalClose = (e?: EventTarget) => {
        if (currNationalityValue !== "") {
            setIsEditClicked(false);
        } else {
            props.onInfoSaveCallback("");
            setIsEditClicked(false);
        }
    };

    const handleSelectChange = (prop: keyof PromptNationalityState, key?: string) => (e: ChangeEvent<{}>, val: InfoTypes.SelectValueType) => {
        setCurrNationalityVal(val);
        setCurrNationalityValue(val.id);
    };

    const saveCurrentNationality = () => {
        setLoading(true);
        
        let putRequest = props.processData;
        let personalData: IPersonalDetails;
        let profileDtls: IProfile = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA);
        if (profileDtls && profileDtls.personal) {
            personalData = profileDtls.personal!;
            personalData!.currentNationality = currNationalityValue
        } else {
            personalData = {
                currentNationality: currNationalityValue
            }
        }
        putRequest.processData = {
            "personal": personalData!
        }
        setPutRequest(putRequest);
    }

    return (
        <GenericModalResponsive
            heading={t("verify_identity.confirm_nationality_title")}
            onCloseModal={(e?: EventTarget) => onEditInfoModalClose(e)}
            open={isEditClicked}
            closeOnBackDrop={false}
            modalWidth={"auto"}
            modalHeight={"auto"}
            modalPadding={isMobileOnly ? 20 : 30}
            modalMargin={isMobileOnly ? 10 : 50}
            modalTop={"15%"}
            className="personal-info-nationality-edit-modal"
            hideModalClose={true}
            >
            <div className="personal-info-nationality-edit-modal">
                {loading ? (
                <div style={{ marginTop: "50px", textAlign: "center" }}>
                    <GenericLoader />
                    <div className="loading-text">{t("common.saving")}</div>
                </div>
                ) : (
                    <>
                        <div className="fullWidth">
                            <GenericSelect
                                id="confirmCurrentNationality"
                                value={currNationalityVal}
                                dataSet={nationalitiesData}
                                fullWidth={true}
                                handleChange={handleSelectChange("currentNationality")}
                                multiple={false}
                                freeSolo={false}
                                label={t("common.current_nationality")}
                                renderChip={false}
                                required={true}
                            />
                        </div>
                        <div className="fullWidth"></div>
                        <div className="fullWidth" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <GenericButton
                                onClick={() => {
                                    saveCurrentNationality();
                                }}
                                disabled={(currNationalityValue === "")}
                                style={{width: "160px"}}
                                type="primary"
                                buttonText={t("common.confirm")}
                            />
                        </div>   
                    </>
                )}
            </div>
        </GenericModalResponsive>

    )
}