import React, { ChangeEvent, useState } from "react";
import InfoTypes from "../../interfaces/InfoTypes";
import "./index.scss";
import { useTranslation } from "react-i18next";
import EditInfoModal from "./EditInfoModal";
import IPersonalDetails from "../../interfaces/IPersonalDetails";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import utilFunctions from "../../utils/util";
import { RegexEnum } from "../../Constants/enums";

interface MiddleNameProps {
  mNameData: InfoTypes.MiddleName;
  mNameIdx: number;
  updatedData: (data: InfoTypes.MiddleName, index: number) => void;
  personalValues: IPersonalDetails;
  isCheckDataEditable: boolean;
}

interface MiddleNameState {
  mappedMNameData: InfoTypes.MiddleName;
}

interface MiddleNameStateData {
  mName: string;
  isNew?: boolean;
  [N: string]: any;
}

const personNameRegex: RegExp = new RegExp(RegexEnum.PERSON_NAME);

export default function MiddleName(props: MiddleNameProps, state: MiddleNameState) {
  const { t } = useTranslation();
  const [mNameValue, setMNameValue] = useState<MiddleNameState>({
    mappedMNameData: props.mNameData,
  });
  const [mNamesEditClicked, setMNamesEditClicked] = useState(false);
  const [, setSelectedMNameValue] = useState({
    mName: "",
  });

  const [selectedMNameToEdit, setSelectedMNameToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
  });

  const handleMiddleNameChange =
    (prop: keyof MiddleNameStateData, index: number) =>
    (e: ChangeEvent<HTMLInputElement>, val: string) => {
      var mName = mNameValue;
      mName.mappedMNameData.mName = val;
      mName.mappedMNameData.isNew = true;

      setMNameValue(mName);
      props.updatedData(mName.mappedMNameData, index);
    };

  const onEditMiddleNameModalClose = () => {
    setMNamesEditClicked(false);
    setSelectedMNameValue({
      mName: "",
    });
    setSelectedMNameToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
    });
  };

  const handleEditMiddleNameFieldChange = (
    mName: InfoTypes.MiddleName,
    id: string,
    title: string,
    val: any,
    index: number
  ) => {
    setSelectedMNameToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: index,
    });
    setSelectedMNameValue({
      mName: mName.mName!,
    });
  };

  const handleEditMiddleNameSaveCallback = (
    prop: keyof MiddleNameStateData,
    val: any,
    index: number
  ) => {
    var mappedVal = {
      mName: val,
      isNew: false,
    };
    setMNameValue({
      ...state,
      mappedMNameData: mappedVal,
    });
    props.updatedData(mappedVal, index);
  };

  return (
    <>
      <TextEditor
        fieldName="middle_name"
        value={props.mNameData.mName}
        editValue={mNameValue.mappedMNameData!.mName}
        label={t("common.middle_name")}
        type="text"
        showEdit={props.isCheckDataEditable}
        isCheckEditable={props.isCheckDataEditable}
        onChangeValue={handleMiddleNameChange("mName", props.mNameIdx)}
        isNew={props.mNameData.isNew}
        editStyle={{ width: "95%" }}
        onEditClicked={(e: EventTarget) => {
          setMNamesEditClicked(true);
          handleEditMiddleNameFieldChange(
            props.mNameData,
            "middleNames",
            t("common.middle_name"),
            props.mNameData.mName,
            props.mNameIdx
          );
        }}
        error={
          mNameValue.mappedMNameData!.mName &&
          mNameValue.mappedMNameData!.mName !== ""
            ? !utilFunctions.testRegex(
                personNameRegex,
                mNameValue.mappedMNameData!.mName
              )
            : false
        }
        helperText={
          mNameValue.mappedMNameData!.mName &&
          mNameValue.mappedMNameData!.mName !== ""
            ? utilFunctions.testRegex(
                personNameRegex,
                mNameValue.mappedMNameData!.mName
              )
              ? ""
              : t("validators.special_chars")
            : ""
        }
      />
      {mNamesEditClicked ? (
        <EditInfoModal
          isEditIconClicked={mNamesEditClicked}
          personalValueState={props.personalValues}
          selectedFieldToEdit={selectedMNameToEdit}
          onCloseEditModal={onEditMiddleNameModalClose}
          onInfoSaveCallback={handleEditMiddleNameSaveCallback}
        />
      ) : (
        ""
      )}
    </>
  );
}
