
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import "./index.scss";
import GenericTextField from "../../Components/GenericTextField";
import GenericDatePicker  from "../../Components/GenericDatePicker";
import GenericButton from "../../Components/GenericButton";
import moment from "moment";
import GenericDataShow from "../../Components/GenericDataShow";
import GenericModal from "../../Components/GenericModal";
import { GenericLoader } from "../../Components/GenericLoader";
import IProfilePatchRequest from "../../interfaces/IProfilePatchRequest";
import Constants from "../../Constants/constants";
import useAPIRequest from "../../Service/useAPIRequest";
import { useTranslation } from "react-i18next"
import utilFunctions from "../../utils/util";
import useCompareDates from "../../utils/useCompareDates";
import { RegexEnum } from "../../Constants/enums";

interface EditInfoModalProps {
    selectedPrevNameToEdit: {
        selectedFieldTitle: string;
        selectedFieldId: any;
        selectedFieldValue: any;
        selectedFieldIndex: number;
    };
    prevNameState: {};
    isEditIconPrevNameClicked: boolean;
    onCloseEditPrevNameModal: () => void;
    onPrevNameSaveCallback: (propName: keyof EditPrevNameState, val: {} | string, index: number) => void;
}

interface EditPrevNameState{
    forename: string;
    surname: string;
    from: Date | null;
    to: Date | null;
}

const InitialPatchRequest: IProfilePatchRequest = {
    path: "",
    newValue: "",
    reason: ""
}

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);
const personNameRegex: RegExp = new RegExp(RegexEnum.PERSON_NAME);
    
export default function EditPrevNameModal (props: EditInfoModalProps) {
    const { t } = useTranslation();
    const oneRef = React.createRef();
    const [isEditPrevNameClicked, setIsEditPrevNameClicked] = useState(true);
    const [editPrevNameFieldNewValue, setEditPrevNameFieldNewValue] = useState("");
    const [editPrevNameDateNewValue, setEditPrevNameDateNewValue] = useState<any>(null);
    const [editPrevNameFieldReason, setEditPrevNameFieldReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [patchRequested, setPatchRequest] = useState(InitialPatchRequest);
    const [editPrevNameFieldError, setEditPrevNameFieldError] = useState(false);
    const [editPrevNameFieldHelperText, setEditPrevNameFieldHelperText] = useState<string>("");
    const [editPrevNameReasonError, setEditPrevNameReasonError] = useState(false);
    const [editPrevNameReasonHelperText, setEditPrevNameReasonHelperText] = useState<string>("");
    const [editPrevNameDateError, setEditPrevNameDateError] = useState(false);
    const [editPrevNameDateHelperText, setEditPrevNameDateHelperText] = useState<string>("");
    const { compareDates } = useCompareDates();

    const SendPatch = async (patchRequest: IProfilePatchRequest) => {
        useAPIRequest(Constants.API_URLS.PATCH_WORKER_PROFILE, {
            method: "PATCH",
            body: patchRequest
        })
        props.onPrevNameSaveCallback(props.selectedPrevNameToEdit.selectedFieldId, 
            ((props.selectedPrevNameToEdit.selectedFieldId === "from" || props.selectedPrevNameToEdit.selectedFieldId === "to") ? editPrevNameDateNewValue : patchRequest.newValue), 
            props.selectedPrevNameToEdit.selectedFieldIndex);
        onEditPrevNameModalClose();
    }

    useEffect(() => {
        async function SendPatchRequest() {
            if(patchRequested.path !== "") {
                await SendPatch(patchRequested);
            }
        }
        SendPatchRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patchRequested]);

    useEffect(() => {
        setIsEditPrevNameClicked(props.isEditIconPrevNameClicked);

    }, [props.isEditIconPrevNameClicked])

    const onEditPrevNameModalClose = (e?: EventTarget) => {
        setIsEditPrevNameClicked(false);
        setEditPrevNameFieldReason("");
        setEditPrevNameFieldNewValue("");
        props.onCloseEditPrevNameModal();
    };

    const handleEditPrevNameReasonChange = (e: ChangeEvent<HTMLInputElement>, val: string)  => {
        setEditPrevNameFieldReason(val);
    }

    const handleEditPrevNameChange = (prop: keyof EditPrevNameState, key?: string) => (event: ChangeEvent<{}>, val: string) => {
        setEditPrevNameFieldNewValue(val);
    };

    const handlePrevNameDateChange = (prop: keyof EditPrevNameState) => (val: Date | null) => {
        setEditPrevNameDateNewValue(val!);
    };

   // Edit Previous Name value validations
   useEffect(() => {
    const todayIsDate = moment();

    if (editPrevNameFieldNewValue === '') {
        setEditPrevNameFieldError(true);
        setEditPrevNameFieldHelperText(t("validators.required"));
    } else {
        if (!utilFunctions.testRegex(personNameRegex, editPrevNameFieldNewValue)) {
          setEditPrevNameFieldError(true);
          setEditPrevNameFieldHelperText(t("validators.special_chars"));
        } else {
          setEditPrevNameFieldError(false);
          setEditPrevNameFieldHelperText("");
        }
    }

    if (editPrevNameFieldReason === '') {
        setEditPrevNameReasonError(true);
        setEditPrevNameReasonHelperText(t("validators.required"));
    } else {
        if (!utilFunctions.testRegex(genericRegex, editPrevNameFieldReason)) {
          setEditPrevNameReasonError(true);
          setEditPrevNameReasonHelperText(t("validators.special_chars"));
        } else {
          setEditPrevNameReasonError(false);
          setEditPrevNameReasonHelperText("");
        }
    }

    if(editPrevNameDateNewValue === null || editPrevNameDateNewValue === 'Invalid date'){
        setEditPrevNameDateError(true);
        setEditPrevNameDateHelperText(t("errors.is_valid_date_or_empty_msg"));
    } else if (moment(editPrevNameDateNewValue) > todayIsDate) {
        setEditPrevNameDateError(true);
        setEditPrevNameDateHelperText(t("errors.current_date_greater_msg"));
    } else {
        const prevName = props.prevNameState;
        const dateType = props.selectedPrevNameToEdit.selectedFieldId;
        const {isValid, errorMessage} = compareDates(editPrevNameDateNewValue, prevName, dateType);

        setEditPrevNameDateError(isValid);
        setEditPrevNameDateHelperText(errorMessage);
    }

   }, [compareDates, 
       editPrevNameDateNewValue, 
       editPrevNameFieldNewValue, 
       editPrevNameFieldReason, 
       props.prevNameState, 
       props.selectedPrevNameToEdit.selectedFieldId, 
       t
    ]);



    const renderInputComponent = () => {
        switch(props.selectedPrevNameToEdit.selectedFieldId!) {
            case "from":
            case "to":
                return <GenericDatePicker
                    variant="inline"
                    label={t("common.birthdate_formatted")}
                    id="date-picker-inline"
                    inputVariant="outlined"
                    required
                    value={editPrevNameDateNewValue}
                    onChangeValue={handlePrevNameDateChange(props.selectedPrevNameToEdit.selectedFieldId)}
                    format={Constants.DATE_FORMAT_CALENDAR}
                    enableFuture={false}
                    fullWidth={true}
                    error={editPrevNameDateError}
                    helperText={editPrevNameDateHelperText}
                ></GenericDatePicker>
            case "forename":
            case "surname":
                return <GenericTextField
                    label={props.selectedPrevNameToEdit.selectedFieldTitle}
                    name={props.selectedPrevNameToEdit.selectedFieldId}
                    type="text"
                    id={props.selectedPrevNameToEdit.selectedFieldId}
                    variant="outlined"
                    margin="dense"
                    fullWidth={true}
                    required
                    value={editPrevNameFieldNewValue}
                    inputRef={oneRef}
                    onChangeValue={handleEditPrevNameChange(props.selectedPrevNameToEdit.selectedFieldId)}
                    error={editPrevNameFieldError}
                    helperText={editPrevNameFieldHelperText}
                />
            default:
                return <></>
        }
    }

    const acceptEditPrevNameChange = () => {
        setLoading(true);
        let path, newVal: any = "";
        path = "personal.previousNames[" + props.selectedPrevNameToEdit.selectedFieldIndex + "]." + props.selectedPrevNameToEdit.selectedFieldId;
                
        switch(props.selectedPrevNameToEdit.selectedFieldId) {
            case "to":
            case "from":
                newVal = moment(editPrevNameDateNewValue).format(Constants.DATE_FORMAT_DISPLAY);
                break;
            case "forename":
            case "surname":
                newVal = editPrevNameFieldNewValue;
                break;
            default:
                path = "";
                newVal = "";
                break;
        }
        let patchRequest: IProfilePatchRequest = {
            path: path,
            newValue: newVal,
            reason: editPrevNameFieldReason
        }
        setPatchRequest(patchRequest);
    }


    return (
        <>
            <GenericModal
                heading={t("prev_name_modal.title")}
                onCloseModal={(e?: EventTarget) => onEditPrevNameModalClose(e)}
                open={props.isEditIconPrevNameClicked && isEditPrevNameClicked}
                closeOnBackDrop={true}
                modalWidth={"500px"}
                modalHeight={"400px"}
                modalTop={"15%"}
                className="personal-info-edit-modal"
            >
            <div className="personal-info-edit-modal">
                {loading ? (
                <div style={{ marginTop: "50px", textAlign: "center" }}>
                    <GenericLoader />
                    <div className="loading-text">{t("common.saving")}</div>
                </div>
                ) : (
                <>
                <div className="fullWidth">
                    <GenericDataShow
                        label={props.selectedPrevNameToEdit.selectedFieldTitle}
                        value={(props.selectedPrevNameToEdit.selectedFieldId === "from" || props.selectedPrevNameToEdit.selectedFieldId === "to") ? moment(props.selectedPrevNameToEdit.selectedFieldValue).format(Constants.DATE_FORMAT_DISPLAY) : props.selectedPrevNameToEdit.selectedFieldValue}
                        isSelectField={false}
                    />
                </div>
                <div className="fullWidth">
                    {renderInputComponent()}
                </div>
                <div className="fullWidth">
                    <GenericTextField
                    label={t("common.reason_for_change")}
                    name={"reasonForChange"}
                    type="text"
                    id={"reasonForChange"}
                    variant="outlined"
                    margin="dense"
                    fullWidth={true}
                    required
                    value={editPrevNameFieldReason}
                    inputRef={oneRef}
                    onChangeValue={handleEditPrevNameReasonChange}
                    error={editPrevNameReasonError}
                    helperText={editPrevNameReasonHelperText}
                    />
                </div>
                <div className="fullWidth" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <GenericButton
                    onClick={() => {
                        acceptEditPrevNameChange();
                    }}
                    disabled={(props.selectedPrevNameToEdit.selectedFieldId === "from" || props.selectedPrevNameToEdit.selectedFieldId === "to") ? 
                        (editPrevNameDateError || editPrevNameReasonError) :
                        (editPrevNameFieldError || editPrevNameReasonError)}
                    type="primary"
                    buttonText={t("common.save")}
                    ></GenericButton>
                </div>
                </>
                )}
            </div>
            </GenericModal>
        </>
    )
}