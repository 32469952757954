import _ from "lodash";
import moment from "moment";
import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import Constants from "../Constants/constants";
import { ComplianceTypes } from "../Constants/enums";
import IComplianceProcess from "../interfaces/IComplianceProcess";
import IGovermentDocument from "../interfaces/IGovermentDocument";
import InfoTypes from "../interfaces/InfoTypes";
import IPersonalDetails from "../interfaces/IPersonalDetails";
import IProfile from "../interfaces/IProfile";
import useAPIRequest from "../Service/useAPIRequest";
import LocalStorageService from "../utils/localStorageService";
import utilFunctions from "../utils/util";
import { useRTWContext } from "./rtw-context";

export type PersonalContextType = {
  personalValues: IPersonalDetails;
  setPersonalValues: Dispatch<SetStateAction<IPersonalDetails>>;
  personalInfoData: IPersonalDetails;
  setPersonalInfoData: Dispatch<SetStateAction<IPersonalDetails>>;
  handleEditSaveCallback: (prop: any, val: any, index: number) => void;
  SendPutPersonalData: (prsnlRequest: IComplianceProcess) => Promise<void>;
  getPersonalDetails: () => IPersonalDetails;
  acceptPersonalDetailsAndGovermentCode: () => void;
  personalRequest: IComplianceProcess | undefined;
  setPersonalRequest: Dispatch<SetStateAction<IComplianceProcess | undefined>>;
  acceptPersonalDetails: () => void;
  isPersonalInfoChanged: boolean;
  setIsPersonalInfoChanged: Dispatch<SetStateAction<boolean>>;
  isPersonalInfoCompleted: boolean;
  setIsPersonalInfoCompleted: Dispatch<SetStateAction<boolean>>;
};

type PersonalContextProviderProps = {
  children?: React.ReactNode;
};

const InitialPersonalInfoState: InfoTypes.PersonalDetailsSmall = {
  forename: "",
  surname: "",
  dateOfBirth: null,
  currentNationality: "",
  currentNationalityVal: {
    title: "",
    id: "",
    value: "",
  }
};

export const PersonalContext = createContext<PersonalContextType | undefined>(undefined);

const PersonalContextProvider = ({ children }: PersonalContextProviderProps) => {
  const { t } = useTranslation();

  const [personalValues, setPersonalValues] = useState<IPersonalDetails>(InitialPersonalInfoState);
  const [personalInfoData, setPersonalInfoData] = useState<IPersonalDetails>(InitialPersonalInfoState);
  const [personalRequest, setPersonalRequest] = useState<IComplianceProcess>();

  const [isPersonalInfoChanged, setIsPersonalInfoChanged] = useState<boolean>(false);

  const [isPersonalInfoCompleted, setIsPersonalInfoCompleted] = useState<boolean>(true);

  const {
    complianceProcessData,
    setLoading,
    handleNextStep,
    handleSaveError,
    handleGenericError,
    govCode,
} = useRTWContext();

  const SendPutPersonalData = async (prsnlRequest: IComplianceProcess) => {
    setLoading(true);
    useAPIRequest(
      Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + complianceProcessData!.id,
      {
        method: "PUT",
        body: prsnlRequest,
      }
    )
      .then((res) => {
        if (res.ok) {
          if (handleNextStep) {
            handleNextStep(
              prsnlRequest,
              Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON
            );
          }
        } else {
          handleSaveError(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (handleGenericError) {
          handleGenericError(t("errors.save_error_msg"));
        }
      });
    setPersonalRequest(undefined);
  };

  const handleEditSaveCallback = (prop: any, val: any, index: number) => {
    let propwithChange: any = personalValues!;
    if (prop === "title") {
      propwithChange.title = val.id;
      propwithChange.titleVal = val;
    } else if (prop === "dateOfBirth") {
      propwithChange.dateOfBirth = moment(val).format(
        Constants.DATE_FORMAT_ISO
      );
    } else {
      propwithChange![prop] = val;
    }
    setPersonalValues(propwithChange);
    setPersonalInfoData(propwithChange);
    setIsPersonalInfoChanged(true);
  };

  const acceptPersonalDetailsAndGovermentCode = () => {
    let personalDtls = personalValues;
    
    if (complianceProcessData!.complianceType === ComplianceTypes.IdentityAndRWAudit) {
      personalDtls = {
        forename: personalValues.forename,
        surname: personalValues.surname,
        dateOfBirth: personalValues.dateOfBirth,
        currentNationality: personalValues.currentNationality
      }
    } else {
      personalDtls.previousNames = personalValues.previousNames;
      personalDtls.previousNationalities = personalValues.previousNationalities; 
    }

    const govermentCodeObject: IGovermentDocument = {
      type: "SettledStatusShareCode",
      documentId: "SettledStatusShareCode",
      group: "NonUkOther",
      identifier: govCode,
      validTo: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
    }

    let prepPersonalData: any = {
      id: complianceProcessData!.id,
      complianceType: complianceProcessData!.complianceType,
      processData: {
        personal: personalDtls!,
        documents: [govermentCodeObject]
      }
    }
    setPersonalRequest(prepPersonalData);
  }

  const getPersonalDetails = () => {
    let personalDtls = personalValues;
    if (complianceProcessData!.complianceType === ComplianceTypes.IdentityAndRWAudit) {
      personalDtls = {
        forename: personalValues.forename,
        surname: personalValues.surname,
        dateOfBirth: personalValues.dateOfBirth,
        currentNationality: personalValues.currentNationality
      }
    } else {
      personalDtls.previousNames = personalValues.previousNames;
      personalDtls.previousNationalities = personalValues.previousNationalities;
    }
    return personalDtls
  }

  const acceptPersonalDetails = () => {
    let personalDtls = personalValues;
    personalDtls.previousNames = personalValues.previousNames;
    personalDtls.previousNationalities = personalValues.previousNationalities;

    const personalData: IPersonalDetails = utilFunctions.cleanseJson(personalDtls);
    let prepPersonalData: IComplianceProcess = {
      id: complianceProcessData!.id,
      complianceType: complianceProcessData!.complianceType,
      processData: {
        personal: personalDtls!
      },
    }
    setPersonalRequest(prepPersonalData);
  }

  useEffect(() => {
    setLoading(true);
    let personalData: IPersonalDetails;
    if (complianceProcessData) {
      if (
        complianceProcessData.processData &&
        complianceProcessData.processData.personal
      ) {
        personalData = complianceProcessData.processData.personal;
      }
    } else {
      let profileDtls: IProfile = LocalStorageService.getData(
        Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
      );
      if (profileDtls && profileDtls.personal) {
        personalData = profileDtls.personal!;
      }
    }

    if (personalData!) {
      if (!personalData.dateOfBirth) {
        personalData.dateOfBirth = null;
      }
      setPersonalValues(personalData);
      setPersonalInfoData(personalData);
    } else {
      setPersonalInfoData(InitialPersonalInfoState);
    }
    setLoading(false);
  }, [complianceProcessData]);

  return (
    <PersonalContext.Provider
      value={{
        personalValues,
        setPersonalValues,
        personalInfoData,
        setPersonalInfoData,
        handleEditSaveCallback,
        SendPutPersonalData,
        getPersonalDetails,
        acceptPersonalDetailsAndGovermentCode,
        personalRequest,
        setPersonalRequest,
        acceptPersonalDetails,
        isPersonalInfoChanged,
        setIsPersonalInfoChanged,
        isPersonalInfoCompleted,
        setIsPersonalInfoCompleted,
      }}
    >
      {children}
    </PersonalContext.Provider>
  );
};

const usePersonalContext = () => {
  const context = useContext(PersonalContext);
  if (context === undefined) {
    throw new Error("Error");
  }
  return context;
};

export { PersonalContextProvider, usePersonalContext };
