
import React, { ChangeEvent, useEffect, useState } from "react";
import "./index.scss";
import GenericTextField from "../../Components/GenericTextField";
import GenericSelect from "../../Components/GenericSelect";
import GenericDatePicker  from "../../Components/GenericDatePicker";
import GenericButton from "../../Components/GenericButton";
import moment from "moment";
import GenericDataShow from "../../Components/GenericDataShow";
import GenericModal from "../../Components/GenericModal";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import { GenericLoader } from "../../Components/GenericLoader";
import IProfilePatchRequest from "../../interfaces/IProfilePatchRequest";
import InfoTypes from "../../interfaces/InfoTypes";
import useAPIRequest from "../../Service/useAPIRequest";
import { useTranslation } from "react-i18next"
import GenericModalResponsive from "../../Components/GenericModalResponsive";
import { isMobileOnly } from "react-device-detect";
import utilFunctions from "../../utils/util";
import IDocumentDefinition from "../../interfaces/IDocumentDefinition";
import useCompareDates from "../../utils/useCompareDates";
import { FormHelperText } from "@material-ui/core";
import { RegexEnum } from "../../Constants/enums";

interface EditInfoModalProps {
    selectedFieldToEdit: {
        selectedFieldTitle: string;
        selectedFieldId: any;
        selectedFieldValue: any;
        selectedFieldIndex: number;
    };
    prevInfoState?: IDocumentDefinition[];
    isEditIconClicked: boolean;
    onCloseEditModal: () => void;
    onInfoSaveCallback: (propName: keyof InfoTypes.DocumentDefinition, val: {} | string, index: number) => void;
}

interface EditInfoState{
    identifier: string;
    emittingEntity: string;
    emittingCountry: string;
    emittingCountryVal?: InfoTypes.SelectValueType;
    validFrom: Date | null;
    validTo: Date | null;
}

const InitialPatchRequest: IProfilePatchRequest = {
    path: "",
    newValue: "",
    reason: ""
}

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);

export default function EditInfoModal (props: EditInfoModalProps) {
    const { t } = useTranslation();
    const oneRef = React.createRef();
    const [isEditClicked, setIsEditClicked] = useState(true);
    const [editInfoFieldNewValue, setEditInfoFieldNewValue] = useState("");
    const [editInfoDateNewValue, setEditInfoDateNewValue] = useState<any>(null);
    const [editInfoSelectedValue, setEditInfoSelectedValue] = useState("");
    const [editInfoFieldReason, setEditInfoFieldReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [patchRequested, setPatchRequest] = useState(InitialPatchRequest);
    const [editInfoFieldError, setEditInfoFieldError] = useState(false);
    const [editInfoFieldHelperText, setEditInfoFieldHelperText] = useState<string>("");
    const [editInfoReasonError, setEditInfoReasonError] = useState(false);
    const [editInfoReasonHelperText, setEditInfoReasonHelperText] = useState<string>("");
    const [editInfoDateError, setEditInfoDateError] = useState(false);
    const [editInfoDateHelperText, setEditInfoDateHelperText] = useState<string>("");
    const { compareDates } = useCompareDates();
    
    const SendPatch = async (patchRequest: IProfilePatchRequest) => {
        useAPIRequest(Constants.API_URLS.PATCH_WORKER_PROFILE, {
            method: "PATCH",
            body: patchRequest
        })
        props.onInfoSaveCallback(props.selectedFieldToEdit.selectedFieldId, 
            ((props.selectedFieldToEdit.selectedFieldId === "validFrom" || props.selectedFieldToEdit.selectedFieldId === "validTo") ? editInfoDateNewValue : editInfoFieldNewValue), 
            props.selectedFieldToEdit.selectedFieldIndex);
        onEditInfoModalClose();
    }

    useEffect(() => {
        async function SendPatchRequest() {
            if(patchRequested.path !== "") {
                await SendPatch(patchRequested);
            }
        }
        SendPatchRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patchRequested]);

    useEffect(() => {
        setIsEditClicked(props.isEditIconClicked);
    }, [props.isEditIconClicked, editInfoFieldNewValue])

    const countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);

    const onEditInfoModalClose = (e?: EventTarget) => {
        setIsEditClicked(false);
        setEditInfoFieldNewValue("");
        setEditInfoFieldReason("");
        setEditInfoSelectedValue("");
        props.onCloseEditModal();
    };

    const handleEditReasonFieldChange = (e: ChangeEvent<HTMLInputElement>, val: string)  => {
        setEditInfoFieldReason(val);
    }

    const handleEditChange = (prop: keyof EditInfoState, key?: string) => (e: ChangeEvent<{}>, val?: any) => {
        setEditInfoFieldNewValue(val);
        setEditInfoSelectedValue(val.id);
    };

    const handleDateChange = (prop: keyof EditInfoState) => (val: Date | null) => {
       
        setEditInfoDateNewValue(val!);
    };

    useEffect(() => {
        const todayIsDate = moment();
        const fieldType = props.selectedFieldToEdit.selectedFieldId;
        const fieldIndex = props.selectedFieldToEdit.selectedFieldIndex;
        let fieldError = false;
        let fieldHelperText = '';
        let reasonError = false;
        let reasonHelperText = '';
        let dateError = false;
        let dateHelperText = '';

        if (editInfoFieldNewValue === '') {
            fieldError = true;
            fieldHelperText = t("validators.required");
          } else {
            const isRegexValid = !utilFunctions.testRegex(genericRegex, editInfoFieldNewValue);
            const regexErrorText = (fieldType === "emittingCountry") ? "" : t("validators.special_chars");
            fieldError = (fieldType === "emittingCountry") ? false : isRegexValid;
            fieldHelperText = isRegexValid ? regexErrorText : '';
          }
  
        if (editInfoFieldReason === '') {
            reasonError = true;
            reasonHelperText = t("validators.required");
        } else {
            const isReasonFieldValid = !utilFunctions.testRegex(genericRegex, editInfoFieldReason);
            reasonError = isReasonFieldValid;
            reasonHelperText = isReasonFieldValid ? t("validators.special_chars") : '';
        } 

        if(editInfoDateNewValue === null || editInfoDateNewValue.toString() === 'Invalid date' || editInfoDateNewValue.toString() === 'Invalid Date'){
          dateError = true;
          dateHelperText = t("errors.is_valid_date_or_empty_msg");
        } else if (fieldType === 'validTo' && moment(editInfoDateNewValue) < todayIsDate) {
            dateError = true;
            dateHelperText = t("errors.valid_to_lesser_now_msg");
        } else {
            const prevInfosState = props.prevInfoState && props.prevInfoState![fieldIndex] ? props.prevInfoState![fieldIndex]: props.selectedFieldToEdit.selectedFieldValue;
            const {isValid, errorMessage} = compareDates(editInfoDateNewValue, prevInfosState, fieldType);

            dateError = isValid;
            dateHelperText = errorMessage
        }
 
        setEditInfoFieldError(fieldError);
        setEditInfoFieldHelperText(fieldHelperText);
        setEditInfoReasonError(reasonError);
        setEditInfoReasonHelperText(reasonHelperText);
        setEditInfoDateError(dateError);
        setEditInfoDateHelperText(dateHelperText);
    }, [editInfoFieldNewValue, 
        editInfoFieldReason, 
        editInfoDateNewValue, 
        t, 
        props.selectedFieldToEdit.selectedFieldId, 
        props.prevInfoState,
        props.selectedFieldToEdit.selectedFieldIndex, 
        compareDates, 
        props.selectedFieldToEdit.selectedFieldValue]);

    const AcceptEditInfoChange = () => {
        setLoading(true);
        let path, newVal: any = "";
        path = "documents[" + props.selectedFieldToEdit.selectedFieldIndex + "]." + props.selectedFieldToEdit.selectedFieldId;
        switch(props.selectedFieldToEdit.selectedFieldId) {
            case "emittingCountry":
                newVal = editInfoSelectedValue;
                break;
            case "validFrom":
            case "validTo":
                newVal = moment(editInfoDateNewValue).format(Constants.DATE_FORMAT_DISPLAY);
                break;
            case "identifier":
            case "emittingEntity":
                newVal = editInfoFieldNewValue;
                break;
            default:
                path = "";
                newVal = "";
                break;
        }
        let patchRequest: IProfilePatchRequest = {
            path: path,
            newValue: newVal,
            reason: editInfoFieldReason
        }
        setPatchRequest(patchRequest);
    }

    const renderInputComponent = () => {
        switch(props.selectedFieldToEdit.selectedFieldId!) {
            case "validFrom":
            case "validTo":
                return <GenericDatePicker
                    variant="inline"
                    label={props.selectedFieldToEdit.selectedFieldTitle}
                    id="date-picker-inline"
                    inputVariant="outlined"
                    required
                    value={editInfoDateNewValue!}
                    onChangeValue={handleDateChange(props.selectedFieldToEdit.selectedFieldId)}
                    format={Constants.DATE_FORMAT_CALENDAR}
                    disablePast={(props.selectedFieldToEdit.selectedFieldId === "validTo")}
                    enableFuture={(props.selectedFieldToEdit.selectedFieldId === "validTo")}
                    inputRef={oneRef}
                    fullWidth={true}
                    error={editInfoDateError}
                    helperText={editInfoDateHelperText}
                ></GenericDatePicker>
            case "emittingCountry":
                return <>             
                    <GenericSelect
                        id="emittingCountry"
                        value={editInfoFieldNewValue}
                        dataSet={countriesData}
                        fullWidth={true}
                        handleChange={handleEditChange("emittingCountry")}
                        multiple={false}
                        freeSolo={false}
                        label={props.selectedFieldToEdit.selectedFieldTitle}
                        renderChip={false}
                        required={true}
                        error={editInfoFieldError}
                    />
                    <FormHelperText className="helper-text-error MuiFormHelperText-contained">
                        {editInfoFieldHelperText}
                    </FormHelperText>
                </>
            case "identifier":
            case "emittingEntity":
                return <GenericTextField
                    label={props.selectedFieldToEdit.selectedFieldTitle}
                    name={props.selectedFieldToEdit.selectedFieldId}
                    type="text"
                    id={props.selectedFieldToEdit.selectedFieldId}
                    variant="outlined"
                    margin="dense"
                    fullWidth={true}
                    required
                    value={editInfoFieldNewValue}
                    inputRef={oneRef}
                    onChangeValue={handleEditChange(props.selectedFieldToEdit.selectedFieldId)}
                    error={editInfoFieldError}
                    helperText={editInfoFieldHelperText}
                />
            default:
                return <></>
        }
    }

    return (
        <GenericModalResponsive
            heading={t("verify_identity.edit_info_title")}
            onCloseModal={(e?: EventTarget) => onEditInfoModalClose(e)}
            open={props.isEditIconClicked && isEditClicked}
            closeOnBackDrop={true}
            modalWidth={"auto"}
            modalHeight={"auto"}
            modalPadding={isMobileOnly ? 20 : 30}
            modalMargin={isMobileOnly ? 10 : 50}
            modalTop={"15%"}
            className="personal-info-edit-modal"
        >
            <div className="personal-info-edit-modal">
                {loading ? (
                <div style={{ marginTop: "50px", textAlign: "center" }}>
                    <GenericLoader />
                    <div className="loading-text">{t("common.saving")}</div>
                </div>
                ) : (
                <>
                    <div className="fullWidth">
                        <GenericDataShow
                        label={props.selectedFieldToEdit.selectedFieldTitle}
                        value={(props.selectedFieldToEdit.selectedFieldId === "validFrom" || props.selectedFieldToEdit.selectedFieldId === "validTo") ? moment(props.selectedFieldToEdit.selectedFieldValue).format(Constants.DATE_FORMAT_DISPLAY) : props.selectedFieldToEdit.selectedFieldValue}
                        isSelectField={(props.selectedFieldToEdit.selectedFieldId === "emittingCountry")}
                        />
                    </div>
                    <div className="fullWidth">
                        {renderInputComponent()}
                    </div>
                    <div className="fullWidth">
                        <GenericTextField
                            label={t("common.reason_for_change")}
                            name={"reasonForChange"}
                            type="text"
                            id={"reasonForChange"}
                            variant="outlined"
                            margin="dense"
                            fullWidth={true}
                            required
                            value={editInfoFieldReason}
                            inputRef={oneRef}
                            onChangeValue={handleEditReasonFieldChange}
                            error={editInfoReasonError}
                            helperText={editInfoReasonHelperText}
                        />
                    </div>
                    <div className="fullWidth" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <GenericButton
                        onClick={() => {
                            AcceptEditInfoChange();
                        }}
                        disabled={(props.selectedFieldToEdit.selectedFieldId === "validFrom" || props.selectedFieldToEdit.selectedFieldId === "validTo") ?
                            (editInfoReasonError || editInfoDateError) : (editInfoReasonError || editInfoFieldError)}
                        type="primary"
                        buttonText={t("common.save")}
                        ></GenericButton>
                    </div>   
                </>
                )}
            </div>
        </GenericModalResponsive>
    )
}
