import "./index.scss";
import InfoTypes from "../../interfaces/InfoTypes";
import Heading from "../../Components/Heading";
import { useTranslation } from "react-i18next"

interface CheckIntroProps {
  content: InfoTypes.ContentDefinition;
}

export default function CheckIntro(props: CheckIntroProps) {
  const { t } = useTranslation();

    return (
      <div id="check-intro" className="check-intro editor-component">
          <div className="check-intro-body">
              <div className="check-intro-body-container">
                <Heading title={(props.content && props.content!.title !== "") ? props.content!.title : t("common.introduction") } />
                <div className="check-intro-body-container-details-row">
                {(props.content) && (props.content.content) && props.content.content !== "" ?
                  <div className="check-intro-body-container-details-row-content editor-component-body">
                  { props.content.content !== ""
                    ? (
                        <div dangerouslySetInnerHTML={{__html: props.content.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                        </div>
                      )
                    : ""
                  }
                  </div>
                  : ""}
                </div>
              </div>
          </div>
      </div>
    );
  }
