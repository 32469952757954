import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import InfoTypes from "../../interfaces/InfoTypes";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IConvictionsInformation from "../../interfaces/IConvictionsInformation";
import GenericTextField from "../../Components/GenericTextField";
import Constants from "../../Constants/constants";
import IProfile from "../../interfaces/IProfile";
import LocalStorageService from "../../utils/localStorageService";
import useAPIRequest from "../../Service/useAPIRequest";
import Heading from "../../Components/Heading";
import { useTranslation } from "react-i18next"
import { LoaderModal } from "../../Components/LoaderModal";

type ConvictionsProps = {
  content: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
  isCheckDataEditable: boolean;
  errorHandler?: (msg: string) => void;
  handleSubmit?: (updatedProcessData?: IComplianceProcess, editor?: string) => void;
}

export default function Convictions(props: ConvictionsProps) {
    const { t } = useTranslation();
    const oneRef = React.createRef();
    const [loading, setLoading] = useState(false);
    const [haveRespondedUnspent, setHaveRespondedUnspent] = useState(false);
    const [haveRespondedUnprotected, setHaveRespondedUnprotected] = useState(false);
    const [unspentConvictedYes, setUnspentConvictedYes] = useState(false);
    const [unspentConvictedNo, setUnspentConvictedNo] = useState(false);
    const [unprotectedConvictedYes, setUnprotectedConvictedYes] = useState(false);
    const [unprotectedConvictedNo, setUnprotectedConvictedNo] = useState(false);
    const [unprotectedConvictionDesc, setUnprotectedConvictionDesc] = useState("");
    const [convictionsRequest, setConvictionsRequest] = useState<IComplianceProcess>();
    
    const SendPutConvictionsData = async (convictionsRequest: IComplianceProcess) => {
      setLoading(true);
      
      useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + props.complianceProcessData.id, {
        method: "PUT",
        body: convictionsRequest
        }).then((res) => {
          if (res.ok) {
            setLoading(false);
            if (props.handleSubmit) {
              props.handleSubmit(convictionsRequest, Constants.COMPLIANCE_STEPS_EDITORS.CONVICTIONS);
            }
          } else {
            setLoading(false);
          }
        }).catch(() => {
          setLoading(false);
          if (props.errorHandler) {
            props.errorHandler(t("errors.save_error_msg"));
          }
        });
    }

    useEffect(() => {
      async function SendPutConvictionsDataRequest() {
          if(convictionsRequest) {
              await SendPutConvictionsData(convictionsRequest);
          }
      }
      SendPutConvictionsDataRequest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convictionsRequest]);
    
    useEffect(() => {
      setLoading(true);
      var convictionsInfoData: IConvictionsInformation;
      if (props.complianceProcessData) {
        if (props.complianceProcessData.processData && props.complianceProcessData.processData.convictions) {
          convictionsInfoData = props.complianceProcessData.processData.convictions;
        }
      } else {
        var profileDtls: IProfile = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA);
        if (profileDtls && profileDtls.convictions) {
          convictionsInfoData = profileDtls.convictions;
        }
      }

      if (convictionsInfoData!) {
        if (convictionsInfoData.hasUnspentConvictions) {
          setUnspentConvictedYes(true);
          setUnspentConvictedNo(false);
        } else {
          setUnspentConvictedYes(false);
          setUnspentConvictedNo(true);
        }
        if (convictionsInfoData.unProtectedConvictions) {
          setUnprotectedConvictedYes(true);
          setUnprotectedConvictionDesc(convictionsInfoData.unprotectedConvictionsDescription!);
          setUnprotectedConvictedNo(false);
        } else {
          setUnprotectedConvictedYes(false);
          setUnprotectedConvictedNo(true);
          setUnprotectedConvictionDesc("");
        }
        setHaveRespondedUnspent(true);
        setHaveRespondedUnprotected(true);
      }
      setLoading(false);
    },[props.complianceProcessData]);

    const handleCheckUnspentResponse = (selection: string) => {
      setHaveRespondedUnspent(true);

      if (selection === "yes") {
        if (unspentConvictedYes) {
          setUnspentConvictedYes(false);
          setHaveRespondedUnspent(false);
        } else {
          setUnspentConvictedYes(true);
        }
        setUnspentConvictedNo(false);
      } else {
        setUnspentConvictedYes(false);
        if (unspentConvictedNo) {
          setUnspentConvictedNo(false);
          setHaveRespondedUnspent(false);
        } else {
          setUnspentConvictedNo(true);
        }
      }
  }

  const handleCheckUnprotectedResponse = (selection: string) => {
    setHaveRespondedUnprotected(true);

    if (selection === "yes") {
      if (unprotectedConvictedYes) {
        setUnprotectedConvictedYes(false);
        setHaveRespondedUnprotected(false);
      } else {
        setUnprotectedConvictedYes(true);
      }
      setUnprotectedConvictedNo(false);
    } else {
      setUnprotectedConvictedYes(false);
      if (unprotectedConvictedNo) {
        setUnprotectedConvictedNo(false);
        setHaveRespondedUnprotected(false);
      } else {
        setUnprotectedConvictedNo(true);
      }
    }
  }

  const handleDescChange = (e: ChangeEvent<HTMLInputElement>, val: string) => {
    setUnprotectedConvictionDesc(val);
  };

  const handleSubmit = () => {

    const convictionsData: IConvictionsInformation = {
      hasUnspentConvictions: unspentConvictedYes,
      unProtectedConvictions: unprotectedConvictedYes,
      unprotectedConvictionsDescription: unprotectedConvictionDesc
    }

    var prepConvictionsData: IComplianceProcess = {
      id: props.complianceProcessData.id,
      complianceType: props.complianceProcessData.complianceType,
      processData: {
        convictions: convictionsData
      }
    }
    setConvictionsRequest(prepConvictionsData);
  }
  
  return (
    <div id="convictions" className="convictions editor-component">
      <div className="convictions-body">
        <div className="convictions-body-container">
          <Heading title={(props.content) && (props.content.title) ? props.content.title : t("convictions.title")} />
          <div className="convictions-body-container-details-row">
          {(props.content) && (props.content.content) && props.content.content !== "" ?
            <div className="convictions-body-container-details-row-content">
            { props.content.content !== ""
              ? (
                  <div className="editor-component-body" dangerouslySetInnerHTML={{__html: props.content.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                  </div>
                )
              : ""
            }
            </div>
            : ""}
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.unspent_convictions_ques")}
            </div>
            <div className="worked-abroad-body-container-details-row-content check-box-ctrls editor-component-body">
              <FormGroup>
                  <FormControlLabel control={<Checkbox checked={haveRespondedUnspent && unspentConvictedYes} disabled={!props.isCheckDataEditable} onClick={() => handleCheckUnspentResponse("yes")} />} label={t("common.yes")} />
                  <FormControlLabel control={<Checkbox checked={haveRespondedUnspent && unspentConvictedNo} disabled={!props.isCheckDataEditable} onClick={() => handleCheckUnspentResponse("no")} />} label={t("common.no")} />
              </FormGroup>
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.declare_unprotected_warning")}
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.complete_declaration_req")}
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.overall_convictions_ques")}
            </div>
            <div className="worked-abroad-body-container-details-row-content check-box-ctrls editor-component-body">
              <FormGroup>
                  <FormControlLabel control={<Checkbox checked={haveRespondedUnprotected && unprotectedConvictedYes} disabled={!props.isCheckDataEditable} onClick={() => handleCheckUnprotectedResponse("yes")} />} label={t("common.yes")} />
                  <FormControlLabel control={<Checkbox checked={haveRespondedUnprotected && unprotectedConvictedNo} disabled={!props.isCheckDataEditable} onClick={() => handleCheckUnprotectedResponse("no")} />} label={t("common.no")} />
              </FormGroup>
            </div>
            <div className="worked-abroad-body-container-details-row-content convictns-desc editor-component-body">
              { (haveRespondedUnprotected && unprotectedConvictedYes) ? 
              <>
              <div className="desc-text-area">
                <GenericTextField
                  label=""
                  placeholder={"Enter details here..."}
                  name={"unprotectedConvictionDesc"}
                  type="text"
                  id={"unprotectedConvictionDesc"}
                  variant="outlined"
                  multiline={true}
                  rows={1}
                  margin="dense"
                  fullWidth={true}
                  disabled={!props.isCheckDataEditable}
                  required
                  value={unprotectedConvictionDesc}
                  inputRef={oneRef}
                  onChangeValue={handleDescChange}
                  error={false}
                  helperText={""}
                  />
              </div>
              </> : ""
              }
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("common.more_info_reference")} <a href="https://www.gov.uk/government/publications/dbs-filtering-guidance/dbs-filtering-guide"> {t("convictions.dbs_filtering_guidelines")}</a> {t("convictions.agreement_statement")}
            </div>
            <div className="worked-abroad-body-container-details-row-content">
            </div>
            
            <div className="convictions-body-container-details-row-content">
              <div className="container-submit-btn">
                {(props.isCheckDataEditable) ?
                <GenericButton
                  onClick={handleSubmit}
                  disabled={(!haveRespondedUnspent) || (!haveRespondedUnprotected)}
                  type="primary"
                  buttonText={"Accept"}
                ></GenericButton> : ""}
              </div>
            </div>
          </div>
        </div>
        <LoaderModal isLoading={loading} />
      </div>
    </div>
  );
}
