import moment from "moment";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import InfoTypes from "../../interfaces/InfoTypes";
import EditPrevNameModal from "./EditPrevNameModal";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Constants from "../../Constants/constants";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import utilFunctions from "../../utils/util";
import { RegexEnum } from "../../Constants/enums";

interface PreviousNameProps {
  prevNameData: InfoTypes.PreviousNames;
  pNameIdx: number;
  haveRespondedPrevName: boolean;
  isProfileView: boolean;
  updatedData: (data: InfoTypes.PreviousNames, index: number) => void;
  isCheckDataEditable: boolean;
}

interface PreviousNameState {
  mappedPrevNameData: InfoTypes.PreviousNames;
}

interface PreviousNameStateData {
  forename: string;
  surname: string;
  from: Date | null;
  to: Date | null;
  isNew: boolean;
  [N: string]: any;
}

const personNameRegex: RegExp = new RegExp(RegexEnum.PERSON_NAME);

export default function PreviousName(props: PreviousNameProps, state: PreviousNameState) {
  const { t } = useTranslation();
  const [prevNameValue, setPrevNameValue] = useState<PreviousNameState>({
    mappedPrevNameData: props.prevNameData,
  });
  const [prevNamesEditClicked, setPrevNamesEditClicked] = useState(false);
  const [selectedPrevNameValue, setSelectedPrevNameValue] = useState({
    forename: "",
    surname: "",
    from: "",
    to: "",
  });

  const [selectedPrevNameToEdit, setSelectedPrevNameToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
  });

  const [prevNameDateFromError, setPrevNameDateFromError] = useState(false);
  const [prevNameDateFromHelperText, setPrevNameDateFromHelperText] = useState('');

  const [prevNameDateToError, setPrevNameDateToError] = useState(false);
  const [prevNameDateToHelperText, setPrevNameDateToHelperText] = useState('');

  const handlePreviousNameChange =
    (prop: keyof PreviousNameStateData, index: number) =>
    (e: ChangeEvent<HTMLInputElement>, val: string) => {
      var prevName: any = prevNameValue;
      prevName.mappedPrevNameData[prop] = val;

      setPrevNameValue({
        ...state,
        mappedPrevNameData: prevName.mappedPrevNameData,
      });
      props.updatedData(prevName.mappedPrevNameData, index);
    };

  const handlePreviousNameDateChange =
    (prop: keyof PreviousNameStateData, index: number) => (val: Date | null) => {
      var mappedVal: any = prevNameValue;
      if (prop === "from") {
        mappedVal!.mappedPrevNameData!.from = moment(val!).format(Constants.DATE_FORMAT_ISO);
      } else {
        mappedVal!.mappedPrevNameData!.to = moment(val!).format(Constants.DATE_FORMAT_ISO);
      }
      setPrevNameValue({
        ...state,
        mappedPrevNameData: mappedVal.mappedPrevNameData,
      });
      props.updatedData(mappedVal.mappedPrevNameData, index);
    };

  const onEditPrevNameModalClose = () => {
    setPrevNamesEditClicked(false);
    setSelectedPrevNameValue({
      forename: "",
      surname: "",
      from: "",
      to: "",
    });
    setSelectedPrevNameToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
    });
  };

  const handleEditPrevNameFieldChange = (
    prevName: InfoTypes.PreviousNames,
    id: string,
    title: string,
    val: any,
    index: number
  ) => {
    setSelectedPrevNameToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: index,
    });
    setSelectedPrevNameValue({
      forename: prevName.forename!,
      surname: prevName.surname!,
      from: moment(prevName.from).format(Constants.DATE_FORMAT_DISPLAY),
      to: moment(prevName.to).format(Constants.DATE_FORMAT_DISPLAY),
    });
  };

  const handleEditPrevNameSaveCallback = (
    prop: keyof PreviousNameStateData,
    val: any,
    index: number
  ) => {
    var propwithChange: any = prevNameValue!.mappedPrevNameData!;
    if (prop === "from" || prop === "to") {
      propwithChange[prop] = moment(val).format(Constants.DATE_FORMAT_ISO);
    } else {
      propwithChange[prop] = val;
    }

    setPrevNameValue({
      ...prevNameValue.mappedPrevNameData,
      mappedPrevNameData: propwithChange,
    });
    props.updatedData(propwithChange, index);
  };

  const handlePrevNameDateErrors = useCallback(() => {

    const prevAddr = prevNameValue.mappedPrevNameData!;

    // Dates validations
    if ((prevAddr.from && prevAddr.to) && 
        (prevAddr!.from.toString() !== 'Invalid date' && prevAddr!.to.toString() !== 'Invalid date')) {
        const isMinDate = prevAddr.from >= prevAddr.to;
        const isMaxDate = prevAddr.to <= prevAddr.from;
        const now = moment().startOf('day');
        const isDateToGreaterThanToday = moment(prevAddr.to).isSameOrAfter(now);

        if (isMaxDate) {
          setPrevNameDateToError(isMaxDate);
          setPrevNameDateToHelperText(t("errors.to_date_lesser_msg"));
        } else {
          setPrevNameDateToError(false);
          setPrevNameDateToHelperText("");
        }

        if (isDateToGreaterThanToday) {
          setPrevNameDateToError(true);
          setPrevNameDateToHelperText(t("errors.to_date_greater_today_msg"));
        }  else {
          setPrevNameDateToError(false);
          setPrevNameDateToHelperText("");
        }
        
        if (isMinDate) {
          setPrevNameDateFromError(isMinDate);
          setPrevNameDateFromHelperText(t("errors.from_date_greater_msg"));
        }  else {
          setPrevNameDateFromError(false);
          setPrevNameDateFromHelperText("");
        }

    } else {
      setPrevNameDateToError(true);
      setPrevNameDateToHelperText(t("errors.is_valid_date_or_empty_msg"));

      setPrevNameDateFromError(true);
      setPrevNameDateFromHelperText(t("errors.is_valid_date_or_empty_msg"));
    }

  }, [prevNameValue.mappedPrevNameData, t])
  
  useEffect(() => {
    handlePrevNameDateErrors();
  }, [prevNameValue.mappedPrevNameData.from, 
    prevNameValue.mappedPrevNameData.to, 
    prevNameDateToError, 
    prevNameDateToHelperText, 
    prevNameDateFromError, 
    prevNameDateFromHelperText, 
    handlePrevNameDateErrors
  ]);
  
  return (
    <>
      <div
        id={`persona-info-prevName-div-${props.pNameIdx}`}
        className="personal-info-body-container-details empty-row"
      >
        <div className="personal-info-body-container-details-row">
          <div className="oneHalfWidth">
            <TextEditor
              fieldName="prev_forename"
              value={props.prevNameData.forename}
              editValue={prevNameValue.mappedPrevNameData!.forename}
              label={t("profile_details.prev_forename")}
              type="text"
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePreviousNameChange("forename", props.pNameIdx)}
              isNew={props.prevNameData.isNew}
              onEditClicked={(e: EventTarget) => {
                setPrevNamesEditClicked(true);
                handleEditPrevNameFieldChange(
                  props.prevNameData,
                  "forename",
                  t("profile_details.prev_forename"),
                  props.prevNameData.forename,
                  props.pNameIdx
                );
              }}
              error={
                prevNameValue.mappedPrevNameData!.forename &&
                prevNameValue.mappedPrevNameData!.forename !== ""
                  ? !utilFunctions.testRegex(
                      personNameRegex,
                      prevNameValue.mappedPrevNameData!.forename
                    )
                  : true
              }
              helperText={
                prevNameValue.mappedPrevNameData!.forename &&
                prevNameValue.mappedPrevNameData!.forename !== ""
                  ? utilFunctions.testRegex(
                      personNameRegex,
                      prevNameValue.mappedPrevNameData!.forename
                    )
                    ? ""
                    : t("validators.special_chars")
                  : t("validators.required")
              }
            />
          </div>
          <div className="oneHalfWidth">
            <TextEditor
              fieldName="prev_surname"
              value={props.prevNameData.surname}
              editValue={prevNameValue.mappedPrevNameData!.surname}
              label={t("profile_details.prev_surname")}
              type="text"
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePreviousNameChange("surname", props.pNameIdx)}
              isNew={props.prevNameData.isNew}
              onEditClicked={(e: EventTarget) => {
                setPrevNamesEditClicked(true);
                handleEditPrevNameFieldChange(
                  props.prevNameData,
                  "surname",
                  t("profile_details.prev_surname"),
                  props.prevNameData.surname,
                  props.pNameIdx
                );
              }}
              error={
                prevNameValue.mappedPrevNameData!.surname &&
                prevNameValue.mappedPrevNameData!.surname !== ""
                  ? !utilFunctions.testRegex(
                      personNameRegex,
                      prevNameValue.mappedPrevNameData!.surname
                    )
                  : true
              }
              helperText={
                prevNameValue.mappedPrevNameData!.surname &&
                prevNameValue.mappedPrevNameData!.surname !== ""
                  ? utilFunctions.testRegex(
                      personNameRegex,
                      prevNameValue.mappedPrevNameData!.surname
                    )
                    ? ""
                    : t("validators.special_chars")
                  : t("validators.required")
              }
            />
          </div>
        </div>
        <div className="personal-info-body-container-details-row">
          <div className="oneHalfWidth">
            <DateEditor
              value={props.prevNameData.from}
              editValue={prevNameValue.mappedPrevNameData!.from}
              label={t("common.date_from_generic")}
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePreviousNameDateChange("from", props.pNameIdx)}
              isNew={props.prevNameData.isNew}
              onEditClicked={(e: EventTarget) => {
                setPrevNamesEditClicked(true);
                handleEditPrevNameFieldChange(
                  props.prevNameData,
                  "from",
                  t("common.date_from_generic"),
                  props.prevNameData.from,
                  props.pNameIdx
                );
              }}
              error={prevNameDateFromError}
              helperText={prevNameDateFromHelperText}
            />
          </div>
          <div className="oneHalfWidth">
            <DateEditor
              value={props.prevNameData.to}
              editValue={prevNameValue.mappedPrevNameData!.to}
              label={t("common.date_to_generic")}
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePreviousNameDateChange("to", props.pNameIdx)}
              isNew={props.prevNameData.isNew}
              onEditClicked={(e: EventTarget) => {
                setPrevNamesEditClicked(true);
                handleEditPrevNameFieldChange(
                  props.prevNameData,
                  "to",
                  t("common.date_to_generic"),
                  props.prevNameData.to,
                  props.pNameIdx
                );
              }}
              error={prevNameDateToError}
              helperText={prevNameDateToHelperText}
            />
          </div>
        </div>
        <div className="personal-info-body-container-details-row empty-row">
          <div></div>
        </div>
      </div>
      {prevNamesEditClicked ? (
        <EditPrevNameModal
          isEditIconPrevNameClicked={prevNamesEditClicked}
          prevNameState={selectedPrevNameValue}
          selectedPrevNameToEdit={selectedPrevNameToEdit}
          onCloseEditPrevNameModal={onEditPrevNameModalClose}
          onPrevNameSaveCallback={handleEditPrevNameSaveCallback}
        />
      ) : (
        ""
      )}
    </>
  );
}
