import "./index.scss";
import Constants from "../../Constants/constants";
import { useHistory } from "react-router-dom";
import CircleIcon from "@material-ui/icons/FiberManualRecordRounded";
import { useTranslation } from "react-i18next"
import utilFunctions from "../../utils/util";
import { Button } from "@material-ui/core";
import PaymentIcon from '@mui/icons-material/Payment';
import { CandidatePaymentStatus, ProcessStatus } from "../../Constants/enums";
import { GetComplianceTypeDisplayName } from "../../utils/referenceDataService";
import moment from "moment";
import InfoTypes from "../../interfaces/InfoTypes";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  primaryButton: {
    background: '#2D7FF9!important',
    color: '#FFF!important',
    border: '1px solid #2D7FF9',
    borderRadius: 25,
    textTransform: 'capitalize',
    padding: '7px 15px 7px 15px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 1px 6px 5px rgba(45, 127, 249, 0.3)'
    },
  },
});

interface RoleProcessesProps {
  process: InfoTypes.Process;
  roleId: string;
  isComplete: boolean; 
}

export default function RoleProcesses(props: RoleProcessesProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const process = props.process;

  const handleMakePayment = (roleId: string,  complianceId: string) => {
    history.push(Constants.COMPLIANCE_FORM_ROUTES.PAYMENT + complianceId, {roleId: roleId});
  };

  
  const navigateToComplianceForm = (complianceId: string, complianceName: string) => {
    history.push(utilFunctions.getComplianceFormRoute(complianceName) + complianceId);
  };

    return (
        <div
            className="comp-points"
            id={process.id}
            key={process.id}
            onClick={() => {
                if (
                    process.status === ProcessStatus.WaitingForWorker && (
                    !process.isCandidatePayment ||
                    process.candidatePaymentStatus === CandidatePaymentStatus.Paid)
                ) {
                    navigateToComplianceForm(process.id, process.complianceType);
                }
            }}
      >
        <div
          className="comp-point-status"
          style={{ color: process.status === ProcessStatus.Complete ? "#93E088" : "Coral" }}
        >
          <>
            <div className="comp-point-data">
              <div
                className="comp-point-text"
                aria-label={GetComplianceTypeDisplayName(process.complianceType)}
              >
                {GetComplianceTypeDisplayName(process.complianceType)}
              </div>
              {process.isCandidatePayment &&
                process.candidatePaymentStatus !== CandidatePaymentStatus.Paid && (
                  <Button
                    onClick={() => handleMakePayment(props.roleId, process.id)}
                    className={classes.primaryButton} 
                    variant="contained" 
                    disableRipple
                    disabled={false}
                    size="small" 
                    startIcon={<PaymentIcon />}
                  >
                    {t("payment.make_payment")}
                  </Button>
                )}
            </div>
            <div className="line-wrapper">
              <div
                className="status-icon"
                style={{ fill: process.status === ProcessStatus.Complete ? "#93E088" : "Coral" }}
              >
                <CircleIcon />
              </div>
              <div className="status-text">
                {t(utilFunctions.GetProcessStatusTranslationKey(process.status))}
              </div>
            </div>
          </>
        </div>
        <hr />
        <div className="text-with-bullet">
          <div className="comp-point-icon">
            <CircleIcon />
          </div>
          <p className="label">{t("dashboard.requested_date")}</p>
          <p className="value">
            {process.createdDate
              ? moment(process.createdDate).format(Constants.DATE_FORMAT_DISPLAY)
              : "--"}
          </p>
        </div>
      </div>
    );
  }
