import { useContext } from "react";
import { DocumentsContext } from "./documents-types";

const useDocumentsContext = () => {
  const context = useContext(DocumentsContext);
  if (context === undefined) {
    throw new Error("useDocumentsContext must be used within a DocumentsContextProvider");
  }
  return context;
};

export default useDocumentsContext;