import "../VerifyIdentity/index.scss";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import { ImportStatus } from "../../Constants/enums";
import { useTranslation } from "react-i18next";
import moment from "moment";
import YotiDigitalIDButton from "./YotiDigitalIDButton";
import { useState } from "react";
import YotiDigitalIDMessage from "./YotiDigitalIDMessage";
import { Checkbox, List, ListItem, Typography, makeStyles } from "@material-ui/core";

interface YotiDigitalIDSessionStatusProps {
  compliance: IComplianceProcess;
  checkYotiSession: () => void;
  isCheckEditable: boolean;
  loading: boolean;
}
const useStyles = makeStyles({
  error: {
    color: "#d32f2f",
  },
});
export default function YotiDigitalIDSessionStatus(props: YotiDigitalIDSessionStatusProps) {
  const { t } = useTranslation();
  const [importStatus] = useState(props.compliance.digitalIdentity?.importStatus);
  const [isConsent, setIsConsent] = useState(false);
  const compliance = props.compliance;

  const classes = useStyles();

  if (compliance && compliance.digitalIdentity) {
    switch (compliance.digitalIdentity.importStatus) {
      case ImportStatus.Pending:
        if (compliance.digitalIdentity.isComplete) {
          return <YotiDigitalIDMessage yotiMsg={t("verify_identity.yoti_process_complete_msg")} />;
        } else {
          if (moment(compliance.digitalIdentity.validTo) > moment()) {
            return (
              <YotiDigitalIDButton
                message={t("verify_identity.yoti_process_session_msg")}
                checkYotiSession={props.checkYotiSession}
                loading={props.loading}
                isDisabled={!props.isCheckEditable}
                buttonText={t("verify_identity.continue_digital_id_verification")}
              />
            );
          } else {
            return (
              <YotiDigitalIDButton
                message={t("verify_identity.yoti_process_session_msg")}
                checkYotiSession={props.checkYotiSession}
                loading={props.loading}
                isDisabled={!props.isCheckEditable}
                buttonText={t("verify_identity.retry_digital_id_verification")}
              />
            );
          }
        }
      case ImportStatus.Invalid:
      case ImportStatus.RequirementsNotMet:
      case ImportStatus.Rejected:
        return (
          <>
            {compliance.retriesDigitalIdentityNumber &&
            compliance.retriesDigitalIdentityNumber >= 2 ? (
              <Typography
                color="primary"
                variant="body1"
                style={{
                  fontSize: "0.9rem!important",
                  marginTop: "1rem",
                  textAlign: "start",
                }}
              >
                {t("verify_identity.yoti_process_fail_retry_msg.exceeded_retry_number")}
              </Typography>
            ) : (
              <YotiDigitalIDButton
                message={
                  importStatus === ImportStatus.Rejected ? (
                    t("verify_identity.yoti_process_retry_if_rejected_msg")
                  ) : (
                    <List>
                      {t("verify_identity.yoti_process_fail_retry_msg.text")
                        .split("##")
                        .map((txt) => (
                          <ListItem className={classes.error}>{txt}</ListItem>
                        ))}
                      <ListItem>
                        {t("verify_identity.yoti_process_fail_retry_msg.action")}
                        <Checkbox
                          value={isConsent}
                          onChange={() => setIsConsent((state) => !state)}
                        />
                      </ListItem>
                    </List>
                  )
                }
                checkYotiSession={props.checkYotiSession}
                loading={props.loading}
                isDisabled={!(props.isCheckEditable && isConsent)}
                buttonText={t("verify_identity.retry_digital_id_verification")}
              />
            )}
          </>
        );
      case ImportStatus.Imported:
        return <YotiDigitalIDMessage yotiMsg={t("verify_identity.yoti_process_complete_msg")} />;
      default:
        return <></>;
    }
  } else return <></>;
}
