import { createContext, useState, useContext, useMemo } from "react";
import { isMobile } from "react-device-detect";

export type DrawerContextType = {
  isOpened: boolean;
  toggleIsOpened: (value: boolean) => void;
};

type DrawerContextProviderProps = {
  children?: React.ReactNode;
};

export const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

const DrawerContextProvider = ({ children }: DrawerContextProviderProps) => {
  const initialIsOpened = !isMobile;
  
  const [isOpened, toggleIsOpened] = useState(initialIsOpened);
  
  const contextValue = useMemo(() => ({ isOpened, toggleIsOpened }), [isOpened]);
  
  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error(
      "useDrawerContext must be used within a DrawerContextProvider"
    );
  }
  return context;
};

export { DrawerContextProvider, useDrawerContext };
