import "../../VerifyIdentity/index.scss";
import { useTranslation } from "react-i18next";
import IImportableDigitalData from "../../../interfaces/IImportableDigitalData";

interface DigitalIdentityDataAddressProps {
  digitalIdentityData: IImportableDigitalData;
}

export default function DigitalIdentityDataAddress(props: DigitalIdentityDataAddressProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="fullWidth data-fields">
        <div
          className="fullWidth data-field-title"
          aria-label={t("address_history.current_address_info")}
        >
          {t("address_history.current_address_info")}:
        </div>
      </div>
      <div className="fullWidth data-fields">
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("digital_identity.building_number")}
          >
            {t("digital_identity.building_number")}: &nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentAddress?.buildingNumber}
            </div>
          </div>
        </div>
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("digital_identity.address_line_1")}
          >
            {t("digital_identity.address_line_1")}:&nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentAddress?.addressLine1}
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidth data-fields">
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("digital_identity.address_line_2")}
          >
            {t("digital_identity.address_line_2")}: &nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentAddress?.addressLine2}
            </div>
          </div>
        </div>
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("digital_identity.address_line_3")}
          >
            {t("digital_identity.address_line_3")}:&nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentAddress?.addressLine3}
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidth data-fields">
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("address_history.town")}
          >
            {t("address_history.town")}:&nbsp;&nbsp;
            <div className="data-show-value">{props.digitalIdentityData?.currentAddress?.town}</div>
          </div>
        </div>
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("address_history.postal_code")}
          >
            {t("address_history.postal_code")}: &nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentAddress?.postalCode}
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidth data-fields">
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("common.country")}
          >
            {t("common.country")}:&nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentAddress?.country}
            </div>
          </div>
        </div>
        <div className="oneHalfWidth"></div>
      </div>
    </>
  );
}
