
import React, { ChangeEvent, useEffect, useState } from "react";
import "./index.scss";
import GenericTextField from "../../Components/GenericTextField";
import GenericDatePicker  from "../../Components/GenericDatePicker";
import GenericButton from "../../Components/GenericButton";
import moment from "moment";
import GenericDataShow from "../../Components/GenericDataShow";
import GenericModal from "../../Components/GenericModal";
import { GenericLoader } from "../../Components/GenericLoader";
import IProfilePatchRequest from "../../interfaces/IProfilePatchRequest";
import useAPIRequest from "../../Service/useAPIRequest";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import GenericSelect from "../../Components/GenericSelect";
import { useTranslation } from "react-i18next"
import useCompareDates from "../../utils/useCompareDates";
import { FormHelperText } from "@material-ui/core";
import utilFunctions from "../../utils/util";
import { RegexEnum } from "../../Constants/enums";

interface EditPrevNationalityModalProps {
    selectedPrevNationalityToEdit: {
        selectedFieldTitle: string;
        selectedFieldId: any;
        selectedFieldValue: any;
        selectedFieldIndex: number;
    };
    prevNationalityState: {};
    isEditIconPrevNationalityClicked: boolean;
    onCloseEditPrevNationalityModal: () => void;
    onPrevNationalitySaveCallback: (propName: keyof EditPrevNationalityState, val: {} | string, index: number) => void;
}

interface EditPrevNationalityState{
    nationality: string;
    from: Date | null;
    to: Date | null;
}

const InitialPatchRequest: IProfilePatchRequest = {
    path: "",
    newValue: "",
    reason: ""
}

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);
    
export default function EditPrevNationalityModal (props: EditPrevNationalityModalProps) {
    const { t } = useTranslation();
    const oneRef = React.createRef();
    const [isEditClicked, setIsEditClicked] = useState(true);
    const [editPrevNationalityFieldNewValue, setEditPrevNationalityFieldNewValue] = useState("");
    const [editPrevNationalityDateFieldNewValue, setEditPrevNationalityDateFieldNewValue] = useState<any>(null);
    const [editPrevNationalityFieldReason, setEditPrevNationalityFieldReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [patchRequested, setPatchRequest] = useState(InitialPatchRequest);
    const nationalitiesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA);
    const [editPrevNationalityFieldError, setEditPrevNationalityFieldError] = useState(false);
    const [editPrevNationalityFieldHelperText, setEditPrevNationalityFieldHelperText] = useState<string>("");
    const [editPrevNationalityReasonError, setEditPrevNationalityReasonError] = useState(false);
    const [editPrevNationalityReasonHelperText, setEditPrevNationalityReasonHelperText] = useState<string>("");
    const [editPrevNationalityDateError, setEditPrevNationalityDateError] = useState(false);
    const [editPrevNationalityDateHelperText, setEditPrevNationalityDateHelperText] = useState<string>("");
    const { compareDates } = useCompareDates();
    
    const SendPatch = async (patchRequest: IProfilePatchRequest) => {
        useAPIRequest(Constants.API_URLS.PATCH_WORKER_PROFILE, {
            method: "PATCH",
            body: patchRequest
        })
        props.onPrevNationalitySaveCallback(props.selectedPrevNationalityToEdit.selectedFieldId, 
            ((props.selectedPrevNationalityToEdit.selectedFieldId === "from" || props.selectedPrevNationalityToEdit.selectedFieldId === "to") ? editPrevNationalityDateFieldNewValue : editPrevNationalityFieldNewValue), 
            props.selectedPrevNationalityToEdit.selectedFieldIndex);
        onEditPrevMationalityModalClose();
    }

    useEffect(() => {
        async function SendPatchRequest() {
            if(patchRequested.path !== "") {
                await SendPatch(patchRequested);
            }
        }
        SendPatchRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patchRequested]);

    useEffect(() => {
        setIsEditClicked(props.isEditIconPrevNationalityClicked);

    }, [props.isEditIconPrevNationalityClicked])

    const onEditPrevMationalityModalClose = (e?: EventTarget) => {
        setIsEditClicked(false);
        setEditPrevNationalityFieldNewValue("");
        setEditPrevNationalityFieldReason("");
        props.onCloseEditPrevNationalityModal();
    };

    const handleEditPrevNationalityReasonChange = (e: ChangeEvent<HTMLInputElement>, val: string)  => {
        setEditPrevNationalityFieldReason(val);
    }

    const handleEditPrevNationalityFieldChange = (prop: keyof EditPrevNationalityState, key?: string) => (event: ChangeEvent<{}>, val: any) => {
        setEditPrevNationalityFieldNewValue(val);
    };

    const handlePrevNationalityDateChange = (prop: keyof EditPrevNationalityState) => (val: Date | null) => {
        setEditPrevNationalityDateFieldNewValue(val!);
    };

    useEffect(() => {
        const todayIsDate = moment();

        if (editPrevNationalityFieldNewValue === '') {
            setEditPrevNationalityFieldError(true);
            setEditPrevNationalityFieldHelperText(t("validators.required"));
        } else {
            setEditPrevNationalityFieldError(false);
            setEditPrevNationalityFieldHelperText("");
        }

        if (editPrevNationalityFieldReason === '') {
            setEditPrevNationalityReasonError(true);
            setEditPrevNationalityReasonHelperText(t("validators.required"));
        } else {
            if (!utilFunctions.testRegex(genericRegex, editPrevNationalityFieldReason)) {
              setEditPrevNationalityReasonError(true);
              setEditPrevNationalityReasonHelperText(t("validators.special_chars"));
            } else {
              setEditPrevNationalityReasonError(false);
              setEditPrevNationalityReasonHelperText("");
            }
        }

        if(editPrevNationalityDateFieldNewValue === null || editPrevNationalityDateFieldNewValue === 'Invalid date'){
            setEditPrevNationalityDateError(true);
            setEditPrevNationalityDateHelperText(t("errors.is_valid_date_or_empty_msg"));
        } else if (moment(editPrevNationalityDateFieldNewValue) > todayIsDate) {
            setEditPrevNationalityDateError(true);
            setEditPrevNationalityDateHelperText(t("errors.current_date_greater_msg"));
        } else {
            const PrevNationality = props.prevNationalityState;
            const dateType = props.selectedPrevNationalityToEdit.selectedFieldId;
            const {isValid, errorMessage} = compareDates(editPrevNationalityDateFieldNewValue, PrevNationality, dateType);
    
            setEditPrevNationalityDateError(isValid);
            setEditPrevNationalityDateHelperText(errorMessage);
        }
    }, [editPrevNationalityDateFieldNewValue, 
        t,
        props.prevNationalityState, 
        props.selectedPrevNationalityToEdit.selectedFieldId, 
        compareDates, 
        editPrevNationalityFieldReason, 
        editPrevNationalityFieldNewValue]);

    const renderInputComponent = () => {
        switch(props.selectedPrevNationalityToEdit.selectedFieldId!) {
            case "from":
            case "to":
                return <GenericDatePicker
                    variant="inline"
                    label={t("common.birthdate_formatted")}
                    id="date-picker-inline"
                    inputVariant="outlined"
                    required
                    value={editPrevNationalityDateFieldNewValue}
                    onChangeValue={handlePrevNationalityDateChange(props.selectedPrevNationalityToEdit.selectedFieldId)}
                    format={Constants.DATE_FORMAT_CALENDAR}
                    fullWidth={true}
                    enableFuture={false}
                    error={editPrevNationalityDateError}
                    helperText={editPrevNationalityDateHelperText}
                ></GenericDatePicker>
            case "nationality":
                return <>
                        <GenericSelect
                            id="nationality"
                            value={editPrevNationalityFieldNewValue}
                            dataSet={nationalitiesData}
                            fullWidth={true}
                            handleChange={handleEditPrevNationalityFieldChange("nationality")}
                            multiple={false}
                            freeSolo={false}
                            label={t("prev_nationality.prev_nationality")}
                            renderChip={false}
                            required={true}
                            error={editPrevNationalityFieldError}
                        />
                        <FormHelperText className="helper-text-error MuiFormHelperText-contained">
                            {editPrevNationalityFieldHelperText}
                        </FormHelperText>
                    </>
            default:
                return <></>
        }
    }

    const acceptEditPrevNationalityChange = () => {
        setLoading(true);
        var path, newVal: any = "";
        path = "personal.previousNationalities[" + props.selectedPrevNationalityToEdit.selectedFieldIndex + "]." + props.selectedPrevNationalityToEdit.selectedFieldId;
                
        switch(props.selectedPrevNationalityToEdit.selectedFieldId) {
            case "to":
            case "from":
                newVal = moment(editPrevNationalityDateFieldNewValue).format(Constants.DATE_FORMAT_DISPLAY);
                break;
            case "nationality":
                newVal = editPrevNationalityFieldNewValue;
                break;
            default:
                path = "";
                newVal = "";
                break;
        }
        let patchRequest: IProfilePatchRequest = {
            path: path,
            newValue: newVal,
            reason: editPrevNationalityFieldReason
        }
        setPatchRequest(patchRequest);
    }

    return (
        <>
            <GenericModal
                heading={t("prev_nationality.title")}
                onCloseModal={(e?: EventTarget) => onEditPrevMationalityModalClose(e)}
                open={props.isEditIconPrevNationalityClicked && isEditClicked}
                closeOnBackDrop={true}
                modalWidth={"500px"}
                modalHeight={"400px"}
                modalTop={"15%"}
                className="personal-info-edit-modal"
            >
            <div className="personal-info-edit-modal">
                {loading ? (
                <div style={{ marginTop: "100px", textAlign: "center" }}>
                    <GenericLoader />
                    <div className="loading-text">{t("common.saving")}</div>
                </div>
                ) : (
                <>
                <div className="fullWidth">
                    <GenericDataShow
                        label={props.selectedPrevNationalityToEdit.selectedFieldTitle}
                        value={(props.selectedPrevNationalityToEdit.selectedFieldId === "from" || 
                         props.selectedPrevNationalityToEdit.selectedFieldId === "to") ?
                         moment(props.selectedPrevNationalityToEdit.selectedFieldValue).format(Constants.DATE_FORMAT_DISPLAY) : 
                         props.selectedPrevNationalityToEdit.selectedFieldValue
                        }
                        isSelectField={(props.selectedPrevNationalityToEdit.selectedFieldId === "nationality")}
                    />
                </div>
                <div className="fullWidth">
                    {renderInputComponent()}
                </div>
                <div className="fullWidth">
                    <GenericTextField
                        label={t("common.reason_for_change")}
                        name={"reasonForChange"}
                        type="text"
                        id={"reasonForChange"}
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                        required
                        value={editPrevNationalityFieldReason}
                        inputRef={oneRef}
                        onChangeValue={handleEditPrevNationalityReasonChange}
                        error={editPrevNationalityReasonError}
                        helperText={editPrevNationalityReasonHelperText}
                    />
                </div>
                <div className="fullWidth" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <GenericButton
                        onClick={() => {
                            acceptEditPrevNationalityChange();
                        }}
                        disabled={(props.selectedPrevNationalityToEdit.selectedFieldId === "from" || props.selectedPrevNationalityToEdit.selectedFieldId === "to") ? 
                            (editPrevNationalityDateError || editPrevNationalityReasonError) :
                            (editPrevNationalityFieldError || editPrevNationalityReasonError)}
                        type="primary"
                        buttonText={t("common.save")}
                    ></GenericButton>
                </div>
                </>
                )}
            </div>
            </GenericModal>
        </>
    )
}