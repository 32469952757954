import "./index.scss";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import { useHistory } from "react-router-dom";
import IProfile from "../../interfaces/IProfile";
import PersonalInformation from "../PersonalInfo";
import AddressHistory from "../AddressHistory";
import { useAuth0 } from "@auth0/auth0-react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from "react-i18next"
import { useState } from "react";
import GenericAlert from "../../Components/GenericAlert";

export default function WorkerProfile() {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  var profileDtls: IProfile = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA);

  const handleGenericError = (message?: string) => {
    setShowError(true);
    setErrorMessage(message!);
  }

  return (
    <div id="profile-wrapper" className="main-wrapper">
      <div className="header">
        <div className="title">{t("profile.greet_user", {user: user?.name})}
        </div>
      </div>
      {(profileDtls === null || profileDtls === undefined) ? (
        <div className="profile-container">
            <div id="personal-info" className="personal-info">
                <div className="personal-info-body">
                    <div className="personal-info-body-container">
                        <div className="personal-info-body-container-title" style={{borderBottom: "none"}}>{t("profile.no_details_found")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      ) : (
          <>
      <div className="profile-container">
        <PersonalInformation isProfileView={false} isCheckDataEditable={true} errorHandler={handleGenericError} />
      </div>
      <div className="profile-container">
        <AddressHistory isProfileView={false} isCheckDataEditable={true} errorHandler={handleGenericError} />
      </div>
      </>
      )}
      {showError ? 
        <GenericAlert
        showButtons={true}
        showSecondaryBtn={false}
        error={true}
        primaryButtonText={t("common.ok")}
        onCloseModal={() => {
          setShowError(false);
          setErrorMessage("");
        }}
        onPrimaryButtonClicked={() => {
          setShowError(false);
          setErrorMessage("");
        }}
        open={showError}
        modalHeight="250px"
        modalWidth="550px"
        modalMargin="200px auto"
      >
        <div className="modal-input">
          {errorMessage && errorMessage !== "" ? errorMessage : t("errors.error_msg")}
        </div>
      </GenericAlert>
      : ""}
    </div>
  );

}
    