import { BrowserRouter, Switch } from "react-router-dom";
import "./routes.scss";
import Dashboard from "../screens/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import LogoutButton from "../Auth/Logout";
import Layout from "../Components/Layout";
import DBSCompliance from "../screens/DBSCompliance";
import DVLACompliance from "../screens/DVLACompliance";
import { IDComplianceScreen } from "../screens/IDCompliance";
import RoleDetails from "../screens/RoleDetails";
import Constants from "../Constants/constants";
import WorkerProfile from "../screens/Profile";
import Convictions from "../screens/Convictions";
import WorkedAbroad from "../screens/WorkedAbroad";
import { reactPlugin } from "../appInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import NonProtectedRoute from "./NonProtectedRoute";
import PrivacyPolicy from "../screens/privacyPolicy";
import CookiePolicy from "../screens/cookiePolicy";
import { RTWComplianceScreen } from "../screens/RTWCompliance";
import { IsCheckInProgress } from "../utils/referenceDataService";
import { ComplianceTypes } from "../Constants/enums";
import Payment from "../screens/Payment";
import GenericError from "../Components/GenericError";

function Routes() {
  return (
    <BrowserRouter>
      <div className="routes-container">
        <Layout>
          <AppInsightsErrorBoundary
            onError={() => (<GenericError />)}
            appInsights={reactPlugin}
          >
            <Switch>
              <ProtectedRoute
                exact
                path="/"
                component={Dashboard}
              />
              <ProtectedRoute
                exact
                path={Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD}
                component={Dashboard}
              />
              <ProtectedRoute
                exact
                path={Constants.COMPLIANCE_FORM_ROUTES.ROLEDETAILS + ":roleId/"}
                component={RoleDetails}
              />
              {!IsCheckInProgress(ComplianceTypes.DBS) && (
                <ProtectedRoute
                  exact
                  path={Constants.COMPLIANCE_FORM_ROUTES.DBS + ":checkId/"}
                  component={DBSCompliance}
                />
              )}
              {!IsCheckInProgress(ComplianceTypes.BasicDBS) && (
                <ProtectedRoute
                  exact
                  path={Constants.COMPLIANCE_FORM_ROUTES.BASICDBS + ":checkId/"}
                  component={DBSCompliance}
                />
              )}
              {!IsCheckInProgress(ComplianceTypes.DVLA) && (
                <ProtectedRoute
                  exact
                  path={Constants.COMPLIANCE_FORM_ROUTES.DVLA + ":checkId/"}
                  component={DVLACompliance}
                />
              )}
              {!IsCheckInProgress(ComplianceTypes.IdentityCheck) && (
                <ProtectedRoute
                  exact
                  path={Constants.COMPLIANCE_FORM_ROUTES.IDENTITYCHECK + ":checkId/"}
                  component={IDComplianceScreen}
                />
              )}
              <ProtectedRoute
                exact
                path={Constants.COMPLIANCE_FORM_ROUTES.PROFILE}
                component={WorkerProfile}
              />
              <ProtectedRoute
                exact
                path="/logout"
                component={LogoutButton}
              />
              <ProtectedRoute
                exact
                path={Constants.COMPLIANCE_FORM_ROUTES.CONVICTIONS}
                component={Convictions}
              />
              <ProtectedRoute
                exact
                path={Constants.COMPLIANCE_FORM_ROUTES.WORK_ABROAD}
                component={WorkedAbroad}
              />
              <NonProtectedRoute
                exact
                path={Constants.NON_PROTECTED_ROUTES.COOKIE_POLICY}
                component={CookiePolicy}
                authenticated={false}
              />
              <NonProtectedRoute
                exact
                path={Constants.NON_PROTECTED_ROUTES.PRIVACY_POLICY}
                component={PrivacyPolicy}
                authenticated={false}
              />
              {!IsCheckInProgress(ComplianceTypes.IdentityAndRWAudit) && (
                <ProtectedRoute
                  exact
                  path={Constants.COMPLIANCE_FORM_ROUTES.RTW + ":checkId/"}
                  component={RTWComplianceScreen}
                />
              )}
              <ProtectedRoute
                  exact
                  path={Constants.COMPLIANCE_FORM_ROUTES.PAYMENT + ":checkId/"}
                  component={Payment}
                />
            </Switch>
          </AppInsightsErrorBoundary>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default Routes;
