import "./index.scss";
import { useTranslation } from "react-i18next";
import Constants from "../../Constants/constants";

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <>
      <div className="footer-wrapper">
        <div className="content-text" aria-label={"Copyright " + year + " Talent Clouds. All Rights Reserved."}>
            © {year}&nbsp;<b>Talent Clouds</b>. {t("footer.all_rights_reserved")}
        </div>
        <div className="footer-right">
            <a className="content-link pad-right" href={Constants.NON_PROTECTED_ROUTES.PRIVACY_POLICY} aria-label={t("footer.privacy_policy_link")}>{t("footer.privacy_policy_link")}</a>
            <a className="content-link" href={Constants.NON_PROTECTED_ROUTES.COOKIE_POLICY} aria-label={t("footer.cookie_policy_link")}>{t("footer.cookie_policy_link")}</a>
        </div>
      </div>
    </>
  );
};

export default Footer;
