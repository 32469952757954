import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import InfoTypes from "../../interfaces/InfoTypes";
import { useEffect, useState } from "react";
import { Checkbox, Typography } from "@material-ui/core";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import Constants from "../../Constants/constants";
import useAPIRequest from "../../Service/useAPIRequest";
import Heading from "../../Components/Heading";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "../../Components/LoaderModal";
import IDBSTerms from "../../interfaces/IDBSTerms";

type DBSTermsAndConditionsProps = {
  content: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
  isCheckDataEditable: boolean;
  handleSubmit?: (
    updatedProcessData?: IComplianceProcess,
    editor?: string
  ) => void;
  errorHandler?: (msg: string) => void;
  isSubmissionComplete?: boolean;
};

interface PostDBSTerms {
  processId: string;
  dbsTerms: IDBSTerms;
}

const InitialDBSTermsState: IDBSTerms = {
  completeAndTrueInformation: false,
  allowCountersigning: false,
  readDbsPrivacyPolicy: false,
  consentResultSharing: false,
  allAboveIsCorrect: false,
};

export default function DBSTermsAndConditions(
  props: DBSTermsAndConditionsProps
) {
  const { t } = useTranslation();
  const [tncRequest, setTnCRequest] = useState<any>();
  const [dbsTerms, setDBSTerms] = useState<IDBSTerms>(InitialDBSTermsState);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const SendPutTnCData = async (tnCRequest: PostDBSTerms) => {
    setLoading(true);
    let url = `${Constants.API_URLS.DBS_SUFFIX}${props.complianceProcessData.id}${Constants.API_URLS.POST_DBS_TERMS}`;

    useAPIRequest(url, {
      method: "POST",
      body: tnCRequest,
    })
      .then((res) => {
        if (res.ok) {
          setLoading(false);
          if (props.handleSubmit) {
            let updatedProp = props.complianceProcessData;
            updatedProp.dbsTerms = tnCRequest.dbsTerms;

            props.handleSubmit(
              updatedProp,
              Constants.COMPLIANCE_STEPS_EDITORS.DBS_TERMS
            );
          }
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        if (props.errorHandler) {
          props.errorHandler(t("errors.save_error_msg"));
        }
      });
  };

  useEffect(() => {
    async function SendPutTnCDataRequest() {
      if (tncRequest) {
        await SendPutTnCData(tncRequest);
      }
    }
    SendPutTnCDataRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tncRequest]);

  useEffect(() => {
    setLoading(true);
    if (props.complianceProcessData && props.complianceProcessData.dbsTerms) {
      setDBSTerms(props.complianceProcessData.dbsTerms);
    } else {
      setDBSTerms(InitialDBSTermsState);
    }
    setLoading(false);
  }, [props.complianceProcessData]);

  const handleSubmit = () => {
    let prepDBSTermsData: PostDBSTerms = {
      processId: props.complianceProcessData.id,
      dbsTerms: dbsTerms,
    };

    setTnCRequest(prepDBSTermsData);
    setDisabled(true);
  };

  const handleCheckResponse = (prop: keyof IDBSTerms) => {
    setDBSTerms({
      ...dbsTerms,
      [prop]: !dbsTerms[prop],
    });
  };

  useEffect(() => {
    if(props.isSubmissionComplete) setDisabled(false);
  }, [props.isSubmissionComplete])

  return (
    <div
      id="dbs-terms-conditions"
      className="dbs-terms-conditions editor-component"
    >
      <div className="dbs-terms-conditions-body">
        <div className="dbs-terms-conditions-body-container">
          <Heading
            title={
              props.content && props.content!.title !== ""
                ? props.content!.title
                : t("dbs_terms_conditions.title")
            }
          />
          <div className="dbs-terms-conditions-body-container-details-rows">
            <div className="dbs-terms-conditions-body-container-details-rows-row">
              {props.content &&
              props.content.content &&
              props.content.content !== "" ? (
                <div className="dbs-terms-conditions-body-container-details-row-content">
                  {props.content.content !== "" ? (
                    <div
                      className="editor-component-body"
                      dangerouslySetInnerHTML={{
                        __html: props.content.content.replace(
                          /(<? *script)/gi,
                          "illegalscript"
                        ),
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="dbs-terms-conditions-body-container-details-rows-row">
              <div className="dbs-terms-conditions-body-container-details-row-content">
                <div
                  className="dbs-terms-conditions-body-container-details-row-content editor-component-body check-box-ctrls"
                  style={{ float: "none" }}
                >
                  <>
                    <Typography
                      className="labels"
                      key={"t-completeAndTrueInformation"}
                    >
                      <Checkbox
                        key={"completeAndTrueInformation"}
                        value={dbsTerms.completeAndTrueInformation}
                        id={"completeAndTrueInformation"}
                        checked={dbsTerms?.completeAndTrueInformation}
                        disabled={!props.isCheckDataEditable}
                        onClick={() =>
                          handleCheckResponse("completeAndTrueInformation")
                        }
                      />
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "dbs_terms_conditions.checkbox_ctrls.consent1"
                          ).replace(/(<? *script)/gi, "illegalscript"),
                        }}
                      />
                    </Typography>
                    <Typography
                      className="labels"
                      key={"t-allowCountersigning"}
                    >
                      <Checkbox
                        key={"allowCountersigning"}
                        value={dbsTerms.allowCountersigning}
                        id={"allowCountersigning"}
                        checked={dbsTerms?.allowCountersigning}
                        disabled={!props.isCheckDataEditable}
                        onClick={() =>
                          handleCheckResponse("allowCountersigning")
                        }
                      />
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "dbs_terms_conditions.checkbox_ctrls.consent2"
                          ).replace(/(<? *script)/gi, "illegalscript"),
                        }}
                      />
                    </Typography>
                    <Typography
                      className="labels"
                      key={"t-readDbsPrivacyPolicy"}
                    >
                      <Checkbox
                        key={"readDbsPrivacyPolicy"}
                        value={dbsTerms.readDbsPrivacyPolicy}
                        id={"readDbsPrivacyPolicy"}
                        checked={dbsTerms?.readDbsPrivacyPolicy}
                        disabled={!props.isCheckDataEditable}
                        onClick={() =>
                          handleCheckResponse("readDbsPrivacyPolicy")
                        }
                      />
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "dbs_terms_conditions.checkbox_ctrls.consent3"
                          ).replace(/(<? *script)/gi, "illegalscript"),
                        }}
                      />
                    </Typography>
                    <Typography
                      className="labels"
                      key={"t-consentResultSharing"}
                    >
                      <Checkbox
                        key={"consentResultSharing"}
                        value={dbsTerms.consentResultSharing}
                        id={"consentResultSharing"}
                        checked={dbsTerms?.consentResultSharing}
                        disabled={!props.isCheckDataEditable}
                        onClick={() =>
                          handleCheckResponse("consentResultSharing")
                        }
                      />
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "dbs_terms_conditions.checkbox_ctrls.consent4"
                          ).replace(/(<? *script)/gi, "illegalscript"),
                        }}
                      />
                    </Typography>
                    <Typography className="labels" key={"t-allAboveIsCorrect"}>
                      <Checkbox
                        key={"allAboveIsCorrect"}
                        value={dbsTerms.allAboveIsCorrect}
                        id={"allAboveIsCorrect"}
                        checked={dbsTerms?.allAboveIsCorrect}
                        disabled={!props.isCheckDataEditable}
                        onClick={() => handleCheckResponse("allAboveIsCorrect")}
                      />
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "dbs_terms_conditions.checkbox_ctrls.consent5"
                          ).replace(/(<? *script)/gi, "illegalscript"),
                        }}
                      />
                    </Typography>
                  </>
                </div>
                <div
                  className="dbs-terms-conditions-body-container-details-row-content editor-component-body"
                  style={{ float: "none" }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("dbs_terms_conditions.close_screen").replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="dbs-terms-conditions-body-container-details-rows-row">
              <div className="dbs-terms-conditions-body-container-details-rows-row-content">
                <div
                  className="dbs-terms-conditions-body-container-details-rows-row-content">
                  <div className="container-submit-btn">
                    {props.isCheckDataEditable ? (
                      <GenericButton
                        onClick={handleSubmit}
                        disabled={
                          !(
                            dbsTerms.allAboveIsCorrect &&
                            dbsTerms.allowCountersigning &&
                            dbsTerms.completeAndTrueInformation &&
                            dbsTerms.consentResultSharing &&
                            dbsTerms.readDbsPrivacyPolicy
                          ) || isDisabled
                        }
                        type="primary"
                        buttonText={isDisabled ? t("common.processing") : t("common.submit_request")}
                      ></GenericButton>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoaderModal isLoading={loading || isDisabled} />
      </div>
    </div>
  );
}
