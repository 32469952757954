import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { DrawerItem } from "./DrawerItemType";
import "./index.scss";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      right: "0%",
      top: "0%",
      bottom: "0%",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "18px",
      display: "flex",
      paddingLeft: 100,
      paddingBottom: 30,
      color: "#757575",
    },
    selected: {
      span: {
        color: "#2D7FF9"
      },
      svg: {
        fill: "#2D7FF9"
      },
      backgroundColor: "transparent",
      color: "#2D7FF9"
    },
    listIcon: {
      minWidth: "auto",
    }
  })
);

type Props = DrawerItem & {
  selected?: boolean;
  onClick?: () => void;
};

const MenuItem: React.FC<Props> = ({
  route,
  literal,
  Icon,
  selected,
  onClick,
  styleName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const link = (
    <div className="menu">
    <ListItem
      button
      selected={selected}
      classes={{
        selected: classes.selected,
        button: classes.button,
      }}
      disableRipple={true}
      onClick={onClick}
    >
      {(Icon) ?  
      <ListItemIcon className={classes.listIcon}>
        <Icon className={(styleName) ? styleName + "menu-item-icon" : "menu-item-icon"} />
      </ListItemIcon> : "" }
      <ListItemText className={(styleName) ? styleName + "menu-item-text" : "menu-item-text"} primary={t(literal)} />
    </ListItem>
    </div>
  );

  return route ? <Link to={route}>{link}</Link> : link;
};

export default MenuItem;
