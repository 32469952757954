import "../../VerifyIdentity/index.scss";
import { useTranslation } from "react-i18next";
import IImportableDigitalData from "../../../interfaces/IImportableDigitalData";

interface DigitalIdentityDataPersonalProps {
  digitalIdentityData: IImportableDigitalData;
}

export default function DigitalIdentityDataPersonal(props: DigitalIdentityDataPersonalProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="fullWidth data-fields">
        <div
          className="fullWidth data-field-title"
          aria-label={t("personal_information.title")}
        >
          {t("personal_information.title")}:
        </div>
      </div>
      <div className="fullWidth data-fields">
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("profile_details.fullname")}
          >
            {t("profile_details.fullname")}:&nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentName?.fullName!}
            </div>
          </div>
        </div>
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("profile_details.given_names")}
          >
            {t("profile_details.given_names")}:&nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentName?.givenNames!}
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidth data-fields">
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("common.forename")}
          >
            {t("common.forename")}:&nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentName?.firstName!}
            </div>
          </div>
        </div>
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("common.middle_name")}
          >
            {t("common.middle_name")}:&nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentName?.middleName!}
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidth data-fields">
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("common.surname")}
          >
            {t("common.surname")}: &nbsp;&nbsp;
            <div className="data-show-value">
              {props.digitalIdentityData?.currentName?.familyName!}
            </div>
          </div>
        </div>
        <div className="oneHalfWidth">
          <div
            className="data-show-label"
            aria-label={t("common.birthdate")}
          >
            {t("common.birthdate")}:&nbsp;&nbsp;
            <div className="data-show-value">{props.digitalIdentityData?.dateOfBirth!}</div>
          </div>
        </div>
      </div>
    </>
  );
}
