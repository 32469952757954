import React, { useEffect, useState } from "react";
import "./index.scss";
import Constants from "../../Constants/constants";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoTypes from "../../interfaces/InfoTypes";
import { Stepper, Step, StepLabel, StepContent, IconButton, makeStyles } from "@material-ui/core";
import { useApi } from "../../Auth/UseApi";
import { GenericLoader } from "../../Components/GenericLoader";
import IComplianceContent from "../../interfaces/IComplianceContent";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IFeedbackRating from "../../interfaces/IFeedbackRating";
import useAPIRequest from "../../Service/useAPIRequest";
import CheckIntro from "../CheckIntro";
import CheckOutro from "../CheckOutro";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import PersonalInformation from "../PersonalInfo";
import {GetComplianceTypeUIContent, GetCountriesData, GetNationalitiesData, GetTitlesData} from "../../utils/referenceDataService";
import { useTranslation } from "react-i18next"
import { ProcessStatus } from "../../Constants/enums";
import GenericAlert from "../../Components/GenericAlert";

type DVLAComplianceParams = {
  checkId: string
}

const useStyles = makeStyles(() => ({
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function DVLACompliance() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [feedback, setFeedback] = useState<IFeedbackRating>();
    const { checkId } = useParams<DVLAComplianceParams>();  
    const dvlaUIContent: IComplianceContent = GetComplianceTypeUIContent("DVLA");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    if (dvlaUIContent) {
      if (dvlaUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.INTRO}) === -1) {
  
        dvlaUIContent.steps.splice(0, 0, {
          title: dvlaUIContent.intro.title,
          content: dvlaUIContent.intro.content,
          detail: dvlaUIContent.intro.detail,
          editor: Constants.COMPLIANCE_STEPS_EDITORS.INTRO
        })
      }
      if (dvlaUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.OUTRO}) === -1) {

        dvlaUIContent.steps.push({
          title: dvlaUIContent.outro.title,
          content: dvlaUIContent.outro.content,
          detail: dvlaUIContent.outro.detail,
          editor: Constants.COMPLIANCE_STEPS_EDITORS.OUTRO
        })
      }
    }

    GetCountriesData();
    GetNationalitiesData();
    GetTitlesData();
  
    const handleNextStep = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBackStep = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSubmitRequest = (feedback: IFeedbackRating) => {
      setFeedback(feedback);
    }
  
    const SubmitFeedback = async (feedback: IFeedbackRating) => {
      useAPIRequest(Constants.API_URLS.POST_FEEDBACK_RATING, {
        method: "POST",
        body: feedback
      })
    }
  
    useEffect(() => {
      async function SubmitFeedbackRequest() {
          if(feedback) {
              await SubmitFeedback(feedback);
          }
      }
      SubmitFeedbackRequest();
    }, [feedback]); 
  
    var { loading: processing, error, data } = useApi<IComplianceProcess>(Constants.API_URLS.GET_WORKER_ROLE_COMPLIANCE + checkId);
    var complianceProcessData: IComplianceProcess;
    var isCheckEditable: boolean;
    if (processing) {
      return <GenericLoader />;
    }
  
    if (error) {
      if (error instanceof Error) {
          return <div>{t("errors.something_wrong_err", {error: error?.message})}</div>;
      }
      return <div>{t("errors.something_wrong")}{t("errors.please_refresh")}</div>;
    } else {
      if (data) {
        complianceProcessData = data;
        isCheckEditable = (complianceProcessData.status === ProcessStatus.WaitingForWorker);
      }
    }

    const loadStepContainer = (editor: string) => {
      switch(editor) {
        case Constants.COMPLIANCE_STEPS_EDITORS.INTRO:
          return <CheckIntro content={dvlaUIContent.intro}  />
        case Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO:
          return <PersonalInformation uiContent={dvlaUIContent.steps[dvlaUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO})]} complianceProcessData={complianceProcessData} isProfileView={true} handleSubmit={handleNextStep} isCheckDataEditable={isCheckEditable} />
        case Constants.COMPLIANCE_STEPS_EDITORS.OUTRO:
          return <CheckOutro content={dvlaUIContent.outro} complianceProcessData={complianceProcessData} handleSubmit={handleSubmitRequest} isCheckDataEditable={isCheckEditable} />
        default:
          return <></>
      }
    }

    const handleGenericError = (message?: string) => {
      setShowError(true);
      setErrorMessage(message!);
    }

    return (
      <div id="dvla-comp-wrapper" className="main-wrapper">
        <div className="header">
          <div className="title">{Constants.COMPLIANCE_TYPES_DISPLAY_VALUE.DVLA} {"  "}
          </div>
        </div>
      <>
      <div className="compliance-container">
        <div className="threeFourthWidth">
          <div className="container-content">
            {(activeStep !== 0) ? 
            <IconButton color="inherit" className={classes.disableRipple}
              disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
              onClick={() => handleBackStep()}>
              <ArrowBackIcon fontSize="large" />
            </IconButton> : "" }
            <div className="container-content-form" style={{paddingLeft: (activeStep === 0) ? "58px" : "0px"}}>
              {loadStepContainer(dvlaUIContent.steps[activeStep].editor!)}
            </div>
            {(activeStep !== dvlaUIContent.steps.length - 1) ? 
            <IconButton color="inherit" className={classes.disableRipple}
              disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
              onClick={() => handleNextStep()}>
              <ArrowForwardIcon fontSize="large" />
            </IconButton> : "" }
          </div>
        </div>
        <div className="oneFourthWidth">
          <div className="centralize-content">
            <Stepper activeStep={activeStep} orientation="vertical" className="stepper-content">
              {dvlaUIContent.steps.map((step, index) => (
                <Step key={step.title} className="step-label">
                  <StepLabel className="step-label">
                    {step.title}
                  </StepLabel>
                  <StepContent>
                    <div className="step-label-desc">
                      <div dangerouslySetInnerHTML={{__html: step.detail.replace(/(<? *script)/gi, 'illegalscript')}} >
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      </div>
      </>
      {showError ? 
        <GenericAlert
        showButtons={true}
        showSecondaryBtn={false}
        error={true}
        primaryButtonText={t("common.ok")}
        onCloseModal={() => {
          setShowError(false);
          setErrorMessage("");
        }}
        onPrimaryButtonClicked={() => {
          setShowError(false);
          setErrorMessage("");
        }}
        open={showError}
        modalHeight="250px"
        modalWidth="550px"
        modalMargin="200px auto"
      >
        <div className="modal-input">
          {errorMessage && errorMessage !== "" ? errorMessage : t("errors.error_msg")}
        </div>
      </GenericAlert>
      : ""}
    </div>
    );
  }
