import { useState, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { GenericLoader } from "../../Components/GenericLoader";
import InfoTypes from "../../interfaces/InfoTypes";
import CardDetails from "./CardDetails";
import "./index.scss";
import OrderItems from "./OrderItems";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';
import useAPIRequest from "../../Service/useAPIRequest";
import Constants from "../../Constants/constants";
import { useParams } from "react-router-dom";
import GenericError from "../../Components/GenericError";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";

const useStyles = makeStyles({
  paymentTitle: {
    fontFamily: 'Montserrat-bold',
    paddingLeft: 15,
    fontSize: 32,
    paddingBottom: 20,
  },
  mainSection: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  borderRight500: {
    borderRight: isMobileOnly ? 'none' : '1px solid #e0e0e0'
  },
  borderTop500: {
    borderTop: isMobileOnly ? '1px solid #e0e0e0' : 'none'
  },
  contentWithPadding: {
   padding: 30
  },
  formControlLabel: {
    fontWeight: 'normal',
  },
  attributes: {
    fontWeight: 'bold',
  }
});

const stripePromise = loadStripe(`${process.env.REACT_APP_PAYMENT_STRIPE}`);

export interface AttributeProps {
  class: string;
  text?: string;
}
export const Attribute = (props: AttributeProps) => { 
  return <span className={props.class}>{props.text}{' '}</span>;
};

type PaymentParams = {
  checkId: string
}

export default function Payment() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { checkId } = useParams<PaymentParams>();
  
  const [totalPriceOrderItems, setTotalPriceOrderItems] = useState(0);
  const [isOrderFilled, setIsOrderFilled] = useState(false);
  const [error, setError] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
 
  const [roleCheckOrder, setRoleCheckOrder] = useState<InfoTypes.RoleCheckOrder>();
  const [roleCheckOrderMinimized, setRoleCheckOrderMinimized] = useState<InfoTypes.RoleCheckOrderMinimized>();

  useEffect(() => {
    async function SendRefreshRequest() {
        await GetOrderInfos();
        if(isOrderFilled) await GetClientSecretKey();
    }
    SendRefreshRequest();
  }, [isOrderFilled]);

  const GetOrderInfos = async () => {
    useAPIRequest(
      Constants.API_URLS.GET_WORKER_ROLE_COMPLIANCE + checkId + Constants.API_URLS.PAYMENT
    ,{
      method: "GET",
      body: "",
    }).then((res) => {
      let total = res.items.reduce((a: number,v: { price: number; }) =>  a = a + v.price , 0 );
      const orders: InfoTypes.RoleCheckOrderMinimized = {
        orderId: res.orderId,
        items: res.items,
        receiptEmail: res.receiptEmail,
        checkId: checkId,
      };
      setTotalPriceOrderItems(total);
      setRoleCheckOrder(res);
      setRoleCheckOrderMinimized(orders);
      setIsOrderFilled(true);
    }).catch((error) => {
      setError(error);
    })
  };

  const GetClientSecretKey = async () => {
    useAPIRequest(Constants.API_URLS.POST_INTENT_STRIPE_SECRET_KEY, {
      method: "POST",
      body: roleCheckOrderMinimized,
    }).then((res) => {
      setClientSecret(res!.clientSecret);
    }).catch((error) => {
        setError(error);
    })
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  if(error) return <GenericError />
  
  return (!isOrderFilled || !clientSecret || !stripePromise) ? (
    <GenericLoader />
  ) : (
    <Elements stripe={stripePromise} options={options}>
      <Box className="main-wrapper" sx={{width: '100%',}}>
        <Grid container>
          <Grid item xs={12} >
            <Typography variant="h5" className={classes.paymentTitle}>{t("payment.title")}</Typography>
          </Grid>
        </Grid>
        <Grid container component={Paper} className={classes.mainSection}>
          <Grid item xs={12} md={5} className={classes.borderRight500}>
            <OrderItems items={roleCheckOrder?.items} total={totalPriceOrderItems} />
            <Divider />
            <CardDetails receiptEmail={roleCheckOrder!.receiptEmail}
              orders={roleCheckOrderMinimized!}
              clientSecret={clientSecret}
              checkId={checkId}
            />
          </Grid>
          <Grid item xs={12} md={7} className={classes.borderTop500}>
            <Typography className={classes.contentWithPadding} variant="subtitle1" gutterBottom>
              <Attribute class={classes.attributes} text={roleCheckOrder?.requesterName} />
              {t("common.from")}{' '}
              <Attribute class={classes.attributes} text={roleCheckOrder?.clientName} />
              {t("payment.request")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Elements>
  );
}


