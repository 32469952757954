import React from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomTextField: any = withStyles({
  root: {
    "& .MuiFormLabel-asterisk": {
      color: "#2d7ff9",
    },
    fontFamily: "Montserrat-Regular",
    "& label.Mui-focused": {
      color: "#757575",
      fontFamily: "Montserrat-Regular",
    },
    " & .MuiOutlinedInput-root": {
      height: "4px;",
    },
    "& label.MuiInputLabel-outlined": {
      color: "rgb(173, 184, 191)",
      fontFamily: "Montserrat-Regular",
      textAlign: "left !important",
      whiteSpace: "break-spaces",
    },
    "& .MuiFormControl-root ": {
      fontFamily: "Montserrat-Regular",
    },
    "& label.Mui-disabled": {
      color: "#ECECEC !important",
    },

    "& label.MuiInputLabel-shrink": {
      color: "#343C44",
      fontFamily: "Montserrat-Regular",
      lineHeight: "12px",
      fontSize: "14px",
      fontWeight: "600",
      letterSpacing: "0px",
      height: "12px",
    },
    "& input.MuiInputBase-input": {
      color: "#343C44",
      fontFamily: "Montserrat-Regular",
      fontSize: "14px",
      fontWeight: "600",
      height: "16px",
      letterSpacing: "0px",
      "&.Mui-disabled": {
        opacity: "0.72 !important",
      },
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-disabled": {
        opacity: "0.72 !important",
        border: "1px solid #E0E0E0",
        borderRadius: "10px",
      },
      "&input": {
        border: "2px solid #757575",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px rgb(255, 255, 255)inset",
        },
      },

      "& textarea": {
        overflow: "auto",
        height: "auto",
      },

      "& fieldset": {
        border: "1px solid #757575",
        borderRadius: "10px",
        "& legend": {
          "& span": {
            padding: "0px",
            fontFamily: "Montserrat-Medium",
          },
        },
        color: "red",
        borderColor: "#757575",
      },

      "&:hover fieldset": {
        // hover
        border: "2px solid #478FFA",
      },
      // focus active
      "&.Mui-focused fieldset": {
        border: "1px solid #478FFA",
      },
      "  MuiInputLabel": {
        outlined: {
          "&$shrink": {
            color: "#757575",
            transform: "translate(0px, 0px) scale(0.75)",
          },
        },
      },
    },
    "& .Mui-error": {
      "&.MuiFormHelperText-root.Mui-error": {
        color: "rgb(209, 0, 0)",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #D74D26",
        borderColor: "#D74D26",
      },
      "&:hover fieldset": {
        border: "1px solid rgb(209, 10, 67)",
      },
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgb(209, 0, 0)",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(209, 0, 0)",
    },
  },
})(TextField);

interface Props {
  label: string;
  name?: string;
  id: string;
  type: string; // html5 types "text" , "number", "tel" ...
  variant: string; // 'filled' | 'outlined' | 'standard'
  value?: string | null | number;
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>, val: string, index?: number) => void;
  error?: boolean;
  helperText?: string;
  checkCapsLock?: boolean;
  onCaps?: (val: boolean) => void;
  InputProps?: {
    endAdornment?: JSX.Element;
    inputComponent?: any;
  };
  margin?: string; // 'dense' | 'none'| 'normal'
  multiline?: boolean;
  placeholder?: string;
  rows?: string | number;
  rowsMax?: string | number;
  disabled?: boolean;
  inputProps?: {
    [key: string]: any;
  };
  fullWidth?: boolean;
  required?: boolean;
  inputRef?: React.Ref<any>;
  defaultValue?: string | null;
  className?: string;
  onblur?: string;
  onfocus?: string;
  InputLabelProps?: any;
}

const GenericTextField = (props: Props) => {
  const handleChange = (e: any, index?: number) => {
    props.onChangeValue(e.target.id, e.target.value, index);
  };

  const handleCaps = (event: any) => {
    if (props.checkCapsLock && event && event.getModifierState("CapsLock")) {
      if (props.onCaps) props.onCaps(true);
    } else {
      if (props.onCaps) props.onCaps(false);
    }
  };

  return (
    <CustomTextField
      onKeyPress={handleCaps}
      label={props.label}
      variant={props.variant}
      id={props.id}
      type={props.type}
      value={props.value}
      onChange={handleChange}
      error={props.error}
      helperText={props.helperText}
      InputProps={props.InputProps}
      inputProps={props.inputProps}
      disabled={props.disabled}
      margin={props.margin}
      multiline={props.multiline}
      placeholder={props.placeholder}
      minRows={props.rows}
      rowsMax={props.rowsMax}
      fullWidth={props.fullWidth}
      required={props.required}
      inputRef={props.inputRef}
      className={props.className}
      InputLabelProps={props.InputLabelProps}
    />
  );
};

export default GenericTextField;
