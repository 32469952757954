import "../VerifyIdentity/index.scss";
import GenericButton from "../../Components/GenericButton";
import { GenericLoader } from "../../Components/GenericLoader";
import YotiIcon from "../../Assets/yoti-icon.png";
import { isMobileOnly } from "react-device-detect";
import { ReactNode } from "react";

interface YotiDigitalIDButtonProps {
  message?: string | ReactNode;
  loading: boolean;
  checkYotiSession: () => void;
  isDisabled: boolean;
  buttonText: string;
  buttonMessage?: string;
}

export default function YotiDigitalIDButton(props: YotiDigitalIDButtonProps) {
  return (
    <>
      {props.message ? <span className="digital-id-message">{props.message}</span> : <></>}
      <div className="digital-id-btn">
        {props.loading ? (
          <GenericLoader />
        ) : (
          <GenericButton
            onClick={() => {
              props.checkYotiSession();
            }}
            disabled={props.isDisabled}
            style={{
              width: isMobileOnly ? "200px" : props.buttonMessage ? "250px" : "380px",
              height: isMobileOnly ? "80px" : "60px",
            }}
            type="primary"
            iconSrc={YotiIcon}
            iconClassName="yoti-icon"
            isIconButton={true}
            buttonText={props.buttonText}
          />
        )}
      </div>
    </>
  );
}
