import _ from "lodash";
import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import Constants from "../Constants/constants";
import { ComplianceTypes } from "../Constants/enums";
import IComplianceContent from "../interfaces/IComplianceContent";
import IComplianceProcess from "../interfaces/IComplianceProcess";
import IFeedbackRating from "../interfaces/IFeedbackRating";
import IPersonalDetails from "../interfaces/IPersonalDetails";
import IProfile from "../interfaces/IProfile";
import CheckIntro from "../screens/CheckIntro";
import CheckOutro from "../screens/CheckOutro";
import useAPIRequest from "../Service/useAPIRequest";
import LocalStorageService from "../utils/localStorageService";
import { GetComplianceTypeUIContent } from "../utils/referenceDataService";
import utilFunctions from "../utils/util";
import IValidationFailure from "../interfaces/IValidationFailure";
import IDVerifyIdentity from "../screens/IDCompliance/IDVerifyIdentity";
import InfoTypes from "../interfaces/InfoTypes";

export type IDCheckContextType = {
  complianceProcessData: IComplianceProcess | undefined;
  setComplianceProcessData: Dispatch<
    SetStateAction<IComplianceProcess | undefined>
  >;
  idUIContent: IComplianceContent;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isCheckEditable: boolean;
  setIsCheckEditable: Dispatch<SetStateAction<boolean>>;
  stepsContainer: JSX.Element;
  handleNextStep: (
    updatedProcessData?: IComplianceProcess,
    editor?: string
  ) => void;
  handleGenericError: (message?: string) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  complianceTypeSubmitData: IComplianceProcess | undefined;
  setComplianceTypeSubmitData: Dispatch<SetStateAction<IComplianceProcess | undefined>>;
  isSubmissionComplete: boolean;
  setSubmissionComplete: Dispatch<SetStateAction<boolean>>;
  isSubmissionError: boolean;
  setSubmissionError: Dispatch<SetStateAction<boolean>>;
  showError: boolean,
  setShowError: Dispatch<SetStateAction<boolean>>,
  confirmImportModalOpen: boolean;
  setConfirmImportModalOpen: Dispatch<SetStateAction<boolean>>;
  handleSaveError: (errors?: IValidationFailure[]) => void;
  idVerifyIdentityContent: InfoTypes.ContentDefinition;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>
};

type IDContextProviderProps = {
  children?: React.ReactNode;
};

export const IDCheckContext = createContext<IDCheckContextType | undefined>(undefined);

const IDContextProvider = ({ children }: IDContextProviderProps) => {
  const { t } = useTranslation();
  const idUIContent = GetComplianceTypeUIContent(
    ComplianceTypes.IdentityCheck
  );
  const [complianceProcessData, setComplianceProcessData] = useState<
    IComplianceProcess | undefined
  >();
  const [feedback, setFeedback] = useState<IFeedbackRating>();
  const [complianceTypeSubmitData, setComplianceTypeSubmitData] =
    useState<IComplianceProcess | undefined>(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const [isCheckEditable, setIsCheckEditable] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [personalRequest, setPersonalRequest] = useState<IComplianceProcess>(); //
  const [loading, setLoading] = useState(false);

  const [isSubmissionComplete, setSubmissionComplete] = useState(false);
  const [isSubmissionError, setSubmissionError] = useState(false);

  const [confirmImportModalOpen, setConfirmImportModalOpen] = useState(false);

  useEffect(() => {
    async function SubmitComplianceTypeDataRequest() {
      if (complianceTypeSubmitData) {
        if (complianceTypeSubmitData.complianceType.toLocaleLowerCase() === ComplianceTypes.IdentityCheck.toLowerCase()) {
            await SubmitIDComplianceData(complianceTypeSubmitData);
        }
      }
    }
    SubmitComplianceTypeDataRequest();
  }, [complianceTypeSubmitData]);

  const SubmitIDComplianceData = async (
    complianceData: IComplianceProcess
  ) => {
    let cleansedRequestObj =
      utilFunctions.cleanseComplianceTypeSubmitRequestObject(complianceData);
    useAPIRequest(
      Constants.API_URLS.POST_WORKER_COMPLETE_ID_CHECK_DATA_SUFFIX +
        complianceData.id +
        Constants.API_URLS.POST_WORKER_COMPLETE_DATA,
      {
        method: "POST",
        body: cleansedRequestObj,
      }
    )
      .then((res) => {
        if (res && res !== undefined) {
          if (res.ok) {
            setSubmissionError(false);
            setSubmissionComplete(true);
          } else {
            const errors: IValidationFailure[] = res;
            handleSubmitErrors(errors);
          }
        }
      })
      .catch(() => {
        handleSubmitErrors();
      });
  };

  const handleSubmitErrors = (errors?: IValidationFailure[]) => {
    if (errors) {
      let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";
      _.map(errors, (err) => {
        errorMsg = errorMsg.concat("<li>", err.errorMessage, "</li>");
      });
      errorMsg.concat("</ul>");
      setSubmissionError(true);
      setSubmissionComplete(true);
      setErrorMessage(errorMsg);
    } else {
      setErrorMessage(t("errors.save_error_msg"));
      setShowError(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    let personalData: IPersonalDetails;
    if (complianceProcessData) {

    } else {
      let profileDtls: IProfile = LocalStorageService.getData(
        Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
      );
    }

    setLoading(false);
  }, [complianceProcessData]);

  const handleGenericError = (message?: string) => {
    setShowError(true);
    setErrorMessage(message!);
  };

  const handleSubmitRequest = (
    feedback: IFeedbackRating,
    complianceData: IComplianceProcess
  ) => {
    setFeedback(feedback);
    if (complianceData) {
      setComplianceTypeSubmitData(complianceData);
    }
  };

  const handleNextStep = (
    updatedProcessData?: IComplianceProcess,
    editor?: string
  ) => {
    if (updatedProcessData) {
      if (complianceProcessData) {
        if (!complianceProcessData.processData) {
          complianceProcessData.processData = {};
        }
        let workerProfile: IProfile = LocalStorageService.getData(
          Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
        );
        if (!workerProfile) {
          workerProfile = {};
        }
        switch (editor!.toLowerCase().trim()) {
          case Constants.COMPLIANCE_STEPS_EDITORS.INTRO.toLowerCase():
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON.toLowerCase():
            complianceProcessData.processData.documents =
              updatedProcessData.processData.documents;
            workerProfile.documents = updatedProcessData.processData.documents;
            break;
          default:
            break;
        }
        LocalStorageService.setData(
          Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA,
          workerProfile
        );
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }; 

  const handleSaveError = (errors?: IValidationFailure[]) => {
    if (handleGenericError) {
      if (errors) {
        let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";
        _.map(errors, (err) => {
          errorMsg = errorMsg.concat("<li>", err.errorMessage, "</li>");
        });
        errorMsg.concat("</ul>");
        handleGenericError(errorMsg);
      } else {
        handleGenericError(t("errors.save_error_msg"));
      }
    }
  };

  if (idUIContent) {
    if (idUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.INTRO}) === -1) {
  
      idUIContent.steps.splice(0, 0, {
        title: idUIContent.intro.title,
        content: idUIContent.intro.content,
        detail: idUIContent.intro.detail,
        editor: Constants.COMPLIANCE_STEPS_EDITORS.INTRO
      })
    }
  
    if (idUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.OUTRO}) === -1) {
  
      idUIContent.steps.push({
        title: idUIContent.outro.title,
        content: idUIContent.outro.content,
        detail: idUIContent.outro.detail,
        editor: Constants.COMPLIANCE_STEPS_EDITORS.OUTRO
      })
    }
  }

  const loadStepContainer = (editor: string) => {
    switch (editor) {
      case Constants.COMPLIANCE_STEPS_EDITORS.INTRO:
        return (
          <CheckIntro
            content={idUIContent.intro}
          />
        );
      case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON:
        return <IDVerifyIdentity />
      case Constants.COMPLIANCE_STEPS_EDITORS.OUTRO:
        return (
          <CheckOutro
            content={idUIContent.outro}
            complianceProcessData={complianceProcessData!}
            handleSubmit={handleSubmitRequest}
            isCheckDataEditable={isCheckEditable}
          />
        );
      default:
        return <></>;
    }
  }; //
  const editorString = idUIContent.steps[activeStep].editor!;

  const stepsContainer = loadStepContainer(editorString);

  const idVerifyIdentityContent =
  idUIContent.steps[
    idUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => {
        return (
          s.editor === Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON
        );
      })
    ];
  
  return (
    <IDCheckContext.Provider
      value={{
        complianceProcessData,
        setComplianceProcessData,
        idUIContent,
        activeStep,
        setActiveStep,
        isCheckEditable,
        setIsCheckEditable,
        stepsContainer,
        handleNextStep,
        handleGenericError,
        loading,
        setLoading,
        complianceTypeSubmitData,
        setComplianceTypeSubmitData,
        isSubmissionComplete,
        isSubmissionError,
        setSubmissionComplete,
        setSubmissionError,
        showError,
        setShowError,
        confirmImportModalOpen,
        setConfirmImportModalOpen,
        handleSaveError,
        idVerifyIdentityContent,
        errorMessage,
        setErrorMessage
      }}
    >
      {children}
    </IDCheckContext.Provider>
  );
};

const useIDCheckContext = () => {
  const context = useContext(IDCheckContext);
  if (context === undefined) {
    throw new Error("Error");
  }
  return context;
};

export { IDContextProvider, useIDCheckContext };
