import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useState, useContext, useEffect } from "react";

export type IntercomType = {
  email?: string;
  name?: string;
};

type IntercomProviderProps = {
  children?: React.ReactNode;
  appId: string;
};

export const Intercom = createContext<IntercomType | undefined>(undefined);

const IntercomProvider = ({ children, appId }: IntercomProviderProps) => {
  const [intercom, setIntercom] = useState<IntercomType>();
  const { user } = useAuth0();

  useEffect(() => {
    (function () {
      var w = window;
      //@ts-ignore
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        //@ts-ignore
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          //@ts-ignore
          i.c(arguments);
        };
        //@ts-ignore
        i.q = [];
        //@ts-ignore
        i.c = function (args) {
          //@ts-ignore
          i.q.push(args);
        };
        //@ts-ignore
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + appId;
          var x = d.getElementsByTagName("script")[0];
          //@ts-ignore
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
          //@ts-ignore
        } else if (w.attachEvent) {
          //@ts-ignore
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
    setIntercom({});
  }, []);
  useEffect(() => {
    if (user) {
      //@ts-ignore
      window.Intercom("boot", {
        app_id: appId,
        email: user.email,
        name: user.name,
      });
    }
  }, [user, intercom]);

  return <Intercom.Provider value={intercom}>{children}</Intercom.Provider>;
};

const useIntercom = () => {
  const context = useContext(Intercom);
  if (context === undefined) {
    throw new Error("Error");
  }
  return context;
};

export { IntercomProvider, useIntercom };
