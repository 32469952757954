
import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import GenericModal from "../../Components/GenericModal";
import { useTranslation } from "react-i18next"
import Constants from "../../Constants/constants";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from "react";


const useStyles = makeStyles({
    wrapIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    iconPosition: {
        position: 'relative',
        top: 4, marginRight: 8
    },
    textSuccess: {
      color: 'rgb(46, 125, 50)!important'
    },
    textDanger: {
      color: 'rgb(211, 47, 47)'
    }
});

export interface ProcessingModalProps {
    isProcessing: boolean;
    messageHeader: string;
    message: string;
    isError: boolean;
}

export interface HeaderModalProps {
    text: string;
    status: boolean;
}

export const ModalHeader = (props: HeaderModalProps) => { 
    const classes = useStyles();
    return <div className={classes.wrapIcon}>
        <span className={!props.status ? classes.textSuccess : classes.textDanger}>
            {!props.status ? <CheckCircleOutlineIcon className={classes.iconPosition}/> : <WarningAmberIcon className={classes.iconPosition} />}
            {props.text}
        </span>
    </div>
};

export default function CheckoutProcessingModal (props: ProcessingModalProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 5000);
    }, []);

    return (
        <>
            <GenericModal
                heading={<ModalHeader 
                    text={props.messageHeader} 
                    status={props.isError} 
                />}
                onCloseModal={() => void 0}
                open={props.isProcessing}
                closeOnBackDrop={true}
                modalWidth={"500px"}
                modalHeight={"200px"}
                modalTop={"25%"}
                className="payment-status-check-modal"
                hideModalClose={true}
            >
                <div className="payment-status-check-modal">
                    <div className="fullWidth">
                        <Typography variant="subtitle1">{props.message}</Typography>
                    </div>
                    <div className="fullWidth" style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <GenericButton
                            onClick={() => {
                              history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
                            }}
                            type="primary"
                            buttonText={disabled ? t("common.wait") : t("common.ok")}
                            disabled={disabled}
                        />
                    </div>   
                </div>
            </GenericModal>
        </>
    )
}