import { useEffect, useRef, useState } from "react";
import "../VerifyIdentity/index.scss";
import Constants from "../../Constants/constants";
import useAPIRequest from "../../Service/useAPIRequest";
import { useTranslation } from "react-i18next";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IYotiSession from "../../interfaces/IYotiSession";
import { isMobileOnly } from "react-device-detect";
import GenericAlert from "../../Components/GenericAlert";
import { ComplianceTypes, ImportStatus } from "../../Constants/enums";
import { useHistory } from "react-router-dom";
import moment from "moment";
import YotiDigitalIDButton from "./YotiDigitalIDButton";
import YotiDigitalIDMessage from "./YotiDigitalIDMessage";
import YotiDigitalIDSessionStatus from "./YotiDigitalIDSessionStatus";
import GenericModalResponsive from "../../Components/GenericModalResponsive";

interface YotiIDVProps {
  complianceProcessData: IComplianceProcess;
  isCheckEditable: boolean;
  onInfoSaveCallback: (res: any) => void;
  requestRefresh?: () => void;
  buttonMessage?: string;
}

const EmptyYotiSession: IYotiSession = {
  session: "",
  token: "",
  ttl: 0,
};

export default function YotiIDV(props: YotiIDVProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const yotiIframeContainer: any = useRef();
  const [loading, setLoading] = useState(false);
  const [yotiSession, setYotiSession] = useState<IYotiSession>();
  const [isYotiSessionActive, setIsYotiSessionActive] = useState(false);
  const [digitalIDCompleted, setDigitalIDCompleted] = useState(false);
  const [showDigitalIDCompleteModal, setShowDigitalIDCompleteModal] = useState(false);
  const [showDigitalIDErrorModal, setShowDigitalIDErrorModal] = useState(false);
  const [sessionErrorMessage, setSessionErrorMessage] = useState("");
  const [importStatus,] = useState(props.complianceProcessData.digitalIdentity?.importStatus);

  /***********Start - Yoti Integration *********** */

  useEffect(() => {
    if (yotiSession && yotiSession.session !== "") {
      flagYotiSession(true);
    }
  }, [yotiSession]);

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event.origin && event.origin === Constants.YOTI_ORIGIN_URL) {
        if (event.data.eventType === "SUCCESS") {
          // Act upon success
          flagYotiSession(false);
          setDigitalIDCompleted(true);
        } else if (event.data.eventType === "ERROR") {
          // Act upon error
          flagYotiSession(false);
          setShowDigitalIDErrorModal(true);
        }
        setShowDigitalIDCompleteModal(true);
      }
    });
  }, []);

  const flagYotiSession = (isActive: boolean) => {
    setIsYotiSessionActive(isActive);
    if (!isActive) {
      setYotiSession(EmptyYotiSession);
    }
  };

  const InitiateYotiSession = () => {
    setLoading(true);
    setSessionErrorMessage("");
    let url = "";
    switch (props.complianceProcessData.complianceType) {
      case ComplianceTypes.IdentityAndRWAudit:
        url =
          Constants.API_URLS.DIGITAL_ID_SUFFIX +
          props.complianceProcessData.id +
          Constants.API_URLS.POST_CREATE_YOTI_SESSION +
          "/rtw";
        break;
      case ComplianceTypes.DBS:
      case ComplianceTypes.BasicDBS:
        url =
          Constants.API_URLS.DIGITAL_ID_SUFFIX +
          props.complianceProcessData.id +
          Constants.API_URLS.POST_CREATE_YOTI_SESSION +
          "/dbs";
        break;
      default:
        //** - For All checks that need Yoti Digital ID session. for eg. Identity Check  */
        url =
          Constants.API_URLS.DIGITAL_ID_SUFFIX +
          props.complianceProcessData.id +
          Constants.API_URLS.POST_CREATE_YOTI_SESSION +
          "/digital-id";
        break;
    }
    useAPIRequest(url, {
      method: "POST",
      body: "",
    })
      .then((res) => {
        setLoading(false);
        if (res && res !== undefined) {
          if (res.session) {
            setYotiSession(res);
          } else {
            setSessionErrorMessage(res.message);
            setLoading(false);
            flagYotiSession(false);
            setShowDigitalIDErrorModal(true);
            setShowDigitalIDCompleteModal(true);
          }
        } else {
          setLoading(false);
          flagYotiSession(false);
        }
      })
      .catch(() => {
        setLoading(false);
        flagYotiSession(false);
      });
  };
  /***********End - Yoti Integration *********** */

  //******** Start - Remove Digital ID If Exists**** */

  const SendRemoveDigitalIDInfoData = () => {
    setLoading(true);
    useAPIRequest(Constants.API_URLS.REMOVE_DIGITAL_IDENTITY + props.complianceProcessData!.id, {
      method: "GET",
      body: "",
    })
      .then((res) => {
        if (res.success) {
          InitiateYotiSession();
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //******** End - Remove Digital ID If Exists**** */

  const CheckYotiSession = async () => {
    const compliance = props.complianceProcessData;
    
    if ((compliance.hasOwnProperty('digitalIdentity') && (
      importStatus === ImportStatus.Invalid || 
      importStatus === ImportStatus.RequirementsNotMet || 
      importStatus === ImportStatus.Rejected)) || (
      compliance.digitalIdentity && (moment(compliance.digitalIdentity.validTo) < moment()))) SendRemoveDigitalIDInfoData();
    else InitiateYotiSession();
  }

  const renderYotiSessionContainer = () => {

    const compliance = props.complianceProcessData;

    if (digitalIDCompleted)
      return <YotiDigitalIDMessage yotiMsg={t("verify_identity.yoti_process_pending_msg")} />
    else if (compliance && compliance.digitalIdentity) 
      return <YotiDigitalIDSessionStatus
        compliance={compliance}
        loading={loading}
        checkYotiSession={CheckYotiSession}
        isCheckEditable={props.isCheckEditable}
      />
    else
      return <YotiDigitalIDButton 
        checkYotiSession={CheckYotiSession}
        loading={loading}
        isDisabled={!props.isCheckEditable} 
        buttonMessage={props.buttonMessage}
        buttonText={props.buttonMessage ? props.buttonMessage : t("verify_identity.start_digital_id_verification")} 
      />
  };

  return (
    <>
      <div className="digital-id-container">{renderYotiSessionContainer()}</div>
      <div
        className="fullWidth"
        style={{ marginBottom: "50px" }}
      >
        <div ref={yotiIframeContainer}></div>
      </div>
      {isYotiSessionActive ? (
        <GenericModalResponsive
          open={isYotiSessionActive}
          modalWidth={isMobileOnly ? "90%" : "50%"}
          modalHeight={isMobileOnly ? "80%" : "70%"}
          modalPadding={isMobileOnly ? 20 : 30}
          modalMargin={isMobileOnly ? 10 : 50}
          closeOnBackDrop={false}
          modalTop={"0px"}
          onCloseModal={() => {
            flagYotiSession(false);
          }}
          hideModalClose={false}
        >
          <iframe
            id={"yoti-iframe-child-div"}
            className="yoti-iframe-child-div"
            title={t("verify_identity.yoti_id_verification")}
            src={`https://api.yoti.com/idverify/v1/web/index.html?sessionID=${
              yotiSession!.session
            }&sessionToken=${yotiSession!.token}`}
            style={{ height: "636px", width: "100%", border: "none" }}
            allow="camera"
          ></iframe>
        </GenericModalResponsive>
      ) : (
        ""
      )}
      <GenericAlert
        showButtons={true}
        showSecondaryBtn={false}
        error={showDigitalIDErrorModal}
        primaryButtonText={t("common.ok")}
        onCloseModal={() => {
          const compliance = props.complianceProcessData;
          setShowDigitalIDCompleteModal(false);
          if (compliance.complianceType === ComplianceTypes.IdentityAndRWAudit) {
            history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
          }
        }}
        onPrimaryButtonClicked={() => {
          const compliance = props.complianceProcessData;
          setShowDigitalIDCompleteModal(false);
          if (compliance.complianceType === ComplianceTypes.IdentityAndRWAudit) {
            history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
          } else {
            if (props.requestRefresh) {
              props.requestRefresh();
            }
          }
        }}
        open={showDigitalIDCompleteModal}
        modalHeight={"300px"}
        modalWidth="620px"
        modalMargin="200px auto"
        modalChildClass="modal-content-height"
      >
        <div className="modal-input">
          <div
            dangerouslySetInnerHTML={{
              __html: showDigitalIDErrorModal
                ? sessionErrorMessage !== ""
                  ? t("digital_identity.digital_id_error")
                      .replace(/(<? *script)/gi, "illegalscript")
                      .replace("{errorMessage}", sessionErrorMessage)
                  : t("digital_identity.digital_id_error")
                      .replace(/(<? *script)/gi, "illegalscript")
                      .replace("{errorMessage}", "")
                : t("digital_identity.digital_id_complete").replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
            }}
          ></div>
        </div>
      </GenericAlert>
    </>
  );
}
