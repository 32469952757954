import { ChangeEvent, Dispatch, SetStateAction, createContext } from "react";
import IDocumentDefinition from "../../interfaces/IDocumentDefinition";
import IDocumentGroupList from "../../interfaces/IDocumentGroupList";
import InfoTypes from "../../interfaces/InfoTypes";
import IDocumentFields from "../../interfaces/IDocumentFields";
import IComplianceProcess from "../../interfaces/IComplianceProcess";

export type DocumentsContextType = {
    docGroupListState: IDocumentGroupList | undefined;
    setDocGroupListState: Dispatch<SetStateAction<IDocumentGroupList | undefined>>;
    docGroupSelection: any;
    setDocGroupSelection: any;
    handleDocGroupChange: (
      prop: keyof DocGroupState,
      key?: string
    ) => (event: ChangeEvent<{}>, val: any) => void;
    handleDocValueChange: (
      prop: keyof InfoTypes.DocumentDefinition,
      index: number
    ) => (event: ChangeEvent<HTMLInputElement>, val: string) => void;
    handleDocValueDateChange: (
      prop: keyof InfoTypes.DocumentDefinition,
      index: number
    ) => (val: Date | null) => void;
    handleDocValueSelectChange: (
      prop: keyof InfoTypes.DocumentDefinition,
      index: number
    ) => (event: any, val: InfoTypes.SelectValueType) => void;
    documentDefinitionData: IDocumentDefinition[] | undefined;
    setDocumentDefinitionData: Dispatch<SetStateAction<IDocumentDefinition[] | undefined>>;
    identityDocValues: IDocumentDefinition[];
    setIdentityDocValues: Dispatch<SetStateAction<IDocumentDefinition[]>>;
    documentTypeFields: DocGroupFieldsState;
    setDocumentTypeFields: Dispatch<SetStateAction<DocGroupFieldsState>>;
    handleRemoveSavedFile: (index: number) => void;
    removeDocument: (index: number) => void;
    pushFiles: (files: any, index: number) => void;
    handleEditSaveCallbackDoc: (
      prop: keyof InfoTypes.DocumentDefinition,
      val: any,
      index: number
    ) => void;
    GetDocumentGroupList: (nationality: string) => Promise<void>;
    GetDocumentGroupSelectedLabels: (prop: keyof DocGroupState, docType: string) => void;
    SendPutDocumentsData: (docRequest: IComplianceProcess) => Promise<void>;
    isPrimaryFormValid: any;
    setIsPrimaryFormValid: any;
    isSecondaryFormValid: any;
    setIsSecondaryFormValid: any;
    isPrimaryFileExist: any;
    setIsPrimaryFileExist: any;
    isSecondaryFileExist: any;
    setIsSecondaryFileExist: any;
    isPrimarySelectSwitch: any;
    setIsPrimarySelectSwitch: any;
    isSecondarySelectSwitch: any;
    setIsSecondarySelectSwitch: any;
    isPrimarySelectSwitchMessage: any;
    setIsPrimarySelectSwitchMessage: any;
    isSecondarySelectSwitchMessage: any;
    setIsSecondarySelectSwitchMessage: any;
  };

  export type DocumentsContextProviderProps = {
    children?: React.ReactNode;
  };
  
  export interface DocGroupState {
    primaryDocuments: InfoTypes.DocumentGroupDefinition;
    secondaryDocuments: InfoTypes.DocumentGroupDefinition;
  }
  
  export interface DocGroupFieldsState {
    primaryDocuments?: IDocumentFields;
    secondaryDocuments?: IDocumentFields;
  }
  
  export const InitialDocGroupFieldsState = {
    primaryDocuments: {},
    secondaryDocuments: {},
  };
  
  export const InitialPrimaryDocumentDefinition: InfoTypes.DocumentDefinition = {
    type: "",
    documentId: "",
    group: "",
    name: "",
    emittingCountry: "",
    emittingCountryVal: {
      title: "",
      id: "",
      value: "",
    },
    emittingEntity: "",
    identifier: "",
    validFrom: null,
    validTo: null,
    url: "",
  };
  
  export const InitialSecondaryDocumentDefinition: InfoTypes.DocumentDefinition = {
    type: "",
    documentId: "",
    group: "",
    name: "",
    emittingCountry: "",
    emittingCountryVal: {
      title: "",
      id: "",
      value: "",
    },
    emittingEntity: "",
    identifier: "",
    validFrom: null,
    validTo: null,
    url: "",
  };
  
  export const InitialDocValues = [InitialPrimaryDocumentDefinition, InitialSecondaryDocumentDefinition];
  
  export const InitialDocGroupState = {
    primaryDocuments: {
      title: "",
      id: "",
      value: "",
    },
    secondaryDocuments: {
      title: "",
      id: "",
      value: "",
    },
  };

  export const docGrpList: IDocumentGroupList = {
    primaryDocuments: [],
    secondaryDocuments: [],
    ternaryDocuments: [],
  };

  export const documentGroups = ['primaryDocuments', 'secondaryDocuments', 'ternaryDocuments'];
  
  export const DocumentsContext = createContext<DocumentsContextType | undefined>(undefined);