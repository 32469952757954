import { List, ListItemText } from "@material-ui/core";
import ListItem from '@mui/material/ListItem';
import { useTranslation } from "react-i18next";

import InfoTypes from "../../interfaces/InfoTypes";
import "./index.scss";
import { ListSubheader } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  orderItemsTitleText: {
    fontSize: 20,
    fontWeight: 'normal',
  },
});

type Props = {
  items?: InfoTypes.RoleCheckOrderItem[];
  total?: number;
};

export default function OrderItems(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <List subheader={<ListSubheader className={classes.orderItemsTitleText}> {t("payment.order_items")}:</ListSubheader>}>
      {props.items &&
        props.items.map((item, index) => (
          <ListItem
            key={`order-item-${index}`}
            secondaryAction={
              <ListItemText
                disableTypography
                primary={<Typography variant="body2">
                  {t("common.currency_symbol")}
                  {item.price}
                </Typography>}
              />
            }
          >
            <ListItemText
              disableTypography
              primary={<Typography variant="body2">
                {item.name}
              </Typography>}
            />
         </ListItem>
        ))}
        {props.total && (
          <ListItem
            secondaryAction={
            <ListItemText
              disableTypography
              primary={<Typography>
                {t("common.currency_symbol")}
                {props.total}
              </Typography>}
            />
          }
        >
          <ListItemText
            disableTypography
            primary={<Typography>
              {t("payment.total")}
            </Typography>}
          />
       </ListItem>
      )}   
    </List>
}
