import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CheckoutProcessingModal from './CheckoutProcessingModal';
import Constants from '../../Constants/constants';
import { useHistory, useLocation } from 'react-router-dom';
import IEngagementRole from '../../interfaces/IEngagementRole';
import LocalStorageService from '../../utils/localStorageService';
import InfoTypes from '../../interfaces/InfoTypes';
import { CandidatePaymentStatus } from '../../Constants/enums';

const useStyles = makeStyles({
  primaryButton: {
    background: '#2D7FF9!important',
    color: '#FFF!important',
    border: '1px solid #2D7FF9',
    borderRadius: 25,
    textTransform: 'capitalize',
    padding: '10px 30px 10px 30px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 1px 6px 5px rgba(45, 127, 249, 0.3)'
    },
    "&:disabled": {
      background: '#FBFBFB!important',
      color: '#E0E0E0!important',
      border: '1px solid #E0E0E0',
    }
  },
  secondaryButton:{
    color: '#2D7FF9',
    backgroundColor: '#FFFFFF',
    border: '1px solid #2D7FF9',
    marginRight: 10,
    borderRadius: 25,
    textTransform: 'capitalize',
    padding: '10px 30px 10px 30px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 1px 6px 5px rgba(45, 127, 249, 0.3)'
    },
  }
});

type Props = {
  clientSecret: string;
  receiptEmail: string;
  isValidEmail: boolean;
  checkId: string;
};

type ProcessParams = {
  roleId: string;
}

const CheckoutForm = (props: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const location = useLocation<ProcessParams>();
  const { roleId } = location.state;

  const [message, setMessage] = useState('');
  const [messageHeader, setMessageHeader] = useState('');
  const [error, setError] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const roleData: IEngagementRole[] = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.WORKER_ROLE_DATA);
  const workerRole = roleData.find((r: IEngagementRole) => r.id === roleId);
  
  const processData = workerRole!.processes.find((p: InfoTypes.Process) => p.id === props.checkId);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        height: 16,
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#32325d"
        },
        border: '2px solid red!important'
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      },
    }
  };

  const handleConfirmAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreementChecked(event.target.checked);
  };

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setMessage(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    
    const cardElement = elements.getElement(CardElement)!;

    const payload = await stripe.confirmCardPayment(props.clientSecret, {
      receipt_email: props.receiptEmail,
      payment_method: {
        card: cardElement,
      }
    });


    if (payload.error) {
      setError(true);
      setMessageHeader(t("payment.process_failed"));
      setMessage(t("payment.process_failed_message"));
      setIsProcessing(false);
    } else {
      processData!.candidatePaymentStatus = CandidatePaymentStatus.Paid;
      LocalStorageService.setData(
        Constants.LOCAL_STORE_KEY.WORKER_ROLE_DATA,
        roleData
      );
      setError(false);
      setMessageHeader(t("payment.process_success"));
      setMessage(t("payment.process_success_message"));
      setIsProcessing(false);
      setSucceeded(true);
    }
  };

  useEffect(() => {
    if(elements){
      elements.update({locale: 'en'});
    } 
  }, [elements]);

  return (
    <form onSubmit={handleSubmit}>
      <CardElement className="card-element" options={cardStyle} onChange={handleChange} />
      <Grid item xs={12}>
          <FormControlLabel
            disableTypography
            control={<Checkbox 
              disabled={error || 
                succeeded || 
                disabled  || 
                props.isValidEmail || 
                isProcessing       
              }
              value="confirm-agreement" 
              color="primary"
              onChange={handleConfirmAgreementChange}
            />}
            label={
            <Typography variant="body2">
              {t("payment.confirm_agreement")}
            </Typography>}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button size="medium" 
              className={classes.secondaryButton} 
              variant="outlined"
              onClick={() => {
                history.push(Constants.COMPLIANCE_FORM_ROUTES.DASHBORAD);
              }}
              disabled={isProcessing || succeeded}
            >
              {t("common.decline")}
            </Button>
            <Button type="submit"
              size="medium" 
              className={classes.primaryButton} 
              variant="contained"
              disableRipple
              disabled={isProcessing || disabled || succeeded || !isAgreementChecked || props.isValidEmail}
            >
              <>
                {isProcessing ? t("payment.process_agreement") : t("common.accept")}
              </>
            </Button>
          </Box>
        </Grid>
        {(error || succeeded) && <CheckoutProcessingModal
          isProcessing={error || succeeded}
          messageHeader={messageHeader}
          message={message}
          isError={error}
        />}
    </form>
  )
};

export default CheckoutForm;