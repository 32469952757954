import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import InfoTypes from "../../interfaces/InfoTypes";
import "./index.scss";
import { GetCountriesData } from "../../utils/referenceDataService";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import EditPrevAddressModal from "./EditPrevAddressModal";
import { useTranslation } from "react-i18next";
import utilFunctions from "../../utils/util";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import SelectEditor from "../../Components/CheckEditors/SelectEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import { FormHelperText } from "@material-ui/core";
import { RegexEnum } from "../../Constants/enums";
import useSelectedPostCodeRegex from "../../utils/useSelectedPostCodeRegex";
import IAddressInfo from "../../interfaces/IAddressInfo";

interface PreviousAddressProps {
  prevAddressData: InfoTypes.PreviousAddress;
  pAddressIdx: number;
  haveRespondedPrevAddress: boolean;
  isProfileView: boolean;
  updatedData: (data: InfoTypes.PreviousAddress, index: number) => void;
  isCheckDataEditable: boolean;
  currAddressData: InfoTypes.AddressInfo,
  linkedPrevAddress: IAddressInfo;
}

interface PreviousAddressState {
  mappedPrevAddressData: InfoTypes.PreviousAddress;
}

interface PreviousAddressStateData {
  building: string;
  street: string;
  town: string;
  postCode: string;
  country: string;
  countryVal?: InfoTypes.SelectValueType;
  county: string;
  residentFrom: Date | null;
  residentTo: Date | null;
  isNew: boolean;
  [K: string]: any;
}

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);

export default function PreviousAddress(props: PreviousAddressProps, state: PreviousAddressState) {
  const { t } = useTranslation();

  const [prevAddressValue, setPrevAddressValue] = useState<PreviousAddressState>({
    mappedPrevAddressData: props.prevAddressData,
  });
  const [prevAddressEditClicked, setPrevAddressEditClicked] = useState(false);

  const [selectedPrevAddressToEdit, setSelectedPrevAddressToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
    inputProps: {},
  });

  const [selectedPrevAddressValues, setSelectedPrevAddressValues] = useState({
    building: "",
    country: "",
    countryVal: {
      title: "",
      value: "",
      id: "",
    },
    county: "",
    residentFrom: "",
    residentTo: "",
    postCode: "",
    street: "",
    town: "",
  });

  const [prevAddressDateFromError, setPrevAddressDateFromError] = useState(false);
  const [prevAddressDateFromHelperText, setPrevAddressDateFromHelperText] = useState('');

  const [prevAddressDateToError, setPrevAddressDateToError] = useState(false);
  const [prevAddressDateToHelperText, setPrevAddressDateToHelperText] = useState('');
  const selectedPostCodeRegex = useSelectedPostCodeRegex(prevAddressValue.mappedPrevAddressData!.countryVal!.id);

  let countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
  if (countriesData === null || countriesData === undefined) {
    countriesData = GetCountriesData();
  }

  const handlePrevAddressChange =
    (prop: keyof PreviousAddressStateData, index: number) =>
    (e: ChangeEvent<HTMLInputElement>, val: string) => {

      let mappedVal: any = prevAddressValue;
      if (prop === "building") mappedVal.mappedPrevAddressData!.building = val;
      else if (prop === "country") mappedVal.mappedPrevAddressData!.country = val;
      else if (prop === "town") mappedVal.mappedPrevAddressData!.town = val;
      else if (prop === "county") mappedVal.mappedPrevAddressData!.county = val;
      else if (prop === "street") mappedVal.mappedPrevAddressData!.street = val;    
      else mappedVal.mappedPrevAddressData!.postCode = val;

      setPrevAddressValue({
        ...state,
        mappedPrevAddressData: {
          ...mappedVal.mappedPrevAddressData,
          [prop]: prop === "postCode" ? val.toUpperCase() : val,
        },
      });
      props.updatedData(prevAddressValue.mappedPrevAddressData, index);
    };

  const handlePrevAddressSelectChange =
    (prop: keyof PreviousAddressStateData, index: number) =>
    (event: any, val: InfoTypes.SelectValueType) => {
      let mappedVal: any = prevAddressValue;
      if (prop === "country") mappedVal.mappedPrevAddressData!.country = val.id;

      setPrevAddressValue({
        ...state,
        mappedPrevAddressData: {
          ...prevAddressValue.mappedPrevAddressData,
          [prop]: val.id,
          [prop + "Val"]: val,
        },
      });
      props.updatedData(prevAddressValue.mappedPrevAddressData, index);
    };

  const handlePrevDateChange =
    (prop: keyof PreviousAddressStateData, index: number) => (val: Date | null) => {
      let mappedVal: any = prevAddressValue;

      if (prop === "residentFrom") {
        mappedVal.mappedPrevAddressData!.residentFrom = moment(val!).format(
          Constants.DATE_FORMAT_ISO
        );
      } else {
        mappedVal!.mappedPrevAddressData!.residentTo = moment(val!).format(
          Constants.DATE_FORMAT_ISO
        );
      }

      setPrevAddressValue({
        ...state,
        mappedPrevAddressData: mappedVal.mappedPrevAddressData,
      });

      props.updatedData(prevAddressValue.mappedPrevAddressData, index);
    };

  const handleEditPrevAddressFieldChange = (
    prevAddress: InfoTypes.PreviousAddress,
    id: string,
    title: string,
    val: any,
    index: number,
    inputProps?: { [key: string]: any }
  ) => {
    setSelectedPrevAddressToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: index,
      inputProps: inputProps!,
    });

    setSelectedPrevAddressValues({
      building: prevAddress.building,
      country: prevAddress.country,
      county: prevAddress.county,
      postCode: prevAddress.postCode,
      residentFrom: moment(prevAddress.residentFrom).format(Constants.DATE_FORMAT_DISPLAY),
      residentTo: moment(prevAddress.residentTo).format(Constants.DATE_FORMAT_DISPLAY),
      street: prevAddress.street,
      town: prevAddress.town,
      countryVal: prevAddress.countryVal!,
    });
  };

  const handleEditPrevAddressSaveCallback = (
    prop: keyof PreviousAddressStateData,
    val: any,
    index: number
  ) => {
    let updatedProps: any = prevAddressValue!.mappedPrevAddressData;

    if (prop === "country") {
      updatedProps.country = val.id;
      updatedProps.countryVal = val;
    } else if (prop === "residentFrom" || prop === "residentTo") {
      updatedProps[prop] = moment(val).format(Constants.DATE_FORMAT_ISO);
    } else {
      updatedProps![prop] = val;
    }

    setPrevAddressValue({
      ...state,
      mappedPrevAddressData: updatedProps,
    });

    props.updatedData(updatedProps, index);
  };

  const onEditPrevAddressModalClose = () => {
    setPrevAddressEditClicked(false);
    setSelectedPrevAddressValues({
      building: "",
      country: "",
      countryVal: {
        title: "",
        value: "",
        id: "",
      },
      county: "",
      residentFrom: "",
      residentTo: "",
      postCode: "",
      street: "",
      town: "",
    });

    setSelectedPrevAddressToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
      inputProps: {},
    });
  };

  const handlePrevAddressDateErrors = useCallback(() => {

    const prevAddr = prevAddressValue.mappedPrevAddressData;

    // Dates validations
    if ((prevAddr.residentFrom && prevAddr.residentTo) && 
        (prevAddr!.residentFrom.toString() !== 'Invalid date' && prevAddr!.residentTo.toString() !== 'Invalid date')) {
        const isMinDate = prevAddr.residentFrom >= prevAddr.residentTo;
        const isMaxDate = prevAddr.residentTo <= prevAddr.residentFrom;
        const now = moment().startOf('day');
        const isDateResidentToGreaterThanToday = moment(prevAddr.residentTo).isSameOrAfter(now);

        if (isMaxDate) {
          setPrevAddressDateToError(isMaxDate);
          setPrevAddressDateToHelperText(t("errors.to_date_lesser_msg"));
        } else {
          setPrevAddressDateToError(false);
          setPrevAddressDateToHelperText("");
        }

        if (isDateResidentToGreaterThanToday) {
          setPrevAddressDateToError(isDateResidentToGreaterThanToday);
          setPrevAddressDateToHelperText(t("errors.resident_to_date_greater_today_msg"));
        }  else {
          setPrevAddressDateToError(false);
          setPrevAddressDateToHelperText("");
        }
        
        if (isMinDate) {
          setPrevAddressDateFromError(isMinDate);
          setPrevAddressDateFromHelperText(t("errors.from_date_greater_msg"));
        }  else {
          setPrevAddressDateFromError(false);
          setPrevAddressDateFromHelperText("");
        }

    } else {
      setPrevAddressDateToError(true);
      setPrevAddressDateToHelperText(t("errors.is_valid_date_or_empty_msg"));

      setPrevAddressDateFromError(true);
      setPrevAddressDateFromHelperText(t("errors.is_valid_date_or_empty_msg"));
    }

  }, [prevAddressValue.mappedPrevAddressData, t])


  useEffect(() => {
    handlePrevAddressDateErrors();
  }, [prevAddressValue.mappedPrevAddressData.residentFrom, 
    prevAddressValue.mappedPrevAddressData.residentTo, 
    prevAddressDateToError, 
    prevAddressDateToHelperText, 
    prevAddressDateFromError, 
    prevAddressDateFromHelperText, 
    handlePrevAddressDateErrors
  ])

  return (
    <>
      <div
        id={`persona-info-prevAddress-div-${props.pAddressIdx}`}
        className="address-history-body-container-details empty-row"
      >
        <div className="address-history-body-container-details-row">
          <div className="oneHalfWidth">
            <TextEditor
              isNew={props.prevAddressData.isNew}
              fieldName="prevPostCode"
              value={props.prevAddressData.postCode}
              editValue={prevAddressValue.mappedPrevAddressData.postCode}
              label={t("address_history.postal_code")}
              type="text"
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePrevAddressChange("postCode", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  props.prevAddressData,
                  "postCode",
                  t("address_history.postal_code"),
                  props.prevAddressData.postCode,
                  props.pAddressIdx
                );
              }}
              error={
                prevAddressValue.mappedPrevAddressData!.postCode &&
                prevAddressValue.mappedPrevAddressData!.postCode !== ""
                  ? !utilFunctions.testRegex(
                      selectedPostCodeRegex,
                      prevAddressValue.mappedPrevAddressData!.postCode
                    )
                  : true
              }
              helperText={
                prevAddressValue.mappedPrevAddressData!.postCode &&
                prevAddressValue.mappedPrevAddressData!.postCode !== ""
                  ? utilFunctions.testRegex(
                      selectedPostCodeRegex,
                      prevAddressValue.mappedPrevAddressData!.postCode
                    )
                    ? ""
                    : t("validators.invalid_postcode")
                  : t("validators.required")
              }
            />
          </div>
          <div className="oneHalfWidth"></div>
        </div>
        <div className="address-history-body-container-details-row">
          <div className="oneHalfWidth">
            <TextEditor
              isNew={props.prevAddressData.isNew}
              fieldName={"prevbuilding"}
              value={props.prevAddressData.building}
              editValue={prevAddressValue.mappedPrevAddressData.building}
              label={t("address_history.building")}
              type="text"
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePrevAddressChange("building", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  props.prevAddressData,
                  "building",
                  t("address_history.building"),
                  props.prevAddressData.building,
                  props.pAddressIdx
                );
              }}
              error={
                prevAddressValue.mappedPrevAddressData!.building &&
                prevAddressValue.mappedPrevAddressData!.building !== ""
                  ? !utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.building
                    )
                  : true
              }
              helperText={
                prevAddressValue.mappedPrevAddressData!.building &&
                prevAddressValue.mappedPrevAddressData!.building !== ""
                  ? utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.building
                    )
                    ? ""
                    : t("validators.special_chars")
                  : t("validators.required")
              }
            />
          </div>
          <div className="oneHalfWidth">
            <TextEditor
              isNew={props.prevAddressData.isNew}
              fieldName="prevStreet"
              value={props.prevAddressData.street}
              editValue={prevAddressValue.mappedPrevAddressData.street}
              label={t("address_history.street")}
              type="text"
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePrevAddressChange("street", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  props.prevAddressData,
                  "street",
                  t("address_history.street"),
                  props.prevAddressData.building,
                  props.pAddressIdx,
                  { maxLength: 60 }
                );
              }}
              inputProps={{ maxLength: 60 }}
              error={
                prevAddressValue.mappedPrevAddressData!.street &&
                prevAddressValue.mappedPrevAddressData!.street !== ""
                  ? !utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.street
                    )
                  : true
              }
              helperText={
                prevAddressValue.mappedPrevAddressData!.street &&
                prevAddressValue.mappedPrevAddressData!.street !== ""
                  ? utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.street
                    )
                    ? ""
                    : t("validators.special_chars")
                  : t("validators.required")
              }
            />
          </div>
        </div>
        <div className="address-history-body-container-details-row">
          <div className="oneHalfWidth">
            <TextEditor
              isNew={props.prevAddressData.isNew}
              fieldName="prevTown"
              value={props.prevAddressData.town}
              editValue={prevAddressValue.mappedPrevAddressData.town}
              label={t("address_history.town")}
              type="text"
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePrevAddressChange("town", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  props.prevAddressData,
                  "town",
                  t("address_history.town"),
                  props.prevAddressData.town,
                  props.pAddressIdx,
                  { maxLength: 30 }
                );
              }}
              error={
                prevAddressValue.mappedPrevAddressData!.town &&
                prevAddressValue.mappedPrevAddressData!.town !== ""
                  ? !utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.town
                    )
                  : true
              }
              helperText={
                prevAddressValue.mappedPrevAddressData!.town &&
                prevAddressValue.mappedPrevAddressData!.town !== ""
                  ? utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.town
                    )
                    ? ""
                    : t("validators.special_chars")
                  : t("validators.required")
              }
              inputProps={{ maxLength: 30 }}
            />
          </div>
          <div className="oneHalfWidth"></div>
        </div>
        <div className="address-history-body-container-details-row">
          <div className="oneHalfWidth">
            <TextEditor
              isNew={props.prevAddressData.isNew}
              fieldName={"prevCounty"}
              value={props.prevAddressData.county}
              editValue={prevAddressValue.mappedPrevAddressData!.county}
              label={t("address_history.county_required")}
              type="text"
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePrevAddressChange("county", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  props.prevAddressData,
                  "county",
                  t("address_history.county_required"),
                  props.prevAddressData.county,
                  props.pAddressIdx
                );
              }}
              error={
                prevAddressValue.mappedPrevAddressData!.county &&
                prevAddressValue.mappedPrevAddressData!.county !== ""
                  ? !utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.county
                    )
                  : true
              }
              helperText={
                prevAddressValue.mappedPrevAddressData!.county &&
                prevAddressValue.mappedPrevAddressData!.county !== ""
                  ? utilFunctions.testRegex(
                      genericRegex,
                      prevAddressValue.mappedPrevAddressData!.county
                    )
                    ? ""
                    : t("validators.special_chars")
                  : t("validators.required")
              }
              inputProps={{ maxLength: 30 }}
            />
          </div>
          <div className="oneHalfWidth">
            <SelectEditor
              isNew={props.prevAddressData.isNew}
              id="prevCountry"
              value={props.prevAddressData.countryVal?.title}
              editValue={prevAddressValue.mappedPrevAddressData!.countryVal}
              label={t("common.country")}
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              dataSet={countriesData}
              handleChange={handlePrevAddressSelectChange("country", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  props.prevAddressData,
                  "country",
                  t("common.country"),
                  props.prevAddressData.countryVal,
                  props.pAddressIdx
                );
              }}
              error={
                prevAddressValue.mappedPrevAddressData!.countryVal &&
                prevAddressValue.mappedPrevAddressData!.countryVal.title !== ""
                  ? false
                  : true
              }
            />
              {(prevAddressValue.mappedPrevAddressData!.countryVal &&
                prevAddressValue.mappedPrevAddressData!.countryVal.title !== "") ? <></> :
                <FormHelperText className="error MuiFormHelperText-contained">
                  {t("validators.required")}
                </FormHelperText>
              }
          </div>
        </div>
        <div className="address-history-body-container-details-row">
          <div className="oneHalfWidth">
            <DateEditor
              isNew={props.prevAddressData.isNew}
              value={props.prevAddressData.residentFrom}
              editValue={prevAddressValue.mappedPrevAddressData.residentFrom}
              label={t("address_history.resident_from")}
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePrevDateChange("residentFrom", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  prevAddressValue.mappedPrevAddressData,
                  "residentFrom",
                  t("address_history.resident_from"),
                  prevAddressValue.mappedPrevAddressData.residentFrom,
                  props.pAddressIdx
                );
              }}
              error={prevAddressDateFromError}
              helperText={prevAddressDateFromHelperText}
            />
          </div>

          <div className="oneHalfWidth">
            <DateEditor
              isNew={props.prevAddressData.isNew}
              value={props.prevAddressData.residentTo}
              editValue={prevAddressValue.mappedPrevAddressData.residentTo}
              label={t("address_history.resident_to")}
              showEdit={props.isCheckDataEditable}
              isCheckEditable={props.isCheckDataEditable}
              onChangeValue={handlePrevDateChange("residentTo", props.pAddressIdx)}
              onEditClicked={(e: EventTarget) => {
                setPrevAddressEditClicked(true);
                handleEditPrevAddressFieldChange(
                  props.prevAddressData,
                  "residentTo",
                  t("address_history.resident_to"),
                  props.prevAddressData.residentTo,
                  props.pAddressIdx
                );
              }}
              error={prevAddressDateToError}
              helperText={prevAddressDateToHelperText}
            />
          </div>
        </div>
        <div className="address-history-body-container-details-row empty-row">
          <div></div>
        </div>
      </div>
      {prevAddressEditClicked ? (
        <EditPrevAddressModal
          isEditPrevAddressIconClicked={prevAddressEditClicked}
          prevAddressState={selectedPrevAddressValues}
          selectedPrevAddressToEdit={selectedPrevAddressToEdit}
          onCloseEditPrevAddressModal={onEditPrevAddressModalClose}
          onPrevAddressSaveCallback={handleEditPrevAddressSaveCallback}
          pAddressIdx={props.pAddressIdx}
          linkedPrevAddress={props.linkedPrevAddress}
        />
      ) : (
        ""
      )}
    </>
  );
}
