import "./index.scss";

interface Props {
  title: string;
  withAsterisk?: boolean;
  className?: string;
  subHeaderText?: string;
  withoutBorder?: boolean;
  centerContent?: boolean;
}

const Heading = (props: Props) => {
  const { title, withAsterisk, className, subHeaderText, withoutBorder, centerContent } = props;

  return (
    <div className={ (className) ? `heading-wrapper ${className}` : "heading-wrapper"}>
      <div className={ (withoutBorder) ?  ((centerContent) ? "text center-text" :  "text float-left") : "text with-border float-left"}  aria-label={title!}>
        {title}
        {withAsterisk ? "*" : ""}
      </div>
      {(subHeaderText && subHeaderText !== "") ? 
      <div className="sub-text" aria-label={subHeaderText!}>
        {subHeaderText}
      </div>
      : ""}
    </div>
  );
};

export default Heading;
