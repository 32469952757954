const ImportableTypes = {

    PersonalInfoFields: {
        dateOfBirth: null,
        forename: "",
        surname: "",
        middleNames: [],
        gender: "",
        currentNationality: "",
        country: ""
    },
    AddressInfoFields: {
        building: "",
        street: "",
        town: "",
        postCode: "",
        country: "",
        county: "",
        residentFrom: null
    },
    DocumentInfoFields: {
        type: "",
        documentId: "",
        emittingCountry: "",
        emittingEntity: "",
        identifier: "",
        validFrom: null,
        validTo: null
    }
}

export default ImportableTypes;