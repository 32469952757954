import _ from "lodash";
import "./index.scss";
import { useAuth0 } from "@auth0/auth0-react";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import "react-multi-carousel/lib/styles.css";
import IEngagementRole from "../../interfaces/IEngagementRole";
import { GenericLoader } from "../../Components/GenericLoader";
import { useApi } from "../../Auth/UseApi";
import InfoTypes from "../../interfaces/InfoTypes";
import { useTranslation } from "react-i18next";
import AccordionBar from "../../Components/AccordionBar";
import { Grid } from "@mui/material";
import { ProcessStatus } from "../../Constants/enums";
import { IsCheckInProgress } from "../../utils/referenceDataService";
import RoleProcesses from "./RoleProcesses";
import GetWorkerProfileData from "./GetWorkerProfileData";
import RoleWrapper from "./RoleWrapper";
import Box from "@material-ui/core/Box";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'unset',
  color: theme.palette.text.secondary,
}));

export default function Dashboard() {
  const { t } = useTranslation();
  const { user } = useAuth0();

  const { loading, error, data } = useApi<IEngagementRole[]>(
    Constants.API_URLS.GET_WORKER_ROLE_HISTORY
  );

  GetWorkerProfileData();

  if (loading) {
    return <GenericLoader />;
  }

  if (error) {
    if (error instanceof Error) {
      return <div>{t("errors.something_wrong_err", { error: error?.message })}</div>;
    }
    return (
      <div>
        {t("errors.something_wrong")}
        {t("errors.please_refresh")}
      </div>
    );
  } else {
    LocalStorageService.setData(Constants.LOCAL_STORE_KEY.WORKER_ROLE_DATA, data!);
  }

  return (
    <div
      id="dashboard-wrapper"
      className="dashboard-wrapper"
    >
      <div
        className="title"
        aria-label={user?.name}
      >
        {user?.name}
      </div>
      <div className="subtitle">{t("dashboard.welcome_message")}</div>
      <AccordionBar
        open={true}
        headingTitle={t("dashboard.active_application")}
        children={
          <div>
            <div className="info-title">{t("dashboard.welcome_message2")}</div>
            <div className="info-container">
              {data === null || data === undefined || data.length === 0 ? (
                <div className="roles">
                  <div className="roles-header">
                    <div
                      className="roles-header-name"
                      aria-label={t("dashboard.no_work_history")}
                    >
                      {t("dashboard.no_work_history")}
                    </div>
                  </div>
                </div>
              ) : (
                _.map(
                  data,
                  (r: IEngagementRole) =>
                    !r?.processes?.every((process: InfoTypes.Process) => {
                      return process.status === ProcessStatus.Complete;
                    }) && (
                      <div
                        className="roles"
                        key={r.id}
                        id={r.id}
                      >
                        <RoleWrapper info={r} />
                        <Box sx={{ flexGrow: 1, maxWidth: 'xs'}} className="ProcessesCrs">
                          <div className="Banner">
                            <Grid container 
                              spacing={{ xs: 2, md: 3 }} 
                              columns={{ xs: 4, sm: 8, md: 12 }}
                              className="BannerGrid"
                            >
                              {r.processes.map(
                                (process: InfoTypes.Process) =>
                                  !IsCheckInProgress(process.complianceType) && (
                                  <Grid item xs={12} sm={4} md={4} key={"role-processes-" + process.id}>
                                    <Item className="roles-processes">
                                      <RoleProcesses
                                        process={process}
                                        roleId={r.id}
                                        isComplete={false}
                                        key={"role-processes-" + process.id}
                                      />
                                    </Item>
                                  </Grid>
                              ))}
                            </Grid>
                          </div>
                        </Box>
                      </div>
                    )
                )
              )}
            </div>
          </div>
        }
      />

      <AccordionBar
        open={true}
        headingTitle={t("dashboard.completed_application")}
        children={
          <div>
            <div className="info-title">{t("dashboard.completed_checks_message")}</div>
            <div className="info-container">
              {data === null || data === undefined || data.length === 0 ? (
                <div className="roles">
                  <div className="roles-header">
                    <div
                      className="roles-header-name"
                      aria-label={t("dashboard.no_work_history")}
                    >
                      {t("dashboard.no_work_history")}
                    </div>
                  </div>
                </div>
              ) : (
                _.map(
                  data,
                  (r: IEngagementRole) =>
                    r?.processes?.every((process: InfoTypes.Process) => {
                      return process.status === ProcessStatus.Complete;
                    }) && (
                      <div
                        className="roles"
                        key={r.id}
                        id={r.id}
                      >
                        <RoleWrapper info={r} />
                        <Box sx={{ flexGrow: 1, maxWidth: 'xs'}} className="ProcessesCrs">
                          <div className="Banner">
                            <Grid container 
                              spacing={{ xs: 2, md: 3 }} 
                              columns={{ xs: 4, sm: 8, md: 12 }}
                              className="BannerGrid"
                            >
                              {r.processes.map(
                                (process: InfoTypes.Process) =>
                                  !IsCheckInProgress(process.complianceType) && (
                                  <Grid item xs={12} sm={4} md={4} key={"role-processes-" + process.id}>
                                    <Item className="roles-processes">
                                      <RoleProcesses
                                        process={process}
                                        roleId={r.id}
                                        isComplete={true}
                                        key={"role-processes-" + process.id}
                                      />
                                    </Item>
                                  </Grid>
                              ))}
                            </Grid>
                          </div>
                        </Box>
                      </div>
                    )
                )
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
