import React, { ChangeEvent, useState } from "react";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import "./index.scss"
import Smiley_0 from "../../Assets/smiley/Blank_Smiley.png";
import Smiley_4 from "../../Assets/smiley/Happy_Smiley.png";
import Smiley_5 from "../../Assets/smiley/VeryHappy_Smiley.png";
import Smiley_3 from "../../Assets/smiley/NoComments_Smiley.png";
import Smiley_2 from "../../Assets/smiley/Sad_Smiley.png";
import Smiley_1 from "../../Assets/smiley/VerySad_Smiley.png";
import GenericTextField from "../GenericTextField";

interface FeedbackRatingProps {
    handleFeedbackRating: (ratingValue: number) => void;
    handleFeedbackContent: (ratingContent: string) => void;
    disabled: boolean;
}

const FeedbackRating = (props: FeedbackRatingProps): JSX.Element => {
    const oneRef = React.createRef();
    const [feedBackValue, setFeedBackValue] = useState<number>(0);
    const [feedbackReason, setFeedbackReason] = useState("");
    const [smileyIcon, setSmileyIcon] = useState<string>(Smiley_0);
    
    const handleFeedbackChange = (val: number) => {
        if (val === 0) {
            setSmileyIcon(Smiley_0);
        }
        else {
            if (val === 1) {
                setSmileyIcon(Smiley_1);
            } else if (val === 2) {
                setSmileyIcon(Smiley_2);
            } else if (val === 3) {
                setSmileyIcon(Smiley_3);
            } else if (val === 4) {
                setSmileyIcon(Smiley_4);
            } else if (val === 5) {
                setSmileyIcon(Smiley_5);
            }
        }
    }

    const handleSetFeedbackValue = (val: number) => {
        handleFeedbackChange(val);
        setFeedBackValue(val);
        props.handleFeedbackRating(val);
    }

    const handleFeedbackReasonChange = (e: ChangeEvent<HTMLInputElement>, val: string)  => {
        setFeedbackReason(val);
        props.handleFeedbackContent(val);
    }

    return (
        <div className="wrapper-feedback">
            <div className="rating-wrapper">
                <div className="sub-title" aria-label="Rate your experience">
                    Rate your experience
                </div>
                <div className="rating-stars">
                    <Rating
                        name="customized-empty"
                        value={feedBackValue}
                        emptyIcon={<StarBorderIcon fontSize="inherit" aria-label="star" />}
                        onChange={(event, newValue) => {
                            handleSetFeedbackValue(Number(newValue));
                        }}
                        disabled={props.disabled}
                        onChangeActive={(event, newHover) => {
                            if (newHover === -1) {
                                if (feedBackValue > 0) {
                                    handleFeedbackChange(feedBackValue);
                                } else {
                                    handleFeedbackChange(0);
                                }
                            } else {
                                handleFeedbackChange(Number(newHover));    
                            }
                        }}
                        size="large"
                    />
                </div>
                <div className="feedback-image" aria-label="smileys">
                    <img style={{ height: '50px', width: '50px' }} className="img-smiley" alt="smiley" src={smileyIcon} />
                </div>
                <div className="fullWidth feedback-text">
                    <GenericTextField
                    label="Feedback Reason"
                    placeholder={"Enter your feedback here..."}
                    name={"Feedback Reason"}
                    type="text"
                    id={"feedbackReason"}
                    variant="outlined"
                    multiline={true}
                    rows={1}
                    margin="dense"
                    fullWidth={true}
                    value={feedbackReason}
                    inputRef={oneRef}
                    disabled={props.disabled}
                    onChangeValue={handleFeedbackReasonChange}
                    error={false}
                    helperText={""}
                    />
                </div>
            </div>
        </div>
    )
}

export default FeedbackRating;