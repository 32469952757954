import { useHistory } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { FunctionComponent } from "react";

const Auth0ProviderWithHistory: FunctionComponent = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {    
    history.push(appState?.returnTo || window.location.pathname);
    history.go(0);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope="read:items write:items delete:items"
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
