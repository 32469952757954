import moment from "moment";
import Constants from "../../Constants/constants";
import GenericDataShow from "../GenericDataShow";
import GenericDatePicker from "../GenericDatePicker";

interface Props {
  value: Date | null | undefined;
  editValue: Date | null | undefined;
  label: string;
  showEdit: boolean;
  isCheckEditable: boolean;
  isNew?: boolean;
  error?: boolean;
  disablePast?: boolean;
  enableFuture?: boolean;
  helperText?: string;
  onChangeValue: (val: Date | null) => void;
  onEditClicked: (e: EventTarget) => void;
}

const DateEditor = (props: Props) => {
  return (
    <>
      {((props.isNew === undefined || props.isNew === null || !props.isNew) && props.value) ||
      !props.isCheckEditable ? (
        <GenericDataShow
          label={props.label}
          value={moment(props.value).format(Constants.DATE_FORMAT_DISPLAY)}
          showEdit={props.showEdit}
          onEditClicked={props.onEditClicked}
        />
      ) : (
        <div className="p-t-30">
          <GenericDatePicker
            variant="inline"
            label={props.label + " (DD/MM/YYYY)"}
            id="date-picker-inline"
            inputVariant="outlined"
            value={(props.editValue === null || props.editValue === undefined)  ? null : moment(props.editValue).toDate()}
            onChangeValue={props.onChangeValue}
            format={Constants.DATE_FORMAT_CALENDAR}
            fullWidth={true}
            disablePast={props.disablePast ? props.disablePast : false}
            enableFuture={props.enableFuture ? props.enableFuture : false}
            error={props.error}
            helperText={props.helperText}
          />
        </div>
      )}
    </>
  );
};

export default DateEditor;
