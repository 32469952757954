import "./index.scss";
import Constants from "../../Constants/constants";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import LocalStorageService from "../../utils/localStorageService";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import UtilityFunctions from "../../utils/util";
import IEngagementRole from "../../interfaces/IEngagementRole";
import InfoTypes from "../../interfaces/InfoTypes";

type RoleDetailsParams = {
  roleId: string;
}

export default function RoleDetails() {
    const history = useHistory();
    const { roleId } = useParams<RoleDetailsParams>();  
    const roleData: IEngagementRole[] = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.WORKER_ROLE_DATA);
    const workerRole = roleData.find((r: IEngagementRole) => r.id === roleId);

    const navigateToComplianceForm = (roleId: string, complianceId: string, complianceType: string) => {
      history.push(UtilityFunctions.getComplianceFormRoute(complianceType) + roleId + "/" + complianceId);
    }

    return (
      <div id="roleDetails-wrapper" className="main-wrapper">
        <div className="header">
          <div className="back">
            <ArrowBackIosIcon className="navigate" onClick={() => { history.goBack(); }} />
          </div>
          <div className="title">{workerRole?.role}</div>
        </div>
        <div className="role">
          <div className="role-header">
              <div className="role-header-location">
              {workerRole?.location}
              </div>
          </div>
          <div className="role-processes">
              {workerRole?.processes.map((process: InfoTypes.Process) => (
              <div className="compliance-points" key={process.id} onClick={() => {navigateToComplianceForm(workerRole.id!, process.id!, process.complianceType!)}}>
                  <div className="compliance-point-text">
                      {process.complianceType}
                  </div>
                  <div className="compliance-point-status">
                      {process.status}
                  </div>
              </div>
              ))}
          </div> 
        </div>        
      </div>
    );
  }
