import * as React from 'react';
import "./index.scss";
import Modal from '@mui/material/Modal';
import CloseIcon from "../../Assets/model-close.svg";

export interface ModalProps {
  heading?: any;
  onCloseModal: () => void;
  open: boolean;
  children: React.ReactElement;
  className?: string;
  modalWidth: string;
  modalHeight: string;
  modalPadding: number;
  modalMargin: number;
  modalTop?: string;
  closeOnBackDrop?: boolean;
  hideModalClose?: boolean;
  hasFooterContent?: boolean;
}

const GenericModalResponsive: React.FC<ModalProps> = (props) => {
  const [open,] = React.useState(props.open);

  const style={
    width: props.modalWidth,
    height: props.modalHeight,
    padding: props.modalPadding,
    margin: props.modalMargin,
    background: 'rgb(255, 255, 255)',
    boxShadow: '8px 8px 16px 0px rgba(74, 80, 82, 0.1)',
    display: 'flex', 
    border: '1px solid rgb(235, 237, 244)',
    borderRadius: 25,
    flexDirection: 'column' as 'column', 
    alignItems: 'center',
  }

  return (
    <Modal 
      open={open} 
      className="modal-container" 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
      }} 
      onClose={(e, reason) => {
      if(reason === "backdropClick" && props.closeOnBackDrop)
        props.onCloseModal();
    }}>
      <div style={style}>
        <div className="modal-header-container">
          {props.heading && <div className="modal-header-title-container">
            <span className="modal-header-title">{props.heading}</span>
          </div>}
          {!(props.hideModalClose) && <div className="modal-header-action">
              <img
                src={CloseIcon}
                alt="Close"
                className="close-icon"
                onClick={props.onCloseModal}
              />
          </div>}
        </div>
        {props.children}
      </div>
    </Modal>
  );
};

export default GenericModalResponsive;
