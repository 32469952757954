import { DrawerItem } from '../Components/Menu/DrawerItemType';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { PowerSettingsNew } from '@material-ui/icons';

export const DRAWER_LIST: DrawerItem[] = [
  {
    route: '/dashboard',
    literal: "menu_items.dashboard",
    Icon: DashboardIcon,
  },
  /* To-do : put it back when the profile page is working */
  /*
   {
    route: '/profile',
    literal: "menu_items.profile",
    Icon: AccountCircleIcon,
  },
  */
  {
    route: '/logout',
    literal: "menu_items.logout",
    styleName: "logout-item",
    Icon: PowerSettingsNew,
  },
];
