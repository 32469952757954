import _ from "lodash";
import { useApi } from "../Auth/UseApi";
import Constants from "../Constants/constants";
import IComplianceContent from "../interfaces/IComplianceContent";
import ICountries from "../interfaces/ICountries";
import InfoTypes from "../interfaces/InfoTypes";
import ITitles from "../interfaces/ITitles";
import LocalStorageService from "./localStorageService";

const InitialComplianceTypeContent = {
    id: "",
    intro: {
      content: "",
      detail: "",
      title: ""
    },
    steps: [{
      content: "",
      detail: "",
      title: ""
    }],
    outro: {
      content: "",
      detail: "",
      title: ""
    }
}


export function GetComplianceTypeUIContent(complianceType: string) {
  let url = Constants.API_URLS.COMPLIANCE + complianceType.toLowerCase() + Constants.API_URLS.GET_COMPLIANCE_CONTENT;
  
  var {error, data } = useApi<IComplianceContent>(url);
  var uiContent: IComplianceContent = InitialComplianceTypeContent;
  if (!error) {
    if(data !== null && data !== undefined) {
      uiContent = data
    }
  }
  return uiContent;
}

export function GetTitlesData() {
  var { error, data } = useApi<ITitles[]>(Constants.API_URLS.GET_TITLES);
  var titles: InfoTypes.Title[] = [];

  if (!error) {
    if(data !== null && data !== undefined && data.length > 0) {
      titles = _.map(data!, (e: ITitles) => {
        return { title: e.id, id: e.id, value: e.value };
      });
      LocalStorageService.setData(Constants.LOCAL_STORE_KEY.TITLES_DATA, titles);
    }
  }
  return titles;
}

export function GetNationalitiesData() {

  var { error, data } = useApi<InfoTypes.Nationality[]>(Constants.API_URLS.GET_NATIONALITIES);
  var nationalityData: InfoTypes.Nationality[] = [];

  if (!error) {
    if(data !== null && data !== undefined && data.length > 0) {
        
      nationalityData = _.map(data!, (e: InfoTypes.Nationality) => {
          return {title: e.value, id: e.id, value: e.id };
      });
      LocalStorageService.setData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA, nationalityData);
    }
  }
  return nationalityData;
}

export function GetCountriesData() {
  var { error, data } = useApi<ICountries[]>(Constants.API_URLS.GET_COUNTRIES);
  var countryData: InfoTypes.Country[] = [];

  if (!error) {
    if(data !== null && data !== undefined && data.length > 0) {
        
      countryData = _.map(data!, (e: ICountries) => {
          return {title: e.value, id: e.id, value: e.id };
      });
      LocalStorageService.setData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA, countryData);
    }
  }
  return countryData;
}

export function GetComplianceTypeDisplayName(complianceType: string) {
  var obj: any = JSON.parse(JSON.stringify(Constants.COMPLIANCE_TYPES_DISPLAY_VALUE));
  return obj[complianceType];
}

export function IsCheckInProgress(check: string) {
  return process.env.REACT_APP_CHECKS_INPROGRESS.includes(check);
}