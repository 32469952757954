import "./index.scss";
import EditIcon from "@material-ui/icons/EditRounded";
import InfoTypes from "../../interfaces/InfoTypes";

interface Props {
  label: string | null;
  showEdit?: boolean;
  value: InfoTypes.SelectValueType | any;
  hideUnderline?: boolean;
  isSelectField?: boolean;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  onEditClicked?: (e: EventTarget) => void;
}

const GenericDataShow = (props: Props) => {
  return (
    <div className="generic-data-show">
      <div className="generic-data-show-label" aria-label={props.label!} style={props.labelStyle}>
        {props.label}{" "}
      </div>
      <div className="generic-data-show-value" style={props.valueStyle}>{(props.isSelectField) ? props.value?.title : props.value}
        <span className={`${props.showEdit ? "edit-icon" : "edit-icon display-none"}`} onClick={(e: any) => {
            e.stopPropagation();
            if (props.onEditClicked) {
              props.onEditClicked(e);
            }
          }} role="img" aria-label="Edit" aria-controls="menu-appbar">
          {<EditIcon />}
        </span>
      </div>
      <div className={`generic-data-show-underline ${props.hideUnderline ? "display-none" : ""}`}></div>
    </div>
  );
};

export default GenericDataShow;
