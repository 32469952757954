import React, { ChangeEvent, useEffect, useState } from "react";
import "./index.scss";
import GenericTextField from "../../Components/GenericTextField";
import GenericSelect from "../../Components/GenericSelect";
import GenericDatePicker from "../../Components/GenericDatePicker";
import GenericButton from "../../Components/GenericButton";
import moment from "moment";
import GenericDataShow from "../../Components/GenericDataShow";
import GenericModal from "../../Components/GenericModal";
import Constants from "../../Constants/constants";
import LocalStorageService from "../../utils/localStorageService";
import { GenericLoader } from "../../Components/GenericLoader";
import IProfilePatchRequest from "../../interfaces/IProfilePatchRequest";
import useAPIRequest from "../../Service/useAPIRequest";
import { useTranslation } from "react-i18next";
import utilFunctions from "../../utils/util";
import useCompareDates from "../../utils/useCompareDates";
import { FormHelperText } from "@material-ui/core";
import { RegexEnum } from "../../Constants/enums";
import useSelectedPostCodeRegex from "../../utils/useSelectedPostCodeRegex";
import IAddressInfo from "../../interfaces/IAddressInfo";

interface EditPrevAddressModalProps {
  selectedPrevAddressToEdit: {
    selectedFieldTitle: string;
    selectedFieldId: any;
    selectedFieldValue: any;
    selectedFieldIndex: number;
    inputProps?: { [key: string]: any };
  };
  prevAddressState: EditPrevAddressState;
  isEditPrevAddressIconClicked: boolean;
  onCloseEditPrevAddressModal: () => void;
  onPrevAddressSaveCallback: (propName: string, val: {} | string, index: number) => void;
  pAddressIdx: number;
  linkedPrevAddress: IAddressInfo
}

interface EditPrevAddressState {
  building: string;
  street: string;
  county: string;
  country: string;
  town: string;
  postCode: string;
  residentFrom: string;
  residentTo: string,
}

const InitialPatchRequest: IProfilePatchRequest = {
  path: "",
  newValue: "",
  reason: "",
};

const genericRegex: RegExp = new RegExp(RegexEnum.GENERIC);

export default function EditPrevAddressModal(props: EditPrevAddressModalProps) {
  const { t } = useTranslation();
  const oneRef = React.createRef();
  const [isEditPrevAddressClicked, setIsEditPrevAddressClicked] = useState(true);
  const [editPrevAddressFieldNewValue, setEditPrevAddressFieldNewValue] = useState("");
  const [editPrevAddressDateNewValue, setEditPrevAddressDateNewValue] = useState<any>(null);
  const [editPrevAddressFieldReason, setEditPrevAddressFieldReason] = useState("");
  const [editPrevAddressSelectedValue, setEditPrevAddressSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [patchRequested, setPatchRequest] = useState(InitialPatchRequest);
  const [editPrevAddressFieldError, setEditPrevAddressFieldError] = useState(false);
  const [editPrevAddressFieldHelperText, setEditPrevAddressFieldHelperText] = useState<string>("");
  const [editPrevAddressReasonError, setEditPrevAddressReasonError] = useState(false);
  const [editPrevAddressReasonHelperText, setEditPrevAddressReasonHelperText] = useState<string>("");
  const [editPrevAddressDateError, setEditPrevAddressDateError] = useState(false);
  const [editPrevAddressDateHelperText, setEditPrevAddressDateHelperText] = useState<string>("");
  const { compareDates } = useCompareDates();
  const selectedPostCodeRegex = useSelectedPostCodeRegex(props.prevAddressState.country);

  const SendPatch = async (patchRequest: IProfilePatchRequest) => {
    useAPIRequest(Constants.API_URLS.PATCH_WORKER_PROFILE, {
      method: "PATCH",
      body: patchRequest,
    });
    props.onPrevAddressSaveCallback(
      props.selectedPrevAddressToEdit.selectedFieldId,
      props.selectedPrevAddressToEdit.selectedFieldId === "residentFrom" ||
        props.selectedPrevAddressToEdit.selectedFieldId === "residentTo"
        ? editPrevAddressDateNewValue
        : editPrevAddressFieldNewValue,
      props.selectedPrevAddressToEdit.selectedFieldIndex
    );
    onEditPrevAddressModalClose();
  };

  useEffect(() => {
    async function SendPatchRequest() {
      if (patchRequested.path !== "") {
        await SendPatch(patchRequested);
      }
    }
    SendPatchRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequested]);

  useEffect(() => {
    setIsEditPrevAddressClicked(props.isEditPrevAddressIconClicked);
  }, [props.isEditPrevAddressIconClicked]);

  const countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);

  const onEditPrevAddressModalClose = (e?: EventTarget) => {
    setIsEditPrevAddressClicked(false);
    setEditPrevAddressFieldReason("");
    setEditPrevAddressFieldNewValue("");
    setEditPrevAddressSelectedValue("");
    props.onCloseEditPrevAddressModal();
  };

  const handleEditPrevAddressReasonChange = (e: ChangeEvent<HTMLInputElement>, val: string) => {
    setEditPrevAddressFieldReason(val);
  };

  const handleEditPrevAddressChange =
    (prop: keyof EditPrevAddressState, key?: string) => (event: ChangeEvent<{}>, val: string) => {
      setEditPrevAddressFieldNewValue(prop === "postCode" ? val.toUpperCase() : val);
    };

  const handleEditPrevAddressSelectChange =
    (prop: keyof EditPrevAddressState, key?: string) => (event: ChangeEvent<{}>, val: any) => {
      setEditPrevAddressFieldNewValue(val);
      setEditPrevAddressSelectedValue(val.id);
    };

  const handleEditPrevAddressDateChange =
    (prop: keyof EditPrevAddressState) => (val: Date | null) => {
      setEditPrevAddressDateNewValue(val!);
    };

    useEffect(() => {
      const todayIsDate = moment();
      const fieldType = props.selectedPrevAddressToEdit.selectedFieldId;
      let fieldError = false;
      let fieldHelperText = '';
      let reasonError = false;
      let reasonHelperText = '';
      let dateError = false;
      let dateHelperText = '';
    
      if (editPrevAddressFieldNewValue === '') {
        fieldError = true;
        fieldHelperText = t("validators.required");
      } else {
        const isPostCodeField =  fieldType === "postCode";
        const isRegexValid = !utilFunctions.testRegex(isPostCodeField ? selectedPostCodeRegex : genericRegex, editPrevAddressFieldNewValue);
        const isCountryField = (fieldType === "country") ? "" : t("validators.special_chars");
        const regexErrorText = isPostCodeField ? t("validators.invalid_postcode") : isCountryField;
        fieldError = (fieldType === "country") ? false : isRegexValid;
        fieldHelperText = isRegexValid ? regexErrorText : '';
      }
    
      if (editPrevAddressFieldReason === '') {
        reasonError = true;
        reasonHelperText = t("validators.required");
      } else {
        const isReasonFieldValid = !utilFunctions.testRegex(genericRegex, editPrevAddressFieldReason);
        reasonError = isReasonFieldValid;
        reasonHelperText = isReasonFieldValid ? t("validators.special_chars") : '';
      }

      if(editPrevAddressDateNewValue === null || editPrevAddressDateNewValue === 'Invalid date'){
        dateError = true;
        dateHelperText = t("errors.is_valid_date_or_empty_msg");
    } else if (moment(editPrevAddressDateNewValue) > todayIsDate) {
        dateError = true;
        dateHelperText = t("errors.current_date_greater_msg");
    } else {
        const prevAddress = props.prevAddressState;
        const newDate = moment(editPrevAddressDateNewValue).format('MM/DD/YYYY');
        const currentDate = moment(props.linkedPrevAddress.currentAddress.residentFrom).format('MM/DD/YYYY');

        if (((props.pAddressIdx === 0 && (newDate !== currentDate)) || (props.pAddressIdx >= 1 
              && props.linkedPrevAddress.previousAddresses 
              && (newDate !== moment(props.linkedPrevAddress.previousAddresses[props.pAddressIdx - 1]!.residentFrom).format('MM/DD/YYYY')))) 
              && fieldType === 'residentTo'
        ) {
          dateError = true;
          dateHelperText = t("errors.previous_dates_current_dates_must_be_consecutive");
        }
        else if((props.pAddressIdx >= 0 
          && props.linkedPrevAddress.previousAddresses 
          && props.linkedPrevAddress.previousAddresses[props.pAddressIdx + 1]
          && (newDate !== moment(props.linkedPrevAddress.previousAddresses[props.pAddressIdx + 1]!.residentTo).format('MM/DD/YYYY')) 
          && fieldType === 'residentFrom' 
        )) {
          dateError = true;
          dateHelperText = t("errors.previous_dates_current_dates_must_be_consecutive");
        } else {
          const {isValid, errorMessage} = compareDates(editPrevAddressDateNewValue, prevAddress, fieldType);
          dateError = isValid;
          dateHelperText = errorMessage
        }
    }
    
      setEditPrevAddressFieldError(fieldError);
      setEditPrevAddressFieldHelperText(fieldHelperText);
      setEditPrevAddressReasonError(reasonError);
      setEditPrevAddressReasonHelperText(reasonHelperText);
      setEditPrevAddressDateError(dateError);
      setEditPrevAddressDateHelperText(dateHelperText);
    }, [editPrevAddressFieldNewValue, 
      editPrevAddressFieldReason, 
      editPrevAddressDateNewValue, 
      t, 
      props.selectedPrevAddressToEdit.selectedFieldId, 
      props.prevAddressState, 
      compareDates, 
      selectedPostCodeRegex, 
      props.linkedPrevAddress.currentAddress.residentFrom, 
      props.pAddressIdx, 
      props.linkedPrevAddress.previousAddresses
    ]);

  const renderInputPrevAddressComponent = () => {
    switch (props.selectedPrevAddressToEdit.selectedFieldId) {
      case "residentFrom":
      case "residentTo":
        return (
          <GenericDatePicker
            variant="inline"
            label={props.selectedPrevAddressToEdit.selectedFieldTitle}
            id="date-picker-inline"
            inputVariant="outlined"
            required
            value={editPrevAddressDateNewValue}
            onChangeValue={handleEditPrevAddressDateChange(
              props.selectedPrevAddressToEdit.selectedFieldId
            )}
            format={Constants.DATE_FORMAT_CALENDAR}
            enableFuture={false}
            fullWidth={true}
            error={editPrevAddressDateError}
            helperText={editPrevAddressDateHelperText}
          />
        );
      case "building":
      case "street":
      case "postCode":
      case "county":
      case "town":
        return (
          <GenericTextField
            label={props.selectedPrevAddressToEdit.selectedFieldTitle}
            name={props.selectedPrevAddressToEdit.selectedFieldId}
            type="text"
            id={props.selectedPrevAddressToEdit.selectedFieldId}
            variant="outlined"
            margin="dense"
            fullWidth={true}
            required={props.selectedPrevAddressToEdit.selectedFieldId !== "county"}
            value={editPrevAddressFieldNewValue}
            inputRef={oneRef}
            inputProps={props.selectedPrevAddressToEdit?.inputProps}
            onChangeValue={handleEditPrevAddressChange(
              props.selectedPrevAddressToEdit.selectedFieldId
            )}
            error={editPrevAddressFieldError}
            helperText={editPrevAddressFieldHelperText}
          />
        );
      case "country":
        return (
          <>
            <GenericSelect
              id="country"
              value={editPrevAddressFieldNewValue}
              dataSet={countriesData}
              fullWidth={true}
              handleChange={handleEditPrevAddressSelectChange("country")}
              multiple={false}
              freeSolo={false}
              label={t("address.country")}
              renderChip={false}
              required={true}
              error={editPrevAddressFieldError}
            />
            <FormHelperText className="helper-text-error MuiFormHelperText-contained">
              {editPrevAddressFieldHelperText}
            </FormHelperText>
          </>
        );
      default:
        return <></>;
    }
  };

  const acceptEditPrevAddressChange = () => {
    setLoading(true);
    let path,
      newVal: any = "";
    path =
      "addresses.previousAddresses[" +
      props.selectedPrevAddressToEdit.selectedFieldIndex +
      "]." +
      props.selectedPrevAddressToEdit.selectedFieldId;

    switch (props.selectedPrevAddressToEdit.selectedFieldId) {
      case "country":
        newVal = editPrevAddressSelectedValue;
        break;
      case "residentFrom":
      case "residentTo":
        newVal = moment(editPrevAddressDateNewValue).format(Constants.DATE_FORMAT_DISPLAY);
        break;
      case "building":
      case "street":
      case "postCode":
      case "county":
      case "town":
        newVal = editPrevAddressFieldNewValue;
        break;
      default:
        path = "";
        newVal = "";
        break;
    }
    let patchRequest: IProfilePatchRequest = {
      path: path,
      newValue: newVal,
      reason: editPrevAddressFieldReason,
    };
    setPatchRequest(patchRequest);
  };

  return (
    <>
      <GenericModal
        heading={t("address.edit_prev_address")}
        onCloseModal={(e?: EventTarget) => onEditPrevAddressModalClose(e)}
        open={props.isEditPrevAddressIconClicked && isEditPrevAddressClicked}
        closeOnBackDrop={true}
        modalWidth={"500px"}
        modalHeight={"400px"}
        modalTop={"15%"}
        className="personal-info-edit-modal"
      >
        <div className="personal-info-edit-modal">
          {loading ? (
            <div style={{ marginTop: "50px", textAlign: "center" }}>
              <GenericLoader />
              <div className="loading-text">{t("common.saving")}</div>
            </div>
          ) : (
            <>
              <div className="fullWidth">
                <GenericDataShow
                  label={props.selectedPrevAddressToEdit.selectedFieldTitle}
                  value={
                    props.selectedPrevAddressToEdit.selectedFieldId === "residentFrom" ||
                    props.selectedPrevAddressToEdit.selectedFieldId === "residentTo"
                      ? moment(props.selectedPrevAddressToEdit.selectedFieldValue).format(
                          Constants.DATE_FORMAT_DISPLAY
                        )
                      : props.selectedPrevAddressToEdit.selectedFieldValue
                  }
                  isSelectField={props.selectedPrevAddressToEdit.selectedFieldId === "country"}
                />
              </div>
              <div className="fullWidth">{renderInputPrevAddressComponent()}</div>
              <div className="fullWidth">
                <GenericTextField
                  label={t("common.reason_for_change")}
                  name={"reasonForChange"}
                  type="text"
                  id={"reasonForChange"}
                  variant="outlined"
                  margin="dense"
                  fullWidth={true}
                  required
                  value={editPrevAddressFieldReason}
                  inputRef={oneRef}
                  onChangeValue={handleEditPrevAddressReasonChange}
                  error={editPrevAddressReasonError}
                  helperText={editPrevAddressReasonHelperText}
                />
              </div>
              <div
                className="fullWidth"
                style={{ width: "100%", display: "flex", justifyContent: "center" }}
              >
                <GenericButton
                  onClick={() => {
                    acceptEditPrevAddressChange();
                  }}
                  disabled={
                    props.selectedPrevAddressToEdit.selectedFieldId === "residentFrom" ||
                    props.selectedPrevAddressToEdit.selectedFieldId === "residentTo"
                      ? editPrevAddressDateError || editPrevAddressReasonError
                      : editPrevAddressFieldError || editPrevAddressReasonError
                  }
                  type="primary"
                  buttonText={t("common.save")}
                />
              </div>
            </>
          )}
        </div>
      </GenericModal>
    </>
  );
}
