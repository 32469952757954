import "./index.scss";
import GenericButton from "../../Components/GenericButton";
import Uploader from "../../Components/Uploader";
import { ChangeEvent, useEffect, useState } from "react";
import _ from "lodash";
import InfoTypes from "../../interfaces/InfoTypes";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import GenericSelect from "../../Components/GenericSelect";
import IDocumentGroupList from "../../interfaces/IDocumentGroupList";
import Constants from "../../Constants/constants";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import IDocumentDefinition from "../../interfaces/IDocumentDefinition";
import IProfile from "../../interfaces/IProfile";
import LocalStorageService from "../../utils/localStorageService";
import moment from "moment";
import Heading from "../../Components/Heading";
import useAPIRequest from "../../Service/useAPIRequest";
import { GetCountriesData } from "../../utils/referenceDataService";
import PromptNationality from "./PromptNationality";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "../../Components/LoaderModal";
import EditInfoModal from "./EditInfoModal";
import utilFunctions from "../../utils/util";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import YotiIDV from "../Yoti/YotiIDV";
import { DigitalIDOption, ImportableTypeEditor, ImportStatus } from "../../Constants/enums";
import YotiDigitalID from "../Yoti/YotiDigitalID";
import IValidationFailure from "../../interfaces/IValidationFailure";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import SelectEditor from "../../Components/CheckEditors/SelectEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import {
  DocGroupFieldsState,
  DocGroupState,
  InitialDocGroupFieldsState,
  InitialDocGroupState,
  InitialDocValues,
  InitialPrimaryDocumentDefinition,
  InitialSecondaryDocumentDefinition,
  InitialTernaryDocumentDefinition,
  VerifyIdentityProps,
} from "./types";
import { documentGroups } from "../../Contexts/DocumentsContext/documents-types";
import PromptIssueDates from "./PromptIssueDates";

export default function VerifyIdentity(props: VerifyIdentityProps) {
  const { t } = useTranslation();
  const [isDigitalVerifyOption, setIsDigitalVerifyOption] = useState("yes");
  const [docGroupListState, setDocGroupListState] = useState<IDocumentGroupList>();
  const [docGroupSelection, setDocGroupSelection] =
    useState<InfoTypes.DocumentGroupDefinitionSelection>(InitialDocGroupState);
  const [identityDocValues, setIdentityDocValues] =
    useState<IDocumentDefinition[]>(InitialDocValues);
  const [documentTypeFields, setDocumentTypeFields] = useState<DocGroupFieldsState>(
    InitialDocGroupFieldsState
  );
  const [confirmNationality, setConfirmNationality] = useState(false);
  const [confirmDocumentIssueDate, setConfirmDocumentIssueDate] = useState(false);
  const [workerCurrNationality, setWorkerCurrNationality] = useState<string>("");
  const [documentDefinitionData, setDocumentDefinitionData] = useState<IDocumentDefinition[]>();
  const [documentsRequest, setDocumentsRequest] = useState<IComplianceProcess>();
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isPrimaryFormValid, setIsPrimaryFormValid] = useState(false);
  const [isSecondaryFormValid, setIsSecondaryFormValid] = useState(false);
  const [isTernaryFormValid, setIsTernaryFormValid] = useState(false);

  const [isPrimaryFileExist, setIsPrimaryFileExist] = useState(false);
  const [isSecondaryFileExist, setIsSecondaryFileExist] = useState(false);
  const [isTernaryFileExist, setIsTernaryFileExist] = useState(false);

  const [isPrimarySelectSwitch, setIsPrimarySelectSwitch] = useState(false);
  const [isSecondarySelectSwitch, setIsSecondarySelectSwitch] = useState(false);
  const [isTernarySelectSwitch, setIsTernarySelectSwitch] = useState(false);

  const [isPrimarySelectSwitchMessage, setIsPrimarySelectSwitchMessage] = useState(false);
  const [isSecondarySelectSwitchMessage, setIsSecondarySelectSwitchMessage] = useState(false);
  const [isTernarySelectSwitchMessage, setIsTernarySelectSwitchMessage] = useState(false);

  const [primaryCountryError, setPrimaryCountryError] = useState(false);
  const [primaryCountryHelperText, setPrimaryCountryHelperText] = useState<string>("");

  const [secondaryCountryError, setSecondaryCountryError] = useState(false);
  const [secondaryCountryHelperText, setSecondaryCountryHelperText] = useState<string>("");

  const [ternaryCountryError, setTernaryCountryError] = useState(false);
  const [ternaryCountryHelperText, setTernaryCountryHelperText] = useState<string>("");

  const [primaryValidToError, setPrimaryValidToError] = useState(false);
  const [primaryValidFromError, setPrimaryValidFromHError] = useState(false);
  const [primaryValidToHelperText, setPrimaryValidToHelperText] = useState<string>("");
  const [primaryValidFromHelperText, setPrimaryValidFromHelperText] = useState<string>("");

  const [secondaryValidToError, setSecondaryValidToError] = useState(false);
  const [secondaryValidFromError, setSecondaryValidFromHError] = useState(false);
  const [secondaryValidToHelperText, setSecondaryValidToHelperText] = useState<string>("");
  const [secondaryValidFromHelperText, setSecondaryValidFromHelperText] = useState<string>("");

  const [ternaryValidToError, setTernaryValidToError] = useState(false);
  const [ternaryValidFromError, setTernaryValidFromHError] = useState(false);
  const [ternaryValidToHelperText, setTernaryValidToHelperText] = useState<string>("");
  const [ternaryValidFromHelperText, setTernaryValidFromHelperText] = useState<string>("");

  const [selectedFieldToEdit, setSelectedFieldToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
  });

  const [isEditRequest, setIsEditRequest] = useState(false);
  const [allAttachments] = useState([]);
  const [, setTempFiles] = useState([]);
  const [, setAttachementDisabled] = useState(true);

  let countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
  if (!countriesData) {
    countriesData = GetCountriesData();
  }

  useEffect(() => {
    setLoading(true);
    let profileDtls: IProfile = LocalStorageService.getData(
      Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
    );
    if (props.complianceProcessData) {
      if (
        props.complianceProcessData?.processData?.personal?.currentNationality &&
        props.complianceProcessData.processData.personal.currentNationality !== ""
      ) {
        setWorkerCurrNationality(
          props.complianceProcessData.processData.personal.currentNationality!
        );
      } else if (
        profileDtls?.personal?.currentNationality &&
        profileDtls.personal.currentNationality !== ""
      ) {
        setWorkerCurrNationality(profileDtls.personal.currentNationality!);
      } else {
        setWorkerCurrNationality("");
      }

      if (props.complianceProcessData.digitalIdentity) {
        if (
          props.complianceProcessData.retriesDigitalIdentityNumber &&
          props.complianceProcessData.retriesDigitalIdentityNumber > 2
        ) {
          console.log("retry");
          setIsDigitalVerifyOption("no");
        } else {
          console.log("max retry");
          setIsDigitalVerifyOption("yes");
        }
      } else if (props.complianceProcessData?.processData?.documents) {
        setIsDigitalVerifyOption("no");
      } else {
        setIsDigitalVerifyOption("yes");
      }
    } else {
      console.log("InitialDocValues");
      setDocumentDefinitionData(InitialDocValues);
    }
    setLoading(false);
  }, [props.complianceProcessData]);

  useEffect(() => {
    if (workerCurrNationality !== "") {
      setConfirmNationality(false);
      GetDocumentGroupList(workerCurrNationality!);
    }
  }, [workerCurrNationality]);

  useEffect(() => {
    if (
      props.complianceProcessData &&
      props.complianceProcessData.processData &&
      props.complianceProcessData.processData.documents &&
      props.complianceProcessData.digitalIdentity &&
      props.complianceProcessData.digitalIdentity?.isComplete &&
      props.complianceProcessData.digitalIdentity?.importStatus === ImportStatus.Imported
    ) {
      props.complianceProcessData.processData.documents.forEach((document) => {
        if (!document.validFrom) {
          if (document.documentId.toLowerCase().includes("passport")) {
            setConfirmDocumentIssueDate(true);
          }
        }
      });
    }
  }, [props.complianceProcessData]);

  useEffect(() => {
    let docDefData: IDocumentDefinition[];
    let profileDtls: IProfile = LocalStorageService.getData(
      Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
    );
    if (
      props.complianceProcessData.processData &&
      props.complianceProcessData.processData.documents
    ) {
      docDefData = props.complianceProcessData.processData.documents;
      setDocumentDefinitionData(docDefData);
    } else {
      if (profileDtls && profileDtls.documents) {
        docDefData = profileDtls.documents;
        setDocumentDefinitionData(docDefData);
      } else {
        docDefData = InitialDocValues;
        setDocumentDefinitionData(InitialDocValues);
      }
    }

    let dataToUpdate = docDefData;
    let valuesToUpdate = identityDocValues;
    let docGrp1, docGrp2, docGrp3: InfoTypes.DocumentGroupDefinition;
    if (dataToUpdate && dataToUpdate?.[0] !== undefined && dataToUpdate[0].documentId! !== "") {
      if (docGroupListState?.primaryDocuments && docGroupListState.primaryDocuments.length > 0) {
        const docGrp = docGroupListState.primaryDocuments.find(
          (d: InfoTypes.DocumentGroupDefinition) =>
            d.id.toLowerCase() === dataToUpdate![0].documentId.toLowerCase()
        );
        if (docGrp) {
          docGrp1 = docGrp;
          GetDocumentGroupSelectedLabels("primaryDocuments", docGrp1.type!);
        }

        if (dataToUpdate[0].emittingCountry && dataToUpdate[0].emittingCountry !== "") {
          let cntry = countriesData.find(
            (c: InfoTypes.Country) =>
              c.id.toLowerCase() === dataToUpdate![0].emittingCountry.toLowerCase()
          );
          if (cntry) {
            dataToUpdate[0].emittingCountryVal = {
              id: cntry.id,
              title: cntry.title,
              value: cntry.value,
            };
          }
        }
        if (dataToUpdate[0].url && dataToUpdate[0].url !== "") {
          dataToUpdate[0].docFiles = [{ uri: dataToUpdate[0].url }];
        }
        valuesToUpdate[0] = dataToUpdate[0];
      }
    }

    if (dataToUpdate && dataToUpdate?.[1] !== undefined && dataToUpdate[1].documentId! !== "") {
      if (
        docGroupListState?.secondaryDocuments &&
        docGroupListState.secondaryDocuments.length > 0
      ) {
        const docGrp = docGroupListState.secondaryDocuments.find(
          (d: InfoTypes.DocumentGroupDefinition) =>
            d.id.toLowerCase() === dataToUpdate![1].documentId.toLowerCase()
        );
        if (docGrp) {
          docGrp2 = docGrp;
          GetDocumentGroupSelectedLabels("secondaryDocuments", docGrp2.type!);
        }

        if (dataToUpdate[1].emittingCountry && dataToUpdate[1].emittingCountry !== "") {
          let cntry2 = countriesData.find(
            (c: InfoTypes.Country) =>
              c.id.toLowerCase() === dataToUpdate![1].emittingCountry.toLowerCase()
          );
          if (cntry2) {
            dataToUpdate[1].emittingCountryVal = {
              id: cntry2.id,
              title: cntry2.title,
              value: cntry2.value,
            };
          }
        }
        if (dataToUpdate[1].url && dataToUpdate[1].url !== "") {
          dataToUpdate[1].docFiles = [{ uri: dataToUpdate[1].url }];
        }
        valuesToUpdate[1] = dataToUpdate[1];
      }
    }

    if (dataToUpdate && dataToUpdate?.[2] !== undefined && dataToUpdate[2].documentId! !== "") {
      if (docGroupListState?.ternaryDocuments && docGroupListState.ternaryDocuments.length > 0) {
        const docGrp = docGroupListState.ternaryDocuments.find(
          (d: InfoTypes.DocumentGroupDefinition) =>
            d.id.toLowerCase() === dataToUpdate![2].documentId.toLowerCase()
        );
        if (docGrp) {
          docGrp3 = docGrp;
          GetDocumentGroupSelectedLabels("ternaryDocuments", docGrp3.type!);
        }

        if (dataToUpdate[2].emittingCountry && dataToUpdate[2].emittingCountry !== "") {
          let cntry3 = countriesData.find(
            (c: InfoTypes.Country) =>
              c.id.toLowerCase() === dataToUpdate![2].emittingCountry.toLowerCase()
          );
          if (cntry3) {
            dataToUpdate[2].emittingCountryVal = {
              id: cntry3.id,
              title: cntry3.title,
              value: cntry3.value,
            };
          }
        }
        if (dataToUpdate[2].url && dataToUpdate[2].url !== "") {
          dataToUpdate[2].docFiles = [{ uri: dataToUpdate[2].url }];
        }
        valuesToUpdate[2] = dataToUpdate[2];
      }
    }
    let docGrpSelected: InfoTypes.DocumentGroupDefinitionSelection = InitialDocGroupState;

    if (docGrp1) docGrpSelected.primaryDocuments = docGrp1;

    if (docGrp2) docGrpSelected.secondaryDocuments = docGrp2;

    if (docGrp3!) docGrpSelected.ternaryDocuments = docGrp3;

    setDocGroupSelection(docGrpSelected);
    setDocumentDefinitionData(valuesToUpdate);
    setIdentityDocValues(valuesToUpdate);
    //console.log("after everything", dataToUpdate, documentDefinitionData, "identityDocValues", identityDocValues, "docGroupSelection", docGroupSelection, "documentTypeFields", documentTypeFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docGroupListState]);

  useEffect(() => {
    setDocumentDefinitionData(identityDocValues);
    setIdentityDocValues(identityDocValues);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeFields]);

  const GetDocumentGroupList = async (nationality: string) => {
    let docGrpList: IDocumentGroupList = {
      primaryDocuments: [],
      secondaryDocuments: [],
      ternaryDocuments: [],
    };

    setLoading(true);
    useAPIRequest(Constants.API_URLS.GET_DOCUMENTS_GROUPS_DBS + nationality.toLowerCase(), {
      method: "GET",
      body: "",
    })
      .then((res) => {
        setLoading(false);
        if (res && res !== undefined) {
          if (res) {
            documentGroups.forEach((group) => {
              docGrpList[group] = _.map(res![group], (e: InfoTypes.DocumentGroupDefinition) => ({
                ...e,
                title: e.description!,
                id: e.id,
                documentId: e.id,
                value: e.id,
              }));
            });

            setDocGroupListState(docGrpList);
          }
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleVerifyOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDigitalVerifyOption((event.target as HTMLInputElement).value);
    setDocGroupSelection(InitialDocGroupState);
    if ((event.target as HTMLInputElement).value === "no") {
      if (workerCurrNationality !== "") {
        setConfirmNationality(false);
        GetDocumentGroupList(workerCurrNationality!);
      } else {
        setConfirmNationality(true);
      }
    }
  };

  const handleConfirmSaveCallback = (val: string) => {
    if (val !== "") {
      setWorkerCurrNationality(val);
      setConfirmNationality(false);
      GetDocumentGroupList(val);
    } else {
      setIsDigitalVerifyOption("yes");
      setConfirmNationality(false);
    }
  };

  const handleConfirmSaveIssueDatesCallback = () => {
    setConfirmDocumentIssueDate(false);
  };

  const handleDocGroupChange =
    (prop: keyof DocGroupState, key?: string) => (event: ChangeEvent<{}>, val: any) => {
      let docGrpListFiltered = docGroupListState;
      let docDefinition = documentDefinitionData;
      let identityDefinition = identityDocValues;
      let primarySelectSwitchFirstAttempt = 0;
      let secondarySelectSwitchFirstAttempt = 0;
      let ternarySelectSwitchFirstAttempt = 0;

      const primaryDocsFiltered = utilFunctions.filterDocuments(
        docGroupListState!.primaryDocuments,
        val
      );
      const secondaryDocsFiltered = utilFunctions.filterDocuments(
        docGroupListState!.secondaryDocuments,
        val
      );
      const ternaryDocsFiltered = utilFunctions.filterDocuments(
        docGroupListState!.ternaryDocuments,
        val
      );

      setDocumentTypeFields({
        ...documentTypeFields,
        [prop]: {},
      });
      switch (prop) {
        case "primaryDocuments":
          docGrpListFiltered!.secondaryDocuments = secondaryDocsFiltered;
          docGrpListFiltered!.ternaryDocuments = ternaryDocsFiltered;
          if (docDefinition && docDefinition[0]) {
            utilFunctions.setDocumentDefinition(
              docDefinition,
              0,
              InitialPrimaryDocumentDefinition,
              val
            );
            utilFunctions.setDocumentDefinition(
              identityDefinition,
              0,
              InitialPrimaryDocumentDefinition,
              val
            );

            if (documentDefinitionData && documentDefinitionData[0].filename !== "")
              documentDefinitionData[0].filename = "";

            setIsPrimaryFileExist(false);

            primarySelectSwitchFirstAttempt++;
            primarySelectSwitchFirstAttempt > 0
              ? setIsPrimarySelectSwitchMessage(true)
              : setIsPrimarySelectSwitchMessage(false);
            setIsPrimarySelectSwitch(true);
          }
          break;
        case "secondaryDocuments":
          docGrpListFiltered!.primaryDocuments = primaryDocsFiltered;
          docGrpListFiltered!.ternaryDocuments = ternaryDocsFiltered;
          if (docDefinition && docDefinition[1]) {
            utilFunctions.setDocumentDefinition(
              docDefinition,
              1,
              InitialSecondaryDocumentDefinition,
              val
            );
            utilFunctions.setDocumentDefinition(
              identityDefinition,
              1,
              InitialSecondaryDocumentDefinition,
              val
            );

            if (documentDefinitionData && documentDefinitionData[1].filename !== "")
              documentDefinitionData[1].filename = "";

            setIsSecondaryFileExist(false);

            secondarySelectSwitchFirstAttempt++;
            secondarySelectSwitchFirstAttempt > 0
              ? setIsSecondarySelectSwitchMessage(true)
              : setIsSecondarySelectSwitchMessage(false);
            setIsSecondarySelectSwitch(true);
          }
          break;
        case "ternaryDocuments":
          docGrpListFiltered!.primaryDocuments = primaryDocsFiltered;
          docGrpListFiltered!.secondaryDocuments = secondaryDocsFiltered;
          if (docDefinition && docDefinition[2]) {
            utilFunctions.setDocumentDefinition(
              docDefinition,
              2,
              InitialTernaryDocumentDefinition,
              val
            );
            utilFunctions.setDocumentDefinition(
              identityDefinition,
              2,
              InitialTernaryDocumentDefinition,
              val
            );

            if (documentDefinitionData && documentDefinitionData[2].filename !== "")
              documentDefinitionData[2].filename = "";

            setIsTernaryFileExist(false);

            ternarySelectSwitchFirstAttempt++;
            ternarySelectSwitchFirstAttempt > 0
              ? setIsTernarySelectSwitchMessage(true)
              : setIsTernarySelectSwitchMessage(false);
            setIsTernarySelectSwitch(true);
          }
          break;
        default:
          break;
      }
      setDocumentDefinitionData(docDefinition);
      setIdentityDocValues(identityDefinition);
      setDocGroupListState(docGrpListFiltered);
      setDocGroupSelection({
        ...docGroupSelection,
        [prop]: val!,
      });
      GetDocumentGroupSelectedLabels(prop, val.type);
    };

  const GetDocumentGroupSelectedLabels = (prop: keyof DocGroupState, docType: string) => {
    let docGrpTypeFields = documentTypeFields;

    useAPIRequest(
      Constants.API_URLS.DOCUMENTS + docType + "/" + Constants.API_URLS.GET_DOCUMENT_TYPE_FIELDS,
      {
        method: "GET",
        body: "",
      }
    )
      .then((res) => {
        if (res && res !== undefined) {
          docGrpTypeFields![prop] = res;
          setDocumentTypeFields((items) => ({
            ...items,
            [prop]: res,
          }));
        }
      })
      .catch((e) => {});
  };

  //******** Start - Event Handlers**** */

  const handleSubmit = () => {
    if (isDigitalVerifyOption === "no") {
      pushUpdateToDocumentsData();
    } else if (props.complianceProcessData.digitalIdentity?.isComplete) {
      if (props.handleSubmit) {
        props.handleSubmit(
          props.complianceProcessData,
          Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON
        );
      }
    } else if (props.errorHandler) {
      props.errorHandler(t("errors.finish_id_verification"));
    }
  };

  const pushUpdateToDocumentsData = () => {
    let docs = _.map(identityDocValues, (doc: IDocumentDefinition) => {
      return { ...doc };
    });

    let documentsData: IDocumentDefinition[] = utilFunctions.cleanseJson(docs);
    let prepDocumentData: IComplianceProcess = {
      id: props.complianceProcessData.id,
      complianceType: props.complianceProcessData.complianceType,
      processData: {
        documents: documentsData,
      },
    };
    setDocumentsRequest(prepDocumentData);
  };

  const handleDocValueChange =
    (prop: keyof InfoTypes.DocumentDefinition, index: number) =>
    (event: ChangeEvent<HTMLInputElement>, val: string) => {
      setIdentityDocValues((items) => ({
        ...items,
        [index]: {
          ...items[index],
          [prop]: val,
        },
      }));
    };

  const handleDocValueDateChange =
    (prop: keyof InfoTypes.DocumentDefinition, index: number) => (val: Date | null) => {
      setIdentityDocValues((items) => ({
        ...items,
        [index]: {
          ...items[index],
          [prop]: moment(val!).format(Constants.DATE_FORMAT_ISO),
        },
      }));
    };

  const handleDocValueSelectChange =
    (prop: keyof InfoTypes.DocumentDefinition, index: number) =>
    (event: any, val: InfoTypes.SelectValueType) => {
      setIdentityDocValues((items) => ({
        ...items,
        [index]: {
          ...items[index],
          [prop]: val.id,
          [prop + "Val"]: val,
        },
      }));
    };

  //******** End - Event Handlers**** */

  //******** Start - Edit Data Fields Event Handlers**** */

  const onEditModalClose = () => {
    setIsEditClicked(false);
    setSelectedFieldToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
    });
  };

  const handleEditSaveCallback = (
    prop: keyof InfoTypes.DocumentDefinition,
    val: any,
    index: number
  ) => {
    let docValToUpdate: any = documentDefinitionData;
    if (prop === "emittingCountry") {
      docValToUpdate![index][prop + "Val"] = val;
      docValToUpdate![index][prop] = val.id;
    } else if (prop === "validFrom" || prop === "validTo") {
      docValToUpdate![index][prop] = moment(val).format(Constants.DATE_FORMAT_ISO);
    } else {
      docValToUpdate![index][prop] = val;
    }
    setDocumentDefinitionData(docValToUpdate);
    setIdentityDocValues(docValToUpdate);
    setIsEditRequest(true);
    pushUpdateToDocumentsData();
  };

  const handleEditFieldChange = (id: string, title: string, val: any, mNameIdx: number) => {
    setSelectedFieldToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: mNameIdx,
    });
  };

  //******** End - Edit Data Fields Event Handlers**** */

  //******** Start - PUT/Save Documents Data Handlers**** */

  const SendPutDocumentsData = async (docRequest: IComplianceProcess) => {
    setLoading(true);
    useAPIRequest(Constants.API_URLS.PUT_WORKER_ROLE_COMPLIANCE + props.complianceProcessData.id, {
      method: "PUT",
      body: docRequest,
    })
      .then((res) => {
        if (res.ok) {
          if (props.handleSubmit && !isEditRequest) {
            props.handleSubmit(
              docRequest,
              Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON
            );
          }
        } else {
          handleSaveError(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (props.errorHandler) {
          props.errorHandler(t("errors.save_error_msg"));
        }
      });
    setIsEditRequest(false);
  };

  const handleSaveError = (errors?: IValidationFailure[]) => {
    if (props.errorHandler) {
      if (errors) {
        let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";
        _.map(errors, (err) => {
          errorMsg = errorMsg.concat("<li>", err.errorMessage, "</li>");
        });
        errorMsg.concat("</ul>");
        props.errorHandler(errorMsg);
      } else {
        props.errorHandler(t("errors.save_error_msg"));
      }
    }
  };

  useEffect(() => {
    async function SendPutDocumentsDataRequest() {
      if (documentsRequest) {
        await SendPutDocumentsData(documentsRequest);
      }
    }
    SendPutDocumentsDataRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsRequest]);

  //******** End - PUT/Save Documents Data Handlers**** */

  /********Start - Document Upload Handler********** */

  const pushPrimaryDoc = (files: any) => {
    setIsPrimarySelectSwitch(false);
    pushFiles(files, 0);
  };

  const pushSecondaryDoc = (files: any) => {
    setIsSecondarySelectSwitch(false);
    pushFiles(files, 1);
  };
  const pushTernaryDoc = (files: any) => {
    setIsTernarySelectSwitch(false);
    pushFiles(files, 2);
  };

  const pushFiles = (files: any, index: number) => {
    _.forEach(files, async (e: any) => {
      e.fileFrom = "local";
      let arr: any = await utilFunctions.getFileToByteArray(e);
      e.binaryFormat = Array.from(new Uint8Array(arr));

      let identityDocVals = identityDocValues;
      identityDocVals[index].binary = files[0].binaryFormat;
      identityDocVals[index].filename = files[0].name;
      identityDocVals[index].contentType = files[0].type;

      index === 0
        ? setIsPrimaryFileExist(true)
        : index === 1
        ? setIsSecondaryFileExist(true)
        : setIsTernaryFileExist(true);

      setIdentityDocValues(identityDocVals);
    });

    if (files?.length > 0 && files?.length < 1) {
      setAttachementDisabled(false);
      setTempFiles(files);
    } else {
      setAttachementDisabled(true);
      setTempFiles([]);
    }
  };

  const removePrimaryDocument = () => {
    removeDocument(0);
  };

  const removeSecondaryDoc = () => {
    removeDocument(1);
  };

  const removeTernaryDoc = () => {
    removeDocument(2);
  };

  const removeDocument = (index: number) => {
    let identityDocVals = identityDocValues;

    delete identityDocVals[index].binary;
    delete identityDocVals[index].filename;

    index === 0
      ? setIsPrimaryFileExist(false)
      : index === 1
      ? setIsSecondaryFileExist(false)
      : setIsTernaryFileExist(false);

    setIdentityDocValues(identityDocVals);
  };

  const handleRemoveSavedFile = (index: number) => {
    let identityVals = identityDocValues;

    delete identityVals[index].binary;
    delete identityVals[index].filename;
    delete identityVals[index].url;

    index === 0
      ? setIsPrimaryFileExist(false)
      : index === 1
      ? setIsSecondaryFileExist(false)
      : setIsTernaryFileExist(false);

    setDocumentDefinitionData(identityVals);
    setIdentityDocValues(identityVals);
  };

  const handleDownloadSavedFile = (index: number) => {
    const newWindow = window.open(identityDocValues[index].url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  /********End - Document Upload Handler********** */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (documentDefinitionData) {
      const primaryId = documentDefinitionData![0].identifier,
        secondaryId = documentDefinitionData![1].identifier,
        ternaryId = documentDefinitionData![2].identifier;
      if (
        primaryId !== "" &&
        documentDefinitionData![0].hasOwnProperty("filename") &&
        documentDefinitionData![0].filename !== ""
      ) {
        setIsPrimaryFormValid(false);
        setIsPrimaryFileExist(true);
      }
      if (
        secondaryId !== "" &&
        documentDefinitionData![1].hasOwnProperty("filename") &&
        documentDefinitionData![1].filename !== ""
      ) {
        setIsSecondaryFormValid(false);
        setIsSecondaryFileExist(true);
      }
      if (
        ternaryId !== "" &&
        documentDefinitionData![2].hasOwnProperty("filename") &&
        documentDefinitionData![2].filename !== ""
      ) {
        setIsTernaryFormValid(false);
        setIsTernaryFileExist(true);
      }
    }
  });

  // Primary data validations
  useEffect(() => {
    const primaryDocValues = identityDocValues![0];
    if (
      docGroupSelection!.primaryDocuments.id !== "" &&
      docGroupSelection!.primaryDocuments.id === "NonUKPassport"
    ) {
      if (
        primaryDocValues.identifier !== "" &&
        primaryDocValues.emittingCountryVal &&
        primaryDocValues.emittingCountryVal!.hasOwnProperty("title") &&
        primaryDocValues.emittingCountryVal!.title !== "" &&
        primaryDocValues.validFrom &&
        primaryDocValues.validFrom.toString() !== "Invalid date" &&
        primaryDocValues.validTo &&
        primaryDocValues.validTo.toString() !== "Invalid date" &&
        moment(primaryDocValues.validTo) > moment(primaryDocValues.validFrom) &&
        moment(primaryDocValues.validTo) > moment() &&
        moment(primaryDocValues.validFrom) <= moment() &&
        isPrimaryFileExist
      )
        setIsPrimaryFormValid(false);
      else setIsPrimaryFormValid(true);
    } else {
      if (
        primaryDocValues.identifier !== "" &&
        primaryDocValues.validFrom &&
        primaryDocValues.validFrom.toString() !== "Invalid date" &&
        moment(primaryDocValues.validFrom) <= moment() &&
        isPrimaryFileExist
      )
        setIsPrimaryFormValid(false);
      else setIsPrimaryFormValid(true);
    }
  }, [docGroupSelection, identityDocValues, isPrimaryFileExist, setIsPrimaryFormValid]);

  useEffect(() => {
    const primaryDocValues = identityDocValues![0];
    if (
      primaryDocValues.emittingCountryVal &&
      primaryDocValues.emittingCountryVal!.hasOwnProperty("title") &&
      primaryDocValues.emittingCountryVal!.title !== ""
    ) {
      setPrimaryCountryError(false);
      setPrimaryCountryHelperText("");
    } else {
      setPrimaryCountryError(true);
      setPrimaryCountryHelperText(t("validators.required"));
    }
  }, [identityDocValues, t]);

  useEffect(() => {
    const primaryDocValues = identityDocValues![0];

    const validTo = primaryDocValues.validTo
      ? primaryDocValues.validTo!.toString()
      : "Invalid date";
    const validFrom = primaryDocValues.validFrom
      ? primaryDocValues.validFrom!.toString()
      : "Invalid date";

    setIsPrimarySelectSwitch(false);

    if (primaryDocValues.validTo === null || validTo === "Invalid date") {
      setPrimaryValidToError(true);
      setPrimaryValidToHelperText(t("errors.valid_to_msg"));
    } else if (moment(primaryDocValues.validTo) < moment()) {
      setPrimaryValidToError(true);
      setPrimaryValidToHelperText(t("errors.valid_to_lesser_now_msg"));
    } else if (moment(primaryDocValues.validFrom) > moment(primaryDocValues.validTo)) {
      setPrimaryValidToError(true);
      setPrimaryValidToHelperText(t("errors.valid_to_lesser_valid_from_msg"));
    } else {
      setPrimaryValidToError(false);
      setPrimaryValidToHelperText(t(""));
    }

    if (primaryDocValues.validFrom === null || validFrom === "Invalid date") {
      setPrimaryValidFromHError(true);
      setPrimaryValidFromHelperText(t("errors.valid_from_msg"));
    } else if (moment(primaryDocValues.validFrom) > moment()) {
      setPrimaryValidFromHError(true);
      setPrimaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
    } else if (moment(primaryDocValues.validFrom) > moment(primaryDocValues.validTo)) {
      setPrimaryValidFromHError(true);
      setPrimaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
    } else {
      setPrimaryValidFromHError(false);
      setPrimaryValidFromHelperText(t(""));
    }
  }, [identityDocValues, isPrimarySelectSwitch, setIsPrimarySelectSwitch, t]);

  // Secondary data validations
  useEffect(() => {
    const secondaryDocValues = identityDocValues![1];

    if (
      docGroupSelection!.secondaryDocuments.id !== "" &&
      (docGroupSelection!.secondaryDocuments.id === "UkPhotoDriverLicence" ||
        docGroupSelection!.secondaryDocuments.id === "PaperDriverLicence" ||
        docGroupSelection!.secondaryDocuments.id === "NationalId" ||
        docGroupSelection!.secondaryDocuments.id === "UkPassport" ||
        docGroupSelection!.secondaryDocuments.id === "NonUkPhotoDriverLicence")
    ) {
      if (
        secondaryDocValues.identifier !== "" &&
        secondaryDocValues.emittingCountryVal &&
        secondaryDocValues.emittingCountryVal!.hasOwnProperty("title") &&
        secondaryDocValues.emittingCountryVal!.title !== "" &&
        secondaryDocValues.validFrom &&
        secondaryDocValues.validFrom.toString() !== "Invalid date" &&
        secondaryDocValues.validTo &&
        secondaryDocValues.validTo.toString() !== "Invalid date" &&
        moment(secondaryDocValues.validTo) > moment(secondaryDocValues.validFrom) &&
        moment(secondaryDocValues.validTo) > moment() &&
        isSecondaryFileExist
      )
        setIsSecondaryFormValid(false);
      else setIsSecondaryFormValid(true);
    } else if (docGroupSelection!.secondaryDocuments.id === "SettledStatusShareCode") {
      if (secondaryDocValues.identifier !== "" && isSecondaryFileExist)
        setIsSecondaryFormValid(false);
      else setIsSecondaryFormValid(true);
    } else {
      if (
        secondaryDocValues.identifier !== "" &&
        secondaryDocValues.validFrom &&
        secondaryDocValues.validFrom.toString() !== "Invalid date" &&
        moment(secondaryDocValues.validFrom) <= moment() &&
        isSecondaryFileExist
      )
        setIsSecondaryFormValid(false);
      else setIsSecondaryFormValid(true);
    }
  }, [docGroupSelection, identityDocValues, isSecondaryFileExist, setIsSecondaryFormValid]);

  useEffect(() => {
    const secondaryDocValues = identityDocValues![1];
    if (
      secondaryDocValues.emittingCountryVal &&
      secondaryDocValues.emittingCountryVal!.hasOwnProperty("title") &&
      secondaryDocValues.emittingCountryVal!.title !== ""
    ) {
      setSecondaryCountryError(false);
      setSecondaryCountryHelperText("");
    } else {
      setSecondaryCountryError(true);
      setSecondaryCountryHelperText(t("validators.required"));
    }
  }, [identityDocValues, t]);

  useEffect(() => {
    const secondaryDocValues = identityDocValues![1];

    const validTo = secondaryDocValues.validTo
      ? secondaryDocValues.validTo!.toString()
      : "Invalid date";
    const validFrom = secondaryDocValues.validFrom
      ? secondaryDocValues.validFrom!.toString()
      : "Invalid date";

    setIsSecondarySelectSwitch(false);

    if (secondaryDocValues.validTo === null || validTo === "Invalid date") {
      setSecondaryValidToError(true);
      setSecondaryValidToHelperText(t("errors.valid_to_msg"));
    } else if (moment(secondaryDocValues.validTo) < moment()) {
      setSecondaryValidToError(true);
      setSecondaryValidToHelperText(t("errors.valid_to_lesser_now_msg"));
    } else if (moment(secondaryDocValues.validFrom) > moment(secondaryDocValues.validTo)) {
      setSecondaryValidToError(true);
      setSecondaryValidToHelperText(t("errors.valid_to_greater_valid_from_msg"));
    } else {
      setSecondaryValidToError(false);
      setSecondaryValidToHelperText(t(""));
    }

    if (secondaryDocValues.validFrom === null || validFrom === "Invalid date") {
      setSecondaryValidFromHError(true);
      setSecondaryValidFromHelperText(t("errors.valid_from_msg"));
    } else if (moment(secondaryDocValues.validFrom) > moment()) {
      setSecondaryValidFromHError(true);
      setSecondaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
    } else if (moment(secondaryDocValues.validFrom) > moment(secondaryDocValues.validTo)) {
      setSecondaryValidFromHError(true);
      setSecondaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
    } else {
      setSecondaryValidFromHError(false);
      setSecondaryValidFromHelperText(t(""));
    }
  }, [identityDocValues, isSecondarySelectSwitch, setIsSecondarySelectSwitch, t]);

  // Ternary data validations
  useEffect(() => {
    const ternaryDocValues = identityDocValues![2];

    if (
      docGroupSelection!.ternaryDocuments &&
      docGroupSelection!.ternaryDocuments.id !== "" &&
      (docGroupSelection!.ternaryDocuments.id === "UkPhotoDriverLicence" ||
        docGroupSelection!.ternaryDocuments.id === "PaperDriverLicence" ||
        docGroupSelection!.ternaryDocuments.id === "NationalId" ||
        docGroupSelection!.ternaryDocuments.id === "UkPassport" ||
        docGroupSelection!.ternaryDocuments.id === "NonUkPhotoDriverLicence")
    ) {
      if (
        ternaryDocValues.identifier !== "" &&
        ternaryDocValues.emittingCountryVal &&
        ternaryDocValues.emittingCountryVal!.hasOwnProperty("title") &&
        ternaryDocValues.emittingCountryVal!.title !== "" &&
        ternaryDocValues.validFrom &&
        ternaryDocValues.validFrom.toString() !== "Invalid date" &&
        ternaryDocValues.validTo &&
        ternaryDocValues.validTo.toString() !== "Invalid date" &&
        moment(ternaryDocValues.validTo) > moment(ternaryDocValues.validFrom) &&
        moment(ternaryDocValues.validTo) > moment() &&
        isTernaryFileExist
      )
        setIsTernaryFormValid(false);
      else setIsTernaryFormValid(true);
    } else if (
      docGroupSelection!.ternaryDocuments &&
      docGroupSelection!.ternaryDocuments.id === "SettledStatusShareCode"
    ) {
      if (ternaryDocValues.identifier !== "" && isTernaryFileExist) setIsTernaryFormValid(false);
      else setIsTernaryFormValid(true);
    } else {
      if (
        ternaryDocValues.identifier !== "" &&
        ternaryDocValues.validFrom &&
        ternaryDocValues.validFrom.toString() !== "Invalid date" &&
        moment(ternaryDocValues.validFrom) <= moment() &&
        isTernaryFileExist
      )
        setIsTernaryFormValid(false);
      else setIsTernaryFormValid(true);
    }
  }, [docGroupSelection, identityDocValues, isTernaryFileExist]);

  useEffect(() => {
    const ternaryDocValues = identityDocValues![2];
    if (
      ternaryDocValues.emittingCountryVal &&
      ternaryDocValues.emittingCountryVal!.hasOwnProperty("title") &&
      ternaryDocValues.emittingCountryVal!.title !== ""
    ) {
      setTernaryCountryError(false);
      setTernaryCountryHelperText("");
    } else {
      setTernaryCountryError(true);
      setTernaryCountryHelperText(t("validators.required"));
    }
  }, [identityDocValues, t]);

  useEffect(() => {
    const ternaryDocValues = identityDocValues![2];

    const validTo = ternaryDocValues.validTo
      ? ternaryDocValues.validTo!.toString()
      : "Invalid date";
    const validFrom = ternaryDocValues.validFrom
      ? ternaryDocValues.validFrom!.toString()
      : "Invalid date";

    setIsTernarySelectSwitch(false);

    if (ternaryDocValues.validTo === null || validTo === "Invalid date") {
      setTernaryValidToError(true);
      setTernaryValidFromHelperText(t("errors.valid_to_msg"));
    } else if (moment(ternaryDocValues.validTo) < moment()) {
      setTernaryValidToError(true);
      setTernaryValidToHelperText(t("errors.valid_to_lesser_now_msg"));
    } else if (moment(ternaryDocValues.validFrom) > moment(ternaryDocValues.validTo)) {
      setTernaryValidToError(true);
      setTernaryValidToHelperText(t("errors.valid_to_greater_valid_from_msg"));
    } else {
      setTernaryValidToError(false);
      setTernaryValidToHelperText(t(""));
    }

    if (ternaryDocValues.validFrom === null || validFrom === "Invalid date") {
      setTernaryValidFromHError(true);
      setTernaryValidFromHelperText(t("errors.valid_from_msg"));
    } else if (moment(ternaryDocValues.validFrom) > moment()) {
      setTernaryValidFromHError(true);
      setTernaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
    } else if (moment(ternaryDocValues.validFrom) > moment(ternaryDocValues.validTo)) {
      setTernaryValidFromHError(true);
      setTernaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
    } else {
      setTernaryValidFromHError(false);
      setTernaryValidFromHelperText(t(""));
    }
  }, [identityDocValues, isTernarySelectSwitch, setIsTernarySelectSwitch, t]);

  const verifyDigitalIdentityStatus = () => {
    if (props.complianceProcessData!.hasOwnProperty("digitalIdentity") === false) return true;
    else {
      if (
        props.complianceProcessData!.digitalIdentity!.importStatus === ImportStatus.Imported &&
        props.complianceProcessData!.digitalIdentity!.isComplete === true
      )
        return false;
      else return true;
    }
  };

  return (
    <>
      <div id="verify-identity" className="verify-identity editor-component">
        <div className="verify-identity-body">
          <div className="verify-identity-body-container">
            <Heading
              title={
                props.uiContent && props.uiContent!.title !== ""
                  ? props.uiContent!.title
                  : t("verify_identity.identity_verification")
              }
            />
            <div className="verify-identity-body-container-details-row">
              <div className="verify-identity-body-container-details-row-content">
                {props.uiContent!.content.indexOf("</") !== -1 ? (
                  <div
                    className="editor-component-body"
                    dangerouslySetInnerHTML={{
                      __html: props.uiContent!.content.replace(/(<? *script)/gi, "illegalscript"),
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="verify-identity-body-container-details-row">
              <div className="verify-identity-body-container-details-row-content">
                <FormControl>
                  <RadioGroup
                    row={false}
                    aria-label="text"
                    name="text"
                    value={isDigitalVerifyOption}
                    onChange={handleVerifyOptionChange}
                  >
                    <FormControlLabel
                      value={"yes"}
                      disabled={
                        !(
                          props.isCheckDataEditable &&
                          utilFunctions.CheckIDVImportedFieldIsEditable(
                            "documentId",
                            ImportableTypeEditor.DocumentsInfo,
                            props.complianceProcessData!
                          )
                        )
                      }
                      control={<Radio color="primary" />}
                      label={t("verify_identity.digital_id_ques")}
                    />

                    {isDigitalVerifyOption === "yes" ? (
                      <div className="verify-identity-body-container-details-row">
                        <div className="verify-identity-body-container-details-row-content">
                          {process.env.REACT_APP_DIGITAL_ID_OPTION === DigitalIDOption.DIGITALID ? (
                            <div className="">
                              <YotiDigitalID
                                isCheckEditable={props.isCheckDataEditable}
                                onInfoSaveCallback={() => {}}
                                complianceProcessData={props.complianceProcessData}
                              />
                            </div>
                          ) : (
                            <div className="">
                              <YotiIDV
                                isCheckEditable={props.isCheckDataEditable}
                                onInfoSaveCallback={() => {}}
                                complianceProcessData={props.complianceProcessData}
                                requestRefresh={props.requestRefresh}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <FormControlLabel
                      value={"no"}
                      disabled={
                        !(
                          props.isCheckDataEditable &&
                          utilFunctions.CheckIDVImportedFieldIsEditable(
                            "documentId",
                            ImportableTypeEditor.DocumentsInfo,
                            props.complianceProcessData!
                          )
                        )
                      }
                      control={<Radio color="primary" />}
                      label={t("verify_identity.upload_id_proofs")}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {isDigitalVerifyOption === "no" ? (
              <>
                {docGroupListState && docGroupListState.primaryDocuments.length > 0 ? (
                  <div className="verify-identity-body-container-details-row">
                    <div className="verify-identity-body-container-details-row-content">
                      <div className="docGrpControl">
                        <GenericSelect
                          id="primaryDocs"
                          value={docGroupSelection!.primaryDocuments}
                          dataSet={docGroupListState!.primaryDocuments}
                          fullWidth={true}
                          handleChange={handleDocGroupChange("primaryDocuments")}
                          multiple={false}
                          disabled={!props.isCheckDataEditable}
                          freeSolo={false}
                          label={t("verify_identity.primary_id_docs")}
                          renderChip={false}
                          name="Document1"
                          error={
                            docGroupSelection.primaryDocuments.id === "" &&
                            !isPrimarySelectSwitchMessage
                          }
                        />
                        {docGroupSelection.primaryDocuments.id === "" &&
                          !isPrimarySelectSwitchMessage && (
                            <div
                              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                              id="genericSelect1HelperText"
                            >
                              {t("errors.document_msg")}
                            </div>
                          )}
                      </div>
                    </div>
                    {docGroupSelection.primaryDocuments &&
                    docGroupSelection.primaryDocuments.id !== "" ? (
                      <>
                        <div className="verify-identity-body-container-details-row-content">
                          <div className="doc-value-fields">
                            <div className="oneHalfWidth uploader-win">
                              {documentDefinitionData &&
                              documentDefinitionData[0] &&
                              (documentDefinitionData[0].filename ||
                                documentDefinitionData[0].url) ? (
                                // <DocViewer pluginRenderers={DocViewerRenderers} key={"primaryDocFile"} documents={documentDefinitionData[0].docFiles} /> : ""
                                <div className="file-list-container">
                                  <div className="file-status-bar" key={"primaryDocList"}>
                                    <div>
                                      <div className="file-type-logo">
                                        <CheckCircleIcon
                                          htmlColor={"#0da931"}
                                          style={{ margin: "10px" }}
                                        />
                                      </div>
                                      <span
                                        style={{ cursor: "pointer" }}
                                        className={`file-name `}
                                        onClick={() => {
                                          handleDownloadSavedFile(0);
                                        }}
                                      >
                                        {identityDocValues[0].filename}
                                      </span>
                                    </div>
                                    <div
                                      className="file-remove"
                                      onClick={() => {
                                        handleRemoveSavedFile(0);
                                      }}
                                    >
                                      <CancelIcon htmlColor={"#ff5e00"} style={{ margin: "0px" }} />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <Uploader
                                  key={"primary-document-uploader"}
                                  pushFiles={pushPrimaryDoc}
                                  supportMessage={`Only .pdf, .jpg, .png formats. Max size 20Mb.`}
                                  maxNumberOfFiles={1 - allAttachments?.length}
                                  sizeOfEachFile={Constants.DOC_UPLOAD.MAX_FILE_SIZE}
                                  maxSize={Constants.DOC_UPLOAD.MAX_PACKAGE_SIZE}
                                  fileAcceptType={Constants.DOC_UPLOAD.FILE_ACCESS_TYPES}
                                  disabled={!props.isCheckDataEditable}
                                  validTypes={Constants.DOC_UPLOAD.VALID_TYPES}
                                  minContainerHeight="120px"
                                  minFileHeight={40}
                                  removeFile={removePrimaryDocument}
                                  isPrimarySelectSwitch={isPrimarySelectSwitch}
                                />
                              )}
                            </div>
                            <div className="oneHalfWidth">
                              {documentTypeFields?.primaryDocuments?.identifierText &&
                              documentTypeFields.primaryDocuments.identifierText !== "" ? (
                                <TextEditor
                                  fieldName="identifier"
                                  value={
                                    documentDefinitionData
                                      ? documentDefinitionData[0].identifier
                                      : null
                                  }
                                  editValue={identityDocValues[0].identifier}
                                  label={documentTypeFields.primaryDocuments.identifierText}
                                  type="text"
                                  showEdit={props.isCheckDataEditable}
                                  isCheckEditable={props.isCheckDataEditable}
                                  onChangeValue={handleDocValueChange("identifier", 0)}
                                  onEditClicked={(e: EventTarget) => {
                                    handleEditFieldChange(
                                      "identifier",
                                      documentTypeFields!.primaryDocuments!.identifierText!,
                                      documentDefinitionData![0].identifier,
                                      0
                                    );
                                    setIsEditClicked(true);
                                  }}
                                  error={utilFunctions.handleErrors(
                                    identityDocValues![0].identifier,
                                    "",
                                    "text"
                                  )}
                                  helperText={utilFunctions.handleHelperText(
                                    identityDocValues![0].identifier,
                                    "",
                                    "text"
                                  )}
                                />
                              ) : (
                                ""
                              )}
                              {documentTypeFields?.primaryDocuments?.emittingCountryText &&
                              documentTypeFields.primaryDocuments.emittingCountryText !== "" ? (
                                <>
                                  <SelectEditor
                                    id="emittingCountry"
                                    value={
                                      documentDefinitionData
                                        ? documentDefinitionData[0].emittingCountryVal?.title
                                        : null
                                    }
                                    editValue={identityDocValues[0].emittingCountryVal}
                                    label={documentTypeFields.primaryDocuments.emittingCountryText}
                                    isCheckEditable={props.isCheckDataEditable}
                                    dataSet={countriesData}
                                    handleChange={handleDocValueSelectChange("emittingCountry", 0)}
                                    showEdit={props.isCheckDataEditable}
                                    onEditClicked={(e: EventTarget) => {
                                      handleEditFieldChange(
                                        "emittingCountry",
                                        documentTypeFields!.primaryDocuments!.emittingCountryText!,
                                        documentDefinitionData![0].emittingCountryVal,
                                        0
                                      );
                                      setIsEditClicked(true);
                                    }}
                                    error={primaryCountryError}
                                  />
                                  {documentDefinitionData &&
                                    documentDefinitionData[0].emittingCountryVal?.title === "" && (
                                      <p
                                        className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                                        style={{
                                          color: "rgb(209, 0, 0)",
                                          margin: "-6px 0 10px 25px",
                                        }}
                                        id="emmitingCountry1HelperText"
                                      >
                                        {primaryCountryHelperText}
                                      </p>
                                    )}
                                </>
                              ) : (
                                ""
                              )}
                              {documentTypeFields?.primaryDocuments?.emittingEntityText &&
                              documentTypeFields.primaryDocuments.emittingEntityText !== "" ? (
                                <TextEditor
                                  fieldName="emittingEntity"
                                  value={
                                    documentDefinitionData
                                      ? documentDefinitionData[0].emittingEntity
                                      : null
                                  }
                                  editValue={identityDocValues[0].emittingEntity}
                                  label={documentTypeFields.primaryDocuments.emittingEntityText}
                                  type="text"
                                  showEdit={props.isCheckDataEditable}
                                  isCheckEditable={props.isCheckDataEditable}
                                  onChangeValue={handleDocValueChange("emittingEntity", 0)}
                                  onEditClicked={(e: EventTarget) => {
                                    handleEditFieldChange(
                                      "emittingEntity",
                                      documentTypeFields!.primaryDocuments!.emittingEntityText!,
                                      documentDefinitionData![0].emittingEntity,
                                      0
                                    );
                                    setIsEditClicked(true);
                                  }}
                                  error={utilFunctions.handleErrors(
                                    identityDocValues![0].emittingEntity,
                                    "",
                                    "text"
                                  )}
                                  helperText={utilFunctions.handleHelperText(
                                    identityDocValues![0].emittingEntity,
                                    "",
                                    "text"
                                  )}
                                />
                              ) : (
                                ""
                              )}
                              {documentTypeFields?.primaryDocuments?.dateFromText &&
                              documentTypeFields.primaryDocuments.dateFromText !== "" ? (
                                <DateEditor
                                  value={
                                    documentDefinitionData
                                      ? documentDefinitionData[0].validFrom
                                      : null
                                  }
                                  editValue={identityDocValues[0].validFrom}
                                  label={documentTypeFields.primaryDocuments.dateFromText}
                                  showEdit={props.isCheckDataEditable}
                                  isCheckEditable={props.isCheckDataEditable}
                                  onChangeValue={handleDocValueDateChange("validFrom", 0)}
                                  onEditClicked={(e: EventTarget) => {
                                    handleEditFieldChange(
                                      "validFrom",
                                      documentTypeFields!.primaryDocuments!.dateFromText!,
                                      documentDefinitionData![0].validFrom,
                                      0
                                    );
                                    setIsEditClicked(true);
                                  }}
                                  error={primaryValidFromError}
                                  helperText={primaryValidFromHelperText}
                                />
                              ) : (
                                ""
                              )}
                              {documentTypeFields?.primaryDocuments?.dateToText &&
                              documentTypeFields.primaryDocuments.dateToText !== "" ? (
                                <DateEditor
                                  value={
                                    documentDefinitionData
                                      ? documentDefinitionData[0].validTo
                                      : null
                                  }
                                  editValue={identityDocValues[0].validTo}
                                  label={documentTypeFields.primaryDocuments.dateToText}
                                  showEdit={props.isCheckDataEditable}
                                  isCheckEditable={props.isCheckDataEditable}
                                  onChangeValue={handleDocValueDateChange("validTo", 0)}
                                  onEditClicked={(e: EventTarget) => {
                                    handleEditFieldChange(
                                      "validTo",
                                      documentTypeFields!.primaryDocuments!.dateToText!,
                                      documentDefinitionData![0].validTo,
                                      0
                                    );
                                    setIsEditClicked(true);
                                  }}
                                  error={primaryValidToError}
                                  helperText={primaryValidToHelperText}
                                  enableFuture={true}
                                  disablePast={true}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="verify-identity-body-container-details-row-content"></div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="verify-identity-body-container-details-row">
                  {docGroupListState && docGroupListState.secondaryDocuments.length > 0 ? (
                    <div className="verify-identity-body-container-details-row-content">
                      <div className="docGrpControl">
                        <GenericSelect
                          id="secondaryDocs"
                          value={docGroupSelection!.secondaryDocuments}
                          dataSet={docGroupListState!.secondaryDocuments}
                          fullWidth={true}
                          handleChange={handleDocGroupChange("secondaryDocuments")}
                          disabled={!props.isCheckDataEditable}
                          multiple={false}
                          freeSolo={false}
                          label={t("verify_identity.trusted_gov_docs")}
                          renderChip={false}
                          name="Document2"
                          error={
                            docGroupSelection.secondaryDocuments.id === "" &&
                            !isSecondarySelectSwitchMessage
                          }
                        />
                        {docGroupSelection.secondaryDocuments.id === "" &&
                          !isSecondarySelectSwitchMessage && (
                            <div
                              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                              id="genericSelect2HelperText"
                            >
                              {t("errors.document_msg")}
                            </div>
                          )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {docGroupSelection.secondaryDocuments &&
                  docGroupSelection.secondaryDocuments.id !== "" ? (
                    <>
                      <div className="verify-identity-body-container-details-row-content">
                        <div className="doc-value-fields">
                          <div className="oneHalfWidth uploader-win">
                            {documentDefinitionData &&
                            documentDefinitionData[1] &&
                            (documentDefinitionData[1].filename ||
                              documentDefinitionData[1].url) ? (
                              <div className="file-list-container">
                                <div className="file-status-bar" key={"primaryDocList"}>
                                  <div>
                                    <div className="file-type-logo">
                                      <CheckCircleIcon
                                        htmlColor={"#0da931"}
                                        style={{ margin: "10px" }}
                                      />
                                    </div>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className={`file-name `}
                                      onClick={() => {
                                        handleDownloadSavedFile(1);
                                      }}
                                    >
                                      {identityDocValues[1].filename}
                                    </span>
                                  </div>
                                  <div
                                    className="file-remove"
                                    onClick={() => {
                                      handleRemoveSavedFile(1);
                                    }}
                                  >
                                    <CancelIcon htmlColor={"#ff5e00"} style={{ margin: "10px" }} />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <Uploader
                                key={"secondary-document-uploader"}
                                pushFiles={pushSecondaryDoc}
                                supportMessage={`Only .pdf, .jpg, .png formats. Max size 20Mb.`}
                                maxNumberOfFiles={1 - allAttachments?.length}
                                sizeOfEachFile={Constants.DOC_UPLOAD.MAX_FILE_SIZE}
                                maxSize={Constants.DOC_UPLOAD.MAX_PACKAGE_SIZE}
                                fileAcceptType={Constants.DOC_UPLOAD.FILE_ACCESS_TYPES}
                                disabled={!props.isCheckDataEditable}
                                validTypes={Constants.DOC_UPLOAD.VALID_TYPES}
                                minContainerHeight="120px"
                                minFileHeight={40}
                                removeFile={removeSecondaryDoc}
                                isSecondarySelectSwitch={isSecondarySelectSwitch}
                              />
                            )}
                          </div>
                          <div className="oneHalfWidth">
                            {documentTypeFields?.secondaryDocuments?.identifierText &&
                            documentTypeFields.secondaryDocuments.identifierText !== "" ? (
                              <TextEditor
                                fieldName="identifier1"
                                value={
                                  documentDefinitionData
                                    ? documentDefinitionData[1].identifier
                                    : null
                                }
                                editValue={identityDocValues[1].identifier}
                                label={documentTypeFields.secondaryDocuments.identifierText}
                                type="text"
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueChange("identifier", 1)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "identifier",
                                    documentTypeFields!.secondaryDocuments!.identifierText!,
                                    documentDefinitionData![1].identifier,
                                    1
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={utilFunctions.handleErrors(
                                  identityDocValues![1].identifier,
                                  "",
                                  "text"
                                )}
                                helperText={utilFunctions.handleHelperText(
                                  identityDocValues![1].identifier,
                                  "",
                                  "text"
                                )}
                              />
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.secondaryDocuments?.emittingCountryText &&
                            documentTypeFields.secondaryDocuments.emittingCountryText !== "" ? (
                              <>
                                <SelectEditor
                                  id="emittingCountry1"
                                  value={
                                    documentDefinitionData
                                      ? documentDefinitionData[1].emittingCountryVal?.title
                                      : null
                                  }
                                  editValue={identityDocValues[1].emittingCountryVal}
                                  label={documentTypeFields.secondaryDocuments.emittingCountryText}
                                  isCheckEditable={props.isCheckDataEditable}
                                  dataSet={countriesData}
                                  handleChange={handleDocValueSelectChange("emittingCountry", 1)}
                                  showEdit={props.isCheckDataEditable}
                                  onEditClicked={(e: EventTarget) => {
                                    handleEditFieldChange(
                                      "emittingCountry",
                                      documentTypeFields!.secondaryDocuments!.emittingCountryText!,
                                      documentDefinitionData![1].emittingCountryVal,
                                      1
                                    );
                                    setIsEditClicked(true);
                                  }}
                                  error={secondaryCountryError}
                                />
                                {documentDefinitionData &&
                                  documentDefinitionData[1].emittingCountryVal?.title === "" && (
                                    <p
                                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                                      style={{
                                        color: "rgb(209, 0, 0)",
                                        margin: "-6px 0 10px 25px",
                                      }}
                                      id="emmitingCountry2HelperText"
                                    >
                                      {secondaryCountryHelperText}
                                    </p>
                                  )}
                              </>
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.secondaryDocuments?.emittingEntityText &&
                            documentTypeFields.secondaryDocuments.emittingEntityText !== "" ? (
                              <TextEditor
                                fieldName="emittingEntity1"
                                value={
                                  documentDefinitionData
                                    ? documentDefinitionData[1].emittingEntity
                                    : null
                                }
                                editValue={identityDocValues[1].emittingEntity}
                                label={documentTypeFields.secondaryDocuments.emittingEntityText}
                                type="text"
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueChange("emittingEntity", 1)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "emittingEntity",
                                    documentTypeFields!.secondaryDocuments!.emittingEntityText!,
                                    documentDefinitionData![1].emittingEntity,
                                    1
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={utilFunctions.handleErrors(
                                  identityDocValues![1].emittingEntity,
                                  "",
                                  "text"
                                )}
                                helperText={utilFunctions.handleHelperText(
                                  identityDocValues![1].emittingEntity,
                                  "",
                                  "text"
                                )}
                              />
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.secondaryDocuments?.dateFromText &&
                            documentTypeFields.secondaryDocuments.dateFromText !== "" ? (
                              <DateEditor
                                value={
                                  documentDefinitionData
                                    ? documentDefinitionData[1].validFrom
                                    : null
                                }
                                editValue={identityDocValues[1].validFrom}
                                label={documentTypeFields.secondaryDocuments.dateFromText}
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueDateChange("validFrom", 1)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "validFrom",
                                    documentTypeFields!.secondaryDocuments!.dateFromText!,
                                    documentDefinitionData![1].validFrom,
                                    1
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={secondaryValidFromError}
                                helperText={secondaryValidFromHelperText}
                              />
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.secondaryDocuments?.dateToText &&
                            documentTypeFields.secondaryDocuments.dateToText !== "" ? (
                              <DateEditor
                                value={
                                  documentDefinitionData ? documentDefinitionData[1].validTo : null
                                }
                                editValue={identityDocValues[1].validTo}
                                label={documentTypeFields.secondaryDocuments.dateToText}
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueDateChange("validTo", 1)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "validTo",
                                    documentTypeFields!.secondaryDocuments!.dateToText!,
                                    documentDefinitionData![1].validTo,
                                    1
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={secondaryValidToError}
                                helperText={secondaryValidToHelperText}
                                enableFuture={true}
                                disablePast={true}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="verify-identity-body-container-details-row-content"></div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="verify-identity-body-container-details-row">
                  {isDigitalVerifyOption === "no" &&
                  docGroupListState &&
                  docGroupListState.ternaryDocuments.length > 0 ? (
                    <div className="verify-identity-body-container-details-row-content">
                      <div className="docGrpControl">
                        <GenericSelect
                          id="ternaryDocs"
                          value={docGroupSelection!.ternaryDocuments}
                          dataSet={docGroupListState!.ternaryDocuments}
                          fullWidth={true}
                          handleChange={handleDocGroupChange("ternaryDocuments")}
                          disabled={!props.isCheckDataEditable}
                          multiple={false}
                          freeSolo={false}
                          label={t("verify_identity.financial_social_history_docs")}
                          renderChip={true}
                          name="Document3"
                          error={
                            docGroupSelection.ternaryDocuments!.id === "" &&
                            !isTernarySelectSwitchMessage
                          }
                        />
                        {docGroupSelection.ternaryDocuments!.id === "" &&
                          !isTernarySelectSwitchMessage && (
                            <div
                              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                              id="genericSelect3HelperText"
                            >
                              {t("errors.document_msg")}
                            </div>
                          )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {docGroupSelection.ternaryDocuments &&
                  docGroupSelection.ternaryDocuments.id !== "" ? (
                    <>
                      <div className="verify-identity-body-container-details-row-content">
                        <div className="doc-value-fields">
                          <div className="oneHalfWidth uploader-win">
                            {documentDefinitionData &&
                            documentDefinitionData[2] &&
                            (documentDefinitionData[2].filename ||
                              documentDefinitionData[2].url) ? (
                              <div className="file-list-container">
                                <div className="file-status-bar" key={"primaryDocList"}>
                                  <div>
                                    <div className="file-type-logo">
                                      <CheckCircleIcon
                                        htmlColor={"#0da931"}
                                        style={{ margin: "10px" }}
                                      />
                                    </div>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className={`file-name `}
                                      onClick={() => {
                                        handleDownloadSavedFile(2);
                                      }}
                                    >
                                      {identityDocValues[2].filename}
                                    </span>
                                  </div>
                                  <div
                                    className="file-remove"
                                    onClick={() => {
                                      handleRemoveSavedFile(2);
                                    }}
                                  >
                                    <CancelIcon htmlColor={"#ff5e00"} style={{ margin: "10px" }} />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <Uploader
                                key={"ternary-document-uploader"}
                                pushFiles={pushTernaryDoc}
                                supportMessage={`Only .pdf, .jpg, .png formats. Max size 20Mb.`}
                                maxNumberOfFiles={1 - allAttachments?.length}
                                disabled={!props.isCheckDataEditable}
                                sizeOfEachFile={Constants.DOC_UPLOAD.MAX_FILE_SIZE}
                                maxSize={Constants.DOC_UPLOAD.MAX_PACKAGE_SIZE}
                                fileAcceptType={Constants.DOC_UPLOAD.FILE_ACCESS_TYPES}
                                validTypes={Constants.DOC_UPLOAD.VALID_TYPES}
                                minContainerHeight="100%"
                                minFileHeight={40}
                                removeFile={removeTernaryDoc}
                                isTernarySelectSwitch={isTernarySelectSwitch}
                              />
                            )}
                          </div>
                          <div className="oneHalfWidth">
                            {documentTypeFields?.ternaryDocuments?.identifierText &&
                            documentTypeFields.ternaryDocuments.identifierText !== "" ? (
                              <TextEditor
                                fieldName="identifier2"
                                value={
                                  documentDefinitionData
                                    ? documentDefinitionData[2].identifier
                                    : null
                                }
                                editValue={identityDocValues[2].identifier}
                                label={documentTypeFields.ternaryDocuments.identifierText}
                                type="text"
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueChange("identifier", 2)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "identifier",
                                    documentTypeFields!.ternaryDocuments!.identifierText!,
                                    documentDefinitionData![2].identifier,
                                    2
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={utilFunctions.handleErrors(
                                  identityDocValues![2].identifier,
                                  "",
                                  "text"
                                )}
                                helperText={utilFunctions.handleHelperText(
                                  identityDocValues![2].identifier,
                                  "",
                                  "text"
                                )}
                              />
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.ternaryDocuments?.emittingCountryText &&
                            documentTypeFields.ternaryDocuments.emittingCountryText !== "" ? (
                              <>
                                <SelectEditor
                                  id="emittingCountry2"
                                  value={
                                    documentDefinitionData
                                      ? documentDefinitionData[2].emittingCountryVal?.title
                                      : null
                                  }
                                  editValue={identityDocValues[2].emittingCountryVal}
                                  label={documentTypeFields.ternaryDocuments.emittingCountryText}
                                  isCheckEditable={props.isCheckDataEditable}
                                  dataSet={countriesData}
                                  handleChange={handleDocValueSelectChange("emittingCountry", 2)}
                                  showEdit={props.isCheckDataEditable}
                                  onEditClicked={(e: EventTarget) => {
                                    handleEditFieldChange(
                                      "emittingCountry",
                                      documentTypeFields!.secondaryDocuments!.emittingCountryText!,
                                      documentDefinitionData![2].emittingCountryVal,
                                      2
                                    );
                                    setIsEditClicked(true);
                                  }}
                                  error={ternaryCountryError}
                                />
                                {documentDefinitionData &&
                                  documentDefinitionData[2].emittingCountryVal?.title === "" && (
                                    <p
                                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                                      style={{
                                        color: "rgb(209, 0, 0)",
                                        margin: "-6px 0 10px 25px",
                                      }}
                                      id="emmitingCountry3HelperText"
                                    >
                                      {ternaryCountryHelperText}
                                    </p>
                                  )}
                              </>
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.ternaryDocuments?.emittingEntityText &&
                            documentTypeFields.ternaryDocuments.emittingEntityText !== "" ? (
                              <TextEditor
                                fieldName="emittingEntity2"
                                value={
                                  documentDefinitionData
                                    ? documentDefinitionData[2].emittingEntity
                                    : null
                                }
                                editValue={identityDocValues[2].emittingEntity}
                                label={documentTypeFields.ternaryDocuments.emittingEntityText}
                                type="text"
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueChange("emittingEntity", 2)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "emittingEntity",
                                    documentTypeFields!.ternaryDocuments!.emittingEntityText!,
                                    documentDefinitionData![2].emittingEntity,
                                    2
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={utilFunctions.handleErrors(
                                  identityDocValues![2].emittingEntity,
                                  "",
                                  "text"
                                )}
                                helperText={utilFunctions.handleHelperText(
                                  identityDocValues![2].emittingEntity,
                                  "",
                                  "text"
                                )}
                              />
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.ternaryDocuments?.dateFromText &&
                            documentTypeFields.ternaryDocuments.dateFromText !== "" ? (
                              <DateEditor
                                value={
                                  documentDefinitionData
                                    ? documentDefinitionData[2].validFrom
                                    : null
                                }
                                editValue={identityDocValues[2].validFrom}
                                label={documentTypeFields.ternaryDocuments.dateFromText}
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueDateChange("validFrom", 2)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "validFrom",
                                    documentTypeFields!.ternaryDocuments!.dateFromText!,
                                    documentDefinitionData![2].validFrom,
                                    2
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={ternaryValidFromError}
                                helperText={ternaryValidFromHelperText}
                              />
                            ) : (
                              ""
                            )}
                            {documentTypeFields?.ternaryDocuments?.dateToText &&
                            documentTypeFields.ternaryDocuments.dateToText !== "" ? (
                              <DateEditor
                                value={
                                  documentDefinitionData ? documentDefinitionData[2].validTo : null
                                }
                                editValue={identityDocValues[2].validTo}
                                label={documentTypeFields.ternaryDocuments.dateToText}
                                showEdit={props.isCheckDataEditable}
                                isCheckEditable={props.isCheckDataEditable}
                                onChangeValue={handleDocValueDateChange("validTo", 2)}
                                onEditClicked={(e: EventTarget) => {
                                  handleEditFieldChange(
                                    "validTo",
                                    documentTypeFields!.ternaryDocuments!.dateToText!,
                                    documentDefinitionData![2].validTo,
                                    2
                                  );
                                  setIsEditClicked(true);
                                }}
                                error={ternaryValidToError}
                                helperText={ternaryValidToHelperText}
                                enableFuture={true}
                                disablePast={true}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="verify-identity-body-container-details-row-content"></div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            <div className="verify-identity-body-container-details-row">
              <div className="container-submit-btn">
                {(
                  isDigitalVerifyOption === "yes"
                    ? !verifyDigitalIdentityStatus() && props.isCheckDataEditable
                    : props.isCheckDataEditable
                ) ? (
                  <GenericButton
                    onClick={handleSubmit}
                    disabled={
                      isDigitalVerifyOption === "yes"
                        ? verifyDigitalIdentityStatus()
                        : isPrimaryFormValid ||
                          isSecondaryFormValid ||
                          isTernaryFormValid ||
                          !isPrimaryFileExist ||
                          !isSecondaryFileExist ||
                          !isTernaryFileExist
                    }
                    type="primary"
                    buttonText={
                      isDigitalVerifyOption === "yes" ? t("common.next") : t("common.accept")
                    }
                  ></GenericButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <LoaderModal isLoading={loading} />
      </div>
      {confirmNationality ? (
        <PromptNationality
          processData={props.complianceProcessData!}
          confirmNationality={confirmNationality}
          onInfoSaveCallback={handleConfirmSaveCallback}
        />
      ) : (
        ""
      )}
      {confirmDocumentIssueDate ? (
        <PromptIssueDates
          processData={props.complianceProcessData!}
          confirmDocumentIssueDate={confirmDocumentIssueDate}
          onInfoSaveCallback={handleConfirmSaveIssueDatesCallback}
        />
      ) : (
        ""
      )}
      {isEditClicked ? (
        <EditInfoModal
          isEditIconClicked={isEditClicked}
          selectedFieldToEdit={selectedFieldToEdit}
          onCloseEditModal={onEditModalClose}
          onInfoSaveCallback={handleEditSaveCallback}
        />
      ) : (
        ""
      )}
    </>
  );
}
