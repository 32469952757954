import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import GenericSelect from "../../Components/GenericSelect";
import { InitialDocValues, useDocumentsContext } from "../../Contexts/DocumentsContext";
import { useRTWContext } from "../../Contexts/rtw-context";
import { RTWPersonalInfo } from "./RTWPersonalInfo";
import { ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import Constants from "../../Constants/constants";
import InfoTypes from "../../interfaces/InfoTypes";
import CancelIcon from "@material-ui/icons/Cancel";
import Uploader from "../../Components/Uploader";
import LocalStorageService from "../../utils/localStorageService";
import { GetCountriesData } from "../../utils/referenceDataService";
import EditInfoModal from "../VerifyIdentity/EditInfoModal";
import IProfile from "../../interfaces/IProfile";
import IDocumentDefinition from "../../interfaces/IDocumentDefinition";
import PromptNationality from "../VerifyIdentity/PromptNationality";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import GenericButton from "../../Components/GenericButton";
import _ from "lodash";
import utilFunctions from "../../utils/util";
import { usePersonalContext } from "../../Contexts/personal-context";
import TextEditor from "../../Components/CheckEditors/TextEditor";
import SelectEditor from "../../Components/CheckEditors/SelectEditor";
import DateEditor from "../../Components/CheckEditors/DateEditor";
import { ComplianceTypes } from "../../Constants/enums";

const InitialDocGroupState = {
  primaryDocuments: {
    title: "",
    id: "",
    value: "",
  },
  secondaryDocuments: {
    title: "",
    id: "",
    value: "",
  },
};

export default function RTWDocumentComponent() {
  const { t } = useTranslation();

  const [selectedFieldToEdit, setSelectedFieldToEdit] = useState({
    selectedFieldTitle: "",
    selectedFieldId: "",
    selectedFieldValue: "",
    selectedFieldIndex: 0,
  });

  const [allAttachments] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [workerCurrNationality, setWorkerCurrNationality] = useState<string>("");
  const [confirmNationality, setConfirmNationality] = useState(false);
  const [documentsRequest, setDocumentsRequest] = useState<IComplianceProcess>();
  const [primaryCountryError, setPrimaryCountryError] = useState(false);
  const [primaryCountryHelperText, setPrimaryCountryHelperText] = useState<string>("");
  const [secondaryCountryError, setSecondaryCountryError] = useState(false);
  const [secondaryCountryHelperText, setSecondaryCountryHelperText] = useState<string>("");
  const [primaryValidToError, setPrimaryValidToError] = useState(false);
  const [primaryValidFromError, setPrimaryValidFromError] = useState(false);
  const [primaryValidToHelperText, setPrimaryValidToHelperText] = useState<string>("");
  const [primaryValidFromHelperText, setPrimaryValidFromHelperText] = useState<string>("");
  const [secondaryValidToError, setSecondaryValidToError] = useState(false);
  const [secondaryValidFromError, setSecondaryValidFromHError] = useState(false);
  const [secondaryValidToHelperText, setSecondaryValidToHelperText] = useState<string>("");
  const [secondaryValidFromHelperText, setSecondaryValidFromHelperText] = useState<string>("");

  let countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
  if (!countriesData) {
    countriesData = GetCountriesData();
  }

  const {
    docGroupListState,
    docGroupSelection,
    handleDocGroupChange,
    documentDefinitionData,
    identityDocValues,
    setIdentityDocValues,
    documentTypeFields,
    handleRemoveSavedFile,
    removeDocument,
    pushFiles,
    handleEditSaveCallbackDoc,
    setDocumentDefinitionData,
    GetDocumentGroupList,
    GetDocumentGroupSelectedLabels,
    setDocGroupSelection,
    SendPutDocumentsData,
    isPrimaryFormValid,
    setIsPrimaryFormValid,
    isPrimaryFileExist,
    isSecondaryFormValid,
    setIsSecondaryFormValid,
    isSecondaryFileExist,
    isPrimarySelectSwitch,
    setIsPrimarySelectSwitch,
    isSecondarySelectSwitch,
    setIsSecondarySelectSwitch,
    isPrimarySelectSwitchMessage,
    isSecondarySelectSwitchMessage,
    setIsPrimaryFileExist,
    setIsSecondaryFileExist,
    handleDocValueChange,
    handleDocValueDateChange,
    handleDocValueSelectChange,
  } = useDocumentsContext();

  const { isCheckEditable, setLoading, complianceProcessData } = useRTWContext();

  const { getPersonalDetails, setIsPersonalInfoChanged, isPersonalInfoCompleted } = usePersonalContext();

  const handleDownloadSavedFile = (index: number) => {
    const newWindow = window.open(identityDocValues[index].url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleEditFieldChange = (id: string, title: string, val: any, mNameIdx: number) => {
    setSelectedFieldToEdit({
      selectedFieldId: id,
      selectedFieldTitle: title,
      selectedFieldValue: val,
      selectedFieldIndex: mNameIdx,
    });
  };

  const removePrimaryDocument = () => {
    removeDocument(0);
  };

  const removeSecondaryDoc = () => {
    removeDocument(1);
  };

  const pushPrimaryDoc = (files: any) => {
    setIsPrimarySelectSwitch(false);
    pushFiles(files, 0);
  };

  const pushSecondaryDoc = (files: any) => {
    setIsSecondarySelectSwitch(false);
    pushFiles(files, 1);
  };

  const onEditModalClose = () => {
    setIsEditClicked(false);
    setSelectedFieldToEdit({
      selectedFieldId: "",
      selectedFieldTitle: "",
      selectedFieldValue: "",
      selectedFieldIndex: 0,
    });
  };

  const handleConfirmSaveCallback = (val: string) => {
    setWorkerCurrNationality(val);
    setConfirmNationality(false);
    GetDocumentGroupList(val);
  };

  const handleSubmit = () => {
    let docs = _.map(identityDocValues, (doc: IDocumentDefinition) => {
      return { ...doc };
    });

    const personalData = getPersonalDetails();
    let documentsData: IDocumentDefinition[] = utilFunctions.cleanseJson(docs);

    let prepDocumentData: IComplianceProcess = {
      id: complianceProcessData!.id,
      complianceType: complianceProcessData!.complianceType.toLowerCase() === ComplianceTypes.Unkown.toLowerCase() ? 
        ComplianceTypes.IdentityAndRWAudit : complianceProcessData!.complianceType,
      processData: {
        personal: personalData!,
        documents: documentsData,
      },
    };

    setDocumentsRequest(prepDocumentData);
  };

  useEffect(() => {
    if (documentDefinitionData) {
      if (documentDefinitionData![0].identifier !== '') setIsPrimaryFormValid(false);
      if (documentDefinitionData![1].identifier !== '') setIsSecondaryFormValid(false);
    }
  });

  useEffect(() => {
    if (documentDefinitionData) {
      const primaryId = documentDefinitionData![0].identifier, 
        secondaryId = documentDefinitionData![1].identifier;
      if (primaryId !== '' && (documentDefinitionData![0].hasOwnProperty('filename') && documentDefinitionData![0].filename !== '')){
        setIsPrimaryFormValid(false);
        setIsPrimaryFileExist(true);
      } 
      if (secondaryId !== '' && (documentDefinitionData![1].hasOwnProperty('filename') && documentDefinitionData![1].filename !== '')){
        setIsSecondaryFormValid(false);
        setIsSecondaryFileExist(true);
      } 
    }
  });

  useEffect(() => {
    const primaryDocValues = identityDocValues![0];
    if (docGroupSelection!.primaryDocuments.id !== '' && docGroupSelection!.primaryDocuments.id === 'NonUKPassport') {
      if (primaryDocValues.identifier !== '' && (
          primaryDocValues.emittingCountryVal && primaryDocValues.emittingCountryVal!.hasOwnProperty('title') && primaryDocValues.emittingCountryVal!.title !== '') && (
          primaryDocValues.validFrom && primaryDocValues.validFrom.toString() !== "Invalid date") && (
          primaryDocValues.validTo && primaryDocValues.validTo.toString() !== "Invalid date") && (
          moment(primaryDocValues.validTo) > moment(primaryDocValues.validFrom)) && (
          moment(primaryDocValues.validTo) > moment()) && (
          moment(primaryDocValues.validFrom) <= moment()) &&
          isPrimaryFileExist
      ) setIsPrimaryFormValid(false);
      else setIsPrimaryFormValid(true);
    } else {
      if (primaryDocValues.identifier !== '' && (
          primaryDocValues.validFrom && primaryDocValues.validFrom.toString() !== "Invalid date") && (
          moment(primaryDocValues.validFrom) <= moment()) &&
          isPrimaryFileExist
      ) setIsPrimaryFormValid(false);
      else setIsPrimaryFormValid(true);
    }
  }, [docGroupSelection, 
    identityDocValues, 
    isPrimaryFileExist, 
    setIsPrimaryFormValid]
  )

  useEffect(() => {
    const primaryDocValues = identityDocValues![0];
      if (primaryDocValues.emittingCountryVal && 
        primaryDocValues.emittingCountryVal!.hasOwnProperty('title') && 
        primaryDocValues.emittingCountryVal!.title !== ''
      ){
        setPrimaryCountryError(false);
        setPrimaryCountryHelperText("");
      } else {
        setPrimaryCountryError(true);
        setPrimaryCountryHelperText(t("validators.required"));
      } 
  }, [identityDocValues, t]);


  useEffect(() => {
    const primaryDocValues = identityDocValues![0];

    const validTo = primaryDocValues.validTo ? primaryDocValues.validTo!.toString() : 'Invalid date';
    const validFrom = primaryDocValues.validFrom ? primaryDocValues.validFrom!.toString() : 'Invalid date';

    setIsPrimarySelectSwitch(false);

    if(primaryDocValues.validTo === null || validTo === 'Invalid date'){
      setPrimaryValidToError(true);
      setPrimaryValidToHelperText(t("errors.valid_to_msg"));
    } else if (moment(primaryDocValues.validTo) < moment()) {
      setPrimaryValidToError(true);
      setPrimaryValidToHelperText(t("errors.valid_to_lesser_now_msg"));
    } else if (moment(primaryDocValues.validFrom) > moment(primaryDocValues.validTo)) {
      setPrimaryValidToError(true);
      setPrimaryValidToHelperText(t("errors.valid_to_lesser_valid_from_msg"));
    } else {
      setPrimaryValidToError(false);
      setPrimaryValidToHelperText(t(""));
    }

    if(primaryDocValues.validFrom === null || validFrom === 'Invalid date'){
      setPrimaryValidFromError(true);
      setPrimaryValidFromHelperText(t("errors.valid_from_msg"));
    } else if (moment(primaryDocValues.validFrom) > moment()) {
      setPrimaryValidFromError(true);
      setPrimaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
    } else if (moment(primaryDocValues.validFrom) > moment(primaryDocValues.validTo)) {
      setPrimaryValidFromError(true);
      setPrimaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
    } else {
      setPrimaryValidFromError(false);
      setPrimaryValidFromHelperText(t(""));
    }

  }, [identityDocValues, isPrimarySelectSwitch, setIsPrimarySelectSwitch, t]);



  useEffect(() => {
    const secondaryDocValues = identityDocValues![1];

    if (docGroupSelection!.secondaryDocuments.id !== '' && (
      docGroupSelection!.secondaryDocuments.id === 'UkPhotoDriverLicence' ||
      docGroupSelection!.secondaryDocuments.id === 'PaperDriverLicence' ||
      docGroupSelection!.secondaryDocuments.id === 'NationalId' ||
      docGroupSelection!.secondaryDocuments.id === 'UkPassport' ||
      docGroupSelection!.secondaryDocuments.id === 'NonUkPhotoDriverLicence')
    ){
      if (secondaryDocValues.identifier !== '' && (
        secondaryDocValues.emittingCountryVal && secondaryDocValues.emittingCountryVal!.hasOwnProperty('title') && secondaryDocValues.emittingCountryVal!.title !== '') && (
        secondaryDocValues.validFrom && secondaryDocValues.validFrom.toString() !== "Invalid date") && (
        secondaryDocValues.validTo && secondaryDocValues.validTo.toString() !== "Invalid date") && (
        moment(secondaryDocValues.validTo) > moment(secondaryDocValues.validFrom)) && (
        moment(secondaryDocValues.validTo) > moment()) &&
        isSecondaryFileExist
      ) setIsSecondaryFormValid(false);
      else setIsSecondaryFormValid(true);
    } else if (docGroupSelection!.secondaryDocuments.id === 'SettledStatusShareCode') {
      if (secondaryDocValues.identifier !== '' && isSecondaryFileExist) setIsSecondaryFormValid(false);
      else setIsSecondaryFormValid(true);
    } else {
      if (secondaryDocValues.identifier !== '' && (
        secondaryDocValues.validFrom && secondaryDocValues.validFrom.toString() !== "Invalid date")  && (
        moment(secondaryDocValues.validFrom) <= moment()) &&
        isSecondaryFileExist
      ) setIsSecondaryFormValid(false);
      else setIsSecondaryFormValid(true);
    }

  }, [docGroupSelection, 
    identityDocValues, 
    isSecondaryFileExist, 
    setIsSecondaryFormValid
  ])

  useEffect(() => {
    const secondaryDocValues = identityDocValues![1];
      if (secondaryDocValues.emittingCountryVal && 
        secondaryDocValues.emittingCountryVal!.hasOwnProperty('title') && 
        secondaryDocValues.emittingCountryVal!.title !== ''
      ){
        setSecondaryCountryError(false);
        setSecondaryCountryHelperText("");
      } else {
        setSecondaryCountryError(true);
        setSecondaryCountryHelperText(t("validators.required"));
      } 
  }, [identityDocValues, t]);


  useEffect(() => {
    const secondaryDocValues = identityDocValues![1];

    const validTo = secondaryDocValues.validTo ? secondaryDocValues.validTo!.toString() : 'Invalid date';
    const validFrom = secondaryDocValues.validFrom ? secondaryDocValues.validFrom!.toString() : 'Invalid date';

    setIsSecondarySelectSwitch(false);

    if(secondaryDocValues.validTo === null || validTo === 'Invalid date'){
      setSecondaryValidToError(true);
      setSecondaryValidToHelperText(t("errors.valid_to_msg"));
    } else if (moment(secondaryDocValues.validTo) < moment()) {
      setSecondaryValidToError(true);
      setSecondaryValidToHelperText(t("errors.valid_to_lesser_now_msg"));
    } else if (moment(secondaryDocValues.validFrom) > moment(secondaryDocValues.validTo)) {
      setSecondaryValidToError(true);
      setSecondaryValidToHelperText(t("errors.valid_to_greater_valid_from_msg"));
    } else {
      setSecondaryValidToError(false);
      setSecondaryValidToHelperText(t(""));
    }

    if(secondaryDocValues.validFrom === null || validFrom === 'Invalid date'){
      setSecondaryValidFromHError(true);
      setSecondaryValidFromHelperText(t("errors.valid_from_msg"));
    } else if (moment(secondaryDocValues.validFrom) > moment()) {
      setSecondaryValidFromHError(true);
      setSecondaryValidFromHelperText(t("errors.valid_from_greater_now_msg"));
    } else if (moment(secondaryDocValues.validFrom) > moment(secondaryDocValues.validTo)) {
      setSecondaryValidFromHError(true);
      setSecondaryValidFromHelperText(t("errors.valid_from_greater_valid_to_msg"));
    } else {
      setSecondaryValidFromHError(false);
      setSecondaryValidFromHelperText(t(""));
    }

  }, [identityDocValues, isSecondarySelectSwitch, setIsSecondarySelectSwitch, t]);

  useEffect(() => {
    let docDefData: IDocumentDefinition[];
    let profileDtls: IProfile = LocalStorageService.getData(
      Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
    );
    if (complianceProcessData?.processData && complianceProcessData.processData.documents) {
      docDefData = complianceProcessData!.processData.documents;
      setDocumentDefinitionData(docDefData);
    } else {
      if (profileDtls && profileDtls.documents) {
        docDefData = profileDtls.documents;
        setDocumentDefinitionData(docDefData);
      } else {
        docDefData = InitialDocValues;
        setDocumentDefinitionData(InitialDocValues);
      }
    }

    let dataToUpdate = docDefData;
    let valuesToUpdate = identityDocValues;
    let docGrp1, docGrp2;

    if (dataToUpdate?.[0] && dataToUpdate[0].documentId! !== "") {
      if (docGroupListState?.primaryDocuments && docGroupListState.primaryDocuments.length > 0) {
        const docGrp = docGroupListState.primaryDocuments.find(
          (d: InfoTypes.DocumentGroupDefinition) =>
            d.id.toLowerCase() === dataToUpdate![0].documentId.toLowerCase()
        );

        if (docGrp) {
          docGrp1 = docGrp;
          GetDocumentGroupSelectedLabels("primaryDocuments", docGrp1.type!);
        }

        if (dataToUpdate[0].emittingCountry && dataToUpdate[0].emittingCountry !== "") {
          let cntry = countriesData.find(
            (c: InfoTypes.Country) =>
              c.id.toLowerCase() === dataToUpdate![0].emittingCountry.toLowerCase()
          );

          if (cntry) {
            dataToUpdate[0].emittingCountryVal = {
              id: cntry.id,
              title: cntry.title,
              value: cntry.value,
            };
          }
        }

        if (dataToUpdate[0].url && dataToUpdate[0].url !== "") {
          dataToUpdate[0].docFiles = [{ uri: dataToUpdate[0].url }];
        }

        valuesToUpdate[0] = dataToUpdate[0];
      }
    }

    if (dataToUpdate?.[1] && dataToUpdate[1].documentId! !== "") {
      if (
        docGroupListState?.secondaryDocuments &&
        docGroupListState.secondaryDocuments.length > 0
      ) {
        const docGrp = docGroupListState.secondaryDocuments.find(
          (d: InfoTypes.DocumentGroupDefinition) =>
            d.id.toLowerCase() === dataToUpdate![1].documentId.toLowerCase()
        );

        if (docGrp) {
          docGrp2 = docGrp;
          GetDocumentGroupSelectedLabels("secondaryDocuments", docGrp2.type!);
        }

        if (dataToUpdate[1].emittingCountry && dataToUpdate[1].emittingCountry !== "") {
          let cntry2 = countriesData.find(
            (c: InfoTypes.Country) =>
              c.id.toLowerCase() === dataToUpdate![1].emittingCountry.toLowerCase()
          );

          if (cntry2) {
            dataToUpdate[1].emittingCountryVal = {
              id: cntry2.id,
              title: cntry2.title,
              value: cntry2.value,
            };
          }
        }

        if (dataToUpdate[1].url && dataToUpdate[1].url !== "") {
          dataToUpdate[1].docFiles = [{ uri: dataToUpdate[1].url }];
        }
        valuesToUpdate[1] = dataToUpdate[1];
      }
    }

    let docGrpSelected: InfoTypes.DocumentGroupDefinitionSelection = InitialDocGroupState;
    if (docGrp1) {
      docGrpSelected.primaryDocuments = docGrp1;
    }

    if (docGrp2) {
      docGrpSelected.secondaryDocuments = docGrp2;
    }

    setDocGroupSelection(docGrpSelected);
    setDocumentDefinitionData(valuesToUpdate);
    setIdentityDocValues(valuesToUpdate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docGroupListState]);

  useEffect(() => {
    setLoading(true);

    let profileDtls: IProfile = LocalStorageService.getData(
      Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
    );

    if (complianceProcessData?.processData) {
      if (
        complianceProcessData.processData.personal?.currentNationality &&
        complianceProcessData.processData.personal.currentNationality !== ""
      ) {
        setWorkerCurrNationality(complianceProcessData.processData.personal.currentNationality!);
      } else if (
        profileDtls?.personal?.currentNationality &&
        profileDtls.personal.currentNationality !== ""
      ) {
        setWorkerCurrNationality(profileDtls.personal.currentNationality!);
      } else {
        setWorkerCurrNationality("");
      }
    } else {
      setDocumentDefinitionData(InitialDocValues);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceProcessData]);

  useEffect(() => {
    setDocumentDefinitionData(identityDocValues);
    setIdentityDocValues(identityDocValues);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeFields]);

  useEffect(() => {
    if (workerCurrNationality !== "") {
      setConfirmNationality(false);
      GetDocumentGroupList(workerCurrNationality!);
    } else {
      setConfirmNationality(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerCurrNationality]);

  useEffect(() => {
    async function SendPutDocumentsDataRequest() {
      if (documentsRequest) {
        setIsPersonalInfoChanged(false);
        await SendPutDocumentsData(documentsRequest);
      }
    }

    SendPutDocumentsDataRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsRequest]);

  return (
    <>
      <RTWPersonalInfo />
      {docGroupListState && docGroupListState.primaryDocuments.length > 0 && (
        <div className="verify-identity-body-container-details-row">
          <div className="verify-identity-body-container-details-row-content">
            <div className="docGrpControl">
              <GenericSelect
                id="primaryDocs"
                value={docGroupSelection!.primaryDocuments}
                dataSet={docGroupListState!.primaryDocuments}
                fullWidth={true}
                handleChange={handleDocGroupChange("primaryDocuments")}
                multiple={false}
                disabled={!isCheckEditable}
                freeSolo={false}
                label={t("verify_identity.primary_id_docs")}
                renderChip={false}
                name="Document1"
                error={(documentDefinitionData && documentDefinitionData[0].documentId === '') && !isPrimarySelectSwitchMessage}
              />
              {((documentDefinitionData && documentDefinitionData[0].documentId === '') && 
                (!isPrimarySelectSwitchMessage && isCheckEditable)) && <div
                className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                id="genericSelect1HelperText"
              >
                {t('errors.document_msg')}
              </div>}
            </div>
          </div>

          {docGroupSelection.primaryDocuments && docGroupSelection.primaryDocuments.id !== "" ? (
            <>
              <div className="verify-identity-body-container-details-row-content">
                <div className="doc-value-fields">
                  <div className="oneHalfWidth uploader-win">
                    {documentDefinitionData &&
                    documentDefinitionData[0] &&
                    (documentDefinitionData[0].filename || documentDefinitionData[0].url) ? (
                      // <DocViewer pluginRenderers={DocViewerRenderers} key={"primaryDocFile"} documents={documentDefinitionData[0].docFiles} /> : ""
                      <div className="file-list-container">
                        <div
                          className="file-status-bar"
                          key={"primaryDocList"}
                        >
                          <div>
                            <div className="file-type-logo">
                              <CheckCircleIcon
                                htmlColor={"#0da931"}
                                style={{ margin: "10px" }}
                              />
                            </div>
                            <span
                              style={{ cursor: "pointer" }}
                              className={`file-name `}
                              onClick={() => {
                                handleDownloadSavedFile(0);
                              }}
                            >
                              {identityDocValues[0].filename}
                            </span>
                          </div>
                          {isCheckEditable && <div
                            className="file-remove"
                            onClick={() => {
                              handleRemoveSavedFile(0);
                            }}
                          >
                            <CancelIcon
                              htmlColor={"#ff5e00"}
                              style={{ margin: "10px" }}
                            />
                          </div>}
                        </div>
                      </div>
                    ) : (
                      <Uploader
                        key={"primary-document-uploader"}
                        pushFiles={pushPrimaryDoc}
                        supportMessage={`Only .pdf, .jpg, .png formats. Max size 20Mb`}
                        maxNumberOfFiles={1 - allAttachments?.length}
                        sizeOfEachFile={Constants.DOC_UPLOAD.MAX_FILE_SIZE}
                        maxSize={Constants.DOC_UPLOAD.MAX_PACKAGE_SIZE}
                        fileAcceptType={Constants.DOC_UPLOAD.FILE_ACCESS_TYPES}
                        disabled={!isCheckEditable}
                        validTypes={Constants.DOC_UPLOAD.VALID_TYPES}
                        minContainerHeight="120px"
                        minFileHeight={40}
                        removeFile={removePrimaryDocument}
                        isPrimarySelectSwitch={isPrimarySelectSwitch}
                      />
                    )}
                  </div>

                  <div className="oneHalfWidth">
                    {documentTypeFields?.primaryDocuments?.identifierText &&
                    documentTypeFields.primaryDocuments.identifierText !== "" ? (
                      <TextEditor
                        fieldName="Document1Identifier"
                        value={documentDefinitionData![0].identifier}
                        editValue={identityDocValues![0].identifier}
                        label={documentTypeFields.primaryDocuments.identifierText}
                        type="text"
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueChange("identifier", 0)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "identifier",
                            documentTypeFields!.primaryDocuments!.identifierText!,
                            documentDefinitionData![0].identifier,
                            0
                          );
                          setIsEditClicked(true);
                        }}
                        error={utilFunctions.handleErrors(
                          identityDocValues![0].identifier, 
                          '',
                          'text'
                        )}
                        helperText={utilFunctions.handleHelperText(
                          identityDocValues![0].identifier, 
                          '',
                          'text'
                        )}
                      />
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.primaryDocuments?.emittingCountryText &&
                    documentTypeFields.primaryDocuments.emittingCountryText !== "" ? (
                      <>
                        <SelectEditor
                          id="emmitingCountry1"
                          value={documentDefinitionData![0].emittingCountryVal?.title}
                          editValue={identityDocValues![0].emittingCountryVal!}
                          label={documentTypeFields.primaryDocuments.emittingCountryText}
                          showEdit={isCheckEditable}
                          isCheckEditable={isCheckEditable}
                          dataSet={countriesData}
                          handleChange={handleDocValueSelectChange("emittingCountry", 0)}
                          onEditClicked={(e: EventTarget) => {
                            handleEditFieldChange(
                              "emittingCountry",
                              documentTypeFields!.primaryDocuments!.emittingCountryText!,
                              documentDefinitionData![0].emittingCountryVal,
                              0
                            );
                            setIsEditClicked(true);
                          }}
                          error={primaryCountryError}
                        />
                        {(documentDefinitionData && documentDefinitionData[0].emittingCountryVal?.title === '') && <p
                          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                          style={{color: 'rgb(209, 0, 0)', margin: '-6px 0 10px 25px'}}
                          id="emmitingCountry1HelperText"
                        >
                          {primaryCountryHelperText}
                        </p>}
                      </>
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.primaryDocuments?.emittingEntityText &&
                    documentTypeFields.primaryDocuments.emittingEntityText !== "" ? (
                      <TextEditor
                        fieldName={"emittingEntity1"}
                        value={documentDefinitionData![0].emittingEntity}
                        editValue={identityDocValues![0].emittingEntity}
                        label={documentTypeFields.primaryDocuments.emittingEntityText}
                        type="text"
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueChange("emittingEntity", 0)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "emittingEntity",
                            documentTypeFields!.primaryDocuments!.emittingEntityText!,
                            documentDefinitionData![0].emittingEntity,
                            0
                          );
                          setIsEditClicked(true);
                        }}
                        error={utilFunctions.handleErrors(
                          identityDocValues![0].emittingEntity, 
                          '',
                          'text'
                        )}
                        helperText={utilFunctions.handleHelperText(
                          identityDocValues![0].emittingEntity, 
                          '',
                          'text'
                        )}
                      />
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.primaryDocuments?.dateFromText &&
                    documentTypeFields.primaryDocuments.dateFromText !== "" ? (
                      <DateEditor
                        value={documentDefinitionData![0].validFrom}
                        editValue={identityDocValues[0].validFrom}
                        label={documentTypeFields.primaryDocuments.dateFromText}
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueDateChange("validFrom", 0)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "validFrom",
                            documentTypeFields!.primaryDocuments!.dateFromText!,
                            documentDefinitionData![0].validFrom,
                            0
                          );
                          setIsEditClicked(true);
                        }}
                        error={primaryValidFromError}
                        helperText={primaryValidFromHelperText}
                      />
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.primaryDocuments?.dateToText &&
                    documentTypeFields.primaryDocuments.dateToText !== "" ? (
                      <DateEditor
                        value={documentDefinitionData![0].validTo}
                        editValue={identityDocValues[0].validTo}
                        label={documentTypeFields.primaryDocuments.dateToText}
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueDateChange("validTo", 0)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "validTo",
                            documentTypeFields!.primaryDocuments!.dateToText!,
                            documentDefinitionData![0].validTo,
                            0
                          );
                          setIsEditClicked(true);
                        }}
                        error={primaryValidToError}
                        helperText={primaryValidToHelperText}
                        enableFuture={true}
                        disablePast={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}

      {docGroupListState && docGroupListState.secondaryDocuments.length > 0 && (
        <div className="verify-identity-body-container-details-row">
          <div className="verify-identity-body-container-details-row-content">
            <div className="docGrpControl">
              <GenericSelect
                id="secondaryDocs"
                value={docGroupSelection!.secondaryDocuments}
                dataSet={docGroupListState!.secondaryDocuments}
                fullWidth={true}
                handleChange={handleDocGroupChange("secondaryDocuments")}
                disabled={!isCheckEditable}
                multiple={false}
                freeSolo={false}
                label={t("verify_identity.trusted_gov_docs")}
                renderChip={false}
                name="Document2"
                error={(documentDefinitionData && documentDefinitionData[1].documentId === '') && !isSecondarySelectSwitchMessage}
              />
              {((documentDefinitionData && documentDefinitionData[1].documentId === '') && 
              (!isSecondarySelectSwitchMessage && isCheckEditable)) && <div
                className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                id="genericSelect2HelperText"
              >
                {t('errors.document_msg')}
              </div>}
            </div>
          </div>

          {docGroupSelection.secondaryDocuments &&
          docGroupSelection.secondaryDocuments.id !== "" ? (
            <>
              <div className="verify-identity-body-container-details-row-content">
                <div className="doc-value-fields">
                  <div className="oneHalfWidth uploader-win">
                    {documentDefinitionData &&
                    documentDefinitionData[1] &&
                    (documentDefinitionData[1].filename || documentDefinitionData[1].url) ? (
                      <div className="file-list-container">
                        <div
                          className="file-status-bar"
                          key={"primaryDocList"}
                        >
                          <div>
                            <div className="file-type-logo">
                              <CheckCircleIcon
                                htmlColor={"#0da931"}
                                style={{ margin: "10px" }}
                              />
                            </div>
                            <span
                              style={{ cursor: "pointer" }}
                              className={`file-name `}
                              onClick={() => {
                                handleDownloadSavedFile(1);
                              }}
                            >
                              {identityDocValues[1].filename}
                            </span>
                          </div>
                          {isCheckEditable && <div
                            className="file-remove"
                            onClick={() => {
                              handleRemoveSavedFile(1);
                            }}
                          >
                            <CancelIcon
                              htmlColor={"#ff5e00"}
                              style={{ margin: "10px" }}
                            />
                          </div>}
                        </div>
                      </div>
                    ) : (
                      <Uploader
                        key={"secondary-document-uploader"}
                        pushFiles={pushSecondaryDoc}
                        supportMessage={`Only .pdf, .jpg, .png formats. Max size 20Mb`}
                        maxNumberOfFiles={1 - allAttachments?.length}
                        sizeOfEachFile={Constants.DOC_UPLOAD.MAX_FILE_SIZE}
                        maxSize={Constants.DOC_UPLOAD.MAX_PACKAGE_SIZE}
                        fileAcceptType={Constants.DOC_UPLOAD.FILE_ACCESS_TYPES}
                        disabled={!isCheckEditable}
                        validTypes={Constants.DOC_UPLOAD.VALID_TYPES}
                        minContainerHeight="120px"
                        minFileHeight={40}
                        removeFile={removeSecondaryDoc}
                        isSecondarySelectSwitch={isSecondarySelectSwitch}
                      />
                    )}
                  </div>

                  <div className="oneHalfWidth">
                    {documentTypeFields?.secondaryDocuments?.identifierText &&
                    documentTypeFields.secondaryDocuments.identifierText !== "" ? (
                      <TextEditor
                        fieldName={"identifierText2"}
                        value={documentDefinitionData![1].identifier}
                        editValue={identityDocValues![1].identifier}
                        label={documentTypeFields.secondaryDocuments.identifierText}
                        type="text"
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueChange("identifier", 1)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "identifier",
                            documentTypeFields!.secondaryDocuments!.identifierText!,
                            documentDefinitionData![1].identifier,
                            1
                          );
                          setIsEditClicked(true);
                        }}
                        error={utilFunctions.handleErrors(
                          identityDocValues![1].identifier, 
                          '',
                          'text'
                        )}
                        helperText={utilFunctions.handleHelperText(
                          identityDocValues![1].identifier, 
                          '',
                          'text'
                        )}
                      />
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.secondaryDocuments?.emittingCountryText &&
                    documentTypeFields.secondaryDocuments.emittingCountryText !== "" ? (
                      <>
                        <SelectEditor
                          id="emittingCountry2"
                          value={documentDefinitionData![1].emittingCountryVal?.title}
                          editValue={identityDocValues![1].emittingCountryVal!}
                          label={documentTypeFields.secondaryDocuments.emittingCountryText}
                          showEdit={isCheckEditable}
                          isCheckEditable={isCheckEditable}
                          dataSet={countriesData}
                          handleChange={handleDocValueSelectChange("emittingCountry", 1)}
                          onEditClicked={(e: EventTarget) => {
                            handleEditFieldChange(
                              "emittingCountry",
                              documentTypeFields.secondaryDocuments!.emittingCountryText!,
                              documentDefinitionData![1].emittingCountryVal,
                              1
                            );
                            setIsEditClicked(true);
                          }}
                          error={secondaryCountryError}
                        />
                        {(documentDefinitionData && documentDefinitionData[1].emittingCountryVal?.title === '') && <p
                          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense" 
                          style={{color: 'rgb(209, 0, 0)', margin: '-6px 0 10px 25px'}}
                          id="emmitingCountry2HelperText"
                        >
                          {secondaryCountryHelperText}
                        </p>}
                    </>
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.secondaryDocuments?.emittingEntityText &&
                    documentTypeFields.secondaryDocuments.emittingEntityText !== "" ? (
                      <TextEditor
                        fieldName="emittingEntity2"
                        value={documentDefinitionData![1].emittingEntity}
                        editValue={identityDocValues![1].emittingEntity}
                        label={documentTypeFields.secondaryDocuments.emittingEntityText}
                        type="text"
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueChange("emittingEntity", 1)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "emittingEntity",
                            documentTypeFields!.secondaryDocuments!.emittingEntityText!,
                            documentDefinitionData![1].emittingEntity,
                            1
                          );
                          setIsEditClicked(true);
                        }}
                        error={utilFunctions.handleErrors(
                          identityDocValues![1].emittingEntity, 
                          '',
                          'text'
                        )}
                        helperText={utilFunctions.handleHelperText(
                          identityDocValues![1].emittingEntity, 
                          '',
                          'text'
                        )}
                      />
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.secondaryDocuments?.dateFromText &&
                    documentTypeFields.secondaryDocuments.dateFromText !== "" ? (
                      <DateEditor
                        value={documentDefinitionData![1].validFrom}
                        editValue={identityDocValues[1].validFrom}
                        label={documentTypeFields.secondaryDocuments.dateFromText}
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueDateChange("validFrom", 1)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "validFrom",
                            documentTypeFields!.secondaryDocuments!.dateFromText!,
                            documentDefinitionData![1].validFrom,
                            1
                          );
                          setIsEditClicked(true);
                        }}
                        error={secondaryValidFromError}
                        helperText={secondaryValidFromHelperText}
                      />
                    ) : (
                      ""
                    )}

                    {documentTypeFields?.secondaryDocuments?.dateToText &&
                    documentTypeFields.secondaryDocuments.dateToText !== "" ? (
                      <DateEditor
                        value={documentDefinitionData![1].validTo}
                        editValue={identityDocValues[1].validTo}
                        label={documentTypeFields.secondaryDocuments.dateToText}
                        showEdit={isCheckEditable}
                        isCheckEditable={isCheckEditable}
                        onChangeValue={handleDocValueDateChange("validTo", 1)}
                        onEditClicked={(e: EventTarget) => {
                          handleEditFieldChange(
                            "validTo",
                            documentTypeFields!.secondaryDocuments!.dateToText!,
                            documentDefinitionData![1].validTo,
                            1
                          );
                          setIsEditClicked(true);
                        }}
                        error={secondaryValidToError}
                        helperText={secondaryValidToHelperText}
                        enableFuture={true}
                        disablePast={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="verify-identity-body-container-details-row-content"></div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
      <div className="verify-identity-body-container-details-row">
        <div className="container-submit-btn">
          {isCheckEditable ? (
            <GenericButton
              onClick={handleSubmit}
              disabled={isPrimaryFormValid || 
                isSecondaryFormValid ||
                !isPrimaryFileExist ||
                !isSecondaryFileExist ||
                !isPersonalInfoCompleted
              }
              type="primary"
              buttonText={t("common.accept")}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {isEditClicked ? (
        <EditInfoModal
          isEditIconClicked={isEditClicked}
          selectedFieldToEdit={selectedFieldToEdit}
          prevInfoState={identityDocValues}
          onCloseEditModal={onEditModalClose}
          onInfoSaveCallback={handleEditSaveCallbackDoc}
        />
      ) : (
        ""
      )}
      {confirmNationality ? (
        <PromptNationality
          processData={complianceProcessData!}
          confirmNationality={confirmNationality}
          onInfoSaveCallback={handleConfirmSaveCallback}
        />
      ) : (
        ""
      )}
    </>
  );
}
