import Drawer from "@material-ui/core/Drawer";
import MenuItemsList from "./MenuItemsList";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useDrawerContext } from "../../Contexts/drawer-context";
import "./index.scss";

const drawerWidth = 282;
const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 20, // keep right padding when drawer closed
  },
  toolbarIcon: {
    justifyContent: "center",
    alignItems: "center",
    height: "70px"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    border: "none",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    border: "none",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
}));

const CustomDrawer = () => {
  const classes = useStyles();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Drawer
      variant={isLargeScreen ? "permanent" : "temporary"}
      classes={{
        paper: clsx(classes.drawerPaper, !isOpened && classes.drawerPaperClose),
      }}
      open={isOpened}
      onClose={() => toggleIsOpened(!isOpened)}
    >
      <MenuItemsList />
    </Drawer>
  );
};

export default CustomDrawer;
