import React from "react";
import "./index.scss";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandMore";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import GenericButton from "../GenericButton";
import Heading from "../Heading";
import { isMobile } from 'react-device-detect';

interface AccordionProps {
  open: boolean;
  headingTitle: string;
  children?: React.ReactElement;
  className?: string;
  modalWidth?: string;
  modalHeight?: string;
  modalMargin?: string;
  modalTop?: string;
  unmountOnExit?: boolean;
  enableEdit?: boolean;
  editClicked?: (e: boolean) => any;
  showEditButton?: boolean;
  editButtonText?: string;
  editButtonClicked?: () => void;
  buttonStyle?: React.CSSProperties;
  modalstyle?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: (isMobile) ? "300px" : "1150px",
      borderRadius: "4px",
      border: "none",
      margin: "8px !important",
      backgroundColor: "trasnparent",
      "&.MuiPaper-root": {
        background: "transparent",
        border: "none"
      },
      "&.MuiAccordion-root": {
        borderRadius: "none"
      },
      "&.MuiSvgIcon-root": {
        color: "rgba(4, 45, 118, 1)",
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
    },

    ".MuiAccordion-root": {
      borderRadius: "4px !important",
    },
    ".Accordion-heading": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    ".edit-icon": {
      margin: "5px 10px",
    },
    ".expand-icon": {
      borderRight: "1px solid rgb(235, 237, 244)",
    },
  })
);

export const AccordionBar: React.FC<AccordionProps> = (props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>(
    props.open ? "panel1" : false
  );

  const [showButton, setShowButton] = React.useState<boolean>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root} style={props.modalstyle}>
      <Accordion
        id="accordion-wrapper"
        TransitionProps={{ unmountOnExit: props.unmountOnExit }}
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className={classes[".MuiAccordion-root"]}
      >
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <ExpandMoreIcon htmlColor={"rgba(45, 127, 249, 1)"}/> : <ExpandLessIcon htmlColor={"rgba(45, 127, 249, 1)"}/> }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={
              props.enableEdit
                ? `${classes[".Accordion-heading"]} ${classes[".expand-icon"]}`
                : classes[".Accordion-heading"]
            }
          >
            <div className="heading-title">

            <Heading
              title={props.headingTitle}
              withAsterisk={false}
            ></Heading>
            {/* <div className="horizontal-line"></div> */}
            <hr />
            </div>
            <div>
              {props.showEditButton && showButton && (
                <GenericButton
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (props.editButtonClicked) {
                      props.editButtonClicked();
                    }
                  }}
                  disabled={false}
                  type={"secondary"}
                  buttonText={props.editButtonText}
                  style={props.buttonStyle}
                />
              )}

              {props.enableEdit && (
                <EditOutlinedIcon
                  htmlColor={"rgba(4, 45, 118, 1)"}
                  className={classes[".edit-icon"]}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (props.editClicked) {
                      props.editClicked(true);
                      setShowButton(!showButton);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ minHeight: "100px" }}>
          <>{props.children}</>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionBar;