import { useTranslation } from "react-i18next";
import Heading from "../../Components/Heading";
import { useIDCheckContext } from "../../Contexts/idCheck-context";
import YotiIDV from "../Yoti/YotiIDV";

export default function IDVerifyIdentity() {
    const { t } = useTranslation();

    const { 
        complianceProcessData,
        isCheckEditable,
        idVerifyIdentityContent,
    } = useIDCheckContext();

  return (
    <div id="verify-identity" className="verify-identity editor-component">
        <div className="verify-identity-body">
            <div className="verify-identity-body-container">
                <Heading
                    title={
                        idVerifyIdentityContent &&
                        idVerifyIdentityContent!.title !== ""
                        ? idVerifyIdentityContent!.title
                        : t("id_check.id_check_identity_verification")
                    }
                />
                <div className="verify-identity-body-container-details-row">
                    <div className="verify-identity-body-container-details-row-content">
                        {idVerifyIdentityContent!.content !== "" ? (
                        <div
                            className="editor-component-body"
                            dangerouslySetInnerHTML={{
                            __html: idVerifyIdentityContent!.content.replace(
                                /(<? *script)/gi,
                                "illegalscript"
                            ),
                            }}
                        ></div>
                        ) : (
                        ""
                        )}
                    </div>
                </div>
                <div className="verify-identity-body-container-details-row">
                    <div className="verify-identity-body-container-details-row-content">
                        <YotiIDV
                            isCheckEditable={isCheckEditable}
                            onInfoSaveCallback={() => {}}
                            complianceProcessData={complianceProcessData!}
                            buttonMessage={t("id_check.yoti_button_text")}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
