import _ from "lodash";
import { createContext, useState, useContext, Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Constants from "../Constants/constants";
import { ComplianceTypes, ImportStatus } from "../Constants/enums";
import IComplianceContent from "../interfaces/IComplianceContent";
import IComplianceProcess from "../interfaces/IComplianceProcess";
import IFeedbackRating from "../interfaces/IFeedbackRating";
import InfoTypes from "../interfaces/InfoTypes";
import IProfile from "../interfaces/IProfile";
import IValidationFailure from "../interfaces/IValidationFailure";
import CheckIntro from "../screens/CheckIntro";
import CheckOutro from "../screens/CheckOutro";
import RTWVerifyIdentity from "../screens/rtwCheck/RTWVerifyIdentity";
import useAPIRequest from "../Service/useAPIRequest";
import LocalStorageService from "../utils/localStorageService";
import { GetComplianceTypeUIContent } from "../utils/referenceDataService";
import utilFunctions from "../utils/util";

export type RTWContextType = {
  complianceProcessData: IComplianceProcess | undefined;
  setComplianceProcessData: Dispatch<SetStateAction<IComplianceProcess | undefined>>;
  rtwUIContent: IComplianceContent;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isCheckEditable: boolean;
  setIsCheckEditable: Dispatch<SetStateAction<boolean>>;
  stepsContainer: JSX.Element;
  handleNextStep: (updatedProcessData?: IComplianceProcess, editor?: string) => void;
  rtwPersonalInfoContent: InfoTypes.ContentDefinition;
  handleGenericError: (message?: string) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  rtwVerifyIdentityContent: InfoTypes.ContentDefinition;
  govCode: string;
  setGovCode: Dispatch<SetStateAction<string>>;
  complianceTypeSubmitData: IComplianceProcess | undefined;
  setComplianceTypeSubmitData: Dispatch<SetStateAction<IComplianceProcess | undefined>>;
  isSubmissionComplete: boolean;
  setSubmissionComplete: Dispatch<SetStateAction<boolean>>;
  isSubmissionError: boolean;
  setSubmissionError: Dispatch<SetStateAction<boolean>>;
  showError: boolean;
  setShowError: Dispatch<SetStateAction<boolean>>;
  confirmImportModalOpen: boolean;
  setConfirmImportModalOpen: Dispatch<SetStateAction<boolean>>;
  handleSaveError: (errors?: IValidationFailure[]) => void;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  isYotiRTWChoosen: boolean;
  setisYotiRTWChoosen: Dispatch<SetStateAction<boolean>>;
  isSetteledVariantChoosen: boolean;
  setIsSetteledVariantChoosen: Dispatch<SetStateAction<boolean>>;
  showManualCheck: boolean;
  setShowManualCheck: Dispatch<SetStateAction<boolean>>;
  isManualCheckChoosen: boolean;
  setIsManualCheckChoosen: Dispatch<SetStateAction<boolean>>;
  isSubmissionLaunched: boolean;
  setSubmissionLaunched: Dispatch<SetStateAction<boolean>>;
  isDocumentSubmissionComplete: boolean;
  setDocumentSubmissionComplete: Dispatch<SetStateAction<boolean>>;
};

type RTWContextProviderProps = {
  children?: React.ReactNode;
};

export const RTWContext = createContext<RTWContextType | undefined>(undefined);

const RTWContextProvider = ({ children }: RTWContextProviderProps) => {
  const { t } = useTranslation();
  const rtwUIContent = GetComplianceTypeUIContent(ComplianceTypes.IdentityAndRWAudit);
  const [complianceProcessData, setComplianceProcessData] = useState<
    IComplianceProcess | undefined
  >();
  const [, setFeedback] = useState<IFeedbackRating>();
  const [complianceTypeSubmitData, setComplianceTypeSubmitData] = useState<
    IComplianceProcess | undefined
  >(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const [isCheckEditable, setIsCheckEditable] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [govCode, setGovCode] = useState("");

  const [isSubmissionComplete, setSubmissionComplete] = useState(false);
  const [isSubmissionError, setSubmissionError] = useState(false);

  const [confirmImportModalOpen, setConfirmImportModalOpen] = useState(false);

  const [isYotiRTWChoosen, setisYotiRTWChoosen] = useState(true);
  const [isSetteledVariantChoosen, setIsSetteledVariantChoosen] = useState(false);
  const [showManualCheck, setShowManualCheck] = useState(true);
  const [isManualCheckChoosen, setIsManualCheckChoosen] = useState(false);

  const [isSubmissionLaunched, setSubmissionLaunched] = useState(false);

  const [isDocumentSubmissionComplete, setDocumentSubmissionComplete] = useState(false);

  useEffect(() => {
    async function SubmitComplianceTypeDataRequest() {
      if (complianceTypeSubmitData) {
        if (
          complianceTypeSubmitData.complianceType.toLocaleLowerCase() ===
          ComplianceTypes.IdentityAndRWAudit.toLowerCase()
        ) {
          await SubmitRTWComplianceData(complianceTypeSubmitData);
        }
      }
    }
    SubmitComplianceTypeDataRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceTypeSubmitData]);

  const SubmitRTWComplianceData = async (complianceData: IComplianceProcess) => {
    let cleansedRequestObj = utilFunctions.cleanseComplianceTypeSubmitRequestObject(complianceData);
    setSubmissionLaunched(true);
    useAPIRequest(
      Constants.API_URLS.POST_WORKER_COMPLETE_RTW_DATA_SUFFIX +
        complianceData.id +
        Constants.API_URLS.POST_WORKER_COMPLETE_DATA,
      {
        method: "POST",
        body: cleansedRequestObj,
      }
    )
      .then((res) => {
        if (res && res !== undefined) {
          if (res.ok) {
            setSubmissionError(false);
            setSubmissionComplete(true);
          } else {
            const errors: IValidationFailure[] = res;
            handleSubmitErrors(errors);
          }
        }
        setSubmissionLaunched(false);
      })
      .catch(() => {
        handleSubmitErrors();
      });
  };

  const handleSubmitErrors = (errors?: IValidationFailure[]) => {
    if (errors) {
      let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";
      _.map(errors, (err) => {
        errorMsg = errorMsg.concat("<li>", err.errorMessage.split("- ")[2], "</li>");
      });
      errorMsg.concat("</ul>");
      setSubmissionError(true);
      setSubmissionComplete(true);
      setErrorMessage(errorMsg);
    } else {
      setErrorMessage(t("errors.save_error_msg"));
    }
  };

  const handleGenericError = (message?: string) => {
    setShowError(true);
    setErrorMessage(message!);
  };

  const handleSubmitRequest = (feedback: IFeedbackRating, complianceData: IComplianceProcess) => {
    setFeedback(feedback);
    if (complianceData) {
      setComplianceTypeSubmitData(complianceData);
    }
  };

  const handleNextStep = (updatedProcessData?: IComplianceProcess, editor?: string) => {
    if (updatedProcessData) {
      if (complianceProcessData) {
        if (!complianceProcessData.processData) {
          complianceProcessData.processData = {};
        }

        let workerProfile: IProfile = LocalStorageService.getData(
          Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA
        );

        if (!workerProfile) {
          workerProfile = {};
        }

        switch (editor!.toLowerCase().trim()) {
          case Constants.COMPLIANCE_STEPS_EDITORS.INTRO.toLowerCase():
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON.toLowerCase():
            if (complianceProcessData!.complianceType === ComplianceTypes.IdentityAndRWAudit) {
              complianceProcessData.processData.personal = {
                forename: updatedProcessData.processData.personal!.forename,
                surname: updatedProcessData.processData.personal!.surname,
                dateOfBirth: updatedProcessData.processData.personal!.dateOfBirth,
                currentNationality: updatedProcessData.processData.personal!.currentNationality,
              };
            }
            complianceProcessData.processData.documents = updatedProcessData.processData.documents;
            workerProfile.documents = updatedProcessData.processData.documents;
            break;
          default:
            break;
        }

        LocalStorageService.setData(Constants.LOCAL_STORE_KEY.WORKER_PROFILE_DATA, workerProfile);
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }; //

  const rtwPersonalInfoContent =
    rtwUIContent.steps[
      rtwUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => {
        return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO;
      })
    ];

  const rtwVerifyIdentityContent =
    rtwUIContent.steps[
      rtwUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => {
        return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON;
      })
    ];

  const handleSaveError = (errors?: IValidationFailure[]) => {
    if (handleGenericError) {
      if (errors) {
        let errorMsg: string = t("errors.save_error_msg_dynamic") + "<ul>";

        _.map(errors, (err) => {
          errorMsg = errorMsg.concat("<li>", err.errorMessage.split("- ")[2], "</li>");
        });

        errorMsg.concat("</ul>");
        handleGenericError(errorMsg);
      } else {
        handleGenericError(t("errors.save_error_msg"));
      }
    }
  };

  const isCheckReadyToSubmit = () => {
    if (complianceProcessData && complianceProcessData.processData) {
      if (complianceProcessData.digitalIdentity) {
        if (complianceProcessData.digitalIdentity.importStatus === ImportStatus.Imported) {
          return false;
        } else {
          return isCheckSubmitDisabledAfterManualInput();
        }
      } else {
        return isCheckSubmitDisabledAfterManualInput();
      }
    } else {
      return true;
    }
  };

  const isCheckSubmitDisabledAfterManualInput = () => {
    if (complianceProcessData?.processData?.documents) {
      if (complianceProcessData.processData.documents[0]) {
        if (
          complianceProcessData.processData.documents[0].type === "SettledStatusShareCode" &&
          complianceProcessData.processData.documents[0].identifier !== ""
        ) {
          return false;
        } else {
          if (complianceProcessData.processData.documents.length === 2) {
            return false;
          } else {
            return true;
          }
        }
      }
    } else {
      return true;
    }
  };

  const loadStepContainer = (editor: string) => {
    if (rtwUIContent && rtwUIContent.id !== "") {
      if (
        rtwUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => {
          return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.INTRO;
        }) === -1
      ) {
        rtwUIContent.steps.splice(0, 0, {
          title: rtwUIContent.intro.title,
          content: rtwUIContent.intro.content,
          detail: rtwUIContent.intro.detail,
          editor: Constants.COMPLIANCE_STEPS_EDITORS.INTRO,
        });
      }

      if (
        rtwUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => {
          return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.OUTRO;
        }) === -1
      ) {
        rtwUIContent.steps.push({
          title: rtwUIContent.outro.title,
          content: rtwUIContent.outro.content,
          detail: rtwUIContent.outro.detail,
          editor: Constants.COMPLIANCE_STEPS_EDITORS.OUTRO,
        });
      }
    }

    switch (editor) {
      case Constants.COMPLIANCE_STEPS_EDITORS.INTRO:
        return <CheckIntro content={rtwUIContent.intro} />;
      case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON:
        return <RTWVerifyIdentity />;
      case Constants.COMPLIANCE_STEPS_EDITORS.OUTRO:
        return (
          <CheckOutro
            content={rtwUIContent.outro}
            complianceProcessData={complianceProcessData!}
            handleSubmit={handleSubmitRequest}
            isCheckDataEditable={isCheckEditable}
            isCheckSubmissionDisabled={isCheckReadyToSubmit() || false}
            isSubmissionLaunched={isSubmissionLaunched}
            isDocumentSubmissionComplete={isDocumentSubmissionComplete}
          />
        );
      default:
        return <></>;
    }
  };

  const editorString = rtwUIContent.steps[activeStep].editor!;

  const stepsContainer = loadStepContainer(editorString);

  return (
    <RTWContext.Provider
      value={{
        complianceProcessData,
        setComplianceProcessData,
        rtwUIContent,
        activeStep,
        setActiveStep,
        isCheckEditable,
        setIsCheckEditable,
        stepsContainer,
        handleNextStep,
        rtwPersonalInfoContent,
        handleGenericError,
        loading,
        setLoading,
        rtwVerifyIdentityContent,
        govCode,
        setGovCode,
        complianceTypeSubmitData,
        setComplianceTypeSubmitData,
        isSubmissionComplete,
        isSubmissionError,
        setSubmissionComplete,
        setSubmissionError,
        showError,
        setShowError,
        confirmImportModalOpen,
        setConfirmImportModalOpen,
        handleSaveError,
        errorMessage,
        setErrorMessage,
        isYotiRTWChoosen,
        setisYotiRTWChoosen,
        isSetteledVariantChoosen,
        setIsSetteledVariantChoosen,
        isManualCheckChoosen,
        setIsManualCheckChoosen,
        showManualCheck,
        setShowManualCheck,
        isSubmissionLaunched,
        setSubmissionLaunched,
        isDocumentSubmissionComplete,
        setDocumentSubmissionComplete
      }}
    >
      {children}
    </RTWContext.Provider>
  );
};

const useRTWContext = () => {
  const context = useContext(RTWContext);
  if (context === undefined) {
    throw new Error("Error");
  }
  return context;
};

export { RTWContextProvider, useRTWContext };
