import Constants from "../Constants/constants";
import CookieStorageService from "./cookieStorageService";

export default class LocalStorageService {
    static getData(key: string) {
      const data = localStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      } else {
        return null;
      }
    }
  
    static setData(key: string, data: object | string | boolean | [] | number) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  
    static removeData(key: string) {
      LocalStorageService.setData(key, "");
      localStorage.removeItem(key);
    }
  
    static removeAllData() {
      localStorage.clear();
      CookieStorageService.removeCookie(Constants.ACCESS_TOKEN);
    }
  }
  